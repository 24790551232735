import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AccountSidebar from '../components/AccountSidebar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import { useUpdateUserMutation } from '../redux/api/userApi';
import { showToast } from '../redux/features/toastSlice';


const EditAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const [updateUser, {isLoading, error, isSuccess}] = useUpdateUserMutation();
  const {user} = useSelector((state) => state.auth);
  const [showcurrentPassword, setShowcurrentPassword] = useState(false);
  const [newPassword, setNewPassword] = useState();
  const [shownewPassword, setShownewPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');

  const handleEmail = (e) => {
    const trimmedEmail = e.target.value.trim();
    setEmail(trimmedEmail);
  
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(trimmedEmail);
  
    setErrorEmail(isValidEmail ? '' : '');
    setIsEmailValid(isValidEmail);
  };
  
  const handleEmailBlur = () => {
    if (!isEmailValid && email.trim()) {
      setErrorEmail('Please enter a valid email');
    }
  };

  const handlePassowrd = (e) => {
    setPassword(e.target.value);
  };

  const handleNewpassword = (e) => {
    setNewPassword(e.target.value);

    setErrorConfirmPassword(
      e.target.value === confirmPassword ? '' : 'Passwords do not match'

    );
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);

    setErrorConfirmPassword(
      e.target.value === newPassword ? '' : 'Passwords do not match'

    );
  };


  useEffect(() => {
    if(user){
      setName(user?.name);
      setEmail(user?.email);

      const isValidEmail = validateEmail(user?.email);
      setIsEmailValid(isValidEmail);
      setErrorEmail(isValidEmail ? '' : 'Please enter a valid email');
    }
    if(error){
      dispatch(showToast(error?.data?.message || 'Something went wrong'));
    }

    if(isSuccess){
      dispatch(showToast('User has been updated successfully'));
    }

  },[user, error,isSuccess]);

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const HandleshowPassword = () => {
    setShowPassword(!showPassword);
  };

  const HandleCurrentPasswordshowandhide = () => {
    setShowcurrentPassword(!showcurrentPassword);
  };

  const HandleNewPasswordshowandhide = () => {
    setShownewPassword(!showPassword);
  };

  const isFormValid = isEmailValid && name && password && confirmPassword === newPassword;

  const handleupdateUser = (e) => {
    e.preventDefault();

    const userupdatedata = {
      'name': name,
      'email': email,
      'current_password': password,
      'new_password': newPassword

    };

    updateUser(userupdatedata);

  };

  return(
    <>
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = { `text-white text-xl lg:text-2xl text-center font-semibold uppercase ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('MyAccount') }</h3>
      </div>
      <div className = 'container mx-auto px-5 lg:px-0'>
        <div className = 'block md:flex'>
          <div className = 'w-full md:w-1/5'>
            <AccountSidebar />
          </div>
          <div className = 'w-full mt-5 md:mt-0 md:w-4/5 xl:flex-1 border border-boderColor-300 rounded'>
            <div className = 'p-5'>
              <form onSubmit = { handleupdateUser }>
                <h3 className = { `text-xl text-black font-semibold ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Accountdetails') }</h3>
                <ul>
                  <li>
                    <div className = 'w-full'>
                      <label className = { `text-sm ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Name') } <span className = 'text-red'>*</span></label>
                      <input className = 'block border border-borderColor w-full py-2 px-3 mt-1 text-sm outline-primary-600' value = { name } onChange = { (e) => setName(e.target.value) } />
                    </div>
                  </li>
                  <li className = 'mt-5'>
                    <div className = 'w-full'>
                      <label className = { `text-sm ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Emailaddress') } <span className = 'text-red'>*</span></label>
                      <input className = 'block border border-borderColor w-full py-2 px-3 mt-1 text-sm outline-primary-600' value = { email } onChange = { handleEmail } onBlur = { handleEmailBlur } />
                      { errorEmail && <p className = "text-xs text-red">{ errorEmail }</p> }
                    </div>
                  </li>
                  <li>
                    <h3 className = { `text-xl text-black font-semibold mt-5 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Passwordchange') }</h3>
                  </li>
                  <li className = 'mt-5'>
                    <div className = 'w-full relative'>
                      <label className = { `text-sm ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Currentpassword(leaveblanktoleaveunchanged)') } </label>
                      <input type = { showcurrentPassword?'text': 'password' } className = 'block border border-borderColor outline-primary-600 w-full py-2 px-3 mt-1 text-sm' value = { password }  onChange = { handlePassowrd } />
                      <p className = 'mobileposition absolute right-2 top-9'><Link to = 'javascript:void(0)' onClick = { HandleCurrentPasswordshowandhide }> { showcurrentPassword?<VisibilityOffOutlinedIcon className = 'eyecolor' />: <VisibilityOutlinedIcon className = 'eyecolor' /> }</Link></p>
                    </div>
                  </li>
                  <li className = 'mt-5'>
                    <div className = 'w-full relative'>
                      <label className = { `text-sm ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Newpassword(leaveblanktoleaveunchanged)') } </label>
                      <input type = { shownewPassword?'text': 'password' } className = 'block border border-borderColor w-full py-2 px-3 mt-1 text-sm outline-primary-600' value = { newPassword } onChange = { handleNewpassword } />
                      <p className = 'mobileposition2 absolute right-2 top-9'><Link to = 'javascript:void(0)' onClick = { HandleNewPasswordshowandhide }> { shownewPassword?<VisibilityOffOutlinedIcon className = 'eyecolor' />: <VisibilityOutlinedIcon className = 'eyecolor' /> }</Link></p>
                    </div>
                  </li>
                  <li className = 'mt-5'>
                    <div className = 'w-full relative'>
                      <label className = { `text-sm ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Confirmnewpassword') } </label>
                      <input type = { showPassword?'text' : 'password' } className = 'block border border-borderColor w-full py-2 px-3 mt-1 text-sm outline-primary-600' onChange = { handleConfirmPassword } />
                      <p className = 'absolute right-2 top-9'><Link to = 'javascript:void(0)' onClick = { HandleshowPassword }> { showPassword?<VisibilityOffOutlinedIcon className = 'eyecolor' />: <VisibilityOutlinedIcon className = 'eyecolor' /> }</Link></p>
                    </div>
                    { errorConfirmPassword && (
                      <p className = "text-xs text-red">{ errorConfirmPassword }</p>
                    ) }
                  </li>
                  <li>
                    <button className = { `inline-block py-2 px-5 text-sm font-semibold text-white uppercase mt-5 rounded hover:bg-primary-600 hover:delay-300 ${selectLanguage == 'ar'?'font-madani-arabic': ''} ${
                      isFormValid ? 'bg-primary' : 'bg-gradient-primary-light'
                    }` } disabled = { !isFormValid || isLoading }>

                      { isLoading? 'Updating...': t('SAVECHANGES') }
                    </button>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default EditAccount;