import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useBestsellerQuery } from '../redux/api/recomendedproductApi';
import Loader from './Loader';
import BestsellerProduct from './TrendingItemProduct';

const OurBestSeller = () => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const { error, data, isLoading } = useBestsellerQuery();

  const limitedProducts = data?.data?.products.slice(0, 4);


  return (
    <>
      <ToastContainer />
      <div className = 'pt-12 md:pt-20 text-center pb-5'>
        <h3 className = { `text-black text-xl text-center font-semibold uppercase ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }>
          { t('BestSellers') }
        </h3>
        <Link to = "/shop/الأكثر%20مبيعا" className = 'inline-block py-2 px-5 border border-borderColor text-xs mt-4 uppercase tracking-widest hover:border-black hover:duration-300'>View all</Link>
      </div>
      { isLoading && <Loader /> }
      { error && <p className = "text-sm text-blacklight">{ error?.data?.message }</p> }
      { data?.data?.total === 0 && (
        <p className = "text-sm text-blacklight text-center pb-16">
          { data?.data?.total === 0 ? 'No data available.' : '' }
        </p>
      ) }
     
      <div className = 'h-full'>
        { data?.data?.total > 0 && (
          <div className = "overflow-hidden h-full">
            <div
              className = "flex justify-between flex-wrap h-full">
              { limitedProducts?.map((product, index) => (
                <div className = 'w-width49% md:w-width23%' key = { product?.product_id }>
                  <BestsellerProduct product = { product } index = { index } />
                </div>
              )) }
            </div>
          </div>
        ) }
      </div>
    </>
  );
};

export default OurBestSeller;
