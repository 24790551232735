import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTrendingnowlistQuery } from '../../redux/api/recomendedproductApi';

const Trendingnow = () => {

  const selectLanguage = localStorage.getItem('selectLanguage');
  const { t } = useTranslation();

  const {error, data, isLoading} = useTrendingnowlistQuery();

  const reversedData = data?.data ? [...data.data].reverse() : [];

  return (
    <>
      <div className = 'pt-12 md:pt-20'>
        <h3 className = { `text-black text-xl text-center font-semibold uppercase pb-6 md:pb-10 ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }>
          { t('TRENDINGNOW') }
        </h3>
        { error && <p>{ error?.message }</p> }
        { isLoading && <p>Loading data...</p> }
        { reversedData.length === 0 && <p className = 'text-sm text-black text-center'>No trending data found</p> }
        <div className = 'flex flex-wrap md:flex-nowrap justify-between'>
          { reversedData.length > 0 && reversedData.map((item, index) => {
            const itemUrl = item?.url.startsWith('http') ? item.url : `/shop/${item?.url}`;
            return (
              <div
                key = { item?.id }
                className = { `w-full md:w-width32% ${index !== 0 ? 'mt-5 md:mt-0' : ''}` }
              >
                <img className = 'w-full' src = { item?.image } alt = 'trending img' />
                <h3 className = 'uppercase text-base font-semibold text-black text-center mt-3'>
                  { item?.name }
                </h3>
                <p className = 'text-black text-sm text-center mt-2'>{ item?.description }</p>
                <div className = 'text-center'>
                  <Link
                    className = 'inline-block py-2 px-5 border border-borderColor text-xs mt-4 uppercase tracking-widest hover:border-black hover:duration-300'
                    to = { itemUrl }
                  >
                    SHOP NOW
                  </Link>
                </div>
              </div>
            );
          }) }
        </div>
      </div>
    </>
  );
};

export default Trendingnow;
