


import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Brandslide from '../components/Brandslide';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import { useBrandcollectionQuery } from '../redux/api/recomendedproductApi';

const Allbrand = () => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const [brands, setBrands] = useState([]);
  const {error, data} = useBrandcollectionQuery();


  useEffect(() => {

    if(error){
      toast(error?.data?.message);
    }

  },[error]);


  
  useEffect(() => {
    const brandsData = data?.data;
    // Remove duplicates by using a Set
    const uniqueBrands = brandsData ? Array.from(new Set(brandsData.map(brand => brand.name)))
      .map(name => {
        return brandsData.find(brand => brand.name === name);
      }) : [];

    setBrands(uniqueBrands);
  }, [data]);

  const groupedBrands = brands.reduce((acc, brand) => {
    const firstLetter = brand.name.charAt(0).toUpperCase();
    if (!acc[firstLetter]) acc[firstLetter] = [];
    acc[firstLetter].push(brand);
    return acc;
  }, {});

  return(
    <>
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = { `text-white text-xl lg:text-2xl text-center font-semibold uppercase ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Allbrand') }</h3>
      </div>
      <div className = 'container mx-auto px-5 lg:px-0'>
        <div><h3 className = { `text-black text-lg lg:text-xl text-center font-bold md:font-semibold mt-12 md:mt-14 mb-4 md:mb-4 uppercase ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>FEATURED BRANDS</h3></div>
        <Brandslide />
        <ul className = 'flex flex-wrap gap-4 mt-2 md:mt-5'>
          { Object.keys(groupedBrands).sort().map(letter => (
            <li  className = 'w-full md:w-width48% lg:w-width32% mt-3' key = { letter }>
              <span className = 'inline-block bg-grey-200 text-lg px-1.5'>{ letter }</span>
              <div className = 'border-b border-grey-300'></div>
              <div className = 'flex flex-wrap gap-x-3 gap-y-2 mt-2'>
                { groupedBrands[letter].map(brand => (
                  <Link
                    key = { brand?.brand_id }
                    to = { `/brand/shop/${brand?.name}` }
                    className = ' block w-width48% text-sm text-blacklight uppercase hover:text-primary'
                  >
                    { brand?.name }
                  </Link>
                )) }
              </div>
            </li>
          )) }
        </ul>
        <Footer />
      </div>
    </>
  );
};

export default Allbrand;