import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Navigation from '../../components/Navigation';
import ProductTabforDescription from '../../components/ProductTabforDescription';
import RecentlyView from '../../components/RecentlyView';
import YoumayalsoLike from '../../components/YoumayalsoLike';
import dummyimg from '../../images/dummyimg.png';
import tabby from '../../images/tabby.png';
import tamara from '../../images/tamara.png';
import { useAddtocartMutation, useAddtowishlistMutation, useDisplayaddtowishlistQuery, useGetProductsDetailsQuery } from '../../redux/api/productsApi';
import { addItemToCart } from '../../redux/features/cartSlice';
import { setCartopen } from '../../redux/features/opencardSlice';
import { showToast } from '../../redux/features/toastSlice';
import Tabbypromo from '../tabbypayment/Tabbypromo';

const ProductDetails = () => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const useridlocal = localStorage.getItem('userid');
  const userid = useSelector((state) => state.auth?.user?.id);
  const params = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);

  const { data, isLoading, error, isError } = useGetProductsDetailsQuery(params?.id);

  const productdetails = data?.data;
  const skuValue = productdetails?.sku?.toString();
  const formattedSku = skuValue?.replace(/[^\d.-]/g, '');

  const [selectedSize, setSelectedSize] = useState('');
  const [isOutOfStock, setIsOutOfStock] = useState(false);


  useEffect(() => {
    if (productdetails?.options.length > 0) {
      setSelectedSize(productdetails.options[0].size);
      setIsOutOfStock(productdetails.options[0].stock === 0 || productdetails.options[0].stock === null);
    }
  }, [productdetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params?.id]);

  useEffect(() => {
    if (isError) {
      dispatch(showToast(error?.data?.message));
      
    }
  }, [error, params?.id]);

  const [addtocart, { error: addtocarterror, isSuccess, isLoading: addtocartisloading }] = useAddtocartMutation();

  useEffect(() => {
    if (error) {
      dispatch(showToast(error?.data?.message));
    }

    if (isSuccess) {
      dispatch(setCartopen(true));
    }
  }, [addtocarterror, isSuccess, dispatch]);

  const handleAddtoCart = (productid) => {
    
    const selectedOption = productdetails.options.find(option => option.id === productid);
  
    const productData = {
      id: productdetails.product_id,
      name: productdetails.name,
      brand: productdetails.brand,
      description: productdetails.description,
      images: productdetails.images,
      fragrance_family: productdetails.fragrance_family,
      ingredients: productdetails.ingredients,
      gender: productdetails.gender,
      model: productdetails.model,
      sku: productdetails.sku,
      scent_type: productdetails.scent_type,
      type: productdetails.type,
      launch_year: productdetails.launch_year,
      total_stock: productdetails.total_stock,
      options: selectedOption,
      quantity: 1,
      total_price: selectedOption ? selectedOption.selling_price : null
    };
  
    if (userid) {
      const data = {
        'stock_options_id': productid,
        'quantity': 1
      };
      addtocart([data]);
    } else {
      dispatch(addItemToCart(productData));
      dispatch(setCartopen(true));
    }
  };


  const {error: errorwishlist, data: datawishlist } = useDisplayaddtowishlistQuery(useridlocal, { skip: !useridlocal });
 
  useEffect(() => {

    if(errorwishlist){
      dispatch(showToast(errorwishlist?.data?.message));
    } 
    
  },[errorwishlist]);

  const [addtowishlist, { error: erroraddtowishlist, data: dataaddtowishlist, isSuccess: issuccessaddtowishlist }] = useAddtowishlistMutation();

  const handleAddtowishlist = (productid) => {
    if(userid){
      if (!datawishlist?.data.find(item => item.product_id === productid)) {
        const data = {
          'product_id': productid
        };
        addtowishlist(data);
      }else{
        dispatch(showToast('Product is already in the wishlist'));
      }
      
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    if (erroraddtowishlist) {
      dispatch(showToast(erroraddtowishlist?.data?.message));
    }

    if (issuccessaddtowishlist) {
      dispatch(showToast('Great choice! Product added to your wishlist.'));
    }
  }, [erroraddtowishlist, dataaddtowishlist, issuccessaddtowishlist]);

  const handleSizeHover = (size, index) => {
    setSelectedSize(size); // Update selected size
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  const handleItemClick = (index) => {

    setActiveIndex(index);
    // setIsOutOfStock(productdetails?.options[index]?.stock === 0 || productdetails?.options[index]?.stock === null);
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  const productPrice = productdetails?.options.find(option => option.size === selectedSize)?.selling_price;
  
  const dividedPrice = productPrice ? (productPrice / 4).toFixed(2) : 0;

  return (
    <>
      <Header />
      <Navigation />
      <div className = 'container mx-auto px-5 lg:px-0'>
        <div className = 'block lg:flex justify-between pt-6'>
          <div className = 'w-full lg:w-width55%'>
            <ul className = 'flex self-center pb-2'>
              <li>
                <Link className = { `inline-block text-grey text-sm hover:text-primary ${selectLanguage == 'ar' ? 'font-Tajawal' : ''}` } to = "/">{ t('Home') }</Link>
              </li>
              <li>
                <Link className = 'inline-block text-grey text-sm ml-2' to = "/">/</Link>
              </li>
              <li>
                <Link className = { `inline-block text-grey text-sm ml-2 hover:text-primary ${selectLanguage == 'ar' ? 'font-Tajawal' : ''}` } to = "/shop">{ t('Shop') }</Link>
              </li>
              <li>
                <Link className = 'inline-block text-grey text-sm ml-2' to = "/shop">/</Link>
              </li>
              <li>
                <span className = { `inline-block text-black text-sm ml-2 ${selectLanguage == 'ar' ? 'font-Tajawal' : ''}` }>{ t('ProductDetails') }</span>
              </li>
            </ul>
            <div className = 'flex'>
              <div className = 'w-16'>
                <ul className = 'mr-3'>
                  { data?.data?.images.length > 0 ? (
                    data?.data?.images.map((imgitem, index) => (
                      <li
                        key = { index }
                        className = { `cursor-pointer border-2 ${index !== 0 ? 'mt-3' : ''} ${
                          activeIndex === index ? 'border-primary' : 'border-borderColor'
                        }` }
                        onClick = { () => handleItemClick(index) }
                      >
                        <div className = 'cursor-pointer w-full'>
                          <img src = { imgitem } alt = { `Thumbnail ${index}` } />
                        </div>
                      </li>
                    ))
                  ) : (
                    <li className = 'mt-3 cursor-pointer border-2'>
                      <div className = 'cursor-pointer w-full'>
                        <img src = { dummyimg } alt = "" />
                      </div>
                    </li>
                  ) }
                </ul>
              </div>
              <div className = 'slideswrp flex-1'>
                <div className = 'w-full relative'>
                  <div className = 'text-primary cursor-pointer absolute top-0 right-0 mr-2 z-10'>
                    <FavoriteBorderOutlinedIcon onClick = { () => handleAddtowishlist(params?.id) } style = { { fontSize: '28px' } } />
                  </div>
                  <div className = 'text-center'>
                    <Slider
                      ref = { sliderRef }
                      dots = { false }
                      infinite = { false }
                      slidesToShow = { 1 }
                      slidesToScroll = { 1 }
                      initialSlide = { 0 } // Default to the first slide
                      className = "image-slider"
                    >
                      {
                        data?.data?.images.length > 0? data?.data?.images.map((imgitem, index) => (
                          <img 
                            key = { index } 
                            className = 'productdetailsimg' 
                            src = { imgitem } 
                            alt = { `Product Image ${index}` } 
                          />
                        )): <img 
                          className = 'productdetailsimg' 
                          src = { dummyimg } 
                        />
                      }
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className = 'w-full lg:w-width45% mt-5 lg:mt-0 ml-0 lg:ml-5'>
            {
              isLoading && <Loader />
            }
            <div>
              <div className = 'relative'>
                <Link to = { `/brand/shop/${productdetails?.brand}` } className = 'text-sm text-black font-semibold uppercase'>{ productdetails?.brand?productdetails?.brand: 'NA' }</Link>
                <h3 className = 'text-sm lg:text-base text-grey uppercase font-Tajawal'>{ productdetails?.name }</h3>
                <p className = 'text-sm text-black font-semibold mt-2'>SAR: { productdetails?.options.find(option => option.size === selectedSize)?.selling_price }</p>
                <div className = 'mt-2'>
                  <h3 className = { `text-sm text-black ${selectLanguage == 'ar' ? 'font-Tajawal' : ''}` }>{ t('Standardsize') }</h3>
                  <ul className = 'flex gap-2 mt-2'>
                    { productdetails?.options?.length > 0 && productdetails.options.some(option => option.size) ? (
                      productdetails.options.map((option, index) =>
                        option.size ? ( // Only render if size exists
                          <li
                            key = { option.id }
                            className = { `flex px-2 py-1 cursor-pointer bg-white rounded ${
                              selectedSize === option.size ? 'border-2 border-primary' : 'border border-borderColor'
                            }` }
                            onMouseEnter = { () => handleSizeHover(option.size, index) }
                          >
                            <img className = "w-5" src = { productdetails?.images?.[0] } alt = "product-small" />
                            <p className = { `text-sm text-center text-black self-center ${selectLanguage === 'ar' ? 'font-Tajawal' : ''}` }>
                              { parseInt(option.size) } ML
                            </p>
                          </li>
                        ) : null // Skip rendering if size is null
                      )
                    ) : (
                      <p className = "text-xs text-gray-500">No size available</p>
                    ) }
                  </ul>
                </div>
                <span className = 'text-sm text-black inline-block mt-3'>SKU: <span>{ formattedSku }</span></span>
                <div className = 'mt-5'>
                  <div className = 'bg-grey-50 py-1 px-2 rounded-sm'>
                    {
                      selectLanguage == 'ar'? <p className = 'text-xs text-black text-center font-Tajawal'>قسمها الى أربع دفعات متساوية { dividedPrice } ريال مع</p> : <p className = 'text-xs text-black text-center'>Divide it into four equal payments of { dividedPrice } riyals with</p>
                    }
                    
                  </div>
                  <div className = 'border border-bg-grey-50 rounded-sm flex justify-between tabbywrp'>
                    <div className = 'w-width50% border-r border-bg-grey-50 px-2 py-2 flex justify-center'>
                      <div className = 'self-center'><img className = 'w-16' src = { tabby } alt = 'tabby' /></div>
                      <Tabbypromo productPrice = { productPrice } />
                    </div>
                    <div className = 'w-width50% px-2 py-2 flex justify-center'>
                      <div className = 'self-center'><img className = 'w-16' src = { tamara } alt = 'tamara' /></div>
                      <span className = 'self-center cursor-pointer text-primary text-xs'>info</span>
                    </div>
                  </div>
                </div>
                <div className = 'mt-5'>
                  <button disabled = { isOutOfStock } onClick = { () => handleAddtoCart(productdetails?.options.find(option => option.size === selectedSize)?.id) } className = { `w-full text-white text-sm uppercase py-3 font-bold ${isOutOfStock?'bg-primary-25': 'bg-primary'} ${selectLanguage == 'ar' ? 'font-Tajawal' : ''}` }> { addtocartisloading ? 'Adding...' : t('ADDTOBAG') }</button>
                  {
                    isOutOfStock && <p className = 'text-sm text-red mt-2'>Out Of Stock</p>
                  }
                </div>
              </div>
            </div>
            <ProductTabforDescription data = { productdetails } productid = { params?.id } />
          </div>
        </div>
        <YoumayalsoLike />
        <RecentlyView />
        <Footer />
      </div>
    </>
  );
};

export default ProductDetails;
