/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import TopHeader from '../../components/TopHeader';


const Gifts = () => {

  return(
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = "container mx-auto px-5 lg:px-10">
        <div className = 'py-6'>
          <img className = 'text-center mx-auto mt-5' src = 'https://cdn.scentbird.com/headless/gh_sign_header_d_0c4d4c2768.svg' alt = 'title img' />
          <h3 className = 'text-blacklight text-4xl font-bold text-center mt-5'>TO MOM, WITH LOVE</h3>
          <p className = 'text-blacklight text-base text-center mt-5'>Treat mom (or yourself!) this Mother's Day with <br></br> luxe gifts blooming with love.</p>
        </div>
        <div className = 'flex justify-between'>
          <div className = 'w-width32%'>
            <Link to = '/gift-subscription'><img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:384/aGVhZGxlc3MvZnJhbWVfMF9kX21pbl9lYjhjNjcxOWU3LmpwZw==' /></Link>
          </div>
          <div className = 'w-width32%'>
            <Link to = '/main-subscription'><img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:384/aGVhZGxlc3MvZnJhbWVfMV9kX21pbl9lMWM5N2FlYTZlLmpwZw==' /></Link>
          </div>
          <div className = 'w-width32%'>
            <Link to = '/main-subscription'><img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:384/aGVhZGxlc3MvZnJhbWVfMl9kX21pbl9kMDFkZDRmMWNjLmpwZw==' /></Link>
          </div>
        </div>
        <div className = 'flex justify-between mt-5'>
          <div className = 'w-width32%'>
            <Link to = '#'><img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:384/aGVhZGxlc3MvZnJhbWVfM19kX21pbl9lMDMwNjdlYjAzLmpwZw==' /></Link>
          </div>
          <div className = 'w-width66%'>
            <Link to = '#'><img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:750/aGVhZGxlc3MvZnJhbWVfNF9kX21pbl9iNTU2MWMwYTUzLmpwZw==' /></Link>
          </div>
        </div>
        <div className = 'flex justify-between mt-5'>
          <div className = 'w-width32%'>
            <Link to = '/main-subscription'><img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:384/aGVhZGxlc3MvZnJhbWVfNV9kX21pbl83MjA0ZmJkZDA4LmpwZw==' /></Link>
          </div>
          <div className = 'w-width32%'>
            <Link to = '#'><img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:384/aGVhZGxlc3MvZnJhbWVfNl9kX21pbl8xYzMxYWRlZDdkLmpwZw==' /></Link>
          </div>
          <div className = 'w-width32%'>
            <Link to = '/scentbird-select'><img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:384/aGVhZGxlc3MvZnJhbWVfN19kX21pbl9mMTMzMzZkNThiLmpwZw==' /></Link>
          </div>
        </div>

        <div className = 'flex justify-between mt-5'>
          <div className = 'w-width32%'>
            <Link to = '/main-subscription'><img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:384/aGVhZGxlc3MvZnJhbWVfOF9kX21pbl9hMjhhYTJhZGJkLmpwZw==' /></Link>
          </div>
          <div className = 'w-width32%'>
            <Link to = '/gift-card'><img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:384/aGVhZGxlc3MvZnJhbWVfOV9kX21pbl9hNzM5YTJmYTZlLmpwZw==' /></Link>
          </div>
          <div className = 'w-width32%'>
            <Link to = '/brand/sisley-paris'><img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:384/aGVhZGxlc3MvZnJhbWVfMTBfZF9taW5fMzk5YTBmZTM0OS5qcGc=' /></Link>
          </div>
        </div>
        <Footer />
      </div>  
    </>
  );
};

export default Gifts;