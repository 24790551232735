/* eslint-disable no-unused-vars */
import React from 'react';
import Loader from '../../components/Loader';
import { useNewlyaddeditemQuery } from '../../redux/api/recomendedproductApi';
import NewarrivalitemProduct from './NewarrivalitemProduct';

const NewarivalItem = () => {
  const { error, data, isLoading } = useNewlyaddeditemQuery();

  const limitedProducts = data?.data?.products?.slice(0, 4);

  return (
    <>
      { isLoading && <Loader /> }
      { error && <p className = "text-sm text-blacklight">{ error?.data?.message }</p> }
      { data?.data?.length === 0 && (
        <p className = "text-sm text-blacklight text-center pb-16">
          { data?.data?.length === 0 ? 'No data available.' : '' }
        </p>
      ) }
     
      <div className = 'h-full'>
        { limitedProducts?.length > 0 && (
          <div className = "overflow-hidden h-full">
            <div
              className = "flex justify-between flex-wrap h-full">
              { limitedProducts?.map((product, index) => (
                <div className = 'w-width49% md:w-width23%' key = { product?.product_id }>
                  <NewarrivalitemProduct product = { product } index = { index } />
                </div>
              )) }
            </div>
          </div>
        ) }
      </div>

      
    </>
  );
};

export default NewarivalItem;
