/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-key */
import React from 'react';
import { Link } from 'react-router-dom';


const HowTheFamily = () => {


  return (
    <>
      <div className = 'mt-8'>
        <div className = 'block md:hidden'>
          <div className = 'w-full'>
            <img src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/ZnJvbnRiaXJkMi9pbWFnZXMvZmVtYWxlLWRlc2t0b3BfZjNlODU2LmpwZw==' alt = 'familyfragrancy' />
          </div>
          <div className = 'text-black py-5 '>
            <h3 className = 'text-2xl lg:text-4xl font-bold mt-2'>How's the family?</h3>
            <p className = 'text-sm lg:text-base font-semibold mt-4'>
                Enter your scent preferences into our quiz, and we'll<br></br>
                determine the fragrance families you gravitate towards.
            </p>
            <Link to = '#' className = 'text-sm uppercase font-semibold inline-block mt-6 bg-black text-white px-5 py-2'>Take the quiz</Link>
          </div>
        </div>
        
        <div className = "hidden md:block w-full h-fit py-28 bg-cover bg-center" style = { {backgroundImage: 'url(\'https://imgcdn.scentbird.com/_/rt:fill/w:1440/ZnJvbnRiaXJkMi9pbWFnZXMvZmVtYWxlLWRlc2t0b3BfZjNlODU2LmpwZw==\')'} }>
          <div className = 'flex'>
            <div className = 'w-3/6 relative'>
              <div className = 'text-black py-14 px-14 '>
                <h3 className = 'text-2xl lg:text-4xl font-bold mt-2'>How's the family?</h3>
                <p className = 'text-sm lg:text-base font-semibold mt-4'>
                Enter your scent preferences into our quiz, and we'll<br></br>
                determine the fragrance families you gravitate towards.
                </p>
                <Link to = '#' className = 'text-sm uppercase font-semibold inline-block mt-6 bg-black text-white px-5 py-2'>Take the quiz</Link>
              </div>
            </div>
            <div className = 'w-3/6'></div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default HowTheFamily;
