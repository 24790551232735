import React from 'react';
import { useTranslation } from 'react-i18next';
import Multilanguage from './Multilanguage';

const TopHeader = () => {
  const selectLanguage = localStorage.getItem('selectLanguage');
  const { t } = useTranslation();
    
  return(
    <>
      <div className = 'bg-grey py-0 md:py-1'>
        <div className = 'container mx-auto px-5 lg:px-10'>
          <div className = 'flex justify-between'>
            <p className = { `self-center text-xs text-white py-2 md:py-3 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('StoreauthenticationcertificatefromtheSaudiBusinessCenter️️') }</p>
            <div className = 'self-center'>
              <Multilanguage />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default TopHeader;