// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/fonts/alfont_com_Madani-Arabic-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/public/fonts/alfont_com_Madani-Arabic-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/public/fonts/Cairo-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("/public/fonts/Tajawal-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("/public/fonts/Tajawal-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("/public/fonts/Tajawal-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'MadaniArabic';

  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'MadaniArabic';

  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'Cairo';

  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');

  font-weight: normal;

  font-style: normal;
}

@font-face {
  font-family: 'Tajawal';

  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff2'),
        url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff'),
        url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype');

  font-weight: normal;

  font-style: normal;

  font-display: swap;
}`, "",{"version":3,"sources":["webpack://./src/style/fonts.css"],"names":[],"mappings":"AAAA;EACI,2BAA2B;;EAC3B,+DAAiF;;EACjF,mBAAmB;;EACnB,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;;EAC3B,+DAAkF;;EAClF,mBAAmB;;EACnB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;;EACpB,+DAA8D;;EAC9D,mBAAmB;;EACnB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;;EACtB;;kEAE+D;;EAC/D,mBAAmB;;EACnB,kBAAkB;;EAClB,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: 'MadaniArabic';\n    src: url('/public/fonts/alfont_com_Madani-Arabic-Regular.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n  }\n\n  @font-face {\n    font-family: 'MadaniArabic';\n    src: url('/public/fonts/alfont_com_Madani-Arabic-Regular.woff') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n  }\n\n  @font-face {\n    font-family: 'Cairo';\n    src: url('/public/fonts/Cairo-Regular.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n  }\n\n  @font-face {\n    font-family: 'Tajawal';\n    src: url('/public/fonts/Tajawal-Regular.woff2') format('woff2'),\n        url('/public/fonts/Tajawal-Regular.woff') format('woff'),\n        url('/public/fonts/Tajawal-Regular.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n    font-display: swap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
