/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

// Generate a single nonce for the session
const NONCE = btoa(
  String.fromCharCode(...crypto.getRandomValues(new Uint8Array(16)))
);

const PaymentForm = ({ checkoutId, orderId, integrity }) => {
  const [navigateurl, setNavigateurl] = useState(null);

  useEffect(() => {
    if (checkoutId) {
      console.log('checkoutId', checkoutId);
      const script = document.createElement('script');
      script.src = `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
      script.integrity = integrity;
      script.crossOrigin = 'anonymous';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        const secureScript = document.createElement('script');
        secureScript.type = 'text/javascript';
        secureScript.setAttribute('nonce', NONCE); // Apply the nonce
        secureScript.innerHTML = `
          var wpwlOptions = {
            paymentTarget: "_top"
          };
        `;
        document.body.appendChild(secureScript);
      };
    }
  }, [checkoutId, integrity]);

  // Function to check payment status
  const checkPaymentStatus = async () => {
    try {
      const response = await fetch(
        `https://backend-core.dimas.com.sa/api/orders/verify-payment/?checkout_id=${checkoutId}&order_id=${orderId}`
      );
      const data = await response.json();

      console.log('data', data);

      if (data?.data.status === 'Success') {
        setNavigateurl('/payment-status');
      } else {
        setNavigateurl('/payment-failed');
      }
    } catch (error) {
      console.error('Error verifying payment:', error);
      setNavigateurl('/payment-failed');
    }
  };

  useEffect(() => {
    if (checkoutId && orderId) {
      checkPaymentStatus();
    }
  }, [checkoutId, orderId]);

  return (
    <>
      <Helmet>
        <meta
          httpEquiv = "Content-Security-Policy"
          content = { `
            style-src 'self' https://eu-prod.oppwa.com 'unsafe-inline';
            frame-src 'self' https://eu-prod.oppwa.com https://oppwa.com;
            script-src 'self' https://eu-prod.oppwa.com 'nonce-${NONCE}' https://mpsnare.iesnare.com;
            connect-src 'self' https://eu-prod.oppwa.com;
            img-src 'self' https://eu-prod.oppwa.com;
          ` }
        />
      </Helmet>
      <div>
        { checkoutId && navigateurl ? (
          <form
            action = { `http://dimas.com.sa/${navigateurl}` }
            className = "paymentWidgets"
            data-brands = "VISA MASTER AMEX"
          ></form>
        ) : (
          <div>Loading...</div>
        ) }
      </div>
    </>
  );
};

export default PaymentForm;
