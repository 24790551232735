/* eslint-disable react/no-unescaped-entities */


import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import check from '../../images/check.png';
import { useShippingcompanylistQuery } from '../../redux/api/orderApi';
import { useAddtocardlistQuery } from '../../redux/api/productsApi';
import { useAddAddressMutation, useAddresslistQuery, useCheckoutcreateorderMutation, useCitylistQuery, useCountrylistQuery, useDeleteAddressMutation, useUpdateAddressMutation } from '../../redux/api/userApi';
// import MapComponent from './MapComponent';
import logo from '../../images/logo.jpg';
import { setCheckoutData } from '../../redux/features/checkoutSlice';
import { setorderid } from '../../redux/features/orderidSlice';
import { showToast } from '../../redux/features/toastSlice';

import SelectCity from './SelectCity';
import SelectCountry from './SelectCountry';

const Shippingdetails = () => {
  const { user } = useSelector((state) => state.auth);
  const checkoutData = useSelector((state) => state.checkout.checkoutData);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const [landmark, setLandmark] = useState('');
  const [street, setStreet] = useState('');
  const [zip_code, setZip_code] = useState('');
  const [description, setDescription] = useState('');
  const [recipientsname, setRecipientsname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [invalidemail, setInvalidemail] = useState('');
  const [invalidphone, setInvalidphone] = useState('');
  const [isNameRequired, setIsNameRequired] = useState(false);
  const [addressdata, setAddressdata] = useState([]);
  const [country, setCountry] = useState([]);
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [productndquantity, setProductndquantity] = useState();
  const [runsaveorder, setRunsaveorder] = useState(false);
  const [countryId, setCountryId] = useState();
  const [activeAddress, setActiveAddress] = useState();
  
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const [isCheckedshiping, setIsCheckedshiping] = useState(false);

  const handleshipingcheckbox = () => {
    setIsCheckedshiping(!isCheckedshiping);
  };


  const [editAddressform, setEditAddressform] = useState(false);

  const [addAddressShow, setAddAddressShow] = useState(false);
  const handleAddAddressShow = () => {
    setAddAddressShow(!addAddressShow);
    setEditAddressform(false);
    setSelectedAddressId(null);

    setLandmark('');
    setStreet('');
    setZip_code('');
    setDescription('');
    setRecipientsname('');
    setPhone('');
    setEmail('');
    setIsChecked(false);

  };

  const { error: errorCountryList, data: dataCountryList,isSuccess: isSuccessCountry } = useCountrylistQuery();
  
  useEffect(() => {
    if (errorCountryList) {
      dispatch(showToast(errorCountryList?.message));
    }

    if (isSuccessCountry) {

      const options = dataCountryList?.data.map((c) => ({
        label: c.name,
        value: c.id
      }));
      setCountry(options || []);
      setSelectedCountryOption(options || null);
      
    }

  }, [errorCountryList, isSuccessCountry,dataCountryList]);

  const handleChangeCountry = (selectedOption) => {
    setSelectedCountryOption(selectedOption);
  };


  const { error: errorCityList, data: dataCityList, isSuccess: isSuccessCity } = useCitylistQuery(selectedCountryOption?.value?selectedCountryOption?.value: '57cc3a39-38bd-49b9-b726-416b7d3adf44');

  const [selectedCityOption, setSelectedCityOption] = useState(null);
  const [citylist, setCitylist] = useState([]);
  
  useEffect(() => {
    if (errorCityList) {
      dispatch(showToast(errorCityList?.message));
    }

    if (isSuccessCity) {

      const options = dataCityList?.data.map((c) => ({
        label: c.name,
        value: c.id
      }));
      setCitylist(options || []);
      setSelectedCityOption(options || null);
      
    }

  }, [errorCityList, isSuccessCity,dataCityList]);

  const handleChangeCity = (selectedOption) => {
    setSelectedCityOption(selectedOption);
  };



  useEffect(() => {
    if (addressdata.length > 0) {
      setActiveAddress(addressdata[0].address_id);
      setCountryId(addressdata[0]?.country?.id);
      localStorage.setItem('addressid',addressdata[0].address_id);
    }
  }, [addressdata]);

  const handleActiveAddress = (address_id, countryid) => {
    setCountryId(countryid);
    setActiveAddress(address_id);
    // localStorage.setItem('addressid',address_id);
  };

  const [shippingAddressboxShow, setShippingAddressboxShow] = useState(true);

  const handleShippingAddressbox = () => {
    setShippingAddressboxShow(true);
  };

  

  const [shippingcompanyboxShow, setShippingcompanyboxShow] = useState(false);

  const handleShippingcompanyboxShow = () => {
    setShippingcompanyboxShow(true);
  };

  // const [paymentboxShow, setPaymentboxShow] = useState(false);

  // const handlepayemtbox = () => {
  //   setPaymentboxShow(true);
  // };

  const handleRecipientNameChange = (event) => {
    const name = event.target.value;
    setRecipientsname(name);
    setIsNameRequired(name.trim() === '');
  };


  const { error: errorAddressList, data: dataAddressList,isSuccess: isSuccessaddress, refetch } = useAddresslistQuery();
  
  useEffect(() => {
    if (errorAddressList) {
      dispatch(showToast(errorAddressList?.message));
    }

    if (isSuccessaddress) {
      setAddressdata(dataAddressList?.data);
      
    }

  }, [errorAddressList, isSuccessaddress,dataAddressList]);

  const [editMode, setEditMode] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(null);


  useEffect(() => {
    // Initialize form fields with selected address data when editMode is true
    if (editMode && selectedAddressId && addressdata?.length) {
      const address = addressdata.find(address => address.address_id === selectedAddressId);

      if(address){
        setLandmark(address.landmark || '');
        setStreet(address.street || '');
        setZip_code(address.zip_code || '');
        setDescription(address.description || '');
        setRecipientsname(address.recipients_name || '');
        setPhone(address.alt_phone || '');
        setEmail(address.email || '');
        setIsChecked(address.send_someone_else || false);
        setSelectedCountryOption({ label: address.country?.name});
        setSelectedCityOption({ label: address.city });

      }

     
    }
  }, [editMode, selectedAddressId,addressdata]);


  const handleEditAddress = (address) => {
    setEditAddressform(true);
    setEditMode(true);
    setAddAddressShow(false);
    setSelectedAddressId(address); // Assuming your address object has an id field
    
  };


  const [addAddress, { error, isLoading, isSuccess }] = useAddAddressMutation();

  useEffect(() => {
    if (error) {
      dispatch(showToast(error?.data?.message));
      
    }

    if (isSuccess) {
      setAddAddressShow(false);
      refetch();
    }
  }, [error, isSuccess]);


  const [updateAddress, { error: errorupdateaddress, isSuccess:isSuccessupdateaddress, data: dataupdateaddress }] = useUpdateAddressMutation();

  useEffect(() => {
    if (errorupdateaddress) {
      dispatch(showToast(errorupdateaddress?.data?.message));
    }

    if (isSuccessupdateaddress) {
      setAddAddressShow(false);
      setEditAddressform(false);
      refetch();
    }
  }, [errorupdateaddress, isSuccessupdateaddress, dataupdateaddress]);

  // Email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Phone number validation regex (example for Saudi Arabia)
  const phoneRegex = /^[0-9]{10}$/;

  const handlePhoneNumberChange = (event) => {
    const number = event.target.value;
    // Regular expression to check if the number is a 10-digit number
    const isValid = /^\d{10}$/.test(number);
    setInvalidphone(isValid);
    setPhone(number);
  };
  // Form submit handler
  const handleSubmit = (event) => {
    event.preventDefault();
  
    let data = {
      country: selectedCountryOption?.label?.trim() || 'Saudi Arabia',
      city: selectedCityOption?.label?.trim() || 'Riyadh',
      landmark: landmark,
      street: street,
      zip_code: zip_code,
      description: description,
      set_as_default: 1
    };
  
    if (isChecked) {
      if (!recipientsname.trim()) {
        setIsNameRequired(true);
        return;
      }
  
      if (email && !emailRegex.test(email)) {
        setInvalidemail('Please enter a valid email address');
        return;
      } else {
        setInvalidemail('');
      }
  
      if (!phoneRegex.test(phone)) {
        setInvalidphone('Please enter a valid 10-digit phone number');
        return;
      } else {
        setInvalidphone('');
      }
  
      data = {
        ...data,
        send_someone_else: true,
        recipients_name: recipientsname,
        phone: phone,
        email: email
      };
    }
  
    if (editMode && selectedAddressId) {
      // Call update address mutation
      
      let datas = {
        address_id: selectedAddressId,
        ...data
      };

      updateAddress(datas);

    } else {
      // Call add address mutation
      addAddress(data);
    }
  };
  

  const validateForm = landmark && street && zip_code && (selectedCityOption?.label || 'Saudi Arabia') && (selectedCountryOption?.label || 'Riyadh');


  const [deleteAddress, { error: errorDelete, isSuccess: isDeleteSuccess }] = useDeleteAddressMutation();

  useEffect(() => {
    if (errorDelete) {
      dispatch(showToast(errorDelete?.data?.message));
    }

    if (isDeleteSuccess) {
      refetch(); // Refetch address list after successful deletion
    }
  }, [errorDelete, isDeleteSuccess, refetch]);


  const handleDeleteAddress = (id) => {
    const data = {
      address: {
        id: id
      }
    };

    deleteAddress(data);
  };
  

  const handleBlur = (event) => {
    event.target.blur();
  };


  const { error: displaycartlisterror, data: displaycartlistdata } = useAddtocardlistQuery();

  useEffect(() => {
    if (displaycartlisterror) {
      dispatch(showToast(displaycartlisterror?.data?.message));
    }
  }, [displaycartlisterror]);


  const handleAddressConfirmation =() => {
    setShippingAddressboxShow(false);
    setShippingcompanyboxShow(true);
  };


  const {error: errorshippingcompany, data: datashippingcompany} = useShippingcompanylistQuery();
  const [shippingCompanyActive, setShippingCompanyActive] = useState(null);

  useEffect(() => {
    if (datashippingcompany?.data?.length > 0) {
      setShippingCompanyActive(datashippingcompany.data[0].id);
    }
  }, [datashippingcompany]);

  const handleSippingCompanyActive = (shipnum) => {
    setShippingCompanyActive(shipnum);
  };

  useEffect(() => {
    

    const productndquantity = {
      products: displaycartlistdata?.data?.map(item => ({
        product_id: item.options.id,
        quantity: item.quantity
      }))
    };

    if (productndquantity?.products && productndquantity?.products.length > 0) {
      setProductndquantity(productndquantity);
      
    }

   
  }, [displaycartlistdata, dataCountryList,selectedCountryOption]);


  const [checkoutcreateorder,{error: createorderError,data: createorderdata, isSuccess: createorderdisSuccess}] = useCheckoutcreateorderMutation();
  
  useEffect(() => {
    if (createorderError) {
      dispatch(showToast(createorderError?.data?.message));
    }
  }, [createorderError]);
  
  useEffect(() => {
  

    if(createorderdata){
      dispatch(setorderid(createorderdata?.data?.order_id));
      localStorage.setItem('orderid',createorderdata?.data?.order_id);
      dispatch(setCheckoutData(createorderdata.data));
    }
  
  }, [createorderdisSuccess,createorderdata]);

  const saveorder = () => {
    let data = {
      ...productndquantity,
      shipping_company_id: shippingCompanyActive,
      country_id: countryId,
      address_id: activeAddress
    };
  
    checkoutcreateorder(data);
    
  };

  const handleConfirmShipment = () => {
    setShippingcompanyboxShow(false);
    setRunsaveorder(true);
    // setPaymentboxShow(true);


    setTimeout(() => {
      setRunsaveorder(false);
    }, 5000);
  };

  useEffect(() => {
    if (runsaveorder) {
      saveorder();
    }
  }, [runsaveorder]);

  // useEffect(() => {
  //   if (activeAddress && shippingCompanyActive && shippingcompanyboxShow) {
  //     saveorder();
  //   }
  // }, [activeAddress, shippingCompanyActive, shippingcompanyboxShow]);

  const handlecheckout = () => {
    navigate('/checkout');
  
  };

  return(
    <>
      <Header />
      <Navigation />
      <div className = 'mt-5'>
        <div className = 'container mx-auto px-5 lg:px-0'>
          <div className = 'w-full md:w-width80% bg-[#f7f7f7] p-4 rounded-md mx-auto'>
            <div className = 'flex'>
              <div className = 'w-24 md:w-32 bg-white p-2 border border-borderColor rounded-md self-center'>
                <img src = { logo } alt = 'logo' />
              </div>
              <div className = 'self-center ml-5'>
                <h3 className = 'text-base md:text-xl font-semibold'>Hello, { user?.name }</h3>
                <ul className = 'flex'>
                  <li>
                    <Link className = { `inline-block text-grey text-xs md:text-sm hover:text-primary ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } to = "/">{ t('Home') }</Link>
                  </li>
                  <li>
                    <Link className = 'inline-block text-grey text-xs md:text-sm ml-2' to = "/">/</Link>
                  </li>
                  <li>
                    <Link className = { `inline-block text-grey text-xs md:text-sm ml-2 hover:text-primary ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } to = "/">{ t('Cart') }</Link>
                  </li>
                  <li>
                    <Link className = 'inline-block text-grey text-xs md:text-sm ml-2' to = "/">/</Link>
                  </li>
                  <li>
                    <span className = { `inline-block text-black text-xs md:text-sm ml-2 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Shipping') }</span>
                  </li>
                </ul>
              </div>
            </div>
            
            { /* <MapComponent /> */ }
            <ul className = 'pt-12'>
              <li className = 'border-b border-borderColor pb-4'>
                <div className = 'flex justify-between'>
                  <div className = 'flex self-center cursor-pointer' onClick = {  handleShippingAddressbox }>
                    <div className = 'w-10 md:w-12 h-10 md:h-12 rounded-full border border-borderColor'>
                      <h3 className = 'text-black text-base md:text-lg text-center mt-2'> { addressdata?.length === 0?1: <img className = 'w-5 mx-auto mt-3 opacity-50' src = { check } alt = 'check' /> } </h3>
                    </div>
                    <div className = 'self-center ml-3'>
                      <h3 className = { `text-base md:text-lg text-black font-medium ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('ShippingAddress') }</h3>
                      <p className = { `text-xs text-blacklight ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Chooseyourdeliveryaddress') }</p>
                    </div>
                  </div>
                  {
                    addressdata?.length > 0 && <div className = 'self-center'>
                      <p onClick = { handleShippingAddressbox } className = 'border border-borderColor rounded-md inline-block py-1 px-2'><span className = 'text-sm text-black cursor-pointer'><EditOutlinedIcon style = { {fontSize: '16px'} } /> <span  className = { `hidden md:inline-block ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Edit') }</span></span></p>
                    </div>
                  }
                </div>
                {
                  shippingAddressboxShow && addressdata?.length === 0 || shippingAddressboxShow && addressdata?.length > 0? <>

                    <div className = 'mt-5'>
                      {
                        addressdata?.length > 0 && addressdata?.map((item) => {
                          const addressKey = `addresskey-${item.address_id}`;
                          return (
                            <div key = { item?.address_id } onClick = { () => handleActiveAddress(item.address_id, item?.country?.id) } className = { `mt-3 cursor-pointer border ${activeAddress == item.address_id ? 'border-primary' : 'border-borderColor'} rounded-md flex justify-between` }>
                              <div className = 'flex flex-1' onClick = { () => setEditAddressform(false) } >
                                <label htmlFor = { addressKey } className = 'flex px-2 md:px-4 py-2 md:py-4 w-full' >
                                  <input type = 'radio' checked = { activeAddress === item.address_id } name = 'adr' className = 'self-center' id = { addressKey } />
                                  <p className = 'text-xs md:text-sm text-black ml-2 self-center'>{ item?.country?.name } - { item?.city } - { item?.landmark } - { item?.zip_code }</p>
                                </label>
                               
                              </div>
                              <div className = 'w-10 md:w-auto flex self-center pr-4'>
                                <p className = 'self-center' onClick = { () => {
                                
                                  handleEditAddress(item.address_id);
                                } }><span className = 'text-xs md:text-sm text-black cursor-pointer '><EditOutlinedIcon style = { { fontSize: '16px' } } /> <span className = { `hidden md:inline-block ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>{ t('Edit') }</span></span></p>
                                <span className = 'self-center text-xs md:text-sm text-red inline-block ml-0 md:ml-3 cursor-pointer' onClick = { () => handleDeleteAddress(item?.address_id) }><DeleteOutlineOutlinedIcon style = { { fontSize: '16px' } } /> <span className = { `hidden md:inline-block ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>{ t('Delete') }</span></span>
                              </div>
                            
                            </div>
                          );
                        })
                      }
                    </div>
                    <div className = 'mt-5 border-2 border-dotted border-secondary rounded-md px-4 py-3'>
                      { !editAddressform? <button className = { `block w-full text-sm md:text-base text-black text-left ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } onClick = { handleAddAddressShow }>{ t('AddNewAddress') }</button> : <p className = 'text-sm md:text-base text-black text-left'>Address modification</p> }
                      {
                        addAddressShow  && <div className = 'text-right text-red'><span className = 'cursor-pointer' onClick = { () => setAddAddressShow(false) }><CancelOutlinedIcon style = { {fontSize: '28px'} } /></span></div>
                      }
                      {
                        addAddressShow || addressdata?.length == 0? <div className = 'border border-borderColor rounded-md p-2 md:p-4 mt-3'>
                          <form onSubmit = { handleSubmit } >
                            <ul>
                              <li>
                                <div className = 'block md:flex justify-between'>
                                  <div className = 'w-full md:w-width49%'>
                                    <label className = { `text-xs md:text-sm text-black block mb-1 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('SelectCountry') } <span className = 'text-red'>*</span></label>
                                    <SelectCountry value = { selectedCountryOption?selectedCountryOption[11]: selectedCountryOption } onChange = { handleChangeCountry } country = { country } />
                                  </div>
                                  <div className = 'w-full md:w-width49% mt-3 md:mt-0'>
                                    <label className = { `text-xs md:text-sm text-black block mb-1 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('SelectCity') } <span className = 'text-red'>*</span></label>
                                    <SelectCity selectedCityOption = { selectedCityOption } handleChangeCity = { handleChangeCity } cityoptions = { citylist } />
                                  </div>
                                </div>
                              </li>
                              <li className = 'mt-3 md:mt-5'>
                                <div className = 'block md:flex justify-between'>
                                  <div className = 'w-full md:w-width49%'>
                                    <label className = { `text-xs md:text-sm text-black block mb-1 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Neighborhood') } <span className = 'text-red'>*</span></label>
                                    <input value = { landmark } onChange = { (e) => setLandmark(e.target.value) } type = 'text' placeholder = { t('NeighborhoodName') } className = { `border border-[#cccccc] rounded-md textsize inline-block w-full ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } />
                                  </div>
                                  <div className = 'w-full md:w-width49% mt-3 md:mt-0'>
                                    <label className = { `text-xs md:text-sm text-black block mb-1 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Street') } <span className = 'text-red'>*</span></label>
                                    <input value = { street } onChange = { (e) => setStreet(e.target.value) } type = 'text' placeholder = { t('StreetName') } className = { `border border-[#cccccc] rounded-md textsize inline-block w-full ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } />
                                  </div>
                                </div>
                              </li>
                              <li className = 'mt-3 md:mt-5'>
                                <div className = 'block md:flex justify-between'>
                                  <div className = 'w-full md:w-width49%'>
                                    <label className = { `text-sm text-black block mb-1 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('PostalCode(Optional)') }</label>
                                    <input value = { zip_code } onChange = { (e) => setZip_code(e.target.value) } type = 'text' placeholder = { t('PostalCode') } className = { `border border-[#cccccc] rounded-md textsize inline-block w-full ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } />
                                  </div>
                                  <div className = 'w-full md:w-width49% mt-3 md:mt-0'>
                                    <label className = { `text-sm text-black block mb-1 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('HouseDescription(Optional)') }</label>
                                    <input value = { description } onChange = { (e) => setDescription(e.target.value) } type = 'text' placeholder = { t('HouseDescription') } className = { `border border-[#cccccc] rounded-md textsize inline-block w-full ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } />
                                  </div>
                                </div>
                              </li>
                              <li className = 'mt-3 md:mt-5'>
                                <button className = { ` rounded-md py-2 px-5 text-center block w-full text-sm md:text-base text-white font-medium ${validateForm?'bg-primary': 'bg-primary-25'} ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } disabled = { !validateForm }>{ isLoading ? 'Saving address' : t('Save') }</button>
                              </li>
                            </ul>
                          </form>
                        </div>: null
                      }


                      { /* editaddress form */ }
                     
                      {
                        editAddressform && <div className = 'text-right text-red'><span className = 'cursor-pointer' onClick = { () => setEditAddressform(false) }><CancelOutlinedIcon style = { {fontSize: '28px'} } /></span></div>
                      }
                      {
                        editAddressform && <div className = 'border border-borderColor rounded-md p-2 md:p-4 mt-3'>
                          <form onSubmit = { handleSubmit } >
                            <ul>
                              <li>
                                <div className = 'block md:flex justify-between'>
                                  <div className = 'w-full md:w-width49%'>
                                    <label className = { `text-xs md:text-sm text-black block mb-1 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('SelectCountry') } <span className = 'text-red'>*</span></label>
                                    <SelectCountry value = { selectedCountryOption } onChange = { handleChangeCountry } country = { country } />
                                  </div>
                                  <div className = 'w-full md:w-width49% mt-3 md:mt-0'>
                                    <label className = { `text-xs md:text-sm text-black block mb-1 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('SelectCity') } <span className = 'text-red'>*</span></label>
                                    <SelectCity selectedCityOption = { selectedCityOption } handleChangeCity = { handleChangeCity } cityoptions = { citylist } />
                                  </div>
                                </div>
                              </li>
                              <li className = 'mt-3 md:mt-5'>
                                <div className = 'block md:flex justify-between'>
                                  <div className = 'w-full md:w-width49%'>
                                    <label className = { `text-xs md:text-sm text-black block mb-1 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Neighborhood') } <span className = 'text-red'>*</span></label>
                                    <input value = { landmark } onChange = { (e) => setLandmark(e.target.value) } type = 'text' placeholder = { t('NeighborhoodName') } className = { `border border-[#cccccc] rounded-md textsize inline-block w-full ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } />
                                  </div>
                                  <div className = 'w-full md:w-width49% mt-3 md:mt-0'>
                                    <label className = { `text-xs md:text-sm text-black block mb-1 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Street') } <span className = 'text-red'>*</span></label>
                                    <input value = { street } onChange = { (e) => setStreet(e.target.value) } type = 'text' placeholder = { t('StreetName') } className = { `border border-[#cccccc] rounded-md textsize inline-block w-full ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } />
                                  </div>
                                </div>
                              </li>
                              <li className = 'mt-3 md:mt-5'>
                                <div className = 'block md:flex justify-between'>
                                  <div className = 'w-full md:w-width49%'>
                                    <label className = { `text-sm text-black block mb-1 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('PostalCode(Optional)') }</label>
                                    <input value = { zip_code } onChange = { (e) => setZip_code(e.target.value) } type = 'text' placeholder = { t('PostalCode') } className = { `border border-[#cccccc] rounded-md textsize inline-block w-full ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } />
                                  </div>
                                  <div className = 'w-full md:w-width49% mt-3 md:mt-0'>
                                    <label className = { `text-sm text-black block mb-1 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('HouseDescription(Optional)') }</label>
                                    <input value = { description } onChange = { (e) => setDescription(e.target.value) } type = 'text' placeholder = { t('HouseDescription') } className = { `border border-[#cccccc] rounded-md textsize inline-block w-full ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } />
                                  </div>
                                </div>
                              </li>
                              <li className = 'mt-3 md:mt-5'>
                                <button className = { ` rounded-md py-2 px-5 text-center block w-full text-sm md:text-base text-white font-medium ${validateForm?'bg-primary': 'bg-primary-25'} ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } disabled = { !validateForm }>{ isLoading ? 'Updating address' : t('Update') }</button>
                              </li>
                            </ul>
                          </form>
                        </div>
                      }
 
                      { /* editaddress form */ }
                    </div>

                    <div>
                      <div className = 'mt-2'>
                        <input onFocus = { handleBlur } type = "checkbox" onChange = { handleCheckboxChange } /> 
                        <label className = 'text-sm inline-block ml-2'>Send Order To Someone else?</label>
                      </div>

                      { isChecked && (
                        <ul>
                        
                          <li className = 'mt-3 md:mt-5'>
                            <div className = 'block md:flex justify-between'>
                              <div className = 'w-full'>
                                <label className = { `text-xs md:text-sm text-black block mb-1 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>The Recipient's name <span className = 'text-red'>*</span></label>
                                <input value = { recipientsname } onChange = { handleRecipientNameChange } type = 'text' className = { `border border-[#cccccc] rounded-md textsize inline-block w-full ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } />
                                { isNameRequired && (
                                  <p className = 'text-sm text-red'>Recipient's name is required</p>
                                ) }
                              </div>
                            </div>
                          </li>
                          <li className = 'mt-3 md:mt-5'>
                            <div className = 'block md:flex justify-between'>
                              <div className = 'w-full md:w-width49%'>
                                <label className = { `text-sm text-black block mb-1 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>Mobile <span className = 'text-red'>*</span></label>
                                <input value = { phone } onChange = { handlePhoneNumberChange } type = 'text' className = { `border border-[#cccccc] rounded-md textsize inline-block w-full ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } />
                                <p className = 'text-sm text-red'>{ invalidphone }</p>
                              </div>
                              <div className = 'w-full md:w-width49% mt-3 md:mt-0'>
                                <label className = { `text-sm text-black block mb-1 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>E-Mail (Optional)</label>
                                <input value = { email } onChange = { (e) => setEmail(e.target.value) } type = 'text'  className = { `border border-[#cccccc] rounded-md textsize inline-block w-full ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } />
                                <p className = 'text-sm text-red'>{ invalidemail }</p>
                              </div>
                            </div>
                          </li>
                           
                        </ul>
                   
                      ) }
                      
                    </div>
                    <div className = 'mt-5'><button onClick = { handleAddressConfirmation } className = { `${addressdata?.length > 0?'bg-primary': 'bg-primary-25'} rounded-md py-2 px-5 text-center block w-full text-sm md:text-base text-white font-medium ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } disabled = { addressdata?.length === 0 }>{ t('AddressConfirmation') }</button></div>
                  </>: null
                }
              </li>
              <li className = 'border-b border-borderColor pb-4 pt-4'>
                <div className = 'flex justify-between' onClick = { addressdata?.length > 0? handleShippingcompanyboxShow: null }>
                  <div className = 'flex self-center cursor-pointer'>
                    <div className = 'w-10 md:w-12 h-10 md:h-12 rounded-full border border-borderColor'>
                      <h3 className = 'text-black text-base md:text-lg text-center mt-2'> { shippingcompanyboxShow?2: <img className = 'w-5 mx-auto mt-3 opacity-50' src = { check } alt = 'check' /> } </h3>
                    </div>
                    <div className = 'self-center ml-3'>
                      <h3 className = { `text-base md:text-lg text-black font-medium ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('ShippingCompany') }</h3>
                      <p className = 'text-xs text-blacklight'>Smsa</p>
                    </div>
                  </div>
                  { /* <div className = 'self-center'>
                    <p className = 'border border-borderColor rounded-md inline-block py-1 px-2'><span className = 'text-sm text-black cursor-pointer'><EditOutlinedIcon style = { {fontSize: '16px'} } /> <span  className = { `hidden md:inline-block ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Edit') }</span></span></p>
                  </div> */ }
                </div>
                {
                  shippingcompanyboxShow && <>
                    <div className = 'mt-5'>
                      { /* <label onClick = { () => handleSippingCompanyActive(1) } htmlFor = 'shippingkey' className = { `mt-3 cursor-pointer border ${shippingCompanyActive == 1?'border-blacklight': 'border-borderColor'} px-4 py-3 rounded-md flex justify-between` }>
                        <div className = 'flex'>
                          <div className = 'flex'>
                            <input type = 'radio' name = 'shipc' className = 'self-center' id = 'shippingkey' />
                            <div className = 'self-center px-3'>
                              <img className = 'w-12' src = 'https://cdn.salla.sa/XTZj2dbvFUGb1rnobc3rJpMLLmHD4MNJXM3yOhot.png' />
                            </div>
                          </div>
                          <div>
                            <p className = 'text-sm text-black self-center'>Redbox</p>
                            <p className = 'text-xs md:text-sm text-black'>استلم طلبك من خزائن ريد بوكس الذكية RedBox، بالوقت المناسب لك، الإيداع سيكون من 1-3 أيام</p>
                          </div>
                        </div>
                        <div>
                          <span className = 'text-xs md:text-sm text-black cursor-pointer'>14 SAR</span>
                          <span className = { `text-xs md:text-sm text-white inline-block ml-3 cursor-pointer bg-primary py-1 px-2 rounded-md ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Free') }</span>
                        </div>
                      </label> */ }
                      {
                        errorshippingcompany?.data?.message && <p className = 'text-sm text-red'>{ errorshippingcompany?.data?.message }</p>
                      }
                      {
                        datashippingcompany?.data?.length > 0 && datashippingcompany?.data?.map((item) => {
                          return(
                            <label key = { item?.id } onClick = { () => handleSippingCompanyActive(item?.id) } htmlFor = { item?.id } className = { `mt-3 cursor-pointer border ${shippingCompanyActive == item?.id?'border-primary': 'border-borderColor'} px-4 py-3 rounded-md flex justify-between` }>
                              <div className = 'flex'>
                                <div className = 'flex'>
                                  <input type = 'radio'  name = 'shipc' className = 'self-center' id = { item?.id } checked = { shippingCompanyActive == item?.id } />
                                  <div className = 'self-center px-3'>
                                    <img className = 'w-8' src = { item?.image } />
                                  </div>
                                </div>
                                <div className = 'self-center'>
                                  <p className = 'text-sm text-black'>{ item?.name }</p>
                                  <p className = 'text-xs md:text-sm text-black'>1 - 3 أيام عمل</p>
                                </div>
                              </div>
                              { /* <div>
                                <span className = 'text-xs md:text-sm text-black cursor-pointer'>14 SAR</span>
                                <span className = { `text-xs md:text-sm text-white inline-block ml-3 cursor-pointer bg-primary py-1 px-2 rounded-md ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Free') }</span>
                              </div> */ }
                            </label>
                          );
                        })
                      }
                      <div className = 'mt-3'>
                        <input onFocus = { handleBlur } type = 'checkbox'  onChange = { handleshipingcheckbox } /> <label className = 'text-sm inline-block ml-1'>Notes</label>
                        {
                          isCheckedshiping && (<div><textarea className = 'border border-[#cccccc] rounded-md textsize inline-block w-full mt-2'></textarea></div>)
                        }
                      </div>
                    </div>
                    <div className = 'mt-5'><button onClick = { handleConfirmShipment } className = { `bg-primary rounded-md py-2 px-5 text-center block w-full text-sm md:text-base text-white font-medium ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('ConfirmShipment') }</button></div>
                  </>
                }
              </li>
              <li className = 'pt-4'>
                <div  className = 'pb-5'>
                  <div className = 'flex self-center cursor-pointer'>
                    <div className = 'w-10 md:w-12 h-10 md:h-12 rounded-full border border-borderColor'>
                      <h3 className = 'text-black text-base md:text-lg text-center mt-2'>3</h3>
                    </div>
                    <div className = 'self-center ml-3'>
                      <h3 className = { `text-base md:text-lg text-black font-medium ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('PayingOff') }</h3>
                      <p className = 'text-xs text-blacklight'>Hyperpay</p>
                    </div>
                  </div>
                </div>
                {
                  !shippingAddressboxShow && !shippingcompanyboxShow && addressdata?.length > 0? 
                    <><ul className = 'mt-8'>
                      <li>
                       
                        { /* {
                          checkouterror?.data?.message && <p className = 'text-sm text-red my-2'>{ checkouterror?.data?.message }</p>
                        } */ }
                      </li>
                      <li className = 'border-b-2 border-dotted border-secondary'>
                        <div className = 'flex justify-between pb-2'>
                          <p className = { `text-sm font-medium ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Subtotal') }</p>
                          <p className = 'text-sm font-medium'>{ checkoutData?.subtotal } SAR</p>
                        </div>
                      </li>
                      <li className = 'border-b-2 border-dotted border-secondary pt-3'>
                        <div className = 'flex justify-between pb-2'>
                          <p className = { `text-sm font-medium ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('ShippingCost') }</p>
                          <p className = { `text-sm font-medium ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ checkoutData?.shipping_cost } SAR</p>
                        </div>
                      </li>
                      <li className = 'border-b-2 border-dotted border-secondary pt-3'>
                        <div className = 'flex justify-between pb-2'>
                          <p className = { `text-sm font-medium ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('VAT') }</p>
                          <p className = 'text-sm font-medium'>{ checkoutData?.vat } SAR</p>
                        </div>
                      </li>
    
                      <li className = 'border-b-2 border-dotted border-secondary pt-8'>
                        <div className = 'flex justify-between pb-2'>
                          <p className = { `text-base font-medium ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('TotalAmount') }</p>
                          <p className = 'text-base font-medium'>{ checkoutData?.total_amount } SAR</p>
                        </div>
                      </li>
                    </ul>
                    <div className = 'text-center mt-5'>{ checkoutData?.total_amount && <button onClick = { handlecheckout }   className = 'inline-block text-white bg-primary rounded-md py-2.5 px-2 w-32 text-sm hover:bg-primary-600 font-medium hover:delay-300 text-center '>Continue</button> }</div></> : null
                }
              </li>
            </ul>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};


export default Shippingdetails;