import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../config/index';
import { userApi } from './userApi';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    credentials: 'include'
  }),
  endpoints: (builder) => ({
    register: builder.mutation({
      query(body) {
        return {
          url: '/users/register/',
          method: 'POST',
          body
        };
      }
    }),
    login: builder.mutation({
      query(body) {
        return {
          url: '/users/login/',
          method: 'POST',
          body
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          localStorage.setItem('token', result?.data?.access_token); // Save token in local storage
          localStorage.setItem('userid', result?.data?.user_id);
          await dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) {
          console.log(error);
        }
      }
    }),
    googleregister: builder.mutation({
      query(body) {
        return {
          url: '/users/google-login/',
          method: 'POST',
          body
        };
      }
    }),
    createorderasaguest: builder.mutation({
      query(body) {
        return {
          url: '/orders/checkout',
          method: 'POST',
          body
        };
      }
    }),
    gestverifyotp: builder.mutation({
      query(body) {
        return {
          url: '/orders/verify-otp',
          method: 'POST',
          body
        };
      }
    })
  })
});

export const { useLoginMutation, useRegisterMutation, useGoogleregisterMutation, useCreateorderasaguestMutation, useGestverifyotpMutation } = authApi;
