import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import RelatedBlog from '../components/RelatedBlog';
import TopHeader from '../components/TopHeader';
import { useBlogDetailsQuery, useLeaveblogcommentMutation, useRelatedBlogMutation } from '../redux/api/recomendedproductApi';
import { showToast } from '../redux/features/toastSlice';

const BlogDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [comment, setComment] = useState('');
  const { error, data } = useBlogDetailsQuery(id);
  const blogDetails = data?.data;

  useEffect(() => {
    if (error) {
      dispatch(showToast(error?.data?.message));
    }
  }, [error]);

  const [relatedBlog, { error: errorRelatedBlog, data: dataRelatedBlog, isLoading }] = useRelatedBlogMutation();

  useEffect(() => {
    if (errorRelatedBlog) {
      dispatch(showToast(errorRelatedBlog?.data?.message));
    }
  }, [errorRelatedBlog]);

  useEffect(() => {
    if (blogDetails) {
      const dataparameters = {
        'blog_id': blogDetails?.blog_id,
        'label': blogDetails?.label,
        'limit': 3
      };
      relatedBlog(dataparameters);
    }
  }, [blogDetails, relatedBlog]);


  const [leaveblogcomment,{error: errorComment, data: datacomment, isSuccess}] = useLeaveblogcommentMutation();

  useEffect(() => {

    if(errorComment){
      dispatch(showToast(errorComment?.data?.message));
    }
    if(isSuccess){
      dispatch(showToast(datacomment?.data?.message));
    }

  },[errorComment, isSuccess]);


  const handlePostComment = (e) => {
    e.preventDefault();
    const data = {
      'blog_id': blogDetails?.blog_id,
      'comment': comment
    };
    leaveblogcomment(data);
  };

  const validForm = comment.length > 0;


  const blogUrl = window.location.href; // Current blog URL

  return (
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = 'text-white text-xl lg:text-2xl text-center font-semibold uppercase'>Blog</h3>
      </div>
      <div className = 'container mx-auto px-5 lg:px-10'>
        <div>
          <h3 className = 'text-black text-xl lg:text-3xl font-semibold pb-2 uppercase'>{ blogDetails?.title }</h3>
          <span className = 'inline-block text-grey pb-6 text-sm lg:text-base'>{ blogDetails?.date }</span>
          <img className = 'w-full' src = { blogDetails?.image } alt = 'blog img' />
          <p className = 'text-sm text-grey mt-5'>{ blogDetails?.description }</p>
          <div className = 'mt-8'>
            <ul className = 'flex'>
              <li>
                <a
                  href = { `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(blogUrl)}` }
                  target = '_blank'
                  rel = 'noopener noreferrer'
                  className = 'bg-[#365493] w-10 h-10 rounded-full flex items-center text-center justify-center text-white mr-2 transition delay-300 hover:translate-y-1 hover:delay-300'
                >
                  <FacebookIcon style = { { fontSize: '24px' } } />
                </a>
              </li>
              <li>
                <a
                  href = { `https://twitter.com/intent/tweet?url=${encodeURIComponent(blogUrl)}&text=${encodeURIComponent(blogDetails?.title)}` }
                  target = '_blank'
                  rel = 'noopener noreferrer'
                  className = 'bg-[#3CF] w-10 h-10 rounded-full flex items-center text-center justify-center text-white mr-2 transition delay-300 hover:translate-y-1 hover:delay-300'
                >
                  <TwitterIcon />
                </a>
              </li>
              <li>
                <a
                  href = { `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(blogUrl)}&media=${encodeURIComponent(blogDetails?.image)}&description=${encodeURIComponent(blogDetails?.title)}` }
                  target = '_blank'
                  rel = 'noopener noreferrer'
                  className = 'bg-[#CB2027] w-10 h-10 rounded-full flex items-center text-center justify-center text-white mr-2 transition delay-300 hover:translate-y-1 hover:delay-300'
                >
                  <PinterestIcon />
                </a>
              </li>
              <li>
                <a
                  href = { `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(blogUrl)}&title=${encodeURIComponent(blogDetails?.title)}&summary=${encodeURIComponent(blogDetails?.description)}` }
                  target = '_blank'
                  rel = 'noopener noreferrer'
                  className = 'bg-[#0274B3] w-10 h-10 rounded-full flex items-center text-center justify-center text-white mr-2 transition delay-300 hover:translate-y-1 hover:delay-300'
                >
                  <LinkedInIcon />
                </a>
              </li>
              <li>
                <a
                  href = { `https://t.me/share/url?url=${encodeURIComponent(blogUrl)}&text=${encodeURIComponent(blogDetails?.title)}` }
                  target = '_blank'
                  rel = 'noopener noreferrer'
                  className = 'bg-[#37AEE2] w-10 h-10 rounded-full flex items-center text-center justify-center text-white transition delay-300 hover:translate-y-1 hover:delay-300'
                >
                  <TelegramIcon />
                </a>
              </li>
            </ul>
          </div>
          <div className = 'text-center mt-10'>
            <Link to = '/blog' className = 'inline-block bg-primary text-sm font-medium rounded-md text-white py-2 px-4 delay-300 hover:bg-primary-600 hover:delay-300'>
              <ArrowBackIosNewOutlinedIcon style = { { fontSize: '16px' } } /> Back to List
            </Link>
          </div>
        </div>
        { dataRelatedBlog?.data?.map((relateditem) => (
          <RelatedBlog relateditem = { relateditem } key = { relateditem?.blog_id } isLoading = { isLoading } />
        )) }
        <h3 className = 'text-black text-xl lg:text-2xl font-semibold mt-16 mb-4 md:mb-8 uppercase'>LEAVE A REPLY</h3>
        <form onSubmit = { handlePostComment }>
          <label className = 'text-black'>
            Comment <span className = 'text-red'>*</span>
          </label>
          <textarea className = 'block w-full border border-borderColor mt-2 h-32' onChange = { (e) => setComment(e.target.value) }></textarea>
          <button 
            className = { `${validForm ? 'bg-primary' : 'bg-primary-600'} inline-block text-base font-semibold rounded-md text-white py-2 px-4 delay-300 hover:bg-primary-600 hover:delay-300 mt-8` } 
            disabled = { !validForm }
          >
            Post Comment
          </button>
        </form>
        <Footer />
      </div>
    </>
  );
};

export default BlogDetails;
