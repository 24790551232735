/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

const PaymentForm = ({ checkoutId, orderId }) => {
  const [navigateurl, setNavigateurl] = useState(null);

  useEffect(() => {
    if (checkoutId) {
      const script = document.createElement('script');
      script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        const secureScript = document.createElement('script');
        secureScript.type = 'text/javascript';
        secureScript.innerHTML = `
          var wpwlOptions = {
            paymentTarget: "_top"
          };
        `;
        document.body.appendChild(secureScript);
      };
    }
  }, [checkoutId]);

  // Function to check payment status
  const checkPaymentStatus = async () => {
    try {
      const response = await fetch(
        `https://backend-core.dimas.com.sa/api/orders/verify-payment/?checkout_id=${checkoutId}&order_id=${orderId}`
      );
      const data = await response.json();

      console.log('data',data);

      if (data?.data.status === 'Success') {
        setNavigateurl('/payment-status');
      } else {
        setNavigateurl('/payment-failed');
      }
    } catch (error) {
      console.error('Error verifying payment:', error);
      setNavigateurl('/payment-failed');
    }
  };

  useEffect(() => {
    if (checkoutId && orderId) {
      checkPaymentStatus();
    }
  }, [checkoutId, orderId]);

  return (
    <div>
      { checkoutId && navigateurl ? (
        <form
          action = { `https://dimas.com.sa/${navigateurl}` }
          className = "paymentWidgets"
          data-brands = "VISA MASTER AMEX"
        ></form>
      ) : (
        <div>Loading...</div>
      ) }
    </div>
  );
};

export default PaymentForm;
