/* eslint-disable react/no-unescaped-entities */


import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import card from '../../images/card.png';
import logo from '../../images/logo.jpg';
import mokafa from '../../images/mokafa.png';
import qitab from '../../images/qitab.png';
import tabby from '../../images/tabbycheckout.png';
import tamara from '../../images/tamaracheckout.png';
import PaymentForm from '../../paymentservices/PaymentForm';
import Tabby from '../tabbypayment/Tabby';
import Tamara from '../tamarapayment/Tamara';

const CheckOut = () => {
  const { user } = useSelector((state) => state.auth);
  const checkoutData = useSelector((state) => state.checkout.checkoutData);
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');

  const [active, setActive] = useState(1);

  const handleActive = (num) => {
    setActive(num);
  };


  return(
    <>
      <Header />
      <Navigation />
      <div className = 'mt-5'>
        <div className = 'container mx-auto px-5 lg:px-0'>
          <div className = 'w-full md:w-width86% bg-[#f7f7f7] p-3 md:p-4 rounded-md mx-auto'>
            <div className = 'flex'>
              <div className = 'w-24 md:w-32 bg-white p-2 border border-borderColor rounded-md self-center'>
                <img src = { logo } alt = 'logo' />
              </div>
              <div className = 'self-center ml-5'>
                <h3 className = 'text-base md:text-xl font-semibold'>Hello, { user?.name }</h3>
                <ul className = 'flex'>
                  <li>
                    <Link className = { `inline-block text-grey text-xs md:text-sm hover:text-primary ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } to = "/">{ t('Home') }</Link>
                  </li>
                  <li>
                    <Link className = 'inline-block text-grey text-xs md:text-sm ml-2' to = "/">/</Link>
                  </li>
                  <li>
                    <Link className = { `inline-block text-grey text-xs md:text-sm ml-2 hover:text-primary ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } to = "/shoping-cart">{ t('Cart') }</Link>
                  </li>
                  <li>
                    <Link className = 'inline-block text-grey text-xs md:text-sm ml-2' to = "/">/</Link>
                  </li>
                  <li>
                    <Link className = { `inline-block text-grey text-xs md:text-sm ml-2 hover:text-primary ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } to = "/shipping-details">{ t('Shipping') }</Link>
                  </li>
                  <li>
                    <Link className = 'inline-block text-grey text-xs md:text-sm ml-2' to = "/">/</Link>
                  </li>
                  <li>
                    <span className = { `inline-block text-black text-xs md:text-sm ml-2 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Checkout') }</span>
                  </li>
                </ul>
              </div>
            </div>
            <ul className = 'mt-8'>
              <li className = 'border-b-2 border-dotted border-secondary'>
                <div className = 'flex justify-between pb-2'>
                  <p className = { `text-sm font-medium ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Subtotal') }</p>
                  <p className = 'text-sm font-medium'>{ checkoutData?.subtotal } SAR</p>
                </div>
              </li>
              <li className = 'border-b-2 border-dotted border-secondary pt-3'>
                <div className = 'flex justify-between pb-2'>
                  <p className = { `text-sm font-medium ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('ShippingCost') }</p>
                  <p className = { `text-sm font-medium ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ checkoutData?.shipping_cost } SAR</p>
                </div>
              </li>
              <li className = 'border-b-2 border-dotted border-secondary pt-3'>
                <div className = 'flex justify-between pb-2'>
                  <p className = { `text-sm font-medium ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('VAT') }</p>
                  <p className = 'text-sm font-medium'>{ checkoutData?.vat } SAR</p>
                </div>
              </li>

              <li className = 'border-b-2 border-dotted border-secondary pt-8'>
                <div className = 'flex justify-between pb-2'>
                  <p className = { `text-base font-medium ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('TotalAmount') }</p>
                  <p className = 'text-base font-medium'>{ checkoutData?.total_amount } SAR</p>
                </div>
              </li>
            </ul>
            <div className = 'mt-8'>
              <h3 className = 'text-lg text-blacklight font-semibold'>How would you like to pay?</h3>
              <div className = 'flex flex-wrap md:flex-nowrap gap-2 md:gap-0 md:justify-between mt-5'>
                <div className = 'w-width48% md:w-width19% cursor-pointer'>
                  <div onClick = { () => handleActive(1) } className = { `w-full bg-white rounded shadow-md p-2 ${active == 1? 'border-2 border-boderColor': ''}` }>
                    <img src = { card } alt = 'card' />
                    <p className = 'text-blacklight text-xs font-semibold mt-3.5 text-center'>Debit Card</p>
                  </div>
                </div>
                <div className = 'w-width48% md:w-width19% cursor-pointer'>
                  <div onClick = { () => handleActive(2) } className = { `w-full bg-white rounded shadow-md p-2 ${active == 2? 'border-2 border-boderColor': ''}` }>
                    <img src = { qitab } alt = 'qitab' />
                    <p className = 'text-blacklight text-xs font-semibold mt-2 text-center'>Qitaf</p>
                  </div>
                </div>
                <div className = 'w-width48% md:w-width19% cursor-pointer'>
                  <div onClick = { () => handleActive(3) } className = { `w-full bg-white rounded shadow-md p-2 ${active == 3? 'border-2 border-boderColor': ''}` }>
                    <img src = { mokafa } alt = 'mokafa' />
                    <p className = 'text-blacklight text-xs font-semibold mt-2 text-center'>Mokafaa</p>
                  </div>
                </div>
                <div className = 'w-width48% md:w-width19% cursor-pointer'>
                  <div onClick = { () => handleActive(4) } className = { `w-full bg-white rounded shadow-md p-2 ${active == 4? 'border-2 border-boderColor': ''}` }>
                    <img src = { tabby } alt = 'tabby' />
                    <p className = 'text-blacklight text-xs font-semibold mt-2 text-center'>Pay in 4</p>
                  </div>
                </div>
                <div className = 'w-width48% md:w-width19% cursor-pointer'>
                  <div onClick = { () => handleActive(5) } className = { `w-full bg-white rounded shadow-md p-2 ${active == 5? 'border-2 border-boderColor': ''}` }>
                    <img src = { tamara } alt = 'tamara' />
                    <p className = 'text-blacklight text-xs font-semibold mt-2 text-center'>Pay in 4 - No late fees</p>
                  </div>
                </div>
              </div>
              {
                active === 1 && <div className = 'mt-8'><PaymentForm orderId = { checkoutData?.order_id } totalamoutpay = { checkoutData?.total_amount } checkoutId = { checkoutData?.checkout_id } /></div>
              }
              {
                active === 4 && <div className = 'mt-8'><Tabby /></div>
              }
              {
                active === 5 && <><Tamara /></>
              }
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};


export default CheckOut;