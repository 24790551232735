import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { useBannerlistQuery } from '../redux/api/recomendedproductApi';

const MobilebannerHome = () => {
  const sliderRef = useRef(null);

  const {error, data} = useBannerlistQuery();

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const settings = {
    infinite: true,
    speed: 500,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    cssEase: 'linear'
  };

  return (
    <div>
      {
        error && error?.message
      }
      <div className = 'block md:hidden'>
        <Slider ref = { sliderRef } { ...settings }>
          {
            data?.data?.map((item) => (
              <div key = { item?.id }>
                <Link to = { item?.banner_render_url } className = 'block'>
                  <img
                    className = "w-full"
                    src = { item?.mobile_image_url?item?.mobile_image_url: item?.image_url }
                    alt = "Banner 2"
                  />
                </Link>
              </div>
            ))
          }
           
        </Slider>
        <div className = 'container mx-auto px-5 lg:px-10'>
          <div className = 'sliderarrow'>
            <button onClick = { goToPrevSlide } className = 'w-8 md:w-10 h-8 md:h-10 bg-white shadow-arrowsad rounded-full'><KeyboardArrowLeftOutlinedIcon /></button>
            <button onClick = { goToNextSlide } className = 'w-8 md:w-10 h-8 md:h-10 bg-white shadow-arrowsad rounded-full inline-block ml-3'><KeyboardArrowRightOutlinedIcon /></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobilebannerHome;
