import CloseIcon from '@mui/icons-material/Close';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


const SearchMainMobile = () => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  const submitHandler = (e) => {
    e.preventDefault();

    if(query?.trim()){
      navigate(`/search?query=${query}`);
    }else{
      navigate('/search');
    }

  };

  return(
    <>
      <div className = 'mobilesearchbox'>
        <Popup 
          className = "custom-popup"
          trigger = { <button className = 'text-primary mt-1.5'><SearchOutlinedIcon style = { {fontSize: '30px'} } /></button> }
          modal
          nested
        >
          { close => (
            <div className = 'container mx-auto px-5 lg:px-10 md:py-2'>
              <div className = 'flex justify-between my-4 md:my-12'>
                <form className = 'w-width90% md:w-width95%' onSubmit = { submitHandler }>
                  <div className = "modal flex py-1.5 px-2 border border-primary">
                    <div className = 'text-primary'>
                      <SearchOutlinedIcon />
                    </div>
                    <div className = "w-full">
                      <input value = { query } onChange = { (e) => setQuery(e.target.value) } className = { `w-full outline-none text-sm ml-2 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } placeholder = { t('Searchforproducts') } />
                    </div>      
                  </div>
                </form>
                <div className = 'self-center'>
                  <button className = "close text-grey" onClick = { close }>
                    <CloseIcon style = { {fontSize: '20px'} } />
                  </button>
                </div>
              </div>
            </div>
          ) }
        </Popup>

      </div>
    </>
  );
};

export default SearchMainMobile;