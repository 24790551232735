/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import TopHeader from '../../components/TopHeader';

const Mothersdayedit = () => {

  return(
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = "container mx-auto px-5 lg:px-10">
        <div>
          <div className = 'text-center'>
            <img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvdGVwX2hlcm9fZF9taW5fOTYxYzBjZWU1YS5qcGc=' alt = 'bannerimg' />
          </div>
        </div>
        <div className = 'border-b border-primary mt-8'></div>
        <div className = 'h-44'></div>
        <Footer />
      </div>  
    </>
  );
};

export default Mothersdayedit;