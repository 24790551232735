import React from 'react';
import { useTranslation } from 'react-i18next';
import Brandslide from '../components/Brandslide';

const Homefeaturedbrand = () => {

  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');

  return(
    <>
      <div>
        <h3 className = { `text-black text-xl text-center font-semibold mt-2 md:mt-6 mb-4 md:mb-6 uppercase ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''} ` }>{ t('FEATUREDBRANDS') }</h3>
        <Brandslide />
      </div>
    </>
  );
};

export default Homefeaturedbrand;