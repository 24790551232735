import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../config/index';
import { logout } from '../features/authSlice';
import { setIsAuthenticated, setLoading, setUser } from '../features/userSlice';


const getToken = () => localStorage.getItem('token');

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    credentials: 'include'
  })(args, api, extraOptions);

  if (result.error) {
    const { status } = result.error;
    if (status === 403 || status === 401) {
      console.log('hi checking');
      api.dispatch(logout());
    }
  }

  return result;
};

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getMe: builder.query({
      query: () => '/users/my-account/',
      transformResponse: (result) => result.data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
          dispatch(setIsAuthenticated(true));
          dispatch(setLoading(false));
        } catch (error) {
          dispatch(setLoading(false));
          // console.log(error);
        }
      },
      providesTags: ['User']
    }),
    updateUser: builder.mutation({
      query(body) {
        return {
          url: '/users/my-account/edit-account/',
          method: 'PUT',
          body
        };
      },
      invalidatesTags: ['User']
    }),
    forgotPassword: builder.mutation({
      query(body) {
        return {
          url: '/users/forgot-password/',
          method: 'POST',
          body
        };
      }
    }),
    verifyotp: builder.mutation({
      query(body) {
        return {
          url: '/reset-password-otp-verify/',
          method: 'PUT',
          body
        };
      }
    }),
    resetPassword: builder.mutation({
      query(body) {
        return {
          url: '/users/reset-password/',
          method: 'POST',
          body
        };
      }
    }),
    subscribe: builder.mutation({
      query(body) {
        return {
          url: '/notifications/subscribe',
          method: 'POST',
          body
        };
      }
    }),
    addAddress: builder.mutation({
      query(body) {
        return {
          url: '/users/my-account/add-address/',
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['addresslist']
    }),
    updateAddress: builder.mutation({
      query(body) {
        return {
          url: '/users/my-account/update-address/',
          method: 'PUT',
          body
        };
      },
      invalidatesTags: ['addresslist']
    }),
    DeleteAddress: builder.mutation({
      query(body) {
        return {
          url: '/users/my-account/delete-address/',
          method: 'DELETE',
          body
        };
      },
      invalidatesTags: ['addresslist']
    }),
    addresslist: builder.query({
      query: () => '/users/my-account/list-address/',
      providesTags: ['addresslist']
    }),
    countrylist: builder.query({
      query: () => '/countries/'
    }),
    citylist: builder.query({
      query: (id) => `/countries/${id}/cities/`
    }),
    checkoutprice: builder.mutation({
      query(body) {
        return {
          url: '/orders/checkout/price',
          method: 'POST',
          body
        };
      }
    }),
    checkoutcreateorder: builder.mutation({
      query(body) {
        return {
          url: '/orders/checkout',
          method: 'POST',
          body
        };
      }
    }),
    savepaymentdetails: builder.mutation({
      query(body) {
        return {
          url: '/orders/payment/details',
          method: 'POST',
          body
        };
      }
    })
  })
});

export const { useGetMeQuery,
  useUpdateUserMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useVerifyotpMutation,
  useSubscribeMutation,
  useAddAddressMutation,
  useAddresslistQuery,
  useDeleteAddressMutation,
  useUpdateAddressMutation,
  useCountrylistQuery,
  useCitylistQuery,
  useCheckoutpriceMutation,
  useCheckoutcreateorderMutation,
  useSavepaymentdetailsMutation
} = userApi;
