/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import TopHeader from '../../components/TopHeader';
import MainSubscriptionCollections from './MainSubscriptionCollections';


const MainSubscription = () => {

  return(
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = "container mx-auto px-5 lg:px-10">
        <div className = " w-full h-fit py-28 bg-cover bg-center" style = { {backgroundImage: 'url(\'https://imgcdn.scentbird.com/_/rt:fill/w:1440/ZnJvbnRiaXJkMi9pbWFnZXMvYmctZGVza3RvcC1mZW1hbGVfNGE5Njc2LmpwZw==\')'} }>
          <div className = 'flex'>
            <div className = 'w-3/6'></div>
            <div className = 'w-3/6 relative'>
              <div className = 'text-black py-8 px-14 '>
                <h3 className = 'text-2xl lg:text-4xl font-bold mt-2 uppercase'><span className = 'text-red'>GET 50%</span> + A FREE CASE</h3>
                <h3 className = 'text-2xl lg:text-4xl font-bold uppercase'>WHEN YOU SUBSCRIBE</h3>
                <p className = 'text-sm lg:text-base font-medium mt-5'>
                Take half off your first month, and discover a world of fragrances.
                </p>
                <Link to = '#' className = 'inline-block bg-black text-white text-base px-8 py-3 font-medium uppercase mt-10'>Subscribe for SAR8.47 </Link>
              </div>
            </div>
          </div>
        </div>
        <div className = 'mt-16'>
          <h3 className = 'text-blacklight text-3xl font-bold text-center'>CURATED COLLECTION</h3>
          <p className = 'text-sm text-blacklight text-center font-medium mt-3'>Tap into a mood or moment with these collections of hand-selected scents.</p>
          <div className = 'mt-16 text-center'>
            <img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/ZnJvbnRiaXJkMi9pbWFnZXMvaGVyby1pbWFnZS1kZXNrdG9wXzE5NmM0Ny5qcGc=' alt = 'bannerimg' />
            <div className = 'bg-black text-center p-10 scentbirdselect w-11/12 mx-auto'>
              <img className = 'mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:384/ZnJvbnRiaXJkMi9pbWFnZXMvc2NlbnRiaXJkLXNlbGVjdC1sb2dvXzIwNjA0OC5wbmc=' alt = 'ns' />
              <p className = 'text-sm text-white font-medium mt-5'>
                Discover our curated collection of premium perfumes and colognes from the most <br></br> exclusive fragrance houses, hand-picked by our team of scent experts.
              </p>
            </div>
            <Link to = '#' className = 'inline-block bg-black text-white text-base px-8 py-3 font-medium uppercase'>Browse Scentbird select</Link>
          </div>
        </div>
        <div className = "mt-20 w-full h-fit py-28 bg-cover bg-center" style = { {backgroundImage: 'url(\'https://imgcdn.scentbird.com/_/rt:fill/w:1440/ZnJvbnRiaXJkMi9pbWFnZXMvYmctZGVza3RvcF9lNTFmNjYuanBn\')'} }>
          <div className = 'flex'>
            <div className = 'w-3/6'></div>
            <div className = 'w-3/6 relative'>
              <div className = 'bg-[#141414] text-white py-14 px-14 '>
                <h3 className = 'text-2xl lg:text-4xl font-bold mt-2'>BUILD YOUR SCENT PROFILE WITH OUR QUIZ</h3>
                <p className = 'text-sm lg:text-base font-semibold mt-4'>
                Tell us about the scents you gravitate towards, and we'll find fragrances tailored to fit your tastes.
                </p>
                <Link to = '#' className = 'inline-block bg-white text-black text-base px-8 py-3 font-medium uppercase mt-5'>Get started with a quiz</Link>
              </div>
            </div>
          </div>
        </div>
        <div className = "mt-20 w-full h-fit py-28 bg-cover bg-center" style = { {backgroundImage: 'url(\'https://imgcdn.scentbird.com/_/rt:fill/w:1440/ZnJvbnRiaXJkMi9pbWFnZXMvZGVza3RvcF9iNWJkODEuanBn\')'} }>
          <div className = 'flex'>
            <div className = 'w-3/6'></div>
            <div className = 'w-3/6 relative'>
              <div className = 'bg-[#141414] text-white py-14 px-14 '>
                <h3 className = 'text-2xl lg:text-4xl font-bold mt-2'>DISCOVER FRAGRANCE BEYOND THE BOTTLE</h3>
                <p className = 'text-sm lg:text-base font-medium mt-4'>
                From body lotions, diffusers, candles, and yes,
                </p>
                <p className = 'text-sm lg:text-base font-medium mt-2'>
                even scented jewelry, there's more than one way
                </p>
                <p className = 'text-sm lg:text-base font-medium mt-2'>
                to immerse yourself in your signature scent.
                </p>
                <Link to = '#' className = 'inline-block bg-white text-black text-base px-8 py-3 font-medium uppercase mt-5'>Shop now</Link>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Link to className = "block mt-20 w-full h-fit py-64 bg-cover bg-center" style = { {backgroundImage: 'url(\'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvR3JvdXBfMjk4N18wMzljZTM1MGJiLmpwZw==\')'} }>
          </Link>
        </div>
        <div>
          <Link to className = "block mt-20 w-full h-fit py-64 bg-cover bg-center" style = { {backgroundImage: 'url(\'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvbXBfYmFubmVyX2Rlc2t0b3BfMDlkYzIzYmRlZi5qcGc=\')'} }>
          </Link>
        </div>
        <MainSubscriptionCollections />
        <div className = 'mt-20'>
          <h3 className = 'text-blacklight text-3xl font-bold text-center'>CAPSULE COLLECTION</h3>
          <p className = 'text-sm text-blacklight text-center font-medium mt-3'>Whether you want to throw a vibe, channel warmer climes, or complement an occasion, these collections are packed with scents that fit the bill.</p>
        </div>
        <Footer />
      </div>  
    </>
  );
};

export default MainSubscription;