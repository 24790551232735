/* eslint-disable react/no-unescaped-entities */
import TuneIcon from '@mui/icons-material/Tune';
import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import TopHeader from '../../components/TopHeader';
import MainSubscriptionCollections from './MainSubscriptionCollections';


const ScentbirdSelect = () => {

  return(
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = "container mx-auto px-5 lg:px-10">
        <div>
          <div className = 'text-center'>
            <img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/ZnJvbnRiaXJkMi9pbWFnZXMvaGVyby1pbWFnZS1mZW1hbGUtZGVza3RvcF9mODdhY2IuanBn' alt = 'bannerimg' />
            <div className = 'bg-black text-center p-10 scentbirdselect w-11/12 mx-auto'>
              <img className = 'mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:384/ZnJvbnRiaXJkMi9pbWFnZXMvc2NlbnRiaXJkLXNlbGVjdC1sb2dvXzIwNjA0OC5wbmc=' alt = 'ns' />
              <p className = 'text-sm text-white font-medium mt-5'>
                Discover our curated collection of premium perfumes and colognes from the most <br></br> exclusive fragrance houses, hand-picked by our team of scent experts.
              </p>
            </div>
          </div>
          <img className = 'w-width92% mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:2272/ZnJvbnRiaXJkMi9pbWFnZXMvYnJhbmRzLTFfMGRkNWNlLnBuZw==' alt = 'brand img' />
          <img className = 'w-width92% mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:2272/ZnJvbnRiaXJkMi9pbWFnZXMvYnJhbmRzLTJfNTJmOTVlLnBuZw==' alt = 'brand img' />
        </div>

        <div className = 'flex justify-between mt-16'>
          <div>
            <h3 className = 'text-lg font-semibold text-primary'>Browsing 51 fragrances</h3>
          </div>
          <div>
            <button className = 'text-base font-semibold text-blacklight border border-borderColor px-5 py-2'>Filter  <span className = 'inline-block ml-16 rounded'><TuneIcon style = { {fontSize: '20px'} } /></span></button>
          </div>
        </div>

        <div className = 'mt-16'>
          <h3 className = 'text-2xl font-medium text-blacklight text-center'>ACQUA DI PARMA</h3>
          <MainSubscriptionCollections />
        </div>
        <div className = 'mt-20'>
          <h3 className = 'text-2xl font-medium text-blacklight text-center'>AMOUAGE</h3>
          <MainSubscriptionCollections />
        </div>
        <div className = 'mt-20'>
          <h3 className = 'text-2xl font-medium text-blacklight text-center uppercase'>Chris Collins</h3>
          <MainSubscriptionCollections />
        </div>
        <div className = 'mt-20'>
          <h3 className = 'text-2xl font-medium text-blacklight text-center uppercase'>DS & DURGA</h3>
          <MainSubscriptionCollections />
        </div>
        <div className = 'mt-20'>
          <h3 className = 'text-2xl font-medium text-blacklight text-center uppercase'>EX NIHILO</h3>
          <MainSubscriptionCollections />
        </div>
        <Footer />
      </div>  
    </>
  );
};

export default ScentbirdSelect;