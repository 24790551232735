import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../redux/features/authSlice';

const AccountSidebar = () => {

  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    navigate(0);
    
  };

  return (
    <>
      <div className = 'pr-8'>
        <h3 className = { `text-black text-lg uppercase  ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('MyAccount') }</h3>
        <hr />
        <ul>
          <li>
            <Link
              to = "/my-account"
              className = { `text-sm text-black font-semibold inline-block w-full py-3 px-2 md:px-3 ${selectLanguage == 'ar'?'font-madani-arabic': ''} ${location.pathname === '/my-account' ? 'bg-primary text-white' : ''}` }
            >
              { t('Dashboard') }
            </Link>
          </li>
          <li>
            <Link
              to = "/my-account/order"
              className = { `text-sm text-black font-semibold inline-block w-full py-3 px-2 md:px-3 ${selectLanguage == 'ar'?'font-madani-arabic': ''} ${location.pathname === '/my-account/order' ? 'bg-primary text-white' : ''}` }
            >
              { t('Orders') }
            </Link>
          </li>
          <li>
            <Link
              to = "/my-account/address"
              className = { `text-sm text-black font-semibold inline-block w-full py-3 px-2 md:px-3 ${selectLanguage == 'ar'?'font-madani-arabic': ''} ${location.pathname === '/my-account/address' ? 'bg-primary text-white' : ''}` }
            >
              { t('Addresses') }
            </Link>
          </li>
          <li>
            <Link
              to = "/my-account/edit-account"
              className = { `text-sm text-black font-semibold w-full inline-block py-3 px-2 md:px-3 ${selectLanguage == 'ar'?'font-madani-arabic': ''} ${location.pathname === '/my-account/edit-account' ? 'bg-primary text-white' : ''}` }
            >
              { t('Accountdetails') }
            </Link>
          </li>
          <li>
            <Link
              to = "/wishlist"
              className = { `text-sm text-black font-semibold inline-block w-full py-3 px-2 md:px-3 ${selectLanguage == 'ar'?'font-madani-arabic': ''} ${location.pathname === '/wishlist' ? 'bg-primary text-white' : ''}` }
            >
              { t('Wishlist') }
            </Link>
          </li>
          <li>
            <Link
              to = "#"
              onClick = { handleLogout }
              className = { `text-sm text-black font-semibold inline-block py-3 px-2 md:px-3 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }
            >
              { t('Logout') }
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default AccountSidebar;
