/* eslint-disable no-unused-vars */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PaymentStatus = () => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');

  // const [clearcart,{error: errorclearcart }] = useClearcartMutation();

  // useEffect(() => {
  //   if (errorclearcart) {
  //     console.log(errorclearcart?.data?.message);
  //   }
  // }, [errorclearcart]);





  // useEffect(() => {

  //   if(isSuccess){

  //     clearcart();
  //   }

  // },[isSuccess]);

  return (
    <div className = "flex justify-center items-center h-screen bg-gray-100">
      <div className = "bg-white m-4 p-4 md:p-8 rounded-lg shadow-lg text-center max-w-md">
        <div className = "flex justify-center items-center mb-6">
          <div className = "bg-primary p-4 rounded-full relative">
            <svg
              className = "w-8 h-8 text-white"
              fill = "none"
              stroke = "currentColor"
              viewBox = "0 0 24 24"
              xmlns = "http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap = "round"
                strokeLinejoin = "round"
                strokeWidth = "2"
                d = "M5 13l4 4L19 7"
              ></path>
            </svg>
            <div className = "absolute inset-0 flex items-center justify-center">
              <div className = "w-20 h-20 rounded-full bg-primary animate-ping"></div>
            </div>
          </div>
        </div>
        <h2 className = { `text-2xl font-semibold mb-2 ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }>{ t('Thankyouforordering') }</h2>
        <p className = "text-gray-600 mb-6">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
        </p>
        <div className = "flex justify-center space-x-4">
          <Link to = '/my-account/order' className = { `px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }>
            { t('ViewOrder') }
          </Link>
          <Link to = "/" className = { `px-4 py-2 bg-primary-500 text-white rounded hover:bg-primary ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }>
            { t('ContinueShopping') }
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentStatus;
