import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';

const PersonalData = () => {
  return (
    <>
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center'>
        <h3 className = 'text-white text-xl lg:text-2xl text-center uppercase font-bold'>My Personal Data</h3>
      </div>

      <div className = ' bg-[#f8f8f8]'>
        <div className = "container mx-auto px-5 lg:px-10">

          <div className = "flex items-center justify-center text-center py-10 lg:py-20 flex-col text-grey gap-4 mb-20">
            <h3 className = 'text-lg lg:text-xl text-black font-semibold w-full '>REQUEST PERSONAL DATA</h3>
            <p>Our website address is: <span className = 'text-black'> https://beautyicon.store/</span></p>
            <p className = 'w-full lg:w-2/4'>Thank you for visiting Beauty icon Couture. We are a E-Commerce Platform, Offering Luxury Item products to enhance your Style & Needs & to experience a Pleasent Shopping Experience. Please read this Personal Data Policy, providing consent to document in order to have permission to use our services.</p>
          </div>
        </div>
      </div>


      <div className = "container mx-auto px-5 lg:px-10">
        <h3 className = 'text-black text-xl lg:text-xl text-center font-medium uppercase'>You can request your personal data by filling this form.</h3>
        <div className = 'pt-3'>
          <div className = 'border-2'>
            <div className = ' py-5 px-7'>
              <p className = 'text-[15px] text-[#777777] pt-8 '>I also consent to having beauty icon collect my email so that they can send me my requested info. For more info check our privacy policy where you’ll get more info on where, how and why we store your data.</p>
            </div>
          </div>
        </div>

        <form action = "">
          <div className = "mt-10 grid grid-cols-1 lg:gap-x-6 lg:gap-y-8 gap-y-4 gap-x-3 sm:grid-cols-6">
            <div className = "sm:col-span-3">
              <label htmlFor = "first-name" className = "block text-sm font-medium leading-6 text-gray-900">Your Name*</label>
              <div className = "mt-2">
                <input type = "text" name = "first-name" id = "first-name" autoComplete = "given-name" className = "block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div className = "sm:col-span-3 ">
              <label htmlFor = "last-name" className = "block text-sm font-medium leading-6 text-gray-900">Your Email*</label>
              <div className = "mt-2">
                <input type = "text" name = "last-name" id = "last-name" autoComplete = "family-name" className = "block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div className = "sm:col-span-3">
              <label htmlFor = "last-name" className = "block text-sm font-medium leading-6 text-gray-900">Phone Number</label>
              <div className = "mt-2">
                <input type = "tel" name = "last-name" id = "last-name" autoComplete = "family-name" className = "block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div className = "sm:col-span-3">
              <label htmlFor = "last-name" className = "block text-sm font-medium leading-6 text-gray-900">Company</label>
              <div className = "mt-2">
                <input type = "text" name = "last-name" id = "last-name" autoComplete = "family-name" className = "block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div className = "col-span-full">
              <label htmlFor = "about" className = "block text-sm font-medium leading-6 text-gray-900">Your Message *</label>
              <div className = "mt-2">
                <textarea id = "about" name = "about" rows = "3" className = "block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
              </div>
            </div>
          </div>
        </form>
        <div className = 'mt-8'>
          <Link to = "/" className = 'bg-primary text-white rounded-md py-3 px-5 '>Ask a Question</Link>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PersonalData;