import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRecomendedQuery } from '../redux/api/recomendedproductApi';
import Loader from './Loader';
import YoumayalsolikeProduct from './YoumayalsolikeProduct';

const YoumayalsoLike = () => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');

  const { error, data, isLoading } = useRecomendedQuery();
  const limitedProducts = data?.data?.products.slice(0, 4);

  return (
    <>
      <ToastContainer />
      <div><h3 className = { `text-black text-xl text-center font-semibold mt-12 md:mt-16 mb-4 md:mb-8 uppercase ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Youmayalsolike') }</h3></div>
      { isLoading && <Loader /> }
      { error && <p className = "text-sm text-blacklight">{ error?.data?.message }</p> }
      { data?.data?.total === 0 && (
        <p className = "text-sm text-blacklight text-center pb-16">
          { data?.data?.total === 0 ? 'No data available.' : '' }
        </p>
      ) }
      <div className = 'h-full'>
        { data?.data?.total > 0 && (
          <div className = "overflow-hidden h-full">
            <div
              className = "flex justify-between flex-wrap h-full">
              { limitedProducts?.map((product, index) => (
                <div className = 'w-width49% md:w-width23%' key = { product?.product_id }>
                  <YoumayalsolikeProduct product = { product } index = { index } />
                </div>
              )) }
            </div>
          </div>
        ) }
      </div>
    </>
  );
};

export default YoumayalsoLike;
