import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Customtoast from '../components/Customtoast';
// import Googleauthentic from '../components/Googleauthentic';
import bg from '../images/download.png';
import logo from '../images/logo.jpg';
import { useLoginMutation } from '../redux/api/authApi';
import { useAddtocartMutation } from '../redux/api/productsApi';
import { selectCartItems } from '../redux/features/cartSlice';
import { showToast } from '../redux/features/toastSlice';

const Login = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const cartItems = useSelector(selectCartItems);
  const [productitem, setProductitem] = useState([]);
  const token = localStorage.getItem('token');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const showToastmessage = useSelector((state) => state.toast?.message);

  const [addtocart, { isSuccess: addtocartSuccess, error: erroraddtocart }] = useAddtocartMutation();

  useEffect(() => {
    if (erroraddtocart) {
      dispatch(showToast(erroraddtocart?.data?.message || 'Error adding to cart'));
    }
  }, [erroraddtocart]);

  useEffect(() => {
    if (addtocartSuccess) {
      localStorage.removeItem('cartItems');
    }
  }, [addtocartSuccess, navigate]);

  useEffect(() => {
    const productdata = cartItems.map(item => ({
      stock_options_id: item.options.id,
      quantity: item.quantity
    }));

    if (productdata.length > 0) {
      setProductitem(productdata);
    }
  }, [cartItems]);


  const [ login, {isLoading, error, data} ] = useLoginMutation();

  useEffect(() => {

    if(error?.data?.message){
      dispatch(showToast(error?.data?.message));
    }
    if(token){
      dispatch(showToast(data?.message));
      if(cartItems?.length > 0){
        addtocart(productitem);
      }
      setTimeout(() => {
        navigate('/');
      },2000);
    }
  },[error, data,token]);

  const LoginHandler = (e) => {
    e.preventDefault();

    const loginData = {
      email,
      password
    };

    login(loginData);
  };

  const handleEmail = (e) => {
    const trimmedEmail = e.target.value.trim();
    setEmail(trimmedEmail);
  
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(trimmedEmail);
  
    setErrorEmail(isValidEmail ? '' : '');
    setIsEmailValid(isValidEmail);
  };
  
  const handleEmailBlur = () => {
    if (!isEmailValid && email.trim()) {
      setErrorEmail('Please enter a valid email');
    }
  };
  

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  


  useEffect(() => {
    document.title = 'Login';
  }, []);

  const isFormValid = isEmailValid && password;

  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  return (
    <>
      { showToastmessage && <Customtoast /> }
      <div className = 'flex justify-between min-h-screen'>     
        <div className = 'bg-white box-border w-full md:w-width60% flex items-center'>
          <div className = 'w-full md:w-width60% m-4 md:m-6 md:mx-auto p-4 md:p-8 shadow-xl rounded-3xl'>
            <Link to = "/" className = 'block'>
              <img className = 'w-52 mx-auto' src = { logo } alt = 'logo'/>
            </Link> 
            <form onSubmit = { LoginHandler }>
              <ul>
               
                <li className = 'pt-4 lg:pt-2.5'>
                  <label className = { 'text-sm text-grey-500 lg:text-grey-800 ' }>Email</label>
                  <input onChange = { handleEmail } onBlur = { handleEmailBlur } value = { email } className = { `block w-full border-none rounded-md px-4 py-2 text-base bg-inputcolor ${errorEmail ? 'outline-red' : 'outline-primary-300'}` } placeholder = "Email" />
                  { errorEmail && <p className = "text-xs text-red">{ errorEmail }</p> }     
                </li>
                <li className = 'relative pt-4 md:pt-2.5'>
                  <label className = { `text-sm text-grey-500 lg:text-grey-800 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>{ t('Password') }</label>
                  <input
                    onChange = { handlePasswordChange }
                    value = { password }
                    placeholder = "Password"
                    type = { showPassword ? 'text' : 'password' }
                    className = { 'block w-full border-none focus:outline-none rounded-md px-4 py-2 text-base bg-inputcolor outline-primary-300' }
                  />
                  <span className = 'cursor-pointer' onClick = { toggleShowPassword }>
                    { showPassword ? (
                      <VisibilityOutlinedIcon
                        className = { `text-grey absolute bottom-2 right-3 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }
                      />
                    ) : (
                      <VisibilityOffOutlinedIcon
                        className = { `text-grey absolute bottom-2 right-3 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }
                      />
                    ) }
                  </span>
                  
                </li>
                <li>
                  <Link to = '/forgot-password' className = 'block text-xs text-grey-600 text-right mt-1 hover:text-primary'>Forgot Password</Link>
                </li>
                <li className = 'flex items-center justify-center mt-8'>
                  <button
                    type = 'submit'
                    className = { 'block w-6/12 bg-inputcolor rounded-full lg:rounded-full font-medium px-4 py-2.5 text-sm ' }
                    disabled = { !isFormValid || isLoading }
                  >
                    { isLoading ? 'Signing...' : capitalizeFirstLetter(t('Signin')) }
                  </button>
                </li>
              </ul>
            </form>
            <ul>
              <li className = 'flex items-center justify-center mb-3'>
                <p className = { 'text-sm text-grey text-center w-full py-2.5 ' }>{ t('Donthaveanaccount') } <Link className = 'text-primary font-bold' to = "/signup">{ capitalizeFirstLetter(t('signup')) }</Link></p>
              </li>
              { /* <li className = 'flex items-center justify-center gap-3 mt-5'>
                <p className = 'w-20 bg-sky h-0.5'></p>
                <p className = 'text-xs text-grey'>other way to sign in</p>
                <p className = 'w-20 bg-sky h-0.5'></p>
              </li>
              <li className = 'flex-col flex justify-center items-center pt-4'>
                <div
                  className = 'text-center w-6/12 rounded-full bg-inputcolor px-1 py-2 '
                >
                  <Googleauthentic />
                </div>
              </li> */ }
            </ul>
          </div>
        </div>
         
        <div className = 'hidden md:block w-width40% bg-inputcolor'>
          <div className = 'w-full h-screen flex items-center ml-[-80px]'>
            <img src = { bg } alt = "" className = 'w-width60%' />
          </div>
        </div>

      </div>
    </>
  );
};

export default Login;