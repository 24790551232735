import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';

const ShippingPolicy = () => {

  return(
    <>
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = 'text-white text-xl lg:text-2xl text-center font-semibold uppercase'>Shipping Policy</h3>
      </div>
      <div className = 'container mx-auto px-5 lg:px-10'>
        <ul>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-10'>1. SHIPPING METHODS</h3>
            <p className = 'text-sm lg:text-base text-grey mt-8'>All orders are shipped worldwide via our affiliate courier DHL. All shipments that are sent by Mengotti Couture, are insured against theft and accidental damage. Upon receipt of shipment and written acknowledgement, this insurance coverage ceases to exist. Please note that shipping costs will not be refunded in case of return. MENGOTTICOUTURE.COM ships worldwide using DHL or TNT but does not deliver to P.O. Boxes. In case of partial shipments, shipping costs will be charged only once. We process and send orders during our main service hours, Monday to Friday, from 8:00 am till 8:00 pm CET and Saturday, from 9:00 am till 6:00 pm CET (with the exception of German public holidays). The merchandise will be ready for dispatch 7 – 10 business day after the receipt of payment. Mengotti Couture Awaits Credit card Processer approvals before shipping.</p>
          </li>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>2.FEES</h3>
            <p className = 'text-sm lg:text-base text-grey mt-10'>Express shipping cost is Shown upon Checkout</p>
          </li>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>3. IMPORT DUTIE FEES</h3>
            <p className = 'text-sm lg:text-base text-grey mt-6'>Orders are Occasionally subject to import fees. Payment of these import fees is the sole responsibility of the Client. Please be aware that sales taxes, customs duties, and handling costs will be charged by DHL on an individual basis directly upon shipment as far as non-European Union (EU) countries are concerned, and will not be refunded by Mengotti Couture in case the articles are returned.</p>
            <p className = 'text-sm lg:text-base text-grey mt-6'>Shipments destined for countries inside & outside the European Union contain an invoice and all any documentation for declaring the value of the merchandise.</p>
            <p className = 'text-sm lg:text-base text-grey mt-6'>All shipments destined for countries inside & outside the European Union, as required by Customs regulations, are accompanied by an invoice declaring the value of the merchandise.</p>
          </li>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>4. PACKAGING</h3>
            <p className = 'text-sm lg:text-base text-grey mt-10'>At Mengotti Couture we always strive to find environmentally friendly solutions for a luxury shopping experience. All of our designer pieces are shipped in eco-friendly packaging</p>
          </li>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>5. WHERE WE SEND YOUR DATA</h3>
            <p className = 'text-sm lg:text-base text-grey mt-10'>Visitor comments may be checked through an automated spam detection service.</p>
          </li>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>6. Why we do not ship Perfumes & Fragrances</h3>
            <p className = 'text-sm lg:text-base text-grey mt-10'>Dear Customer We Do NOT ship perfumes and fragrances at the moment due to the new law of international flammable items shipping movement, all major shippers in our location have reduced this fleet at the moment</p>
            <p className = 'text-sm lg:text-base text-grey mt-6'>Visitor comments may be checked through an automated spam detection service.</p>
          </li>
        </ul>
        <Footer />
      </div>
    </>
  );
};

export default ShippingPolicy;