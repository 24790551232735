
import React from 'react';
import Slider from 'react-slick';

const FragranceBanner = () => {

  const settings = {
    infinite: true,
    speed: 500,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    cssEase: 'linear'
  };

  return (
    <>
      <Slider { ...settings }>
        <div>
          <img
            className = "w-full"
            src = "https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvZGVza3RvcF81XzZmMzM1MzY1NTUuanBn"
            alt = "Banner 1"
          />
          
        </div>
        <div>
          <img
            className = "w-full"
            src = "https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvZGVza3RvcF8zXzkyN2IyM2E4NzkuanBn"
            alt = "Banner 2"
          />
        </div>
        <div>
          <img
            className = "w-full"
            src = "https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvZGVza3RvcF8yXzM2NDU2OWRhZDcuanBn"
            alt = "Banner 3"
          />
        </div>
        <div>
          <img
            className = "w-full"
            src = "https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvZGVza3RvcF8xXzU0MjI1NWQ3NmYuanBn"
            alt = "Banner 4"
          />
        </div>
        <div>
          <img
            className = "w-full"
            src = "https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvZGVza3RvcF8wX2Y5MzUyMTBhNjcuanBn"
            alt = "Banner 5"
          />
        </div>
      </Slider>
    </>
  );
};

export default FragranceBanner;
