import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import { useGetorderDetailsQuery } from '../redux/api/productsApi';


const OrderDetails = () => {
 
  const { orderid } = useParams();

  const {error, data, isLoading } = useGetorderDetailsQuery(orderid);
  const orderDetails = data?.data?.order_details;
  const shipping_details = data?.data?.shipping_details;
  const product_details = data?.data?.product_details;
  // Function to format date and time
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    });
  };

  return (
    <>
      <Header />
      <Navigation />
      <div className = 'container mx-auto px-5 lg:px-0'>
        {
          error && <p className = 'text-sm text-red'>{ error?.data?.message }</p>
        }
        {
          isLoading && <p>{ isLoading?'Loading...': '' }</p>
        }
        <div className = 'w-full mt-8 hidden md:block'>
          <div className = 'flex justify-between text-sm font-medium'>
            <div className = 'flex gap-3'>
              <p>Ordered on { orderDetails ? formatDateTime(orderDetails.date) : '' }</p>
              <div className = 'h-4 w-px bg-gray-300'></div>
              <p>Order# <bdi>{ orderDetails?.order_id }</bdi></p>
            </div>
            <Link to = '/' className = 'hover:text-red-800'>Invoice</Link>
          </div>
        </div>
        <div className = 'w-full mt-2 p-5 border border-boderColor-300 rounded mx-auto hidden md:block'>
          <div className = ' flex justify-between text-sm font-medium'>
            <ul className = 'w-width25%'>
              <li className = 'font-bold'>Shipping Address</li>
              <li className = 'pt-1'>Phone: { shipping_details?.phone === null?'NA': shipping_details?.phone  }</li>
              <li className = 'pt-1'>Address: { shipping_details?.address === null?'NA': shipping_details?.address } </li>
            </ul>
            <ul className = 'w-width25%'>
              <li className = 'font-bold'>Payment Methods</li>
              <li className = 'pt-1'>{ data?.data?.payment_details?.payment_method }</li>
            </ul>
            <div className = 'w-width40% flex justify-between'>
              <ul>
                <li className = 'font-bold'>Order Summary</li>
                <li className = 'pt-1'>Item(s) Subtotal:</li>
                <li className = 'pt-1'>Shipping:</li>
                <li className = 'pt-1'>Vat:</li>
                <li className = 'pt-1'>Total:</li>
                <li className = 'pt-1 font-bold'>Grand Total:</li>
              </ul>
              <ul>
                <li><br /></li>
                <li className = 'pt-1'> SAR { data?.data?.subtotal }</li>
                <li className = 'pt-1'> SAR { data?.data?.shipping_cost }</li>
                <li className = 'pt-1'> SAR { data?.data?.vat }</li>
                <li className = 'pt-1'> SAR { data?.data?.total_amount }</li>
                <li className = 'pt-1 font-bold'> SAR { data?.data?.total_amount }</li>
              </ul>
            </div>
          </div>
        </div>

        {
          product_details?.length > 0 &&

            product_details.map((item) => {
              return(
                <div key = { item?.product_id } className = 'w-full mt-2 p-5 border border-boderColor-300 rounded mx-auto hidden md:block'>
                  <div className = 'flex justify-between gap-4'>
                    <div className = 'w-32'><Link to = { `/product/${item?.product_id}` } className = 'block w-full'><img src = { item?.images[0] } alt = "عطر ازارو دو او دو تواليت 80مل" className = 'w-full object-contain'  /></Link></div>
                    <div className = 'w-width60%'>
                      <Link to = { `/brand/shop/${item?.brand}` } className = 'text-primary font-bold block text-sm hover:text-primary hover:underline delay-200'>{ item?.brand }</Link>
                      <Link to = { `/product/${item?.product_id}` } className = 'wrap text-sm hover:underline font-medium text-[#007185] hover:text-primary font-madani-arabic'>{ item?.name }
                      </Link>
                      <p className = 'text-xs text-primary font-medium mt-2'>SAR { item?.price }</p>
                      <Link to = { `/product/${item?.product_id}` } className = 'inline-block bg-primary hover:bg-primary-600 text-white py-2 px-8 rounded text-xs font-medium mt-4'>View</Link>
                    </div>
                    <div className = 'w-width25% flex flex-col '>
                      <Link to = { `/product/${item?.product_id}` } className = 'border border-borderColor rounded py-2 px-8 hover:bg-gray-100 shadow-md text-nowrap text-xs font-medium'>Ask Product Question</Link>
                      <Link to = { `/product/${item?.product_id}` } className = 'border border-borderColor rounded py-2 px-8 hover:bg-gray-100 shadow-md text-nowrap text-xs font-medium my-3'>Write a Product review</Link>
                      <button className = 'border border-borderColor rounded py-2 px-8 hover:bg-gray-100 shadow-md text-nowrap text-xs font-medium '>Cancel</button>
                    </div>
                  </div>
                </div>
              );
            })
        }

        { /* small device */ }

        <div className = 'mb-4  md:hidden block'>
          <h2 className = 'text-lg font-semibold mt-8'>View order details</h2>
          <div className = 'my-2 border border-boderColor-300 rounded p-3 text-sm font-medium'>
            <div className = 'pt-1'>
              <span className = ' font-medium text-[#595959] block w-28'>Order date</span>
              <span className = 'text-sm'>{ orderDetails ? formatDateTime(orderDetails.date) : '' }</span>
            </div>
            <div className = 'pt-2'>
              <span className = ' font-medium text-[#595959] block w-28'>Order #</span>
              <span className = 'text-sm'><bdi>{ orderDetails?.order_id }</bdi></span>
            </div>
          
            <div className = 'border-b border-borderColor my-3'></div>
            <Link to = '/' className = 'flex justify-between'>
              <span className = 'font-medium'>Download Invoice</span>
              <span><KeyboardArrowRightIcon style = { {fontSize: '18px'} }/></span>
            </Link>
          </div>
         
          <h2 className = 'text-base font-semibold mt-5'>Payment information</h2>
          <div className = 'my-2 text-sm'>
            <div className = 'mb-3 flex justify-between border border-boderColor-300 rounded p-3 text-sm'>
              <p className = 'text-sm font-medium text-[#595959]'>Payments Methods</p>
              { /* <p className = 'text-sm'>{ data?.data?.payment_details[0]?.payment_method?data?.data?.payment_details[0]?.payment_method: 'NA' }</p> */ }
            </div>
          </div>
          <h2 className = 'text-base font-semibold mt-5'>Shipping Address</h2>
          <div className = 'my-2 p-3 border border-boderColor-300 rounded  text-sm font-medium'>
            <div className = 'flex justify-between'>
              <p className = 'text-sm font-medium text-[#595959]'>Phone:</p>
              <p className = 'text-sm'>{ shipping_details?.phone === null?'NA': shipping_details?.phone  }</p>
            </div>
            <p className = 'text-sm mt-2'> { shipping_details?.address === null?'NA': shipping_details?.address }</p>
            
          </div>
          <h2 className = 'text-base font-semibold mt-5'>Order Summary</h2>
          <div className = 'mt-2 p-3 border border-boderColor-300 rounded text-sm font-medium'>
            <ul>
              <li>
                <div className = 'flex justify-between'>
                  <p className = 'text-sm text-[#595959]'>Item(s) Subtotal:</p>
                  <p className = 'text-sm text-black'>SAR { data?.data?.subtotal }</p>
                </div>
              </li>
              <li className = 'mt-2'>
                <div className = 'flex justify-between'>
                  <p className = 'text-sm text-[#595959]'>Shipping:</p>
                  <p className = 'text-sm text-black'>SAR { data?.data?.shipping_cost }</p>
                </div>
              </li>
              <li className = 'mt-2'>
                <div className = 'flex justify-between'>
                  <p className = 'text-sm text-[#595959]'>Vat:</p>
                  <p className = 'text-sm text-black'>SAR { data?.data?.vat }</p>
                </div>
              </li>
              <li className = 'mt-2'>
                <div className = 'flex justify-between'>
                  <p className = 'text-sm text-[#595959]'>Total:</p>
                  <p className = 'text-sm text-black'>SAR { data?.data?.total_amount }</p>
                </div>
              </li>
              <li className = 'mt-2'>
                <div className = 'flex justify-between'>
                  <p className = 'text-sm font-bold text-[#595959]'>Grand Total:</p>
                  <p className = 'text-sm font-bold text-black'>SAR { data?.data?.total_amount }</p>
                </div>
              </li>
            </ul>
          </div>
        
          {
            product_details?.length > 0 &&

            product_details.map((item) => {
              return(
                <div key = { item?.product_id } className = 'w-full mt-5 p-4 border border-boderColor-300 rounded mx-auto'>
                  <div className = 'gap-3'>
                    <div className = 'w-full'><Link to = { `/product/${item?.product_id}` } className = 'block w-full'><img src = { item?.images[0] } alt = "عطر ازارو دو او دو تواليت 80مل" className = 'w-full object-contain'  /></Link></div>
                    <div className = 'w-full'>
                      <Link to = { `/brand/shop/${item?.brand}` } className = 'text-primary font-bold block text-sm hover:text-primary hover:underline delay-200'>{ item?.brand }</Link>
                      <Link to = { `/product/${item?.product_id}` } className = 'wrap text-sm hover:underline font-medium text-[#007185] hover:text-primary font-madani-arabic'>{ item?.name }
                      </Link>
                      <p className = 'text-xs text-primary font-medium mt-2'>SAR { item?.price }</p>
                      <Link to = { `/product/${item?.product_id}` } className = 'inline-block bg-primary hover:bg-primary-600 text-white py-2 px-8 rounded text-xs font-medium mt-4'>View</Link>
                    </div>
                    <div className = 'w-full flex flex-col mt-8'>
                      <Link to = { `/product/${item?.product_id}` } className = 'border border-borderColor rounded py-2 px-8 hover:bg-gray-100 shadow-md text-nowrap text-xs font-medium'>Ask Product Question</Link>
                      <Link to = { `/product/${item?.product_id}` } className = 'border border-borderColor rounded py-2 px-8 hover:bg-gray-100 shadow-md text-nowrap text-xs font-medium my-3'>Write a Product review</Link>
                      <button className = 'border border-borderColor rounded py-2 px-8 hover:bg-gray-100 shadow-md text-nowrap text-xs font-medium '>Cancel</button>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
        <Footer />
      </div>
    </>
  );
};

export default OrderDetails;