import i18n from 'i18next';
import React, { useEffect, useRef } from 'react';
import { initReactI18next } from 'react-i18next';
import tAr from '../language/ar/Arabic.json';
import tEn from '../language/en/EnglishLanguage.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: tEn },
    ar: { translation: tAr }
  },
  lng: localStorage.getItem('selectLanguage') || 'en',
  fallbackLng: 'en',
  interpolation: { escapeValue: false }
});

const Multilanguage = () => {
  const languageRef = useRef();

  useEffect(() => {
    const changeLang = () => {
      const selectedLanguage = languageRef.current.value;
      localStorage.setItem('selectLanguage', selectedLanguage);
      i18n.changeLanguage(selectedLanguage);
    };

    changeLang();
  }, []);

  const handleLanguageChange = () => {
    const selectedLanguage = languageRef.current.value;
    localStorage.setItem('selectLanguage', selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <div>
      <select className = 'bg-white border-primary text-xs md:text-sm py-1 text-balck rounded-md outline-none' onChange = { handleLanguageChange } ref = { languageRef } defaultValue = { localStorage.getItem('selectLanguage') }>
        <option value = "en">EN</option>
        <option value = "ar">AR</option>
      </select>
    </div>
  );
};

export default Multilanguage;
