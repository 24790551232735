import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Loader from '../components/Loader';
import Navigation from '../components/Navigation';
import TopHeader from '../components/TopHeader';
import { useBloglistQuery } from '../redux/api/recomendedproductApi';

const Blog = () => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const {error, data, isLoading} = useBloglistQuery();

  useEffect(() => {
    if(error){
      toast(error.data?.message);
    }
  },[]);

  const truncateDescription = (description, maxLength) => {
    if (description.length <= maxLength) {
      return description;
    }
    return description.substring(0, maxLength) + '...';
  };

  const maxLength = 200;

  return(
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = { `text-white text-xl lg:text-2xl text-center font-semibold uppercase ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Blog') }</h3>
      </div>
      <div className = "container mx-auto px-5 lg:px-10">
        <div className = 'flex justify-center item-center flex-col pb-8'>
          <h2 className = "text-xl lg:text-3xl text-black font-semibold text-center pt-5 lg:pt-10">OUR BLOGS & LATEST ARTICOLES</h2>
          <p className = "text-grey text-sm mb-5 mt-3 text-center"> Stay Up-To date With The Latest  Wearing What And  Trending All Over The World</p>
        </div>
        <div>
          <div className = "mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {
              isLoading && <Loader />
            }
            { data?.data?.map((postitem) => (
              <article key = { postitem?.blog_id } className = "flex max-w-xl flex-col items-start justify-between shadow-custom">
                <Link to = { `/blog/${postitem?.blog_id}` }>
                  <div className = 'w-full'>
                    <img className = 'w-full' src = { postitem?.image } alt = "blog" />
                  </div>
                  <div className = 'p-5'>
                    <div className = "flex items-center gap-x-4 text-xs">
                      <span className = "text-gray-500">
                        { postitem.date }
                      </span>
                      <Link
                        to = { `/blog/${postitem?.blog_id}` }
                        className = "relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                      >
                        { postitem?.label }
                      </Link>
                    </div>
                    <div className = "group relative">
                      <h3 className = "mt-3 text-lg font-semibold leading-6 text-black group-hover:text-gray-600">
                        <p>
                          <span className = "absolute inset-0" />
                          { postitem.title }
                        </p>
                      </h3>
                      <p className = "mt-5 line-clamp-3 text-sm leading-6 text-grey">{ truncateDescription(postitem?.description, maxLength) }</p>
                    </div>
                  
                  </div>
                </Link>
              </article>
            )) }
          </div>
        </div>
        <Footer />
       
      </div>  
    </>
  );
};

export default Blog;