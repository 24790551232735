import React from 'react';
import { useTranslation } from 'react-i18next';

const ShipingDelivery = () => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');
  

  return(
    <>
      <ul className = { `list-disc ml-5 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>
        <li><p className = { `text-sm text-grey ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Ordersareprocessedwithin1-3') }</p></li>
        <li><p className = { `text-sm text-grey mt-2 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('InternationalShipping') }</p></li>
        <li><p className = { `text-sm text-grey mt-2 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Ordersplaced') }</p></li>
        <li><p className = { `text-sm text-grey mt-2 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('OrderswithStandard') }</p></li>
        <li><p className = { `text-sm text-grey mt-2 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Perfumes&FragrancesExport') }</p></li>
      </ul>
    </>
  );
};

export default ShipingDelivery;