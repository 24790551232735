/* eslint-disable react/jsx-key */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import ShopProduct from '../pages/products/ShopProduct';
import { useFilterProductsMutation } from '../redux/api/recomendedproductApi';
import { showToast } from '../redux/features/toastSlice';
import CustomPagination from './CustomPagination';
import Filtter from './Filtter';
import Footer from './Footer';
import Header from './Header';
import Navigation from './Navigation';

const Brandshop = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const minVal = useSelector((state) => state.range.minVal);
  const maxVal = useSelector((state) => state.range.maxVal);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [rightfilter, setRightfilter] = useState('BestSeller');
  let {brandcat} = useParams();

  useEffect(() => {

  },[brandcat]);

  const resPerPage = 50;
  const [currentPage, setCurrentPage] = useState(1);
  const setCurrentPageNo = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);


  const [getProducts, {error,isLoading, data}] = useFilterProductsMutation();
  useEffect(() => {
    const staticBody = {
      'category': selectedCategories,
      'brand': [...selectedBrands,brandcat],
      'price': {
        'minimum': minVal,
        'maximum': maxVal
      },
      'sort_by': rightfilter
    };
    getProducts({ body: staticBody, size: resPerPage, page: currentPage });
  }, [brandcat, selectedBrands,selectedCategories, getProducts,minVal,maxVal,rightfilter,currentPage]);

  useEffect(() => {
    if (error) {
      dispatch(showToast(error?.data?.message));
    }
  }, [error]);


  const handlerightsidefilter = (e) => {
    setRightfilter(e.target.value);
  };

  return (
    <>
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = 'text-white text-xl lg:text-2xl text-center font-semibold uppercase'>{ brandcat }</h3>
      </div>
      <div className = 'container mx-auto px-5 lg:px-0'>
        <div className = 'flex'>
          <div className = 'w-56 hidden md:block'>
            <Filtter setSelectedBrands = { setSelectedBrands } setSelectedCategories = { setSelectedCategories } />
          </div>
          <div className = 'ml-0 md:ml-5 flex-1'>
            <p className = { `self-center text-base text-grey block md:hidden mr-5 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ data?.total?data?.total: 0 } { t('Products') }</p>
            <ul className = 'flex mb-2 md:hidden'>
              <li>
                <Link className = { `inline-block text-grey text-sm hover:text-primary ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } to = "/">{ t('Home') }</Link>
              </li>
              <li>
                <Link className = 'inline-block text-grey text-sm ml-2' to = "/">/</Link>
              </li>
              <li>
                <Link className = { `inline-block text-grey text-sm ml-2 hover:text-primary ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } to = "/all-brand">{ t('Brandshop') }</Link>
              </li>
              <li>
                <Link className = 'inline-block text-grey text-sm ml-2' to = "/">/</Link>
              </li>
              <li>
                <span className = 'inline-block text-black text-sm ml-2'>{ brandcat }</span>
              </li>
            </ul>
            <div className = 'flex justify-between'>
              <div className = 'self-center'>
                <ul className = 'hidden mb-3 md:flex'>
                  <li>
                    <Link className = { `inline-block text-grey text-sm hover:text-primary ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } to = "/">{ t('Home') }</Link>
                  </li>
                  <li>
                    <Link className = 'inline-block text-grey text-sm ml-2' to = "/">/</Link>
                  </li>
                  <li>
                    <Link className = { `inline-block text-grey text-sm ml-2 hover:text-primary ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } to = "/all-brand">{ t('Brandshop') }</Link>
                  </li>
                  <li>
                    <Link className = 'inline-block text-grey text-sm ml-2' to = "/">/</Link>
                  </li>
                  <li>
                    <span className = 'inline-block text-black text-sm ml-2'>{ brandcat }</span>
                  </li>
                </ul>
                <div className = 'block md:hidden'>
                  <Filtter setSelectedBrands = { setSelectedBrands } setSelectedCategories = { setSelectedCategories } />
                </div>
              </div>
              <div className = 'flex self-center'>
                <p className = { `self-center text-base text-grey hidden md:block mr-5 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ data?.total?data?.total: 0 } { t('Products') }</p>
                <select onChange = { handlerightsidefilter } className = { `border border-[#E5E7EB] text-sm lg:text-base ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>
                  <option value = "BestSeller">{ t('BestSellers') }</option>
                  <option value = 'TopToLow'>{ t('ToptoLow') }</option>
                </select>
              </div>
            </div>
            <div className = 'mt-3'>
              <div className = 'flex flex-wrap gap-1 md:gap-2'>
                {
                  isLoading && <Loader />
                }
                {
                  data?.data.length === 0 && <p className = { `text-sm text-blacklight ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Noproductfoundforthiscategory') }</p>
                }
                { data?.data?.map((product) => (
                  <ShopProduct product = { product } key = { product?.id } />
                )) }
              </div>
            </div>
            <CustomPagination
              resPerPage = { resPerPage }
              filteredProductsCount = { data?.total || 0 }
              onChange = { setCurrentPageNo }
            />
          </div>
        </div>
        <Footer />
      </div>

      
    </>
  );
};

export default Brandshop;