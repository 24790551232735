/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

const ProductAditionalinformation = ({data}) => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');

  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };
  return(
    <>
      <div className = 'w-full lg:w-11/12'>
        <ul>
          <li>
            <div className = 'flex justify-between py-3'>
              <p className = { `text-sm text-black ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ capitalizeFirstLetter(t('Size')) }</p>
              <p className = { `text-sm text-grey ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>100 { t('ML') }</p>
            </div>
            <hr></hr>
          </li>
          <li>
            <div className = 'flex justify-between py-3'>
              <p className = { `text-sm text-black ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ capitalizeFirstLetter(t('BRANDS')) }</p>
              <p className = { 'text-sm text-grey' }>{ data?.brand }</p>
            </div>
            <hr></hr>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ProductAditionalinformation;