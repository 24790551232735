/* eslint-disable react/prop-types */
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const PaymentForm = ({totalamoutpay}) => {
  const [checkoutId, setCheckoutId] = useState(null);


  useEffect(() => {
    const prepareCheckout = async () => {
      const url = 'https://eu-test.oppwa.com/v1/checkouts';
      const data = new URLSearchParams({
        entityId: '8a8294174b7ecb28014b9699220015ca',
        amount: totalamoutpay,
        currency: 'EUR',
        paymentType: 'DB'
      });
      const headers = {
        'Authorization': 'Bearer OGE4Mjk0MTc0YjdlY2IyODAxNGI5Njk5MjIwMDE1Y2N8c3k2S0pzVDg=',
        'Content-Type': 'application/x-www-form-urlencoded'
      };

      try {
        const response = await axios.post(url, data, { headers });
        setCheckoutId(response.data.id);
        console.log('response.data.id',response.data.id);
      } catch (error) {
        console.error('Error preparing checkout:', error);
      }
    };
    if(totalamoutpay){
      prepareCheckout();
    }
    
  }, [totalamoutpay]);

  useEffect(() => {
    if (checkoutId) {
      const script = document.createElement('script');
      script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
      script.async = true;
      document.body.appendChild(script);
    }
  }, [checkoutId]);

  return (
    <div>
      { checkoutId ? (
        <form
          action = "http://dimas.com.sa/payment-status"
          className = "paymentWidgets"
          data-brands = "VISA MASTER AMEX"
        ></form>
      ) : (
        <div>Loading...</div>
      ) }
    </div>
  );
};

export default PaymentForm;