/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

const Tabbypromo = ({productPrice}) => {

  useEffect(() => {
    // Function to dynamically load external script
    const loadScript = (src, onLoad) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = onLoad;
      document.body.appendChild(script);
    };
    
    // Load the TabbyPromo script
    loadScript('https://checkout.tabby.ai/tabby-promo.js', () => {
      new window.TabbyPromo({});
    });
  }, []);

  return (
    <>
      <button type = "button"
        data-tabby-info = "installments"
        data-tabby-price = { productPrice }
        data-tabby-currency = "SAR" className = 'self-center cursor-pointer text-primary text-xs'> 
        info
      </button>
    </>
  );
};

export default Tabbypromo;
