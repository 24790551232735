/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { Dialog, Transition } from '@headlessui/react';
import CloseIcon from '@mui/icons-material/Close';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import dummyimg from '../images/dummyimg.png';
import { useAddtocardlistQuery, useDeletecartitemMutation } from '../redux/api/productsApi';
import { removeItemFromCart, selectCartItems } from '../redux/features/cartSlice';
import { setCartopen } from '../redux/features/opencardSlice';
import { showToast } from '../redux/features/toastSlice';

export default function ShoppingCartPopup({ setCartOpen, isCartOpen }) {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const { cartopen } = useSelector((state) => state.opencard);
  const dispatch = useDispatch();
  const userid = useSelector((state) => state.auth?.user?.id);

  const { error, data: addtocartlistdata } = useAddtocardlistQuery(userid, { skip: !userid });
  const cartItems = useSelector(selectCartItems);

  useEffect(() => {
    if (error) {
      dispatch(showToast(error?.data?.message));
    }
  }, [error]);

  const handleCloseModel = () => {
    setCartOpen(false);
    dispatch(setCartopen(false));
  };

  const [deletecart, { error: deletecarterror }] = useDeletecartitemMutation();

  useEffect(() => {
    if (deletecarterror) {
      dispatch(showToast(deletecarterror?.data?.message));
    }
  }, [deletecarterror]);

  const removeCartItemHandler = (id, optionsId) => {
    if(userid){
      const data = {
        id: id,
        quantity: 0
      };
      deletecart(data);

    }else{
      dispatch(removeItemFromCart({ id, options: { id: optionsId } }));
    }

  };

  const calculateSubtotal = () => {
    if (userid) {
      return addtocartlistdata?.data.reduce(
        (acc, item) => acc + (item?.options?.selling_price * item?.quantity),
        0
      ) || 0;
    } else {
      return cartItems.reduce(
        (acc, item) => acc + (item?.options?.selling_price * item?.quantity),
        0
      ) || 0;
    }
  };

  const itemCount = userid? addtocartlistdata?.data.length : cartItems?.length;

  return (
    <>
      <Transition.Root show = { isCartOpen || cartopen } as = { Fragment }>
        <Dialog as = "div" className = "relative z-50" onClose = { handleCloseModel }>
          <Transition.Child
            as = { Fragment }
            enter = "ease-in-out duration-500"
            enterFrom = "opacity-0"
            enterTo = "opacity-100"
            leave = "ease-in-out duration-500"
            leaveFrom = "opacity-100"
            leaveTo = "opacity-0"
          >
            <div className = "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className = "fixed inset-0 overflow-hidden">
            <div className = "absolute inset-0 overflow-hidden">
              <div className = "pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as = { Fragment }
                  enter = "transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom = "translate-x-full"
                  enterTo = "translate-x-0"
                  leave = "transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom = "translate-x-0"
                  leaveTo = "translate-x-full"
                >
                  <Dialog.Panel className = "pointer-events-auto w-screen max-w-md">
                    <div className = "flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className = "flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                        <div className = "flex items-start justify-between">
                          <Dialog.Title className = { `text-lg font-medium text-gray-900 ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }>
                            { t('Shoppingcart') }
                          </Dialog.Title>
                          <div className = "ml-3 flex h-7 items-center">
                            <button
                              type = "button"
                              className = "relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                              onClick = { handleCloseModel }
                            >
                              <span className = "absolute -inset-0.5" />
                              <span className = "sr-only">Close panel</span>
                              <CloseIcon />
                            </button>
                          </div>
                        </div>

                        <div className = "mt-8">
                          <div className = "flow-root">
                            <ul role = "list" className = "-my-6 divide-y divide-gray-200">
                              { itemCount === 0 ? (
                                <p className = { `${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }>
                                  { t('Yourcartisempty') }
                                </p>
                              ) : (
                                userid ? (
                                  addtocartlistdata?.data.map((product, index) => (
                                    <li key = { index } className = "flex py-6">
                                      <div className = "h-16 w-16 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                        <img
                                          src = { product?.product?.images[0] || dummyimg }
                                          alt = "product"
                                          className = "h-full w-full object-cover object-center"
                                        />
                                      </div>
                                      <div className = "ml-4 flex flex-1 flex-col">
                                        <div>
                                          <div className = "flex justify-between text-base font-medium text-gray-900">
                                            <h3>
                                              <Link to = { `/product/${product?.product?.id}` } className = { selectLanguage === 'ar' ? 'font-madani-arabic' : 'font-madani-arabic' }>
                                                { product?.product?.name }
                                              </Link>
                                            </h3>
                                            <p className = "ml-4">{ product?.options?.selling_price }</p>
                                          </div>
                                        </div>
                                        <div className = "flex flex-1 items-end justify-between text-sm">
                                          <p className = { `text-gray-500 ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }>
                                            { t('Qty') } { product?.quantity }
                                          </p>
                                          <div className = "flex">
                                            <button
                                              onClick = { () => removeCartItemHandler(product?.id, product?.options?.id) }
                                              type = "button"
                                              className = { `font-medium text-indigo-600 hover:text-indigo-500 ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }
                                            >
                                              { t('Remove') }
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  ))
                                ) : (
                                  cartItems.map((product, index) => (
                                    <li key = { index } className = "flex py-6">
                                      <div className = "h-16 w-16 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                        <img
                                          src = { product?.images[0]?product?.images[0] : dummyimg }
                                          alt = "product"
                                          className = "h-full w-full object-cover object-center"
                                        />
                                      </div>
                                      <div className = "ml-4 flex flex-1 flex-col">
                                        <div>
                                          <div className = "flex justify-between text-base font-medium text-gray-900">
                                            <h3>
                                              <Link to = { `/product/${product?.id}` } className = { selectLanguage === 'ar' ? 'font-madani-arabic' : '' }>
                                                { product?.name }
                                              </Link>
                                            </h3>
                                            <p className = "ml-4">{ product?.options?.selling_price }</p>
                                          </div>
                                        </div>
                                        <div className = "flex flex-1 items-end justify-between text-sm">
                                          <p className = { `text-gray-500 ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }>
                                            { t('Qty') } { product?.quantity }
                                          </p>
                                          <div className = "flex">
                                            <button
                                              onClick = { () => removeCartItemHandler(product?.id, product?.options?.id) }
                                              type = "button"
                                              className = { `font-medium text-indigo-600 hover:text-indigo-500 ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }
                                            >
                                              { t('Remove') }
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  ))
                                )
                              ) }
                            </ul>


                          </div>
                        </div>
                      </div>

                      <div className = "border-t border-gray-200 px-4 py-6 sm:px-6">
                        <div className = "flex justify-between text-base font-medium text-gray-900">
                          <p className = { `${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }>{ t('Subtotal') }</p>
                          <p>SAR { calculateSubtotal() }</p>
                        </div>
                        <p className = { `mt-0.5 text-sm text-gray-500 ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }>
                          { t('Shippingtaxescalculatedcart') }
                        </p>
                        <div className = "mt-6">
                          <Link
                            to = "/shoping-cart"
                            onClick = { handleCloseModel }
                            className = { `flex items-center justify-center rounded-md border border-transparent bg-primary px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-primary-600 ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }
                          >
                            { t('Gotocart') }
                          </Link>
                        </div>
                        <div className = "mt-6 flex justify-center text-center text-sm text-gray-500">
                          <p className = { `${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }>
                            { t('or') }{ ' ' }
                            <button
                              type = "button"
                              className = { `font-medium text-primary hover:text-indigo-500 ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }
                              onClick = { handleCloseModel }
                            >
                              { t('ContinueShopping') }
                              <span aria-hidden = "true"> &rarr;</span>
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
