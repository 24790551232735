import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import React, { useState } from 'react';

const ProductQuatity =() => {

  const [count, setCount] = useState(0);

  const Handleincrement = () => {

    setCount(count + 1);
  };


  const HandleDecrement = () => {

    setCount(count - 1);
  };


  return(
    <>
      
      <div className = 'inline-block py-1 border'>
        <button className = 'inline-block px-2' onClick = { HandleDecrement }><RemoveOutlinedIcon style = { {fontSize: '16px'} } /></button>
        <span className = 'text-base'>{ count }</span>
        <button className = 'inline-block px-2' onClick = { Handleincrement }><AddOutlinedIcon style = { {fontSize: '16px'} } /></button>
      </div>
    </>
  );
};

export default ProductQuatity;