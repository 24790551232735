/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useCategorylistQuery, useScentfamilylistQuery } from '../redux/api/recomendedproductApi';

const ShopDropdown = ({ isHoveredshop,setIsHoveredshop }) => {
  const selectLanguage = localStorage.getItem('selectLanguage');

  const { error, data } = useCategorylistQuery();

  const categories = data?.data || [];

  const { error: errorscentfamily, data: datascentfamily } = useScentfamilylistQuery();

  const scentfamily = datascentfamily?.data || [];

  const handleshopDropdown = () => {
    setIsHoveredshop(false);
  };

  return (
    <>
      <div className = { `bg-white border-t border-boderColor-300 absolute z-20 left-0 top-30 w-full block ${isHoveredshop ? 'block duration-150' : 'hidden'}` }>
        <div className = 'container mx-auto px-5 lg:px-10'>
          <div className = 'flex justify-around py-5'>
            <div></div>
            <div>
              <ul className = ' capitalize'>
                <li>
                  <h3 className = 'text-base font-semibold text-blacklight'>Category</h3>
                </li>
                {
                  error && <p className = 'text-sm text-red'>{ error?.data?.message }</p>
                }
                { categories?.map((item) => (
                  <li key = { item?.item_id }>
                    <Link to = { `/shop/${item?.name_en}` } onClick = { () => handleshopDropdown() } className = { `inline-block text-sm text-gray-600 mt-1.5 duration-200 hover:text-primary hover:underline ${selectLanguage == 'ar'?'font-madani-arabic': 'font-madani-arabic'}` }>
                      { selectLanguage == 'ar'?item?.name_ar: item?.name_en }
                    </Link>
                  </li>
                )) }
              </ul>
            </div>
            <div>
              <ul className = ' capitalize '>
               
                <li>
                  <h3 className = 'text-base font-semibold text-blacklight'>Scent family</h3>
                </li>
                {
                  errorscentfamily && <p className = 'text-sm text-red'>{ errorscentfamily?.data?.message }</p>
                }
                { scentfamily?.map((item) => (
                  <li key = { item?.item_id }>
                    <Link to = { `/shop/${item?.name_en}` } onClick = { () => handleshopDropdown() } className = { `inline-block text-sm text-gray-600 mt-1.5 duration-200 hover:text-primary hover:underline ${selectLanguage == 'ar'?'font-madani-arabic': 'font-madani-arabic'}` }>
                      { selectLanguage == 'ar'?item?.name_ar: item?.name_en }
                    </Link>
                  </li>
                )) } 
               
              </ul>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default ShopDropdown;
