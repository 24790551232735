import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NewProducts from './NewProducts';
import PopularItems from './PopularItems';
import TrendingItem from './TrendingItem';

const ProductsTabs = () => {
  const selectLanguage = localStorage.getItem('selectLanguage');
  const [activeTab, setActiveTab] = useState('trending');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const { t } = useTranslation();

  return (
    <>
      
      <div className = { `block md:flex border-b pt-10 md:pt-12 border-borderColor ${selectLanguage == 'ar'?'md:flex-row-reverse': 'md:flex-row'}` }>
        <button
          className = { `${selectLanguage == 'ar'?'font-madani-arabic': ''} text-black pb-3 text-left inline-block  text-sm lg:text-base  duration-300 ${
            activeTab === 'trending' ? 'border-b-4 border-primary font-bold' : 'border-none font-semibold'
          } ${selectLanguage == 'ar'? 'ml-0 md:ml-5': 'ml-0'}` }
          onClick = { () => handleTabClick('trending') }
        >
          { t('TRENDINGITEM') }
        </button>
        <button
          className = { `${selectLanguage == 'ar'?'font-madani-arabic': ''} text-black pb-3 text-left inline-block  text-sm lg:text-base px-2 md:px-5 md:ml-5 md:mt-0 ${
            activeTab === 'popular' ? 'border-b-4 border-primary font-bold' : 'border-none font-semibold'
          }` }
          onClick = { () => handleTabClick('popular') }
        >
          { t('POPULARITEMS') }
        </button>
        <button
          className = { `${selectLanguage == 'ar'?'font-madani-arabic': ''} text-black pb-3 text-left inline-block  text-sm lg:text-base ml-0 md:ml-5 md:mt-0 ${
            activeTab === 'new' ? 'border-b-4 border-primary font-bold' : 'border-none font-semibold'
          }` }
          onClick = { () => handleTabClick('new') }
        >
          { t('NEWPRODUCTS') }
        </button>
      </div>
      <div>
        { activeTab === 'trending' && <TrendingItem /> }
        { activeTab === 'popular' && <PopularItems /> }
        { activeTab === 'new' && <NewProducts /> }
      </div>
    </>
  );
};

export default ProductsTabs;
