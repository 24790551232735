/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useHomecatloglistQuery } from '../redux/api/recomendedproductApi';

const ShopByCategories = () => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');

  const {error, data} = useHomecatloglistQuery();

  const reversedData = data?.data ? [...data.data].reverse() : [];

  return (
    <>
      <div className = 'pt-12 md:pt-20'>
        <h3 className = { `text-black text-xl text-center font-semibold uppercase pb-2 md:pb-5 ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }>
          { t('SHOPBYCATEGORIES') }
        </h3>
      </div>
      {
        error && <p className = 'text-sm text-blacklight'>{ error?.data?.message }</p>
      }
      {
        data?.data?.length === 0 && <p className = 'text-sm text-blacklight'>{ data?.data?.length === 0?' No categories found': '' }</p>
      }
      <div className = 'flex flex-wrap justify-between'>
        {
          reversedData?.map((item) => {
            const itemUrl = item?.category_render_url.startsWith('http') ? item.category_render_url : `/shop/${item?.category_render_url}`;
            return(
              <Link key = { item?.id } to = { itemUrl } className = 'block w-width49% md:w-width23.5% relative mt-2 md:mt-5 group'>
                <div className = "relative">
                  <img src = { item?.image_url } alt = "catalog img" className = "w-full h-auto transform transition-transform duration-300 group-hover:scale-105" />
                  <div className = "absolute inset-0 bg-black bg-opacity-20 flex items-center justify-center duration-300 group-hover:bg-opacity-30">
                    <h3 className = "text-white text-lg">{ item?.name }</h3>
                  </div>
                </div>
              </Link>
            );
          })
        }
      </div>
    </>
  );
};

export default ShopByCategories;
