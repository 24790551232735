/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import TopHeader from '../../components/TopHeader';
import imgbanner from '../../images/bannerworks.jpeg';
import MainSubscriptionCollections from './MainSubscriptionCollections';


const BrandDrift = () => {
    
  return(
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = "container mx-auto px-5 lg:px-10">
        <div>
          <img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvaGVyb19pbWFnZV9kZXNrdG9wXzljZjI1N2Y1MzIuanBn' alt = 'banner' />
        </div>
        <div className = 'mt-10'>
          <h3 className = 'text-black text-4xl uppercase font-bold'>DRIFT</h3>
          <h4 className = 'text-base font-medium text mt-3'>Est in: USA <span> 6 products</span></h4>
        </div>
        <div className = 'flex justify-between'>
          <div className = 'w-width49%'>
            <p className = 'mt-5 text-base text-blacklight'>
            Drift is a car scent company that began as an alternative to all of the tacky
                air care products on the market. Our products are designed to blend in, with
                amazing scents that stand out. We take pride in our fragrances, keeping
                them free from the traditional chemicals used in the fragrance industry. We
                are always phthalate and paraben free. We never skimp on quality of our
                scents or the products that deliver them. Our design ethos is simple but
                effective. We love our community, those who won’t settle for ordinary, and we
                want to deliver a best in class experience.
            </p>
          </div>
        </div>
        <div className = 'mt-16'>
          <div className = 'flex justify-between'>
            <div className = 'w-width48%'>
              <img className = 'w-full mt-32' src = { imgbanner } alt = 'imgbanner' />
            </div>
            <div className = 'w-width48%'>
              <h3 className = 'text-black text-3xl uppercase font-bold'>HOW IT WORKS</h3>
              <h4 className = 'text-xl text-blacklight font-semibold mt-3'>Introducing Drift — a unique, fragrant,<br></br>
                customizable air freshener for your car
              </h4>
              <div className = 'bg-primary text-center p-5 rounded mt-5'>
                <h3 className = 'text-white text-lg font-bold'>1</h3>
                <p className = 'text-sm text-white'>Choose between a wood, stone, or metal freshener.</p>
              </div>
              <div className = 'bg-primary text-center p-5 rounded mt-5'>
                <h3 className = 'text-white text-lg font-bold'>2</h3>
                <p className = 'text-sm text-white'>
                The wood and stone options will fit onto a metal clip
                you'll affix to your car visor, while the metal option
                can be attached to your air conditioner vent.
                </p>
              </div>
              <div className = 'bg-primary text-center p-5 rounded mt-5'>
                <h3 className = 'text-white text-lg font-bold'>3</h3>
                <p className = 'text-sm text-white'>Your car scent will last up to 30 days.</p>
              </div>
            </div>
          </div>
        </div>
        <div className = 'mt-20'>
          <h3 className = 'text-3xl text-black font-bold text-center uppercase mt-8'>STARTER SETS</h3>
          <MainSubscriptionCollections />
        </div>
        <div className = 'mt-20'>
          <h3 className = 'text-3xl text-black font-bold text-center uppercase mt-8'>CAR SCENT REFILLS</h3>
          <MainSubscriptionCollections />
        </div>
        <div className = "w-full h-fit py-28 bg-cover bg-center mt-20" style = { {backgroundImage: 'url(\'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvZWRpdG9yaWFsX2Rlc2t0b3BfMjgyYTMxMWM1NC5qcGc=\')'} }>
          <div className = 'flex'>
            <div className = 'w-3/6'></div>
            <div className = 'w-3/6 relative'>
              <div className = 'bg-[#141414] text-white py-14 px-14 '>
                <span className = 'text-lg lg:text-xl font-semibold uppercase'>OUR MISSION</span>
                <p className = 'text-sm lg:text-base  mt-4'>
                Drift creates scents to give everyone a sense of place, reimagining
                how fragrance lives in your spaces. Our mission is to enhance your
                world, one scent at a time.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className = 'mt-20'>
          <img className = 'mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:750/aGVhZGxlc3Mvc2luZ2xlX2ltYWdlX2Rlc2t0b3BfMTc5YWMzOTZmYy5qcGc=' alt = 'banner' />
          <div className = 'w-8/12 mx-auto'>
            <div className = 'w-full h-px bg-primary mt-8'></div>
            <div className = 'text-center py-5'>
              <h4 className = 'text-base text-primary font-semibold'>A MODERN APPROACH TO FRAGRANCE</h4>
              <h3 className = 'text-2xl font-semibold mt-5'>“A perfume can really become a person. The <br></br>perfume is also a part of the personality.”</h3>
              <h4 className = 'text-lg text-primary font-semibold mt-5'>Madame Isabelle d’Ornano</h4>
              <p className = 'text-base text-blacklight mt-2'>Sisley Co-Founder</p>
            </div>
            <div className = 'w-full h-px bg-primary'></div>
          </div>
          <img className = 'mt-20' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvZm9vdGVyX2JhY2tncm91bmRfZGVza3RvcF8xMzk4NDViNmE5LmpwZw==' alt = 'banner' />
        </div>
        <Footer />
      </div>
      
    </>
  );
};

export default BrandDrift;