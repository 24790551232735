
import React from 'react';
import { useTabbyCheckoutMutation } from '../../redux/api/tabbyApi';
import Tabbycard from './Tabbycard';

const Tabby = () => {


  const [tabbyCheckout, { isLoading, isError }] = useTabbyCheckoutMutation();

  const handleCheckout = async () => {
    const payload = {
      'payment': {
        'amount': '100', 
        'currency': 'SAR', 
        'description': 'string',
        'buyer': {
          'phone': '500000001', 
          'email': 'card.success@tabby.ai', 
          'name': 'John Doe', 
          'dob': '1990-01-01' 
        },
        'buyer_history': {
          'registered_since': '2019-08-24T14:15:22Z', 
          'loyalty_level': 0,
          'wishlist_count': 0, 
          'is_social_networks_connected': true,
          'is_phone_number_verified': true, 
          'is_email_verified': true 
        },
        'order': {
          'tax_amount': '0.00',
          'shipping_amount': '0.00',
          'discount_amount': '0.00',
          'updated_at': '2019-08-24T14:15:22Z',
          'reference_id': 'string', 
          'items': [
            {
              'title': 'string', 
              'description': 'string', 
              'quantity': 1, 
              'unit_price': '0.00', 
              'discount_amount': '0.00',
              'reference_id': 'string',
              'image_url': 'http://example.com',
              'product_url': 'http://example.com',
              'gender': 'Male',
              'category': 'string',  
              'color': 'string',
              'product_material': 'string',
              'size_type': 'string',
              'size': 'string',
              'brand': 'string'
            }
          ]
        },
        'order_history': [
          {
            'purchased_at': '2019-08-24T14:15:22Z', 
            'amount': '0.00', 
            'payment_method': 'card', 
            'status': 'new',
            'buyer': { 
              'phone': 'string', 
              'email': 'user@example.com', 
              'name': 'string', 
              'dob': '2019-08-24' 
            },
            'shipping_address': {
              'city': 'string', 
              'address': 'string', 
              'zip': 'string' 
            },
            'items': [
              {
                'title': 'string',
                'description': 'string',
                'quantity': 1,
                'unit_price': '0.00',
                'discount_amount': '0.00',
                'reference_id': 'string',
                'image_url': 'http://example.com',
                'product_url': 'http://example.com',
                'ordered': 0,
                'captured': 0,
                'shipped': 0,
                'refunded': 0,
                'gender': 'Male',
                'category': 'string',
                'color': 'string',
                'product_material': 'string',
                'size_type': 'string',
                'size': 'string',
                'brand': 'string'
              }
            ]
          }
        ],
        'shipping_address': {
          'city': 'string', 
          'address': 'string', 
          'zip': 'string' 
        },
        'meta': {
          'order_id': '#1234', 
          'customer': '#customer-id' 
        },
        'attachment': {
          'body': '{"flight_reservation_details": {"pnr": "TR9088999","itinerary": [...],"insurance": [...],"passengers": [...],"affiliate_name": "some affiliate"}}',
          'content_type': 'application/vnd.tabby.v1+json'
        }
      },
      'lang': 'en', 
      'merchant_code': 'default', 
      'merchant_urls': {
        'success': 'http://dimas.com.sa/payment-status',
        'cancel': 'https://your-store/cancel',
        'failure': 'https://your-store/failure'
      }
    };

    try {
      const response = await tabbyCheckout(payload).unwrap();
      const checkoutUrl = response?.data?.configuration?.available_products?.installments[0].web_url;
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error('Failed to initiate checkout:', error);
    }
  };

 

  return (
    <div>
      <Tabbycard />
      <div className = 'text-center'>
        <button className = 'inline-block text-white bg-primary rounded-md py-2.5 px-2 w-32 text-sm hover:bg-primary-600 font-medium hover:delay-300 text-center' onClick = { handleCheckout } disabled = { isLoading }>
          { isLoading ? 'Processing...' : 'Pay with tabby' }
        </button>
        { isError && <p>Error occurred during checkout</p> }
      </div>
    </div>
  );
};

export default Tabby;
