import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';

const Cookies = () => {

  return(
    <>
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = 'text-white text-xl lg:text-2xl text-center font-semibold uppercase'>Cookies</h3>
      </div>
      <div className = 'container mx-auto px-5 lg:px-10'>
        <ul>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>1. Necessary</h3>
            <p className = 'text-sm lg:text-base text-grey mt-3'>Necessary cookies are absolutely essential for the website to function properly. These cookies ensure basic functionalities and security features of the website, anonymously.</p>
          </li>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>2. Functional</h3>
            <p className = 'text-sm lg:text-base text-grey mt-3'>Functional cookies help to perform certain functionalities like sharing the content of the website on social media platforms, collect feedbacks, and other third-party features.</p>
          </li>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>3. Performance</h3>
            <p className = 'text-sm lg:text-base text-grey mt-3'>Performance cookies are used to understand and analyze the key performance indexes of the website which helps in delivering a better user experience for the visitors.</p>
          </li>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>4. Analytics</h3>
            <p className = 'text-sm lg:text-base text-grey mt-3'>Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics the number of visitors, bounce rate, traffic source, etc.</p>
          </li>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>5. Advertisement</h3>
            <p className = 'text-sm lg:text-base text-grey mt-3'>Advertisement cookies are used to provide visitors with relevant ads and marketing campaigns. These cookies track visitors across websites and collect information to provide customized ads.</p>
          </li>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>6. Others</h3>
            <p className = 'text-sm lg:text-base text-grey mt-3'>Other uncategorized cookies are those that are being analyzed and have not been classified into a category as yet.</p>
          </li>
         
        </ul>
        <Footer />
      </div>
    </>
  );
};

export default Cookies;