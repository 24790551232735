/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import Loader from './Loader';


const RelatedBlog = ({relateditem, isLoading}) => {

 

  return(
    <>
      <div>
        <h3 className = "text-black text-xl lg:text-2xl font-semibold mt-16 mb-4 md:mb-8 uppercase">RELATED POSTS</h3>
        <div className = "mx-auto grid  grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          { isLoading && <Loader /> }
          { relateditem?.map((post) => (
            <article key = { post?.blog_id } className = "flex max-w-xl flex-col items-start justify-between shadow-custom">
              <Link to = { post?.blog_id }>
                <div className = 'w-full'>
                  <img className = 'w-full' src = 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80' alt = "blog" />
                </div>
                <div className = 'p-5'>
                  <div className = "flex items-center gap-x-4 text-xs">
                    <time dateTime = { post.datetime } className = "text-gray-500">
                      { post?.date }
                    </time>
                    <a
                      href = { post?.blog_id }
                      className = "relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                    >
                      { post.category.title }
                    </a>
                  </div>
                  <div className = "group relative">
                    <h3 className = "mt-3 text-lg font-semibold leading-6 text-black group-hover:text-gray-600">
                      <p>
                        <span className = "absolute inset-0" />
                        { post.title }
                      </p>
                    </h3>
                    <p className = "mt-5 line-clamp-3 text-sm leading-6 text-grey">{ post.description }</p>
                  </div>
                  
                </div>
              </Link>
            </article>
          )) }
        </div>
      </div> 
    </>
  );
};

export default RelatedBlog;