import React from 'react';
import { useTamaraCheckoutMutation } from '../../redux/api/tamaraApi';
import Tamaracard from './Tamaracard';

const Tamara = () => {
  const [tamaraCheckout, { isLoading, isError }] = useTamaraCheckoutMutation();

  const handleCheckout = async () => {
    const payload = {
      total_amount: {
        amount: 100,
        currency: 'SAR'
      },
      shipping_amount: {
        amount: 0,
        currency: 'SAR'
      },
      tax_amount: {
        amount: 0,
        currency: 'SAR'
      },
      order_reference_id: '1231234123-abda-fdfe--afd31241',
      order_number: 'S12356',
      discount: {
        amount: {
          amount: 200,
          currency: 'SAR'
        },
        name: 'Christmas 2020'
      },
      items: [
        {
          name: 'Lego City 8601',
          type: 'Digital',
          reference_id: '123',
          sku: 'SA-12436',
          quantity: 1,
          discount_amount: {
            amount: 100,
            currency: 'SAR'
          },
          tax_amount: {
            amount: 10,
            currency: 'SAR'
          },
          unit_price: {
            amount: 490,
            currency: 'SAR'
          },
          total_amount: {
            amount: 100,
            currency: 'SAR'
          }
        }
      ],
      consumer: {
        email: 'customer@email.com',
        first_name: 'Mona',
        last_name: 'Lisa',
        phone_number: '551230987'
      },
      country_code: 'SA',
      description: 'lorem ipsum dolor',
      merchant_url: {
        cancel: 'http://awesome-qa-tools.s3-website.me-south-1.amazonaws.com/#/cancel',
        failure: 'http://awesome-qa-tools.s3-website.me-south-1.amazonaws.com/#/fail',
        success: 'http://dimas.com.sa/payment-status',
        notification: 'https://store-demo.com/payments/tamarapay'
      },
      payment_type: 'PAY_BY_INSTALMENTS',
      instalments: 3,
      billing_address: {
        city: 'Riyadh',
        country_code: 'SA',
        first_name: 'Mona',
        last_name: 'Lisa',
        line1: '3764 Al Urubah Rd',
        line2: 'string',
        phone_number: '532298658',
        region: 'As Sulimaniyah'
      },
      shipping_address: {
        city: 'Riyadh',
        country_code: 'SA',
        first_name: 'Mona',
        last_name: 'Lisa',
        line1: '3764 Al Urubah Rd',
        line2: 'string',
        phone_number: '532298658',
        region: 'As Sulimaniyah'
      },
      platform: 'platform name here',
      is_mobile: false,
      locale: 'en_US',
      risk_assessment: {
        customer_age: 22,
        customer_dob: '31-01-2000',
        customer_gender: 'Male',
        customer_nationality: 'SA',
        is_premium_customer: true,
        is_existing_customer: true,
        is_guest_user: true,
        account_creation_date: '31-01-2019',
        platform_account_creation_date: 'string',
        date_of_first_transaction: '31-01-2019',
        is_card_on_file: true,
        is_COD_customer: true,
        has_delivered_order: true,
        is_phone_verified: true,
        is_fraudulent_customer: true,
        total_ltv: 501.5,
        total_order_count: 12,
        order_amount_last3months: 301.5,
        order_count_last3months: 2,
        last_order_date: '31-01-2021',
        last_order_amount: 301.5,
        reward_program_enrolled: true,
        reward_program_points: 300,
        phone_verified: false
      },
      additional_data: {
        delivery_method: 'home delivery',
        pickup_store: 'Store A',
        store_code: 'Store code A',
        vendor_amount: 0,
        merchant_settlement_amount: 0,
        vendor_reference_code: 'AZ1234'
      }
    };

    try {
      const response = await tamaraCheckout(payload).unwrap();
      // Assuming the checkout URL is returned from the response
      const checkoutUrl = response?.data?.checkout_url;
      console.log('checkoutUrl',checkoutUrl);
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error('Failed to initiate Tamara checkout:', error);
    }
  };

  return (
    <div className = 'text-center'>
      <Tamaracard />
      <button
        className = 'bg-primary text-white text-sm px-4 py-2 rounded mt-5'
        onClick = { handleCheckout }
        disabled = { isLoading }
      >
        { isLoading ? 'Processing...' : 'Pay with Tamara' }
      </button>
      { isError && <p>Error occurred during checkout</p> }
    </div>
  );
};

export default Tamara;
