
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import { useContactusMutation } from '../redux/api/contactApi';
import { showToast } from '../redux/features/toastSlice';

const Contactqueary = () => {
  // State variables for form inputs
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    contact_reason: '',
    full_name: '',
    email: '',
    mobile_number: '',
    order_number: '',
    title: '',
    comment: ''
  });

  const [errors, setErrors] = useState({});
  const [contactUs, {error, data, isSuccess, isLoading }] = useContactusMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: ''
      }));
    }
  };

  // Validate form inputs
  const validate = () => {
    const newErrors = {};
    if (!formData.contact_reason) newErrors.contact_reason = 'Reason is required';
    if (!formData.full_name) newErrors.full_name = 'Full Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.mobile_number) newErrors.mobile_number = 'Mobile Number is required';
    if (!formData.title) newErrors.title = 'Title is required';
    if (!formData.comment) newErrors.comment = 'Comment is required';
    return newErrors;
  };

  useEffect(() => {

    if(isSuccess){
      dispatch(showToast(data?.message));
    }
    
  },[isSuccess, data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      await contactUs(formData);
      setFormData({
        contact_reason: '',
        full_name: '',
        email: '',
        mobile_number: '',
        order_number: '',
        title: '',
        comment: ''
      }); 
    } catch (error) {
      console.log('error',error);
    }
  };

  return (
    <>
      <Header />
      <Navigation />
      <div className = 'container mx-auto px-5 lg:px-0'>
        <div className = 'pt-6'>
          <ul className = 'flex'>
            <li><a className = 'inline-block text-grey text-xs md:text-sm hover:text-primary ' href = '/'>Home</a></li>
            <li><a className = 'inline-block text-grey text-xs md:text-sm ml-2' href = '/'>/</a></li>
            <li><a className = 'inline-block text-black text-xs md:text-sm ml-2 font-semibold' href = '#'>Contact Us</a></li>
          </ul>
          <div className = 'bg-lightwhite p-5 mt-3'>
            <h3 className = 'text-center text-black text-xl font-semibold uppercase'>Contact Us</h3>
            <p className = 'text-center text-sm text-blacklight mt-1'>Customer service timing:10am - 10pm</p>
            <div className = 'w-full md:w-width80% mx-auto'>
              <div className = 'flex flex-wrap md:flex-nowrap justify-center mt-5'>
                <Link to = "#" className = 'block mr-0 md:mr-4 bg-white w-full md:w-48 text-center py-3 px-5 rounded-md'>
                  <WhatsAppIcon style = { { fontSize: '22px' } } />
                  <span className = 'inline-block ml-2 text-base align-middle'>WhatsApp</span>
                </Link>
                <Link to = "#" className = 'block mt-3 md:mt-0 mr-0 md:mr-4 bg-white  w-full md:w-48 text-center py-3 px-5 rounded-md'>
                  <LocalPhoneIcon style = { { fontSize: '22px' } } />
                  <span className = 'inline-block ml-2 text-base align-middle'>Phone</span>
                </Link>
                <Link to = "#" className = 'block bg-white mt-3 md:mt-0 w-full md:w-48 text-center py-3 px-5 rounded-md'>
                  <MailOutlineIcon style = { { fontSize: '22px' } } />
                  <span className = 'inline-block ml-2 text-base align-middle'>Email</span>
                </Link>
              </div>
            </div>
          </div>
          <div className = 'bg-greylight px-5 py-8 mt-5'>
            {
              error && <p>{ error?.data?.message }</p>
            }
            <form onSubmit = { handleSubmit }>
              <ul>
                <li>
                  <div className = 'flex flex-wrap md:flex-nowrap justify-between'>
                    <div className = 'w-full md:w-width49%'>
                      <label className = 'block text-xs font-semibold text-black'>Contact Us Reason *</label>
                      <select
                        name = "contact_reason"
                        value = { formData.contact_reason }
                        onChange = { handleChange }
                        className = { `inline-block mt-2 w-full py-3 px-3 border border-borderColor rounded text-xs text-blacklight outline-none focus:outline-none focus:ring-0 focus:border-borderColor ${errors.reason ? 'border-red-500' : ''}` }
                      >
                        <option value = "" disabled hidden>Select one</option>
                        <option value = "Customer Profile">Customer Profile</option>
                        <option value = "Marketing">Marketing</option>
                        <option value = "Order">Order</option>
                        <option value = "Product">Product</option>
                        <option value = "Shipping">Shipping</option>
                        <option value = "Store">Store</option>
                      </select>
                      { errors.contact_reason && <span className = "text-red-500 text-xs">{ errors.contact_reason }</span> }
                    </div>
                    <div className = 'w-full md:w-width49% self-center'>
                      <label className = 'block mt-3 md:mt-0 text-xs font-semibold text-black'>Full Name *</label>
                      <input
                        name = "full_name"
                        value = { formData.full_name }
                        onChange = { handleChange }
                        className = { `inline-block mt-2 w-full py-3 px-3 border border-borderColor rounded text-xs text-blacklight outline-none ${errors.fullName ? 'border-red-500' : ''}` }
                      />
                      { errors.full_name && <span className = "text-red-500 text-xs">{ errors.full_name }</span> }
                    </div>
                  </div>
                </li>
                <li className = 'mt-3 md:mt-5'>
                  <div className = 'flex flex-wrap md:flex-nowrap justify-between'>
                    <div className = 'w-full md:w-width49%'>
                      <label className = 'block text-xs font-semibold text-black'>Email Address *</label>
                      <input
                        name = "email"
                        value = { formData.email }
                        onChange = { handleChange }
                        className = { `inline-block mt-2 w-full py-3 px-3 border border-borderColor rounded text-xs text-blacklight outline-none ${errors.email ? 'border-red-500' : ''}` }
                      />
                      { errors.email && <span className = "text-red-500 text-xs">{ errors.email }</span> }
                    </div>
                    <div className = 'w-full md:w-width49% self-center'>
                      <label className = 'block mt-3 md:mt-0 text-xs font-semibold text-black'>Mobile Number *</label>
                      <input
                        name = "mobile_number"
                        value = { formData.mobile_number }
                        onChange = { handleChange }
                        className = { `inline-block mt-2 w-full py-3 px-3 border border-borderColor rounded text-xs text-blacklight outline-none ${errors.mobile ? 'border-red-500' : ''}` }
                      />
                      { errors.mobile_number && <span className = "text-red-500 text-xs">{ errors.mobile_number }</span> }
                    </div>
                  </div>
                </li>
                <li className = 'mt-3 md:mt-5'>
                  <div className = 'flex flex-wrap md:flex-nowrap justify-between'>
                    <div className = 'w-full md:w-width49%'>
                      <label className = 'block text-xs font-semibold text-black'>Order Number</label>
                      <input
                        name = "order_number"
                        value = { formData.order_number }
                        onChange = { handleChange }
                        className = "inline-block mt-2 w-full py-3 px-3 border border-borderColor rounded text-xs text-blacklight outline-none"
                      />
                    </div>
                    <div className = 'w-full md:w-width49% self-center'>
                      <label className = 'block mt-3 md:mt-0 text-xs font-semibold text-black'>Title *</label>
                      <input
                        name = "title"
                        value = { formData.title }
                        onChange = { handleChange }
                        className = { `inline-block mt-2 w-full py-3 px-3 border border-borderColor rounded text-xs text-blacklight outline-none ${errors.title ? 'border-red-500' : ''}` }
                      />
                      { errors.title && <span className = "text-red-500 text-xs">{ errors.title }</span> }
                    </div>
                  </div>
                </li>
                <li className = 'mt-3 md:mt-5'>
                  <label className = 'block text-xs font-semibold text-black'>Comment *</label>
                  <textarea
                    name = "comment"
                    value = { formData.comment }
                    onChange = { handleChange }
                    className = { `inline-block mt-2 w-full py-3 px-3 border border-borderColor rounded text-xs text-blacklight outline-none ${errors.comment ? 'border-red-500' : ''}` }
                    rows = "5"
                  ></textarea>
                  { errors.comment && <span className = "text-red-500 text-xs">{ errors.comment }</span> }
                </li>
                <li className = 'mt-3 md:mt-5'>
                  <button type = "submit" disabled = { isLoading } className = 'inline-block bg-primary w-full py-3 rounded text-white text-sm font-semibold uppercase'>Submit</button>
                </li>
              </ul>
            </form>
          </div>
        </div>
        <Footer />
      </div>
      
    </>
  );
};

export default Contactqueary;
