
import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
// import './interceptor/axios';
import { store } from './redux/store';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GoogleOAuthProvider clientId = "137254023107-edpsf7tcccrns0hbuv64466stvhcivrt.apps.googleusercontent.com">
    <Provider store = { store }>
      <App />
    </Provider>
  </GoogleOAuthProvider>
);


