import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import TopHeader from '../components/TopHeader';




const ReferFriend = () => {
  return (
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = 'text-white text-xl lg:text-2xl text-center font-semibold uppercase'>Give $25, Get $25</h3>
      </div>
      <div className = "container mx-auto px-5 lg:px-10">
        <div className = 'flex flex-col-reverse md:flex-row justify-between'>
          <div className = 'w-full lg:w-width48% self-center'>
            <p className = 'text-center'>Refer a Friend</p>
            <h3 className = 'text-center mt-8 font-semibold text-2xl md:text-3xl mb-5 max-sm:mt-10'>GIVE $25, GET $25</h3>
            <p className = 'text-center text-sm md:text-base text-grey'>Give your friends $25 off their first order of $150+ and get <br></br> $25 (in points) for each successful referral.</p>
            <div className = ' mt-10 w-full md:w-3/5 mx-auto'>
              <input type = "text" placeholder = 'Your email address'className = 'block w-full border border-borderColor' />
              <button className = ' bg-primary block text-base text-white font-semibold w-full mt-5 py-2 rounded-md'>Next</button>
            </div>
          </div>
          <div className = 'w-full lg:w-width48% self-center pb-8 md:pb-0'>
            <img src = 'https://cdn-widget-assets.yotpo.com/static_assets/m2igOM6vpylIXDPCQMJUZA/images/image_2023_02_14_21_19_06_004' alt = "" className = 'w-full object-cover'/>
          </div>
        </div>
        
        <Footer />
       
      </div>  

    </>
  );
  
}; 

export default ReferFriend ;
  