import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  minVal: 0,
  maxVal: 1000
};

const rangeSlice = createSlice({
  name: 'range',
  initialState,
  reducers: {
    setMinVal: (state, action) => {
      state.minVal = action.payload;
    },
    setMaxVal: (state, action) => {
      state.maxVal = action.payload;
    },
    setRange: (state, action) => {
      state.minVal = action.payload.min;
      state.maxVal = action.payload.max;
    }
  }
});

export const { setMinVal, setMaxVal, setRange } = rangeSlice.actions;

export default rangeSlice.reducer;
