/* eslint-disable react/jsx-key */


import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Loader from '../components/Loader';
import { useSearchProductsQuery } from '../redux/api/productsApi';
import { showToast } from '../redux/features/toastSlice';
import CustomPagination from './CustomPagination';
import Footer from './Footer';
import Header from './Header';
import Navigation from './Navigation';
import ShopSeacrhProduct from './ShopSeacrhProduct';

const ShopSeacrhpage = () => {
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();

  const query = searchParams.get('query') || '';

  const params = {query };

  const {data, isLoading, error} = useSearchProductsQuery(params);
  useEffect(() => {

    if(error){
      dispatch(showToast(error?.data?.message));
    }

  },[error]);

  return (
    <>
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = 'text-white text-xl lg:text-2xl text-center font-semibold uppercase'>Search</h3>
      </div>
      <div className = 'container mx-auto px-5 lg:px-0'>
        <div className = 'flex'>
        
          <div className = 'flex-1'>
            <div className = 'flex justify-between'>
              <div className = 'self-center'>
                <p className = 'inline-block text-grey text-sm hover:text-primary'>Search</p>
              </div>
              <div className = 'flex self-center'>
                <p className = 'self-center text-base text-grey mr-0 md:mr-5'>{ data?.data?.products.length  } Products</p>
              </div>
            </div>
            <div className = 'mt-3'>
              <div className = 'flex flex-wrap gap-1 md:gap-2'>
                { isLoading && <Loader /> }
                { data?.data?.products.length > 0 ? (
                  data?.data?.products?.map((product) => <ShopSeacrhProduct key = { product.id } product = { product } />)
                ) : (
                  data?.data?.length == 0?'No matching products found!': ''
                ) }
              </div>
            </div>
            <CustomPagination resPerPage = { data?.resPerPage } filteredProductsCount = { data?.filterProductCount }  />
          </div>
        </div>
        
        <Footer />
      </div>

      
    </>
  );
};

export default ShopSeacrhpage;