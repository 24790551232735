import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import { useGestverifyotpMutation } from '../redux/api/authApi';
import { useAddtocartMutation } from '../redux/api/productsApi';
import { selectCartItems } from '../redux/features/cartSlice';
import { showToast } from '../redux/features/toastSlice';

const Guestcheckoutotpverify = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);
  const [productitem, setProductitem] = useState([]);
  const [otp, setOtp] = useState('');
  const email = localStorage.getItem('email');
  const phone = localStorage.getItem('phone_number');
  const navigate = useNavigate();

  const [addtocart, { isSuccess: addtocartSuccess, error: erroraddtocart }] = useAddtocartMutation();
  const [gestverifyotp, { isLoading, error, isSuccess, data }] = useGestverifyotpMutation();

  useEffect(() => {
    if (erroraddtocart) {
      dispatch(showToast(erroraddtocart?.data?.message || 'Error adding to cart'));
    }
  }, [erroraddtocart]);

  useEffect(() => {
    if (error) {
      dispatch(showToast(error?.data?.message || 'Error verifying OTP'));
    }
  }, [error]);

  useEffect(() => {
    const productdata = cartItems.map(item => ({
      stock_options_id: item.options.id,
      quantity: item.quantity
    }));

    if (productdata.length > 0) {
      setProductitem(productdata);
    }
  }, [cartItems]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(showToast(data?.data?.message));
      localStorage.setItem('orderid', data?.data?.order_id);
      localStorage.setItem('token', data?.data?.access_token);
      localStorage.setItem('userid', data?.data?.user_id);
      addtocart(productitem);
    }
  }, [isSuccess, data, addtocart, productitem]);

  useEffect(() => {
    if (addtocartSuccess) {
      setTimeout(() => {
        navigate('/shipping-details');
      }, 1000);
    }
  }, [addtocartSuccess, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();

    // const products = cartItems.map(item => ({
    //   product_id: item.options.id,
    //   quantity: item.quantity
    // }));

    const orderData = {
      otp
    };

    if (phone && phone.length >= 10) {
      orderData.phone_number = phone;
      orderData.email = email;
    }

    gestverifyotp(orderData);
  };

  const isFormValid = otp.length > 0 && (email || (phone && phone.length === 10));

  return (
    <>
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = 'text-white text-xl lg:text-2xl text-center font-semibold uppercase'>Verify OTP</h3>
      </div>
      <div className = 'container mx-auto px-5 lg:px-10'>
        <div className = 'w-full md:w-2/5 mx-auto'>
          <p className = 'text-base text-grey'>Verify OTP.</p>
          <form onSubmit = { submitHandler }>
            <div>
              <label className = 'text-sm text-grey-500 lg:text-grey-800 inline-block mt-2 pb-1'>OTP</label>
              <input type = 'number' className = 'block w-full border border-grey-300 rounded-md px-4 py-2 text-sm outline-primary-600' placeholder = 'Enter OTP' onChange = { (e) => setOtp(e.target.value) } />
            </div>
            <button className = { `block w-full text-white rounded-3xl lg:rounded-md px-4 py-2.5 text-sm ${!isFormValid ? 'bg-gradient-primary-light' : 'bg-gradient-primary'} mt-5` } disabled = { !isFormValid }>
              { isLoading ? 'Sending' : 'Save' }
            </button>
          </form>
        </div>
        <Footer />
        <style>
          { `
          /* Hide number input spinners */
          input[type=number]::-webkit-inner-spin-button, 
          input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none; 
            margin: 0; 
          }
          input[type=number] { 
            -moz-appearance: textfield; 
          }
          /* Hide up and down arrows for input type number in IE */
          input[type="number"]::-ms-clear, 
          input[type="number"]::-ms-reveal {
            display: none; 
            width: 0; 
            height: 0;
          }
          /* Hide up and down arrows for input type number in Firefox */
          input[type="number"] {
            -moz-appearance: textfield;
          }
        ` }
        </style>
      </div>
    </>
  );
};

export default Guestcheckoutotpverify;
