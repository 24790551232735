import LocationOnIcon from '@mui/icons-material/LocationOn';
import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';


const StoreLocator = () => {

  return(
    <>
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = 'text-white text-xl lg:text-2xl text-center font-semibold uppercase'>Store Locator</h3>
      </div>
      <div className = "container mx-auto px-5 lg:px-10">
        <div className = 'flex flex-wrap md:flex-nowrp justify-between'>
          <div className = 'flex w-full mb-5 md:mb-0 md:w-1/5'>
            <div>
              <p className = 'text-primary mr-2'><LocationOnIcon style = { {fontSize: '25px'} } /></p>
            </div>
            <div>
              <h3 className = 'text-black text-xl font-bold'>Beauty Icon</h3>
              <p className = 'text-base text-grey mt-2'>509 S Galena Street Aspen, Colorado 81611 United States</p></div>
          </div>
          <div className = 'w-full md:w-4/5'>
            <iframe className = 'w-full min-h-screen' src = "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319" ></iframe>
          </div>
        </div>
        <Footer />
      </div>  
    </>
  );
};

export default StoreLocator;