
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideToast } from '../redux/features/toastSlice';

const Customtoast = () => {
  const dispatch = useDispatch();
  const { message, showToast } = useSelector((state) => state.toast);
  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        dispatch(hideToast());
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [showToast, dispatch]);

  return (
    <div className = { `custom-toast ${showToast ? 'show' : ''}` }>
      <div className = "toast-content">
        <span>{ message }</span>
        <div className = "progress-bar" ></div>
      </div>
    </div>
  );
};

export default Customtoast;
