
import React, { useEffect } from 'react';

const Tamaracard = () => {
  useEffect(() => {
    // Dynamically load the Tamara script
    const script = document.createElement('script');
    script.src = 'https://cdn.tamara.co/widget-v2/tamara-widget.js';
    script.defer = true;

    // Append the script to the document
    document.head.appendChild(script);

    // Set the widget configuration
    window.tamaraWidgetConfig = {
      lang: 'en', // Set language [ar|en]
      country: 'SA',
      publicKey: 'd17ce727-6f7b-477f-bc97-727398505eb8', 
      css: '.tamara-widget { background-color: #f0f0f0; }',
      style: {
        fontSize: '16px',
        badgeRatio: 1 
      }
    };

    // Clean up script on component unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className = 'mt-5'><tamara-widget type = "tamara-summary" inline-type = "2" amount = "100"></tamara-widget></div>
    </>
  );
};

export default Tamaracard;
