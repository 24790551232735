/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import TopHeader from '../../components/TopHeader';
import Colognes from './Colognes';
import PerfumeMonth from './PerfumeMonth';

const SubscriptionPerfumeMonth = () => {

  const [active, setActive] = useState(1);

  const handleHimndHertab = (item) => {
    setActive(item);
  };


  return(
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = "container mx-auto px-5 lg:px-10">
        <div className = 'relative'>
          <div className = 'text-center'>
            <img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/ZnJvbnRiaXJkMi9pbWFnZXMvZGVza3RvcF9hMmQzOGEuanBn' alt = 'bannerimg' />
          </div>
          <div className = 'absolute bottom-5 left-5'>
            <h3 className = 'text-3xl txt-black font-bold uppercase'>FRAGRANCE OF <br></br>THE MONTH ARCHIVE</h3>
            <p className = 'text-base text-blacklight'>Revisit each of the featured fragrances from the past 12 months.</p>
          </div>
        </div>
        <div className = 'w-44 flex justify-between mt-5'>
          <div className = 'cursor-pointer' onClick = { () => handleHimndHertab(1) }><h3 className = { `text-base uppercase text-black font-bold py-1.5 ${active == 1? 'border-b-2 border-primary' : ''}` }>Perfumes</h3></div>
          <div className = 'cursor-pointer ml-8' onClick = { () => handleHimndHertab(2) }><h3 className = { `text-base uppercase text-black font-bold py-1.5 ${active == 2? 'border-b-2 border-primary' : ''}` }>Colognes</h3></div>
        </div>
        <div className = 'border-b border-primary mb-5'></div>
        {
          active == 1 && <div>
            
            <PerfumeMonth />
          </div>
        }
        {
          active == 2 && <div>
           
            <Colognes />
          </div>
        }
        <Footer />
      </div>  

    </>
  );
};

export default SubscriptionPerfumeMonth;