import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';

const PrivacyPolicy = () => {

  return(
    <>
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = 'text-white text-xl lg:text-2xl text-center font-semibold uppercase'>Privacy Policy</h3>
      </div>
      <div className = 'container mx-auto px-5 lg:px-10'>
        <div className = 'mt-12'>
          <h3 className = 'text-black text-lg lg:text-2xl text-center'>WHAT PERSONAL DATA WE COLLECT AND WHY WE COLLECT IT</h3>
          <h4 className = 'text-black text-lg lg:text-xl mt-8'>1. COMMENTS</h4>
          <p className = 'text-sm lg:text-base text-grey mt-6'>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from abcd.com (the “Site”).</p>
          <h5 className = 'text-grey text-lg font-bold mt-8'>Personal information we collect</h5>
          <p className = 'text-sm lg:text-base text-grey mt-2'>When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information”.</p>
          <h5 className = 'text-grey text-lg font-bold mt-8'>We collect Device Information using the following technologies:</h5>
          <p className = 'text-sm lg:text-base break-all text-grey mt-2'>– “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.abcd.org.</p>
          <p className = 'text-sm lg:text-base break-all text-grey mt-2'>– “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</p>
          <p className = 'text-sm lg:text-base break-all text-grey mt-2'>– “Web beacons”, “tags”, and “pixels” are electronic files used to record information about how you browse the Site.</p>
          <p className = 'text-sm lg:text-base break-all text-grey mt-8'>Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers), email address, and phone number. We refer to this information as “Order Information”.</p>
          <p className = 'text-sm lg:text-base break-all text-grey mt-8'>When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.</p>
          <p className = 'text-sm lg:text-base break-all text-grey mt-8'>How do we use your personal information?</p>
          <p className = 'text-sm lg:text-base break-all text-grey mt-2'>We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to:</p>
          <p className = 'text-sm lg:text-base break-all text-grey mt-1'>– Communicate with you;</p>
          <p className = 'text-sm lg:text-base break-all text-grey mt-1'>– Screen our orders for potential risk or fraud; and</p>
          <p className = 'text-sm lg:text-base break-all text-grey mt-1'>– When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.</p>
          <p className = 'text-sm lg:text-base break-all text-grey mt-8'>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).</p>
          <p className = 'text-sm lg:text-base break-all text-grey mt-8'>Sharing you personal Information</p>
          <p className = 'text-sm lg:text-base break-all text-grey'>We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use Shopify to power our online store–you can read more about how Shopify uses your Personal Information here: https://www.shopify.com/legal/privacy. We also use Google Analytics to help us understand how our customers use the Site — you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.</p>
          <p className = 'text-sm lg:text-base break-all text-grey mt-8'>Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>
          <h5 className = 'text-grey break-all text-lg font-bold mt-8'>Behavioural advertising</h5>
          <p className = 'text-sm lg:text-base break-all text-grey'>As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.</p>
          <h5 className = 'text-sm lg:text-base break-all text-grey font-bold mt-8'>You can opt out of targeted advertising by using the links below:</h5>
          <p className = 'text-sm lg:text-base break-all text-grey'>– Facebook: https://www.facebook.com/settings/?tab=ads</p>
          <p className = 'text-sm lg:text-base break-all text-grey'>– Google: https://www.google.com/settings/ads/anonymous</p>
          <p className = 'text-sm lg:text-base break-all text-grey'>– Bing: https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</p>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;