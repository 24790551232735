import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CheckoutpageLogin from '../../components/CheckoutpageLogin';
import Footer from '../../components/Footer';
import Googleauthentic from '../../components/Googleauthentic';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import women from '../../images/1.png';
import men from '../../images/4.png';
import { useCreateorderasaguestMutation } from '../../redux/api/authApi';
import { selectCartItems } from '../../redux/features/cartSlice';
import { showToast } from '../../redux/features/toastSlice';

const Checkoutlogin = () => {
  const cartItems = useSelector(selectCartItems);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedScent, setSelectedScent] = useState('feminine');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [countryCode, setCountryCode] = useState('966');
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [createorderasaguest, { error, data, isLoading, isSuccess }] = useCreateorderasaguestMutation();
  const handleEmailChange = (e) => {
    const trimmedEmail = e.target.value.trim();
    setEmail(trimmedEmail);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(trimmedEmail);

    setErrorEmail(isValidEmail ? '' : '');
    setIsEmailValid(isValidEmail);
  };

  const handleEmailBlur = () => {
    if (!isEmailValid && email.trim()) {
      setErrorEmail('Please enter a valid email');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const products = cartItems?.map(item => ({
      product_id: item.options.id,
      quantity: item.quantity
    }));
  
    const orderData = {
      products
    };
    orderData.phone_number =  '+' + countryCode + phone;
    localStorage.setItem('phone_number', '+' + countryCode + phone);
    orderData.email = email;
    localStorage.setItem('email', email); 
    createorderasaguest(orderData);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(showToast(data?.message));

      setTimeout(() => {
        navigate('/guest-verify-otp');
      });
    }
  }, [isSuccess]);

  const isFormValid = ((phone?.length === 10 || phone?.length === 9) && isEmailValid) && !isLoading;

  const arabCountries = [
    { name: 'Bahrain', code: 'bh', dialCode: '973' },
    { name: 'Cyprus', code: 'cy', dialCode: '357' },
    { name: 'Egypt', code: 'eg', dialCode: '20' },
    { name: 'Iran', code: 'ir', dialCode: '98' },
    { name: 'Iraq', code: 'iq', dialCode: '964' },
    { name: 'Jordan', code: 'jo', dialCode: '962' },
    { name: 'Kuwait', code: 'kw', dialCode: '965' },
    { name: 'Lebanon', code: 'lb', dialCode: '961' },
    { name: 'Oman', code: 'om', dialCode: '968' },
    { name: 'Palestine', code: 'ps', dialCode: '970' },
    { name: 'Qatar', code: 'qa', dialCode: '974' },
    { name: 'Saudi Arabia', code: 'sa', dialCode: '966' },
    { name: 'Syria', code: 'sy', dialCode: '963' },
    { name: 'Turkey', code: 'tr', dialCode: '90' },
    { name: 'United Arab Emirates', code: 'ae', dialCode: '971' },
    { name: 'Yemen', code: 'ye', dialCode: '967' }
  ];

  const handleCountryChange = (e) => {
    const selectedCountry = arabCountries.find(country => country.code === e.target.value);
    setCountryCode(selectedCountry ? selectedCountry.dialCode : '');
  };

  const handlePhoneInputChange = (e) => {
    const phoneValue = e.target.value.trim(); // Trim whitespace from the input
    setPhone(phoneValue);
    if (phoneValue.length === 10 || phoneValue.length === 9) {
      setPhoneError('');
    } else {
      setPhoneError('Phone number must be exactly 9 or 10 digits');
    }
  };

  return (
    <>
      <Header />
      <Navigation />
      <div className = 'container mx-auto px-5 lg:px-0'>
        <div>
          <h3 className = 'text-2xl text-blacklight text-center mt-8 pb-4'>CHECKOUT</h3>
          <div className = 'text-center font-sans'>
            <h2 className = 'mb-5'>Which type of beauty do you prefer?</h2>
            <div className = 'flex justify-center space-x-6 mb-8'>
              <label
                className = { `flex flex-col items-center border-2 p-5 w-32 h-32 rounded-full cursor-pointer transition ${
                  selectedScent === 'feminine' ? 'border-primary bg-[#f9f5f0]' : 'border-gray-300'
                }` }
                onClick = { () => setSelectedScent('feminine') }
              >
                <img src = { women } alt = "Feminine" className = "w-12 h-12 mb-2" />
                <span className = "font-medium">Feminine</span>
              </label>
              <label
                className = { `flex flex-col items-center border-2 p-5 w-32 h-32 rounded-full cursor-pointer transition ${
                  selectedScent === 'masculine' ? 'border-primary bg-[#f9f5f0]' : 'border-gray-300'
                }` }
                onClick = { () => setSelectedScent('masculine') }
              >
                <img src = { men } alt = "Masculine" className = "w-12 h-12 mb-2" />
                <span className = "font-medium">Masculine</span>
              </label>
            </div>
          </div>
          <div className = 'block md:flex justify-between'>
            <div className = 'w-full md:w-width48% p-6 border border-borderColor'>
              <CheckoutpageLogin />
              <div className = 'flex justify-between mt-4'>
                <div className = 'w-width42% h-px bg-grey-300 self-center'></div>
                <div><p>or</p></div>
                <div className = 'w-width42% h-px bg-grey-300 self-center'></div>
              </div>
              <div className = 'flex justify-between mt-4'>
                <div className = 'w-full border border-grey-300 text-center rounded py-2 px-4'>
                  <Googleauthentic />
                </div>
              </div>
            </div>
            <div className = 'w-full md:w-width48% relative'>
              <h3 className = 'text-lg text-center uppercase font-semibold mt-8 md:mt-0'>CONTINUE AS GUEST</h3>
              <p className = 'text-sm text-center text-blacklight mt-2'>Continue to checkout as guest and create your <br></br> account later</p>
              <form onSubmit = { handleSubmit }>
                <ul>
                  <li>
                    <label className = 'text-sm text-grey-500 lg:text-grey-800'>Phone <span>*</span></label>
                    <div className = "flex">
                      <select 
                        className = " border border-grey rounded-md px-2 py-2 text-sm"
                        onChange = { handleCountryChange }
                        defaultValue = "sa"
                      >
                        { arabCountries.map(country => (
                          <option key = { country.code } value = { country.code }>
                               +{ country.dialCode }
                          </option>
                        )) }
                      </select>
                      <input
                        type = "text"
                        className = { 'block w-full border border-grey rounded-md px-4 py-2 text-sm ml-2' }
                        onChange = { handlePhoneInputChange }
                        value = { phone }
                        placeholder = "Phone number"
                      />
                    </div>
                    { phoneError && <p className = "text-xs text-red mt-1">{ phoneError }</p> }
                  </li>
                  { /* <li>
                    <div className = 'flex justify-between mt-4'>
                      <div className = 'w-width42% h-px bg-grey-300 self-center'></div>
                      <div><p>or</p></div>
                      <div className = 'w-width42% h-px bg-grey-300 self-center'></div>
                    </div>
                  </li> */ }
                  <li className = 'mt-2.5'>
                    <label className = 'text-sm text-grey-500 lg:text-grey-800'>Email <span>*</span></label>
                    <input
                      value = { email }
                      onChange = { handleEmailChange }
                      onBlur = { handleEmailBlur }
                      className = 'block w-full border border-grey-300 px-4 py-2.5 text-sm outline-primary'
                      placeholder = 'Enter email'
                    />
                    { errorEmail && <p className = "text-xs text-red">{ errorEmail }</p> }
                  </li>
                 
                  <li className = 'mt-8 md:mt-0 block md:absolute bottom-0 w-full'>
                    <button
                      className = { `block w-full px-4 py-2.5 text-sm border border-primary uppercase font-semibold  ${isFormValid ? 'text-primary' : 'text-primary-200'}` }
                      disabled = { !isFormValid }
                    >
                      { isLoading ? 'Loading...' : 'Continue to checkout' }
                    </button>
                    { error && <p className = 'text-sm text-red'>{ error?.data?.message }</p> }
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Checkoutlogin;
