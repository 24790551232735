import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import { useVerifyotpMutation } from '../redux/api/userApi';
import { showToast } from '../redux/features/toastSlice';

const VerifyOtp = () => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState('');
  const email = 'mdnasim4234@gmail.com';

  const navigate = useNavigate();

  const [resetPassword,{isLoading, error, isSuccess,data}] = useVerifyotpMutation();

  useEffect(() => {

    if(error){
      dispatch(showToast(error?.data?.message || 'Something went wrong'));
    }
    if(isSuccess){
      dispatch(showToast(data?.message));
      navigate('/password/reset-form');
    }

  },[error, isSuccess,data]);


  const submitHandler = (e) => {
    e.preventDefault();

    const Data = { otp, email };
    resetPassword(Data);
    
  };

  const isFormValid = email && otp.length > 0?true : false;

  return(
    <>
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = 'text-white text-xl lg:text-2xl text-center font-semibold uppercase'>My Account</h3>
      </div>
      <div className = 'container mx-auto px-5 lg:px-0'>
        <div className = 'w-full md:w-2/5 mx-auto'>
          <p className = 'text-base text-grey'>Verify otp.</p>
          <form onSubmit = { submitHandler }>
            <div>
              <lebel className = 'text-sm text-grey-500 lg:text-grey-800 inline-block mt-2 pb-1'>Otp</lebel>
              <input type = 'number' className = 'block w-full border border-grey rounded-md px-4 py-2 text-sm outline-primary-600' placeholder = 'Enter otp' onChange = { (e) => {setOtp(e.target.value); } }  />
            </div>
            <button className = { `block w-full text-white rounded-3xl lg:rounded-md px-4 py-2.5 text-sm ${!isFormValid?'bg-gradient-primary-light':'bg-gradient-primary'} mt-5` } disabled = { !isFormValid }>{ isLoading?'Sending': 'Save' }</button>
          </form>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default VerifyOtp;