import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';

const TermsConditions = () => {

  return(
    <>
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = 'text-white text-xl lg:text-2xl text-center font-semibold uppercase'>Terms And Conditions</h3>
      </div>
      <div className = 'container mx-auto px-5 lg:px-10'>
        <ul>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>1. SHOPPING ONLINE  ORDER PAYMENT</h3>
            <p className = 'text-sm lg:text-base text-grey mt-3'>The following methods of payment are accepted by abcd.com: CREDIT CARD When Clients place orders online, Credit Card details are entered into a protected online banking system provided by TAP COMPANY, PAYPAL, Visa, Mastercard, American Express and JCB are accepted.</p>
          </li>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>2. CREDIT CARD SECURITY</h3>
            <p className = 'text-sm lg:text-base text-grey mt-3'>The following methods of payment are accepted by abcd.com: CREDIT CARD When Clients place orders online, Credit Card details are entered into a protected online banking system provided by TAP COMPANY, PAYPAL, Visa, Mastercard, American Express and JCB are accepted.</p>
          </li>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>3. CREDIT CARD CHARGES</h3>
            <p className = 'text-sm lg:text-base text-grey mt-3'>If there are sufficient funds and the account information is valid, the transaction will be processed instantly. At the moment the order is confirmed, the Credit Card will be charged for both available items and for Pre-Order items, which will be shipped later in the season when the merchandise arrives.</p>
          </li>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>4. BANK WIRE TRANSFER</h3>
            <p className = 'text-sm lg:text-base text-grey mt-3'>When Bank Wire Transfer is selected as the method of payment, the Client will automatically receive an email containing the bank account information for abcd.com. The merchandise will then be reserved for the order until payment has been confirmed. The Client is asked to fax or email a scanned copy of the Bank Wire Transfer within 48 hours of receiving the email request for payment. It is mandatory that the Client fax or email a scanned copy of the Bank Wire Transfer within 48 hours to avoid the cancelation of the order. Merchandise will be shipped once the payment has reached abcd.com’s bank account. If the fax or scanned copy of the Bank Wire Transaction is not received within 48 hours of the request, the order will be cancelled. The merchandise will be shipped once abcd.com has confirmed the payment and the merchandise is available for shipment. This method of payment is not available in all countries. Please check availability at the time of purchase.</p>
          </li>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>5. PAYPAL</h3>
            <p className = 'text-sm lg:text-base text-grey mt-3'>The following methods of payment are accepted by abcd.com: CREDIT CARD When Clients place orders online, Credit Card details are entered into a protected online banking system provided by TAP COMPANY, PAYPAL, Visa, Mastercard, American Express and JCB are accepted.</p>
          </li>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>6. FIRST-TIME CLIENTS</h3>
            <p className = 'text-sm lg:text-base text-grey mt-3'>For Credit Card and PayPal payments abcd.com may request the Bank or PayPal to double check the transaction and the account information. This verification process will delay immediate shipping. abcd.com also reserves the right to ship the order only to the Billing Address.</p>
          </li>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>7. WHY ARE THERE DELAYS FOR SHIPPING OUT PERFUMES & FRAGRANCES</h3>
            <p className = 'text-sm lg:text-base text-grey mt-3'>Dear Customer, Due to the new law of international flammable items shipping movement, all major shippers in our locations have reduced this fleet at the moment. To Satisfy our customers we useing the UN Dangerous Goods Authorization provided by our Ministry of health with is also costing an extra 7$ / Invoice</p>
          </li>
        </ul>
        <Footer />
      </div>
    </>
  );
};

export default TermsConditions;