/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import TopHeader from '../../components/TopHeader';
import imgbanner from '../../images/river.jpeg';
import MainSubscriptionCollections from '../shopgifts/MainSubscriptionCollections';


const FeaturesBrand = () => {
    
  return(
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = "container mx-auto px-5 lg:px-10">
        <div>
          <img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvRkVBVFVSRURfQlJBTkRfUEFHRV9ERVNLVE9QX2Q1MzAyZmM4NzQuanBn' alt = 'banner' />
        </div>
        <div className = 'mt-10'>
          <h3 className = 'text-black text-4xl uppercase font-bold'>VERONIQUE GABAI FRAGRANCES</h3>
          <h4 className = 'text-base font-medium text mt-3'>Est. in: 2019, USA <span>6 products</span></h4>
        </div>
        <div className = 'flex justify-between mt-5'>
          <div className = 'w-width45%'>
            <img src = { imgbanner } alt = 'sideimg' />
          </div>
          <div className = 'w-width45% mt-16'>
            <h3 className = 'text-3xl text-black font-bold'>ABOUT THE BRAND</h3>
            <p className = 'mt-3 text-blacklight text-base'>
            Discover VERONIQUE GABAI, a collection of perfumes and beauty must-haves, all inspired by the Mediterranean and the French Riviera, all vegan, crafted around the best of nature for your wellbeing.
            </p>
          </div>
        </div>
        <div className = 'mt-20'>
          <img className = 'w-64 mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:384/aGVhZGxlc3MvR3JvdXBfMjk5M181YjRiZTM4ZTkxLmpwZw==' />
          <h3 className = 'text-3xl text-black font-bold text-center uppercase mt-8'>Our fragrances</h3>
          <MainSubscriptionCollections />
        </div>
        <div className = "w-full h-fit py-28 bg-cover bg-center mt-20" style = { {backgroundImage: 'url(\'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvTUlTU09OX1NUQVRFTUVOVF9CQU5ORVJfREVTS1RPUF9lMTEzNzMxMDcwLmpwZw==\')'} }>
          <div className = 'flex'>
            <div className = 'w-3/6'></div>
            <div className = 'w-3/6 relative'>
              <div className = 'bg-[#141414] text-white py-14 px-14 '>
                <span className = 'text-lg lg:text-xl font-semibold uppercase'>OUR MISSION</span>
                <p className = 'text-sm lg:text-base  mt-4'>
                Our perfumes are crafted with the best natural ingredients, for your well-being. Highly concentrated in naturals, they bloom uniquely on each skin, making them true signature scents, letting you express who you are. And our refillable bottles are to add beauty in your life and be kept for a long time.”Sunshine for the senses and the soul.”
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className = 'mt-20'>
          <img className = 'mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:750/aGVhZGxlc3MvRk9VTkRFUl9JTUFHRV9ERVNLVE9QXzg0NjYyNmEwODUuanBn' alt = 'banner' />
          <div className = 'w-8/12 mx-auto'>
            <div className = 'w-full h-px bg-primary mt-8'></div>
            <div className = 'text-center py-5'>
              <h4 className = 'text-base text-primary font-semibold'>A MODERN APPROACH TO FRAGRANCE</h4>
              <h3 className = 'text-2xl font-semibold mt-5'>“There is a place in my heart, where I was born, in the South of France; a place of discovery, wonder and grace, where the sun is like gold. My brand is like a home in the Côte d'Azur, wide open to welcome you in and committed to bridging luxury and sustainability.”</h3>
              <h4 className = 'text-lg text-primary font-semibold mt-5'>Madame Isabelle d’Ornano</h4>
              <p className = 'text-base text-blacklight mt-2'>Sisley Co-Founder</p>
            </div>
            <div className = 'w-full h-px bg-primary'></div>
          </div>
          <div className = "w-full h-fit py-28 bg-cover bg-center mt-20" style = { {backgroundImage: 'url(\'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvTWFza19ncm91cF8yXzU0MzQzM2QyOTIuanBn\')'} }>
            <div className = 'flex'>
              <div className = 'w-3/6 relative'>
                <div className = 'bg-[#9b7f73] text-white py-14 px-14 '>
                  <span className = 'text-lg lg:text-4xl font-bold uppercase'>SCENT WORLD</span>
                  <p className = 'text-xl font-bold mt-2'>FEATURING:</p>
                  <h3 className = 'text-2xl font-bold mt-2 text-black'>VERONIQUE GABAI</h3>
                  <p className = 'text-sm lg:text-base  mt-4'>
                  In this episode, Veronique opens up about what it took to start her own brand after a career at the helm of Giorgio Armani Profumi, Guerlain, and the Estée Lauder companies. We uncover the essence of her perfumes: a souvenir from Tunisia, a rose that can only be harvested for thirty days a year, and a perfume made of pleasure, called le Point G.
                  </p>
                </div>
              </div>
              <div className = 'w-3/6'></div>
            </div>
          </div>
          <img className = 'mt-20' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvQk9UVE9NX01PRFVMRV9ERVNLVE9QX2ViYTkwNWU0MmMuanBn' alt = 'banner' />
        </div>
        <Footer />
      </div>
      
    </>
  );
};

export default FeaturesBrand;