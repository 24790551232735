/* eslint-disable react/jsx-key */
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import FragranceProduct from './FragranceProduct';


const FloralFruityGreen = () => {

  const sliderRef = useRef(null);

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const data = {
    'products': [
      {
        '_id': '65e0dc040bd3d1e85c856631',
        'name': 'Nina Ricci L Extase Rose Absolu Perfume 30ml Hair Mist',
        'price': 45.89,
        'description': 'Ultra-fast cards (2) to take better pictures and Full HD videos (1) with your compact to mid-range point-and-shoot cameras and camcorders. With SanDisk Ultra SDXC UHS-I cards you’ll benefit from faster downloads, high capacity, and better performance to capture and store 128GB (5) of high quality pictures and Full HD video (1). Take advantage of ultra-fast read speeds of up to 80MB/s (3) to save time moving photos and videos from the card to your computer. From a leader in flash memory storage, SanDisk Ultra SDXC UHS-I cards are compatible with SDHC and SDXC digital devices, and come with a 10-year limited warranty (6).',
        'ratings': 4,
        'images': [
          {
            'public_id': 'shopit/demo/nkkjkta63uiazppzkmjf',
            'url': 'https://cdn.salla.sa/KVlQp/ZiznfWPMnlMdOTaneYo8rl0Wouh9As036wAeKnu2.jpg',
            '_id': '65e0dc040bd3d1e85c856632'
          },
          {
            'public_id': 'shopit/demo/hz1iwdqzrvxtfxincvju',
            'url': 'https://cdn.salla.sa/KVlQp/epp1oQCLjxSQX8kdVdckNKhOiwR2Vfj7l8nsz2bJ.jpg',
            '_id': '65e0dc040bd3d1e85c856633'
          },
          {
            'public_id': 'shopit/demo/oc2dvvkxyxukc13y9pjv',
            'url': 'https://cdn.salla.sa/KVlQp/0eH5cKNfc1L1C6zbeexbz15ms7Mx2sQtuoVvCc2t.jpg',
            '_id': '65e0dc040bd3d1e85c856634'
          },
          {
            'public_id': 'shopit/demo/oc2dvvkxyxukc13y9pjv',
            'url': 'https://cdn.salla.sa/KVlQp/r3vZswm4XtHRoqofvqUvtQvoXdkmNoKCK4mQq3lO.jpg',
            '_id': '65e0dc040bd3d1e85c856634'
          }
        ],
        'category': 'Electronics',
        'seller': 'Ebay',
        'stock': 48,
        'numOfReviews': 4,
        'reviews': [],
        '__v': 3,
        'createdAt': '2024-02-29T19:33:24.753Z',
        'updatedAt': '2024-03-18T07:54:36.386Z'
      },
      {
        '_id': '65e0dc040bd3d1e85c856631',
        'name': 'Nina Ricci L Extase Rose Absolu Perfume 30ml Hair Mist',
        'price': 45.89,
        'description': 'Ultra-fast cards (2) to take better pictures and Full HD videos (1) with your compact to mid-range point-and-shoot cameras and camcorders. With SanDisk Ultra SDXC UHS-I cards you’ll benefit from faster downloads, high capacity, and better performance to capture and store 128GB (5) of high quality pictures and Full HD video (1). Take advantage of ultra-fast read speeds of up to 80MB/s (3) to save time moving photos and videos from the card to your computer. From a leader in flash memory storage, SanDisk Ultra SDXC UHS-I cards are compatible with SDHC and SDXC digital devices, and come with a 10-year limited warranty (6).',
        'ratings': 2,
        'images': [
          {
            'public_id': 'shopit/demo/nkkjkta63uiazppzkmjf',
            'url': 'https://cdn.salla.sa/KVlQp/ZiznfWPMnlMdOTaneYo8rl0Wouh9As036wAeKnu2.jpg',
            '_id': '65e0dc040bd3d1e85c856632'
          },
          {
            'public_id': 'shopit/demo/hz1iwdqzrvxtfxincvju',
            'url': 'https://cdn.salla.sa/KVlQp/epp1oQCLjxSQX8kdVdckNKhOiwR2Vfj7l8nsz2bJ.jpg',
            '_id': '65e0dc040bd3d1e85c856633'
          },
          {
            'public_id': 'shopit/demo/oc2dvvkxyxukc13y9pjv',
            'url': 'https://cdn.salla.sa/KVlQp/0eH5cKNfc1L1C6zbeexbz15ms7Mx2sQtuoVvCc2t.jpg',
            '_id': '65e0dc040bd3d1e85c856634'
          },
          {
            'public_id': 'shopit/demo/oc2dvvkxyxukc13y9pjv',
            'url': 'https://cdn.salla.sa/KVlQp/r3vZswm4XtHRoqofvqUvtQvoXdkmNoKCK4mQq3lO.jpg',
            '_id': '65e0dc040bd3d1e85c856634'
          }
        ],
        'category': 'Electronics',
        'seller': 'Ebay',
        'stock': 48,
        'numOfReviews': 2,
        'reviews': [],
        '__v': 3,
        'createdAt': '2024-02-29T19:33:24.753Z',
        'updatedAt': '2024-03-18T07:54:36.386Z'
      },
      {
        '_id': '65e0dc040bd3d1e85c856631',
        'name': 'Nina Ricci L Extase Rose Absolu Perfume 30ml Hair Mist',
        'price': 45.89,
        'description': 'Ultra-fast cards (2) to take better pictures and Full HD videos (1) with your compact to mid-range point-and-shoot cameras and camcorders. With SanDisk Ultra SDXC UHS-I cards you’ll benefit from faster downloads, high capacity, and better performance to capture and store 128GB (5) of high quality pictures and Full HD video (1). Take advantage of ultra-fast read speeds of up to 80MB/s (3) to save time moving photos and videos from the card to your computer. From a leader in flash memory storage, SanDisk Ultra SDXC UHS-I cards are compatible with SDHC and SDXC digital devices, and come with a 10-year limited warranty (6).',
        'ratings': 0,
        'images': [
          {
            'public_id': 'shopit/demo/nkkjkta63uiazppzkmjf',
            'url': 'https://cdn.salla.sa/KVlQp/ZiznfWPMnlMdOTaneYo8rl0Wouh9As036wAeKnu2.jpg',
            '_id': '65e0dc040bd3d1e85c856632'
          },
          {
            'public_id': 'shopit/demo/hz1iwdqzrvxtfxincvju',
            'url': 'https://cdn.salla.sa/KVlQp/epp1oQCLjxSQX8kdVdckNKhOiwR2Vfj7l8nsz2bJ.jpg',
            '_id': '65e0dc040bd3d1e85c856633'
          },
          {
            'public_id': 'shopit/demo/oc2dvvkxyxukc13y9pjv',
            'url': 'https://cdn.salla.sa/KVlQp/0eH5cKNfc1L1C6zbeexbz15ms7Mx2sQtuoVvCc2t.jpg',
            '_id': '65e0dc040bd3d1e85c856634'
          },
          {
            'public_id': 'shopit/demo/oc2dvvkxyxukc13y9pjv',
            'url': 'https://cdn.salla.sa/KVlQp/r3vZswm4XtHRoqofvqUvtQvoXdkmNoKCK4mQq3lO.jpg',
            '_id': '65e0dc040bd3d1e85c856634'
          }
        ],
        'category': 'Electronics',
        'seller': 'Ebay',
        'stock': 48,
        'numOfReviews': 0,
        'reviews': [],
        '__v': 3,
        'createdAt': '2024-02-29T19:33:24.753Z',
        'updatedAt': '2024-03-18T07:54:36.386Z'
      },
      {
        '_id': '65e0dc040bd3d1e85c856631',
        'name': 'Nina Ricci L Extase Rose Absolu Perfume 30ml Hair Mist',
        'price': 45.89,
        'description': 'Ultra-fast cards (2) to take better pictures and Full HD videos (1) with your compact to mid-range point-and-shoot cameras and camcorders. With SanDisk Ultra SDXC UHS-I cards you’ll benefit from faster downloads, high capacity, and better performance to capture and store 128GB (5) of high quality pictures and Full HD video (1). Take advantage of ultra-fast read speeds of up to 80MB/s (3) to save time moving photos and videos from the card to your computer. From a leader in flash memory storage, SanDisk Ultra SDXC UHS-I cards are compatible with SDHC and SDXC digital devices, and come with a 10-year limited warranty (6).',
        'ratings': 0,
        'images': [
          {
            'public_id': 'shopit/demo/nkkjkta63uiazppzkmjf',
            'url': 'https://cdn.salla.sa/KVlQp/ZiznfWPMnlMdOTaneYo8rl0Wouh9As036wAeKnu2.jpg',
            '_id': '65e0dc040bd3d1e85c856632'
          },
          {
            'public_id': 'shopit/demo/hz1iwdqzrvxtfxincvju',
            'url': 'https://cdn.salla.sa/KVlQp/epp1oQCLjxSQX8kdVdckNKhOiwR2Vfj7l8nsz2bJ.jpg',
            '_id': '65e0dc040bd3d1e85c856633'
          },
          {
            'public_id': 'shopit/demo/oc2dvvkxyxukc13y9pjv',
            'url': 'https://cdn.salla.sa/KVlQp/0eH5cKNfc1L1C6zbeexbz15ms7Mx2sQtuoVvCc2t.jpg',
            '_id': '65e0dc040bd3d1e85c856634'
          },
          {
            'public_id': 'shopit/demo/oc2dvvkxyxukc13y9pjv',
            'url': 'https://cdn.salla.sa/KVlQp/r3vZswm4XtHRoqofvqUvtQvoXdkmNoKCK4mQq3lO.jpg',
            '_id': '65e0dc040bd3d1e85c856634'
          }
        ],
        'category': 'Electronics',
        'seller': 'Ebay',
        'stock': 48,
        'numOfReviews': 0,
        'reviews': [],
        '__v': 3,
        'createdAt': '2024-02-29T19:33:24.753Z',
        'updatedAt': '2024-03-18T07:54:36.386Z'
      },
      {
        '_id': '65e0dc040bd3d1e85c856631',
        'name': 'Nina Ricci L Extase Rose Absolu Perfume 30ml Hair Mist',
        'price': 45.89,
        'description': 'Ultra-fast cards (2) to take better pictures and Full HD videos (1) with your compact to mid-range point-and-shoot cameras and camcorders. With SanDisk Ultra SDXC UHS-I cards you’ll benefit from faster downloads, high capacity, and better performance to capture and store 128GB (5) of high quality pictures and Full HD video (1). Take advantage of ultra-fast read speeds of up to 80MB/s (3) to save time moving photos and videos from the card to your computer. From a leader in flash memory storage, SanDisk Ultra SDXC UHS-I cards are compatible with SDHC and SDXC digital devices, and come with a 10-year limited warranty (6).',
        'ratings': 0,
        'images': [
          {
            'public_id': 'shopit/demo/nkkjkta63uiazppzkmjf',
            'url': 'https://cdn.salla.sa/KVlQp/ZiznfWPMnlMdOTaneYo8rl0Wouh9As036wAeKnu2.jpg',
            '_id': '65e0dc040bd3d1e85c856632'
          },
          {
            'public_id': 'shopit/demo/hz1iwdqzrvxtfxincvju',
            'url': 'https://cdn.salla.sa/KVlQp/epp1oQCLjxSQX8kdVdckNKhOiwR2Vfj7l8nsz2bJ.jpg',
            '_id': '65e0dc040bd3d1e85c856633'
          },
          {
            'public_id': 'shopit/demo/oc2dvvkxyxukc13y9pjv',
            'url': 'https://cdn.salla.sa/KVlQp/0eH5cKNfc1L1C6zbeexbz15ms7Mx2sQtuoVvCc2t.jpg',
            '_id': '65e0dc040bd3d1e85c856634'
          },
          {
            'public_id': 'shopit/demo/oc2dvvkxyxukc13y9pjv',
            'url': 'https://cdn.salla.sa/KVlQp/r3vZswm4XtHRoqofvqUvtQvoXdkmNoKCK4mQq3lO.jpg',
            '_id': '65e0dc040bd3d1e85c856634'
          }
        ],
        'category': 'Electronics',
        'seller': 'Ebay',
        'stock': 48,
        'numOfReviews': 0,
        'reviews': [],
        '__v': 3,
        'createdAt': '2024-02-29T19:33:24.753Z',
        'updatedAt': '2024-03-18T07:54:36.386Z'
      },
      {
        '_id': '65e0dc040bd3d1e85c856631',
        'name': 'Nina Ricci L Extase Rose Absolu Perfume 30ml Hair Mist',
        'price': 45.89,
        'description': 'Ultra-fast cards (2) to take better pictures and Full HD videos (1) with your compact to mid-range point-and-shoot cameras and camcorders. With SanDisk Ultra SDXC UHS-I cards you’ll benefit from faster downloads, high capacity, and better performance to capture and store 128GB (5) of high quality pictures and Full HD video (1). Take advantage of ultra-fast read speeds of up to 80MB/s (3) to save time moving photos and videos from the card to your computer. From a leader in flash memory storage, SanDisk Ultra SDXC UHS-I cards are compatible with SDHC and SDXC digital devices, and come with a 10-year limited warranty (6).',
        'ratings': 0,
        'images': [
          {
            'public_id': 'shopit/demo/nkkjkta63uiazppzkmjf',
            'url': 'https://cdn.salla.sa/KVlQp/ZiznfWPMnlMdOTaneYo8rl0Wouh9As036wAeKnu2.jpg',
            '_id': '65e0dc040bd3d1e85c856632'
          },
          {
            'public_id': 'shopit/demo/hz1iwdqzrvxtfxincvju',
            'url': 'https://cdn.salla.sa/KVlQp/epp1oQCLjxSQX8kdVdckNKhOiwR2Vfj7l8nsz2bJ.jpg',
            '_id': '65e0dc040bd3d1e85c856633'
          },
          {
            'public_id': 'shopit/demo/oc2dvvkxyxukc13y9pjv',
            'url': 'https://cdn.salla.sa/KVlQp/0eH5cKNfc1L1C6zbeexbz15ms7Mx2sQtuoVvCc2t.jpg',
            '_id': '65e0dc040bd3d1e85c856634'
          },
          {
            'public_id': 'shopit/demo/oc2dvvkxyxukc13y9pjv',
            'url': 'https://cdn.salla.sa/KVlQp/r3vZswm4XtHRoqofvqUvtQvoXdkmNoKCK4mQq3lO.jpg',
            '_id': '65e0dc040bd3d1e85c856634'
          }
        ],
        'category': 'Electronics',
        'seller': 'Ebay',
        'stock': 48,
        'numOfReviews': 0,
        'reviews': [],
        '__v': 3,
        'createdAt': '2024-02-29T19:33:24.753Z',
        'updatedAt': '2024-03-18T07:54:36.386Z'
      },
      {
        '_id': '65e0dc040bd3d1e85c856631',
        'name': 'Nina Ricci L Extase Rose Absolu Perfume 30ml Hair Mist',
        'price': 45.89,
        'description': 'Ultra-fast cards (2) to take better pictures and Full HD videos (1) with your compact to mid-range point-and-shoot cameras and camcorders. With SanDisk Ultra SDXC UHS-I cards you’ll benefit from faster downloads, high capacity, and better performance to capture and store 128GB (5) of high quality pictures and Full HD video (1). Take advantage of ultra-fast read speeds of up to 80MB/s (3) to save time moving photos and videos from the card to your computer. From a leader in flash memory storage, SanDisk Ultra SDXC UHS-I cards are compatible with SDHC and SDXC digital devices, and come with a 10-year limited warranty (6).',
        'ratings': 0,
        'images': [
          {
            'public_id': 'shopit/demo/nkkjkta63uiazppzkmjf',
            'url': 'https://cdn.salla.sa/KVlQp/ZiznfWPMnlMdOTaneYo8rl0Wouh9As036wAeKnu2.jpg',
            '_id': '65e0dc040bd3d1e85c856632'
          },
          {
            'public_id': 'shopit/demo/hz1iwdqzrvxtfxincvju',
            'url': 'https://cdn.salla.sa/KVlQp/epp1oQCLjxSQX8kdVdckNKhOiwR2Vfj7l8nsz2bJ.jpg',
            '_id': '65e0dc040bd3d1e85c856633'
          },
          {
            'public_id': 'shopit/demo/oc2dvvkxyxukc13y9pjv',
            'url': 'https://cdn.salla.sa/KVlQp/0eH5cKNfc1L1C6zbeexbz15ms7Mx2sQtuoVvCc2t.jpg',
            '_id': '65e0dc040bd3d1e85c856634'
          },
          {
            'public_id': 'shopit/demo/oc2dvvkxyxukc13y9pjv',
            'url': 'https://cdn.salla.sa/KVlQp/r3vZswm4XtHRoqofvqUvtQvoXdkmNoKCK4mQq3lO.jpg',
            '_id': '65e0dc040bd3d1e85c856634'
          }
        ],
        'category': 'Electronics',
        'seller': 'Ebay',
        'stock': 48,
        'numOfReviews': 0,
        'reviews': [],
        '__v': 3,
        'createdAt': '2024-02-29T19:33:24.753Z',
        'updatedAt': '2024-03-18T07:54:36.386Z'
      },
      {
        '_id': '65e0dc040bd3d1e85c856631',
        'name': 'Nina Ricci L Extase Rose Absolu Perfume 30ml Hair Mist',
        'price': 45.89,
        'description': 'Ultra-fast cards (2) to take better pictures and Full HD videos (1) with your compact to mid-range point-and-shoot cameras and camcorders. With SanDisk Ultra SDXC UHS-I cards you’ll benefit from faster downloads, high capacity, and better performance to capture and store 128GB (5) of high quality pictures and Full HD video (1). Take advantage of ultra-fast read speeds of up to 80MB/s (3) to save time moving photos and videos from the card to your computer. From a leader in flash memory storage, SanDisk Ultra SDXC UHS-I cards are compatible with SDHC and SDXC digital devices, and come with a 10-year limited warranty (6).',
        'ratings': 0,
        'images': [
          {
            'public_id': 'shopit/demo/nkkjkta63uiazppzkmjf',
            'url': 'https://cdn.salla.sa/KVlQp/ZiznfWPMnlMdOTaneYo8rl0Wouh9As036wAeKnu2.jpg',
            '_id': '65e0dc040bd3d1e85c856632'
          },
          {
            'public_id': 'shopit/demo/hz1iwdqzrvxtfxincvju',
            'url': 'https://cdn.salla.sa/KVlQp/epp1oQCLjxSQX8kdVdckNKhOiwR2Vfj7l8nsz2bJ.jpg',
            '_id': '65e0dc040bd3d1e85c856633'
          },
          {
            'public_id': 'shopit/demo/oc2dvvkxyxukc13y9pjv',
            'url': 'https://cdn.salla.sa/KVlQp/0eH5cKNfc1L1C6zbeexbz15ms7Mx2sQtuoVvCc2t.jpg',
            '_id': '65e0dc040bd3d1e85c856634'
          },
          {
            'public_id': 'shopit/demo/oc2dvvkxyxukc13y9pjv',
            'url': 'https://cdn.salla.sa/KVlQp/r3vZswm4XtHRoqofvqUvtQvoXdkmNoKCK4mQq3lO.jpg',
            '_id': '65e0dc040bd3d1e85c856634'
          }
        ],
        'category': 'Electronics',
        'seller': 'Ebay',
        'stock': 48,
        'numOfReviews': 0,
        'reviews': [],
        '__v': 3,
        'createdAt': '2024-02-29T19:33:24.753Z',
        'updatedAt': '2024-03-18T07:54:36.386Z'
      }

    ]
  };


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  

  return (
    <>
      <div className = 'mt-8'>
        <div className = 'block md:hidden'>
          <div className = 'bg-[#141414] text-white py-5 px-5 '>
            <span className = 'text-base md:text-lg lg:text-xl font-semibold uppercase'>FRAGRANCE FAMILY</span>
            <h3 className = 'text-2xl lg:text-4xl font-bold mt-2'>FLORAL FRUITY GREEN</h3>
            <p className = 'text-sm lg:text-base font-medium md:font-semibold mt-4'>
            Natural, energetic, and fresh - fragrances in the Floral Fruity Green family leave an impression of freshly-picked flowers. Reminiscent of Springtime, these fragrances showcase notes of dew-kissed petals, watery fruits and crisp green leaves.
            </p>
            <Link to = '#' className = 'text-sm uppercase font-medium md:font-semibold inline-block mt-4'>Learn more <span><ArrowRightAltIcon style = { {fontSize: '24px'} } /></span></Link>
          </div>
          <div className = 'w-full'>
            <img src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvZmZfZ3JlZW5fZF8zMjE5M2U1MTEyLmpwZw==' alt = 'familyfragrancy' />
          </div>
        </div>
        <div className = "hidden md:block w-full h-fit py-28 bg-cover bg-center" style = { {backgroundImage: 'url(\'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvZmZfZ3JlZW5fZF8zMjE5M2U1MTEyLmpwZw==\')'} }>
          <div className = 'flex'>
            <div className = 'w-3/6'></div>
            <div className = 'w-3/6 relative'>
              <div className = 'bg-[#141414] text-white py-14 px-14 '>
                <span className = 'text-lg lg:text-xl font-semibold uppercase'>FRAGRANCE FAMILY</span>
                <h3 className = 'text-2xl lg:text-4xl font-bold mt-2'>FLORAL FRUITY GREEN</h3>
                <p className = 'text-sm lg:text-base font-semibold mt-4'>
                Natural, energetic, and fresh - fragrances in the Floral Fruity Green family leave an impression of freshly-picked flowers. Reminiscent of Springtime, these fragrances showcase notes of dew-kissed petals, watery fruits and crisp green leaves.
                </p>
                <Link to = '#' className = 'text-sm uppercase font-semibold inline-block mt-4'>Learn more <span><ArrowRightAltIcon style = { {fontSize: '24px'} } /></span></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className = 'mt-8'>
        <h3 className = 'text-black text-lg lg:text-3xl font-bold uppercase text-center pb-2'>SCENTS IN THIS FRAGRANCE FAMILY</h3>
        <div className = 'relative'>
          <div className = 'slicksliderfragrance'>
            <Slider ref = { sliderRef } { ...settings }>
              { data?.products?.map((product) => (
                <FragranceProduct key = { product._id } product = { product } />
              )) }
            </Slider>
          </div>
          <div className = 'absolute w-full flex justify-between wrp-arrowicon'>
            <button onClick = { goToPrevSlide } className = 'w-10 h-10 bg-white shadow-arrowsad rounded-full'><KeyboardArrowLeftOutlinedIcon /></button>
            <button onClick = { goToNextSlide } className = 'w-10 h-10 bg-white shadow-arrowsad rounded-full inline-block ml-3'><KeyboardArrowRightOutlinedIcon /></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FloralFruityGreen;
