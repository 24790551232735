/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import TopHeader from '../../components/TopHeader';


const Horoscope = () => {
    
  return(
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = "container mx-auto px-5 lg:px-10">
        <div className = 'mt-16 text-center'>
          <h3 className = 'text-primary text-4xl uppercase font-bold'>YOUR 2024</h3>
          <h3 className = 'text-black text-4xl uppercase font-bold'>SCENT HOROSCOPE</h3>
          <p className = 'text-blacklight font-base mt-3'>Your 2024 scent recommendations <br></br>
            are written in the stars. Find your <br></br>
            zodiac sign to reveal your specially <br></br>
            curated picks.
          </p>
        </div>
        <div className = 'mt-8'>
          <div className = 'flex flex-wrap justify-between'>
            <div className = 'w-width23% text-center mt-8'>
              <img className = 'mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:128/ZnJvbnRiaXJkMi9pbWFnZXMvYXJpZXNfYTJiMThmLnBuZw==' alt = 'img' />
              <img className = 'mx-auto' src = 'https://cdn.scentbird.com/frontbird2/images-svg/aries_e8da24.svg' alt = 'smallimg' />
              <p>Bold, ambitious, your view from the top sure is nice.</p>
              <Link to = '#' className = 'block bg-black text-base text-white py-2 w-4/5 mx-auto mt-5'>Reveal collection</Link>
            </div>
            <div className = 'w-width23% text-center mt-8'>
              <img className = 'mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:128/ZnJvbnRiaXJkMi9pbWFnZXMvYXJpZXNfYTJiMThmLnBuZw==' alt = 'img' />
              <img className = 'mx-auto' src = 'https://cdn.scentbird.com/frontbird2/images-svg/aries_e8da24.svg' alt = 'smallimg' />
              <p>Bold, ambitious, your view from the top sure is nice.</p>
              <Link to = '#' className = 'block bg-black text-base text-white py-2 w-4/5 mx-auto mt-5'>Reveal collection</Link>
            </div>
            <div className = 'w-width23% text-center mt-8'>
              <img className = 'mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:128/ZnJvbnRiaXJkMi9pbWFnZXMvYXJpZXNfYTJiMThmLnBuZw==' alt = 'img' />
              <img className = 'mx-auto' src = 'https://cdn.scentbird.com/frontbird2/images-svg/aries_e8da24.svg' alt = 'smallimg' />
              <p>Bold, ambitious, your view from the top sure is nice.</p>
              <Link to = '#' className = 'block bg-black text-base text-white py-2 w-4/5 mx-auto mt-5'>Reveal collection</Link>
            </div>
            <div className = 'w-width23% text-center mt-8'>
              <img className = 'mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:128/ZnJvbnRiaXJkMi9pbWFnZXMvYXJpZXNfYTJiMThmLnBuZw==' alt = 'img' />
              <img className = 'mx-auto' src = 'https://cdn.scentbird.com/frontbird2/images-svg/aries_e8da24.svg' alt = 'smallimg' />
              <p>Bold, ambitious, your view from the top sure is nice.</p>
              <Link to = '#' className = 'block bg-black text-base text-white py-2 w-4/5 mx-auto mt-5'>Reveal collection</Link>
            </div>
            <div className = 'w-width23% text-center mt-8'>
              <img className = 'mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:128/ZnJvbnRiaXJkMi9pbWFnZXMvYXJpZXNfYTJiMThmLnBuZw==' alt = 'img' />
              <img className = 'mx-auto' src = 'https://cdn.scentbird.com/frontbird2/images-svg/aries_e8da24.svg' alt = 'smallimg' />
              <p>Bold, ambitious, your view from the top sure is nice.</p>
              <Link to = '#' className = 'block bg-black text-base text-white py-2 w-4/5 mx-auto mt-5'>Reveal collection</Link>
            </div>
            <div className = 'w-width23% text-center mt-8'>
              <img className = 'mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:128/ZnJvbnRiaXJkMi9pbWFnZXMvYXJpZXNfYTJiMThmLnBuZw==' alt = 'img' />
              <img className = 'mx-auto' src = 'https://cdn.scentbird.com/frontbird2/images-svg/aries_e8da24.svg' alt = 'smallimg' />
              <p>Bold, ambitious, your view from the top sure is nice.</p>
              <Link to = '#' className = 'block bg-black text-base text-white py-2 w-4/5 mx-auto mt-5'>Reveal collection</Link>
            </div>
            <div className = 'w-width23% text-center mt-8'>
              <img className = 'mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:128/ZnJvbnRiaXJkMi9pbWFnZXMvYXJpZXNfYTJiMThmLnBuZw==' alt = 'img' />
              <img className = 'mx-auto' src = 'https://cdn.scentbird.com/frontbird2/images-svg/aries_e8da24.svg' alt = 'smallimg' />
              <p>Bold, ambitious, your view from the top sure is nice.</p>
              <Link to = '#' className = 'block bg-black text-base text-white py-2 w-4/5 mx-auto mt-5'>Reveal collection</Link>
            </div>
            <div className = 'w-width23% text-center mt-8'>
              <img className = 'mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:128/ZnJvbnRiaXJkMi9pbWFnZXMvYXJpZXNfYTJiMThmLnBuZw==' alt = 'img' />
              <img className = 'mx-auto' src = 'https://cdn.scentbird.com/frontbird2/images-svg/aries_e8da24.svg' alt = 'smallimg' />
              <p>Bold, ambitious, your view from the top sure is nice.</p>
              <Link to = '#' className = 'block bg-black text-base text-white py-2 w-4/5 mx-auto mt-5'>Reveal collection</Link>
            </div>
            <div className = 'w-width23% text-center mt-8'>
              <img className = 'mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:128/ZnJvbnRiaXJkMi9pbWFnZXMvYXJpZXNfYTJiMThmLnBuZw==' alt = 'img' />
              <img className = 'mx-auto' src = 'https://cdn.scentbird.com/frontbird2/images-svg/aries_e8da24.svg' alt = 'smallimg' />
              <p>Bold, ambitious, your view from the top sure is nice.</p>
              <Link to = '#' className = 'block bg-black text-base text-white py-2 w-4/5 mx-auto mt-5'>Reveal collection</Link>
            </div>
            <div className = 'w-width23% text-center mt-8'>
              <img className = 'mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:128/ZnJvbnRiaXJkMi9pbWFnZXMvYXJpZXNfYTJiMThmLnBuZw==' alt = 'img' />
              <img className = 'mx-auto' src = 'https://cdn.scentbird.com/frontbird2/images-svg/aries_e8da24.svg' alt = 'smallimg' />
              <p>Bold, ambitious, your view from the top sure is nice.</p>
              <Link to = '#' className = 'block bg-black text-base text-white py-2 w-4/5 mx-auto mt-5'>Reveal collection</Link>
            </div>
            <div className = 'w-width23% text-center mt-8'>
              <img className = 'mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:128/ZnJvbnRiaXJkMi9pbWFnZXMvYXJpZXNfYTJiMThmLnBuZw==' alt = 'img' />
              <img className = 'mx-auto' src = 'https://cdn.scentbird.com/frontbird2/images-svg/aries_e8da24.svg' alt = 'smallimg' />
              <p>Bold, ambitious, your view from the top sure is nice.</p>
              <Link to = '#' className = 'block bg-black text-base text-white py-2 w-4/5 mx-auto mt-5'>Reveal collection</Link>
            </div>
            <div className = 'w-width23% text-center mt-8'>
              <img className = 'mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:128/ZnJvbnRiaXJkMi9pbWFnZXMvYXJpZXNfYTJiMThmLnBuZw==' alt = 'img' />
              <img className = 'mx-auto' src = 'https://cdn.scentbird.com/frontbird2/images-svg/aries_e8da24.svg' alt = 'smallimg' />
              <p>Bold, ambitious, your view from the top sure is nice.</p>
              <Link to = '#' className = 'block bg-black text-base text-white py-2 w-4/5 mx-auto mt-5'>Reveal collection</Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      
    </>
  );
};

export default Horoscope;