import React from 'react';
import { Helmet } from 'react-helmet';
import BannerHome from '../components/BannerHome';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import OurBestSeller from '../components/OurBestSeller';
import ShopByCategries from '../components/ShopByCategries';
import Celebrate from './celebrate/Celebrate';
import Homefeaturedbrand from './Homefeaturedbrand';
import Newarival from './newarival/Newarival';
import Trendingnow from './trendingnow/Trendingnow';
// import BlogHome from './BlogHome';


const Home = () => {
  return (
    <div>
      <Helmet>
        <meta charset = "utf-8" />
        <title>Dimas</title>
        <meta name = "description" content = "Dimas" />
        <meta name = "author" content = "Dimas" />
        <link rel = "canonical" href = "#" />
      </Helmet>
      <Header />
      <Navigation /> 
      <BannerHome />
      <div className = 'container mx-auto px-5 lg:px-0'>
        <Homefeaturedbrand />
        <Celebrate />
        <Newarival />
        <Trendingnow />
        <OurBestSeller />
        <ShopByCategries />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
