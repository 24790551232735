import { createSlice } from '@reduxjs/toolkit';

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
    checkoutPrice: 0
  },
  reducers: {
    setCheckoutPrice: (state, action) => {
      state.checkoutPrice = action.payload;
    }
  }
});

export const { setCheckoutPrice } = checkoutSlice.actions;
export default checkoutSlice.reducer;
