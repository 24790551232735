/* eslint-disable react/prop-types */
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useBrandcollectionQuery, useCategorycollectionQuery } from '../redux/api/recomendedproductApi';
import { showToast } from '../redux/features/toastSlice';
import DoubleRangeSlider from './DoubleRangeSlider';

const Filtter = ({ setSelectedBrands, setSelectedCategories }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const [isBrandOpen, setBrandOpen] = useState(false);
  const [isCatlogOpen, setCatlogOpen] = useState(false);
  const [isPriceOpen, setPriceOpen] = useState(false);
  const [brands, setBrands] = useState([]);


  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };




  const { error, data } = useBrandcollectionQuery();
  const { error: errorcategory, data: datacategory } = useCategorycollectionQuery();

  useEffect(() => {
    if (error) {
      dispatch(showToast(error?.data?.message));
    }
  }, [error]);

  useEffect(() => {
    const brandsData = data?.data;
    const uniqueBrands = brandsData
      ? Array.from(new Set(brandsData.map(brand => brand.name)))
        .map(name => {
          return brandsData.find(brand => brand.name === name);
        })
        .sort((a, b) => a.name.localeCompare(b.name)) // Sort the brands alphabetically by name
      : [];

    setBrands(uniqueBrands);
  }, [data]);

  useEffect(() => {
    if (errorcategory) {
      dispatch(showToast(errorcategory?.data?.message));
    }
  }, [errorcategory]);

  const handleBrandChange = (e) => {
    const { checked, value } = e.target;
    setSelectedBrands(prev =>
      checked ? [...prev, value] : prev.filter(brand => brand !== value)
    );
  };

  const handleCategoryChange = (e) => {
    const { checked, value } = e.target;
    setSelectedCategories(prev =>
      checked ? [...prev, value] : prev.filter(category => category !== value)
    );
  };


  const handleBlur = (event) => {
    event.target.blur();
  };

  return (
    <>
      <div className = 'hidden md:block'>
        <div>
          <div className = 'flex p-b cursor-pointer' onClick = { () => setBrandOpen(!isBrandOpen) }>
            <span className = 'text-blacklight font-bold'>{ isBrandOpen ? <ExpandLessIcon /> : <KeyboardArrowDownIcon /> }</span>
            <p className = { `text-blacklight text-sm font-bold uppercase mt-1 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>{ t('BRAND') }</p>
          </div>
          { isBrandOpen && (
            <div>
              <ul className = ' text-left mt-3 brandscroll custom-scroll'>
                { brands?.map(brand => (
                  <li className = 'mt-1' key = { brand.id }>
                    <div className = 'flex justify-between'>
                      <div>
                        <input
                          type = 'checkbox'
                          id = { `brand-${brand.id}` }
                          value = { brand.name }
                          onChange = { handleBrandChange }
                          onFocus = { handleBlur }
                        />{ ' ' }
                        <label htmlFor = { `brand-${brand.id}` } className = 'cursor-pointer text-xs text-blacklight uppercase inline-block ml-1'>{ brand?.name }</label>
                      </div>
                      <div>
                        <span className = 'text-sm text-blacklight uppercase'>({ brand?.count })</span>
                      </div>
                    </div>
                  </li>
                )) }
              </ul>
            </div>
          ) }
        </div>
        <div className = 'w-full h-px bg-grey-500 my-4'></div>
        <div className = 'flex cursor-pointer' onClick = { () => setCatlogOpen(!isCatlogOpen) }>
          <span className = 'text-blacklight font-bold'>{ isCatlogOpen ? <ExpandLessIcon /> : <KeyboardArrowDownIcon /> }</span>
          <p className = { `text-blacklight text-sm font-bold uppercase mt-1 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>{ t('CATEGORIES') }</p>
        </div>
        { isCatlogOpen && (
          <div>
            <ul className = 'text-left mt-3 brandscroll custom-scroll'>
              { datacategory?.data?.map((item) => (
                <li className = 'mt-1' key = { item?.id }>
                  <div className = 'flex justify-between'>
                    <div>
                      <input
                        type = 'checkbox'
                        id = { `category-${item?.id}` }
                        value = { item?.name_en }
                        onChange = { handleCategoryChange }
                        onFocus = { handleBlur }
                      />{ ' ' }
                      <label htmlFor = { `category-${item?.id}` } className = ' cursor-pointer text-xs text-blacklight uppercase inline-block ml-1 font-madani-arabic'>{ selectLanguage == 'ar'?item?.name_ar: item?.name_en }</label>
                    </div>
                    <div>
                      <span className = 'text-sm text-blacklight uppercase'>({ item.count })</span>
                    </div>
                  </div>
                </li>
              )) }
            </ul>
          </div>
        ) }
        <div className = 'w-full h-px bg-grey-500 my-4'></div>
        <div className = 'flex pb-2 cursor-pointer' onClick = { () => setPriceOpen(!isPriceOpen) }>
          <span className = 'text-blacklight font-bold'>{ isPriceOpen ? <ExpandLessIcon /> : <KeyboardArrowDownIcon /> }</span>
          <p className = { `text-blacklight text-sm font-bold uppercase mt-1 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>{ t('PRICE') }</p>
        </div>
        { isPriceOpen && (
          <div>
            <div className = 'mt-5'>
              <DoubleRangeSlider
                min = { 0 }
                max = { 1000 }
                onChange = { () => ({ min, max }) => { min, max; } }
              />
            </div>
          </div>
        ) }
      </div>
      <div className = 'block md:hidden'>
        <span className = 'text-center mx-auto mt-3 text-sm align-middle' onClick = { toggleMenu } ><MenuOutlinedIcon style = { {fontSize: '24px'} } /> Show sidebar</span>
      </div>
      <div className = { `block md:hidden mobile-menu max-h-screen ${
        isMenuOpen ? 'slide-in' : 'slide-out'
      } transition-transform duration-300 drop-shadow-lg` } ref = { menuRef }>
        <div>
          <p className = 'flex justify-end'><CloseIcon onClick = { toggleMenu } style = { {fontSize: '25px'} } /></p>
          <div>
            <div className = 'flex p-b cursor-pointer' onClick = { () => setBrandOpen(!isBrandOpen) }>
              <span className = 'text-blacklight font-bold'>{ isBrandOpen ? <ExpandLessIcon /> : <KeyboardArrowDownIcon /> }</span>
              <p className = { `text-blacklight text-sm font-bold uppercase mt-1 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>{ t('BRAND') }</p>
            </div>
            { isBrandOpen && (
              <div>
                <ul className = ' text-left mt-3 brandscroll custom-scroll'>
                  { brands?.map(brand => (
                    <li className = 'mt-1' key = { brand.id }>
                      <div className = 'flex justify-between'>
                        <div>
                          <input
                            type = 'checkbox'
                            id = { `brand-${brand.id}` }
                            value = { brand.name }
                            onChange = { handleBrandChange }
                            onFocus = { handleBlur }
                          />{ ' ' }
                          <label htmlFor = { `brand-${brand.id}` } className = 'text-xs text-blacklight uppercase inline-block ml-1'>{ brand?.name }</label>
                        </div>
                        <div>
                          <span className = 'text-sm text-blacklight uppercase'>({ brand?.count })</span>
                        </div>
                      </div>
                    </li>
                  )) }
                </ul>
              </div>
            ) }
          </div>
          <div className = 'w-full h-px bg-grey-500 my-4'></div>
          <div className = 'flex cursor-pointer' onClick = { () => setCatlogOpen(!isCatlogOpen) }>
            <span className = 'text-blacklight font-bold'>{ isCatlogOpen ? <ExpandLessIcon /> : <KeyboardArrowDownIcon /> }</span>
            <p className = { `text-blacklight text-sm font-bold uppercase mt-1 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>{ t('CATEGORIES') }</p>
          </div>
          { isCatlogOpen && (
            <div>
              <ul className = 'text-left mt-3 brandscroll custom-scroll'>
                { datacategory?.data?.map((item) => (
                  <li className = 'mt-1' key = { item?.id }>
                    <div className = 'flex justify-between'>
                      <div>
                        <input
                          type = 'checkbox'
                          id = { `category-${item?.id}` }
                          value = { item?.name_en }
                          onChange = { handleCategoryChange }
                          onFocus = { handleBlur }
                        />{ ' ' }
                        <label htmlFor = { `category-${item?.id}` } className = ' cursor-pointer text-xs text-blacklight uppercase inline-block ml-1 font-madani-arabic'>{ selectLanguage == 'ar'?item?.name_ar: item?.name_en }</label>
                      </div>
                      <div>
                        <span className = 'text-sm text-blacklight uppercase'>({ item.count })</span>
                      </div>
                    </div>
                  </li>
                )) }
              </ul>
            </div>
          ) }
          <div className = 'w-full h-px bg-grey-500 my-4'></div>
          <div className = 'flex pb-2 cursor-pointer' onClick = { () => setPriceOpen(!isPriceOpen) }>
            <span className = 'text-blacklight font-bold'>{ isPriceOpen ? <ExpandLessIcon /> : <KeyboardArrowDownIcon /> }</span>
            <p className = { `text-blacklight text-sm font-bold uppercase mt-1 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>{ t('PRICE') }</p>
          </div>
          { isPriceOpen && (
            <div>
              <div className = 'mt-5'>
                <DoubleRangeSlider
                  min = { 0 }
                  max = { 1000 }
                  onChange = { () => ({ min, max }) => { min, max; } }
                />
              </div>
            </div>
          ) }
        </div>
      </div>
    </>
  );
};

export default Filtter;
