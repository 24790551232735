/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import ProductsTabs from '../../components/ProductsTabs';
import TopHeader from '../../components/TopHeader';
import GetroomCollection from './GetroomCollection';
import Rabelbestseller from './Rabelbestseller';

const ConfessionsRebel = () => {
    
  return(
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = "container mx-auto px-5 lg:px-10">
        <div>
          <img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvaGVyb19kZXNrdG9wX2JiNzQ2MDVlMWEuanBn' alt = 'banner' />
        </div>
        <ProductsTabs />
        <div className = "w-full h-fit py-28 bg-cover bg-center mt-20" style = { {backgroundImage: 'url(\'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvbWlzc2lvbl9kZXNrdG9wXzExYjdmNWQwY2UuanBn\')'} }>
          <div className = 'flex'>
            <div className = 'w-3/6'></div>
            <div className = 'w-3/6 relative'>
              <div className = 'bg-[#141414] text-white py-14 px-14 '>
                <span className = 'text-lg lg:text-xl font-semibold uppercase'>WE STARTED A SCENT REVOLUTION</span>
                <p className = 'text-sm lg:text-base  mt-4'>
                We were born in 2018, holding fast to the idea that the fragrance industry was in dire need of a change. When we looked around, all we saw was a sea of sameness: outdated archetypes of femininity and masculinity paired with unrelatable images of luxury that we had no say in.
                </p>
                <p className = 'text-sm lg:text-base mt-5'>
                In truth, we are complex in ways that cannot be contained in the traditional heteronormative stereotypes, existing beyond the binary with raw, unfiltered voices that deserve to be heard.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className = 'mt-20'>
          <Rabelbestseller />
        </div>
        <div className = 'mt-12'>
          <img src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvaGVyb19kZXNrdG9wX2Q4ODAyZDE2NzcuanBn' alt = 'bannnerimg' />
          <div className = 'mt-16'>
            <GetroomCollection />
          </div>
        </div>
        <div className = 'mt-20'>
          <img src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvaGVyb19kZXNrdG9wX2IzZjc1ZTFkNmYuanBn' alt = 'bannnerimg' />
          <div className = 'mt-16'>
            <GetroomCollection />
          </div>
        </div>
        <div className = 'mt-20'>
          <img src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvaGVyb19kZXNrdG9wX2Y2YzJlNDcyODguanBn' alt = 'bannnerimg' />
          <div className = 'mt-16'>
            <GetroomCollection />
          </div>
        </div>
        <div className = 'mt-20'>
          <img className = 'mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:750/aGVhZGxlc3MvcXVvdGVfZGVza3RvcF8zNWM5ZWNlNDhkLmpwZw==' alt = 'banner' />
          <div className = 'w-8/12 mx-auto'>
            <div className = 'w-full h-px bg-primary mt-8'></div>
            <div className = 'text-center py-5'>
              <h4 className = 'text-base text-primary font-semibold'>A MODERN APPROACH TO FRAGRANCE</h4>
              <h3 className = 'text-2xl font-semibold mt-5'>“Confessions of a Rebel is an invitation to be unabashedly ourselves,
without mincing our words, filtering our message, or second-
guessing what needs to be said. It’s a call to speak and act on behalf
of that authentic voice lying dormant inside us, waiting to be
amplified and heard. It’s a realization that we all deserve to feel
celebrated, recognized, and understood. It’s a trailblazer in the
world of scents.”</h3>
              <h4 className = 'text-lg text-primary font-semibold mt-5'>Madame Isabelle d’Ornano</h4>
              <p className = 'text-base text-blacklight mt-2'>Sisley Co-Founder</p>
            </div>
            <div className = 'w-full h-px bg-primary'></div>
          </div>
          <img className = 'mt-20' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvZm9vdGVyX2Rlc2t0b3BfZTkyMDkzMGQ0NC5qcGc=' alt = 'banner' />
        </div>
        <Footer />
      </div>
      
    </>
  );
};

export default ConfessionsRebel;