/* eslint-disable react/prop-types */
import EditIcon from '@mui/icons-material/Edit';
import StarPurple500OutlinedIcon from '@mui/icons-material/StarPurple500Outlined';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StarRatings from 'react-star-ratings';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import userprofile from '../images/userprofile.svg';
import { useAskquestionMutation, useReviewlistMutation, useWritereviewMutation } from '../redux/api/productsApi';

const ProductReviews = ({dataproductid}) => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const [activeTab, setActiveTab] = useState('reviews');
  const [writeReviewbox, setWriteReviewbox] = useState(false);
  const [rating, setRating] = useState(0);
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [questionName, setQuestionName] = useState('');
  const [questionEmail, setQuestionEmail] = useState('');
  const [questionText, setQuestionText] = useState('');
  const [errors, setErrors] = useState({});
  const [questionErrors, setQuestionErrors] = useState({});
  const [selectreview, setSelectreview] = useState('all');


  const [writereview, { error, isLoading, data, isSuccess }] = useWritereviewMutation();
  const [askquestion, { error: erroraskquestion, data: dataaskquestion, isLoading: isloadingaskquestion, isSuccess: isSuccessaskquestion }] = useAskquestionMutation();


  const handleTab = (tab) => {
    setActiveTab(tab);
  };

  const handleReviewBox = () => {
    setWriteReviewbox(!writeReviewbox);
  };

  const validate = () => {
    const newErrors = {};
    if (rating === 0) newErrors.rating = 'Rating is required';
    if (!title) newErrors.title = 'Title is required';
    if (!name) newErrors.name = 'Name is required';
    if (!email) newErrors.email = 'Email is required';
    if (!comment) newErrors.comment = 'Comment is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e, setField) => {
    const { value } = e.target;
    setField(value);
    setErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: '' }));
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    try {
      await writereview({
        product_id: dataproductid,
        comment,
        score: rating,
        title,
        name,
        email
      });
      
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setTitle('');
      setName('');
      setEmail('');
      setComment('');
      setRating(0);
      setErrors({});
      setWriteReviewbox(false);
      toast(data?.message);
    }
  },[isSuccess,data]);

  const validateQuestion = () => {
    const newErrors = {};
    if (!questionName) newErrors.name = 'Name is required';
    if (!questionEmail) newErrors.email = 'Email is required';
    if (!questionText) newErrors.question = 'Question is required';
    setQuestionErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleQuestionChange = (e, setField) => {
    const { value } = e.target;
    setField(value);
    setQuestionErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: '' }));
  };

  const handleSubmitQuestion = async () => {
    if (!validateQuestion()) return;
    try {
      await askquestion({
        name: questionName,
        email: questionEmail,
        question: questionText
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isSuccessaskquestion) {
      setQuestionName('');
      setQuestionEmail('');
      setQuestionText('');
      setQuestionErrors({});
      toast(dataaskquestion?.message);
    }
  }, [isSuccessaskquestion, dataaskquestion]);


  const [reviewlist,{error: reviewlisterror, data: reviewlistdata, isLoading: reviewlistisloading}] = useReviewlistMutation();

  useEffect(() => {
  
    if(selectreview){

      const reviewdata = {
        'product_id': dataproductid,
        'rating': selectreview
      };

      reviewlist(reviewdata);
    }

  },[selectreview]);

  const Datefun = (apiDate) => {
    const date = new Date(apiDate);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const formattedDate = `${day} ${month} ${year}`;
  
    return <div>{ formattedDate }</div>;
  };

  return (
    <>
      <ToastContainer />
      <div className = 'text-center md:text-left py-3 md:py-0 mb-8'>
        <div className = 'inline-block border py-2 px-4 cursor-pointer' onClick = { handleReviewBox }>
          <span><EditIcon /></span>
          <span className = { `inline-block ml-2 text-sm md:text-base ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>{ t('WriteAReview') }</span>
        </div>
      </div>
      { writeReviewbox && (
        <div className = 'w-full md:w-3/5 pb-5'>
          <h3 className = { `text-black text-lg lg:text-xl text-bold mt-5 lg:mt-0 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>{ t('WriteAReview') }</h3>
          <ul className = 'mt-5 lg:mt-8 list-none'>
            <li>
              <label>Score</label>
              <p>
                <StarRatings
                  rating = { rating }
                  starRatedColor = "#ffb829"
                  numberOfStars = { 5 }
                  name = 'rating'
                  starDimension = '22px'
                  starSpacing = '1px'
                  changeRating = { (e) => {
                    setRating(e);
                    setErrors((prevErrors) => ({ ...prevErrors, rating: '' }));
                  } }
                />
                { errors.rating && <p className = 'text-red-500 text-sm'>{ errors.rating }</p> }
              </p>
            </li>
            <li className = 'mt-5'>
              <input
                placeholder = 'Write Title'
                className = 'border border-primary block w-full py-1.5 px-4'
                name = 'title'
                value = { title }
                onChange = { (e) => handleInputChange(e, setTitle) }
              />
              { errors.title && <p className = 'text-red-500 text-sm'>{ errors.title }</p> }
            </li>
            <li className = 'mt-5'>
              <input
                placeholder = 'Use your name'
                className = 'border border-primary block w-full py-1.5 px-4'
                name = 'name'
                value = { name }
                onChange = { (e) => handleInputChange(e, setName) }
              />
              { errors.name && <p className = 'text-red-500 text-sm'>{ errors.name }</p> }
            </li>
            <li className = 'mt-5'>
              <input
                placeholder = 'Email'
                className = 'border border-primary block w-full py-1.5 px-4'
                name = 'email'
                value = { email }
                onChange = { (e) => handleInputChange(e, setEmail) }
              />
              { errors.email && <p className = 'text-red-500 text-sm'>{ errors.email }</p> }
            </li>
            <li className = 'mt-5'>
              <textarea
                placeholder = 'Write Review'
                className = 'border border-primary block w-full py-1.5 px-4 rounded-none'
                name = 'comment'
                value = { comment }
                onChange = { (e) => handleInputChange(e, setComment) }
              />
              { errors.comment && <p className = 'text-red-500 text-sm'>{ errors.comment }</p> }
            </li>
            <li className = 'text-center mt-6 mb-6'>
              <button
                className = 'inline-block bg-primary py-2 px-8 text-white text-sm lg:text-base rounded'
                onClick = { handleSubmit }
                disabled = { isLoading }
              >
                { isLoading ? 'Posting...' : 'POST' }
              </button>
              { error && <p className = 'text-red-500 text-sm'>{ error?.data?.message }</p> }
            </li>
          </ul>
        </div>
      ) }

      <div className = 'flex mb-5 mt-5 md:mt-0'>
        <button
          className = { `inline-block border py-2.5 px-5 text-black text-sm ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''} ${activeTab === 'reviews' ? 'bg-primary text-white' : ''}` }
          onClick = { () => { handleTab('reviews'); } }
        >
          { t('REVIEWS') }
        </button>
        <button
          className = { `inline-block border py-2.5 px-5 text-black text-sm ml-3 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''} ${activeTab === 'questions' ? 'bg-primary text-white' : ''}` }
          onClick = { () => { handleTab('questions'); } }
        >
          { t('QUESTIONS') }
        </button>
      </div>
      <div>
        { activeTab === 'reviews' && (
          <div>
            <h3 className = { `text-lg text-black font-bold mb-4 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>{ t('FilterReviews') }</h3>
            <div className = 'block md:flex mb-8'>
              <div>
                <select onChange = { (e) => setSelectreview(e.target.value) } className = { `block w-full md:w-60 border border-primary py-1.5 px-4 font-sm ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>
                  <option disabled>Rating</option>
                  <option value = "all">{ t('All') }</option>
                  <option value = "5">5 { t('Star') }</option>
                  <option value = "4">4 { t('Star') }</option>
                  <option value = "3">3 { t('Star') }</option>
                  <option value = "2">2 { t('Star') }</option>
                  <option value = "1">1 { t('Star') }</option>
                </select>
              </div>
            </div>
            <ul className = 'list-none'>
              {
                reviewlisterror && <p className = 'text-sm text-red'>{ reviewlisterror?.data?.message }</p>
              }
              {
                reviewlistisloading && <p className = 'text-sm'>{ reviewlistisloading?'Loading...': '' }</p>
              }
              {
                reviewlistdata?.data.length === 0 && <p className = 'text-sm'>No reviews</p>
              }

              {
                reviewlistdata?.data.length > 0 && reviewlistdata?.data.map((item) => {
                  return(
                    <li key = { item?.product_id } className = 'pb-2 border-b border-primary'>
                      <div className = 'flex justify-between'>
                        <div className = 'flex'>
                          <div className = 'w-11 h-11 mr-4'>
                            <div className = 'w-11 h-11 rounded-full flex items-center justify-center bg-primary text-white'>
                              <img src = { userprofile } alt = 'profile' />
                            </div>
                          </div>
                          <div>
                            <p className = 'text-primary'>{ item?.user_name }</p>
                            <p className = 'text-sm text-primary'> { item?.rating } { t('Star') }
                              { Array.from({ length: item?.rating }).map((_, index) => (
                                <StarPurple500OutlinedIcon key = { index } style = { { fontSize: '16px' } } />
                              )) }
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className = 'text-sm text-right'>{ Datefun(item?.date) }</p>
                        </div>
                      </div>
                      <div className = 'mt-2'>
                        <p className = 'text-sm'>{ item?.review }</p>
                      </div>
                    </li>
                  );
                })
              }
              
            </ul>
          </div>
        ) }

        { activeTab === 'questions' && (
          <div className = 'w-full md:w-3/5 pb-5'>
            <h3 className = { `text-black text-lg lg:text-xl text-bold mt-5 lg:mt-0 ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }>{ t('Askaquestion') }</h3>
            <ul className = 'mt-5 lg:mt-8 list-none'>
              <li className = 'mt-5'>
                <input
                  placeholder = 'Use your name'
                  className = 'border border-primary block w-full py-1.5 px-4'
                  name = 'name'
                  value = { questionName }
                  onChange = { (e) => handleQuestionChange(e, setQuestionName) }
                />
                { questionErrors.name && <p className = 'text-red-500 text-sm'>{ questionErrors.name }</p> }
              </li>
              <li className = 'mt-5'>
                <input
                  placeholder = 'Email'
                  className = 'border border-primary block w-full py-1.5 px-4'
                  name = 'email'
                  value = { questionEmail }
                  onChange = { (e) => handleQuestionChange(e, setQuestionEmail) }
                />
                { questionErrors.email && <p className = 'text-red-500 text-sm'>{ questionErrors.email }</p> }
              </li>
              <li className = 'mt-5'>
                <textarea
                  placeholder = 'Write Question'
                  className = 'border border-primary block w-full py-1.5 px-4 rounded-none'
                  name = 'question'
                  value = { questionText }
                  onChange = { (e) => handleQuestionChange(e, setQuestionText) }
                />
                { questionErrors.question && <p className = 'text-red-500 text-sm'>{ questionErrors.question }</p> }
              </li>
              <li className = 'text-center mt-6 mb-6'>
                <button
                  className = 'inline-block bg-primary py-2 px-8 text-white text-sm lg:text-base rounded'
                  onClick = { handleSubmitQuestion }
                  disabled = { isloadingaskquestion }
                >
                  { isloadingaskquestion ? 'Submitting...' : 'SUBMIT' }
                </button>
                { erroraskquestion && <p className = 'text-red-500 text-sm'>{ erroraskquestion?.data?.message }</p> }
              </li>
            </ul>
          </div>
        ) }
      </div>
    </>
  );
};

export default ProductReviews;
