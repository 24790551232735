import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import TopHeader from '../components/TopHeader';

const OrderTracking = () => {

  return(
    <>
      <div className = 'min-h-screen'>
        <TopHeader />
        <Header />
        <Navigation />
        <div className = 'bg-black h-36 flex items-center justify-center mb-8'>
          <h3 className = 'text-white text-2xl text-center font-semibold uppercase'>ORDER TRACKING</h3>
        </div>
        <div className = 'container mx-auto px-5 lg:px-10'>
          <h3 className = 'text-black text-sm md:text-base text-center mt-10'>To track your order please enter your Order ID in the box below and press the { ' "Track"' } button. This was given to you on your receipt and in the confirmation email you should have received.</h3>
          <div className = 'flex flex-wrap md:flex-nowrap justify-evenly mt-8'>
            <div className = 'w-full md:w-2/5'>
              <label className = 'block text-sm md:text-base text-black pb-1'>Order ID</label>
              <input className = 'border border-borderColor inline-block py-2 px-4 w-full' placeholder = 'Enter order id' />
            </div>
            <div className = 'w-full md:w-2/5 mt-5 md:mt-0'>
              <label className = 'block text-sm md:text-base text-black pb-1'>Billing email</label>
              <input className = 'border border-borderColor inline-block py-2 px-4 w-full' placeholder = 'Enter email' />
            </div>
            <div className = 'relative top-6'><button className = 'inline-block text-sm md:text-base text-white bg-primary py-2 px-8'>TRACK</button></div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default OrderTracking;