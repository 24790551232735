
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import { useForgotPasswordMutation } from '../redux/api/userApi';
import { showToast } from '../redux/features/toastSlice';

const LostPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectLanguage = localStorage.getItem('selectLanguage');

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [forgotPassword, {isLoading, error,isSuccess,data}] = useForgotPasswordMutation();

  useEffect(() => {

    if(error){
      dispatch(showToast(error?.data?.message));
      dispatch(showToast(error?.data?.error));
    }

    if(isSuccess) {
      dispatch(showToast(data?.message));
      setTimeout(() => {
        navigate('/password/reset-form');
      },2000);

      localStorage.setItem('emailforgetpassword',email);
    }
    
   
  },[error,isSuccess]);

  const SubmitHandler = (e) => {
    e.preventDefault();

    const forgotData = {
      email
    };
    forgotPassword(forgotData);
  };

  const isFormValid = email.length > 0;


  return(
    <>
      <div className = 'min-h-screen'>
        <Header />
        <Navigation />
        <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
          <h3 className = { `text-white text-xl lg:text-2xl text-center font-semibold uppercase ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('MyAccount') }</h3>
        </div>
        <div className = 'container mx-auto px-5 lg:px-0'>
          <div className = 'w-full md:w-2/5 mx-auto'>
            <p className = { `text-base text-grey ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Pleaseenteremailaddressyoureceive') }</p>
            <form onSubmit = { SubmitHandler }>
              <lebel className = { `text-sm text-grey-500 lg:text-grey-800 inline-block mt-5 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Email') }</lebel>
              <input value = { email } onChange = { (e) => setEmail(e.target.value) }  className = { `block w-full border border-grey rounded-md px-4 py-2 text-sm outline-primary-600 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } placeholder = { t('Enteremail') } />
              <button className = { `block w-full text-white rounded-3xl lg:rounded-md px-4 py-2.5 text-sm ${selectLanguage == 'ar'?'font-madani-arabic': ''} ${!isFormValid?'bg-gradient-primary-light':'bg-gradient-primary'} mt-5` } disabled = { !isFormValid }>

                {
                  isLoading? 'Sending...': t('RESETPASSWORD')
                }
              </button>
            </form>
          </div>
          <Footer />
        </div>
      </div>
      
    </>
  );

};

export default LostPassword;