import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import BestSellerCollection from './collections/BestSellerCollection';
import ContactLenses from './collections/ContactLenses';
import EyeWear from './collections/EyeWear';
import NewProductsCollection from './collections/NewProductsCollection';
import PopularCollection from './collections/PopularCollection';
import TrendingCollection from './collections/TrendingCollection';
import Brandshop from './components/Brandshop';
import Customtoast from './components/Customtoast';
import Guestcheckoutotpverify from './components/Guestcheckoutotpverify';
import Privateroute from './components/PrivateRoute';
import PrivateRouteLogin from './components/PrivateRouteLogin';
import Shop from './components/Shop';
import ShopSeacrhpage from './components/ShopSeacrhpage';
import Allbrand from './pages/Allbrand';
import Blog from './pages/Blog';
import BlogDetails from './pages/BlogDetails';
import Contactqueary from './pages/Contactqueary';
import Cookies from './pages/Cookies';
import EditAccount from './pages/EditAccount';
import Home from './pages/Home';
import Login from './pages/Login';
import LostPassword from './pages/LostPassword';
import LoyaltyRewards from './pages/LoyaltyRewards';
import MyAccountDashboard from './pages/MyAccountDashboard';
import Order from './pages/Order';
import OrderDetails from './pages/OrderDetails';
import OrderTracking from './pages/OrderTracking';
import PasswordResetForm from './pages/PasswordResetForm';
import PasswordResetLink from './pages/PasswordResetLink';
import PersonalData from './pages/PersonalData';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ReferFriend from './pages/ReferFriend';
import RefundPolicy from './pages/RefundPolicy';
import ShippingPolicy from './pages/ShippingPolicy';
import ShopingCard from './pages/ShopingCard';
import SignUp from './pages/SignUp';
import StoreLocator from './pages/StoreLocator';
import TermsConditions from './pages/TermsConditions';
import VerifyOtp from './pages/VerifyOtp';
import WishList from './pages/WishList';
import Address from './pages/address/Address';
import CheckOut from './pages/checkout/CheckOut';
import Checkoutlogin from './pages/checkout/Checkoutlogin';
import Shippingdetails from './pages/checkout/Shippingdetails';
import FeaturesBrand from './pages/discover/FeaturesBrand';
import Horoscope from './pages/discover/Horoscope';
import Notes from './pages/discover/Notes';
import SubscriptionPerfumeMonth from './pages/discover/SubscriptionPerfumeMonth';
import FragranceFamilies from './pages/fragrancefamilies/FragranceFamilies';
import ProductDetails from './pages/products/ProductDetails';
import BrandDrift from './pages/shopgifts/BrandDrift';
import BrandSisleyParis from './pages/shopgifts/BrandSisleyParis';
import CandlesndhomeScents from './pages/shopgifts/CandlesndhomeScents';
import ConfessionsRebel from './pages/shopgifts/ConfessionsRebel';
import GiftCard from './pages/shopgifts/GiftCard';
import GiftSubscription from './pages/shopgifts/GiftSubscription';
import Gifts from './pages/shopgifts/Gifts';
import GiftshopPerfumes from './pages/shopgifts/GiftshopPerfumes';
import MainSubscription from './pages/shopgifts/MainSubscription';
import Mothersdayedit from './pages/shopgifts/Mothersdayedit';
import ScentbirdSelect from './pages/shopgifts/ScentbirdSelect';
import PaymentFailed from './paymentservices/PaymentFailed';
import PaymentForm from './paymentservices/PaymentForm';
import PaymentStatus from './paymentservices/PaymentStatus';

function App() {
  const showToastmessage = useSelector((state) => state.toast?.message);
  return (
    <div>
      { showToastmessage && <Customtoast /> }
      <BrowserRouter>
        <Routes>
          <Route path = '/' element = { <Home /> } />
          <Route path = '/payment' element = { <PaymentForm /> } />
          <Route path = '/payment-status' element = { <PaymentStatus /> } />
          <Route path = '/payment-failed' element = { <PaymentFailed /> } />
          <Route path = '/brand/shop/:brandcat' element = { <Brandshop /> } />
          <Route path = '/order-details/:orderid' element = { <OrderDetails /> } />
          <Route path = '/shop/:category' element = { <Shop /> } />
          <Route path = '/product/:id' element = { <ProductDetails /> } />
          <Route path = '/search' element = { <ShopSeacrhpage /> } />
          <Route path = '/all-brand' element = { <Allbrand /> } />
          <Route path = '/shipping-details' element = { <Shippingdetails /> } />
          <Route path = '/checkout' element = { <CheckOut /> } />
          <Route path = '/checkout-login' element = { <Checkoutlogin /> } />
          <Route path = '/signup' element = { <SignUp /> } />
          <Route path = '/shoping-cart' element = { <ShopingCard /> } />
          <Route path = '/wishlist' element = { <WishList /> } />
          <Route path = '/privacy-policy' element = { <PrivacyPolicy /> } />
          <Route path = '/terms-and-conditions' element = { <TermsConditions /> } />
          <Route path = '/cookies' element = { <Cookies /> } />
          <Route path = '/refund-returns' element = { <RefundPolicy /> } />
          <Route path = '/shipping-policy' element = { <ShippingPolicy /> } />
          <Route path = '/order/tracking' element = { <OrderTracking /> } />
          <Route path = '/password/reset-link' element = { <PasswordResetLink /> } />
          <Route path = '/password/reset-form' element = { <PasswordResetForm /> } />
          <Route path = '/home/shop/eyewear' element = { <EyeWear /> } />
          <Route path = '/home/shop/eyewear/contact-lenses' element = { <ContactLenses /> } />
          <Route path = '/blog' element = { <Blog /> } />
          <Route path = '/blog/:id' element = { <BlogDetails /> } />
          <Route path = '/store-locator' element = { <StoreLocator /> } />
          <Route path = '/loyalty-rewards' element = { <LoyaltyRewards /> } />
          <Route path = '/refer-a-friend' element = { <ReferFriend /> } />
          <Route path = '/personal-data' element = { <PersonalData /> } />
          <Route path = '/home/shop/bestsellers' element = { <BestSellerCollection /> } />
          <Route path = '/home/shop/trending-items' element = { <TrendingCollection /> } />
          <Route path = '/home/shop/popular-items' element = { <PopularCollection /> } />
          <Route path = '/home/shop/new-product-items' element = { <NewProductsCollection /> } />
          <Route path = '/gifts' element = { <Gifts /> } />
          <Route path = '/gift-subscription' element = { <GiftSubscription /> } />
          <Route path = '/main-subscription' element = { <MainSubscription /> } />
          <Route path = '/scentbird-select' element = { <ScentbirdSelect /> } />
          <Route path = '/gift-card' element = { <GiftCard /> } />
          <Route path = '/brand/sisley-paris' element = { <BrandSisleyParis /> } />
          <Route path = '/brand-drift' element = { <BrandDrift /> } />
          <Route path = '/confessions-rebel' element = { <ConfessionsRebel /> } />
          <Route path = '/shop-perfumes' element = { <GiftshopPerfumes /> } />
          <Route path = '/mothers-day-edit' element = { <Mothersdayedit /> } />
          <Route path = '/candles-homescents' element = { <CandlesndhomeScents /> } />
          <Route path = '/features-brand' element = { <FeaturesBrand /> } />
          <Route path = '/horoscope' element = { <Horoscope /> } />
          <Route path = '/subscription/perfume-of-the-month/all' element = { <SubscriptionPerfumeMonth /> } />
          <Route path = '/fragrance-families' element = { <FragranceFamilies /> } />
          <Route path = '/notes' element = { <Notes /> } />
          <Route path = '/guest-verify-otp' element = { <Guestcheckoutotpverify /> } />
          <Route path = '/contact-us' element = { <Contactqueary /> } />
          <Route path = '' element = { <Privateroute /> }>
            <Route path = '/my-account/edit-account' element = { <EditAccount /> } />
            <Route path = '/my-account' element = { <MyAccountDashboard /> } />
            <Route path = '/my-account/order' element = { <Order /> } />
            <Route path = '/my-account/address' element = { <Address /> } />
          </Route>
          <Route path = '' element = { <PrivateRouteLogin /> }>
            <Route path = '/login' element = { <Login /> } />
            <Route path = '/forgot-password' element = { <LostPassword /> } />
            <Route path = '/verify-otp' element = { <VerifyOtp /> } />
            <Route path = '/password/reset-form' element = { <PasswordResetForm /> } />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
