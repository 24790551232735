import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../config/index';

const getToken = () => localStorage.getItem('token');

export const tabbyApi = createApi({
  reducerPath: 'tabbyApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    tabbyCheckout: builder.mutation({
      query(body) {
        return {
          url: '/notifications/tabby-checkout/',
          method: 'POST',
          body
        };
      }
    })
  })
});

export const {useTabbyCheckoutMutation} = tabbyApi;
