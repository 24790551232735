/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const SelectCity = ({ selectedCityOption, handleChangeCity, cityoptions }) => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');
  return (
    <Select
      className = { `textsize ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }
      options = { cityoptions }
      value = { selectedCityOption }
      onChange = { handleChangeCity }
      placeholder = { t('SearchForTheCity') }
      isSearchable
    />
  );
};

export default SelectCity;
