import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../config/index';
import { logout } from '../features/authSlice';

const getToken = () => localStorage.getItem('token');

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  })(args, api, extraOptions);

  if (result.error) {
    const { status } = result.error;
    if (status === 403 || status === 401) {
      api.dispatch(logout());
    }
  }

  return result;
};

export const productsApi = createApi({
  reducerPath: 'productApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['displaywishlist'],
  endpoints: (builder) => ({
    getProducts: builder.mutation({
      query(body) {
        return {
          url: '/store/product/all/',
          method: 'POST',
          body
        };
      }
    }),
    addtocart: builder.mutation({
      query(body) {
        return {
          url: '/shopping-bag/cart/add',
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['addtocardlist']
    }),
    deletecartitem: builder.mutation({
      query(body) {
        return {
          url: '/shopping-bag/cart/delete',
          method: 'DELETE',
          body
        };
      },
      invalidatesTags: ['addtocardlist']
    }),
    getProductsDetails: builder.query({
      query: (id) => `/store/product/${id}`
    }),
    addtocardlist: builder.query({
      query: () => '/shopping-bag/cart/display',
      providesTags: ['addtocardlist']
    }),
    addtowishlist: builder.mutation({
      query(body) {
        return {
          url: '/shopping-bag/wishlist/add',
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['displaywishlist']
    }),
    deletewishlist: builder.mutation({
      query(body) {
        return {
          url: '/shopping-bag/wishlist/delete',
          method: 'DELETE',
          body
        };
      },
      invalidatesTags: ['displaywishlist']
    }),
    clearallwishlist: builder.mutation({
      query(body) {
        return {
          url: '/shopping-bag/clear/wishlist',
          method: 'DELETE',
          body
        };
      },
      invalidatesTags: ['displaywishlist']
    }),
    displayaddtowishlist: builder.query({
      query: () => '/shopping-bag/wishlist/display',
      providesTags: ['displaywishlist']
    }),
    searchProducts: builder.query({
      query: (params) => ({
        url: '/store/products/search',
        params: {
          query: params?.query
        }
      })
    }),
    writereview: builder.mutation({
      query(body) {
        return {
          url: '/reviews/post/',
          method: 'POST',
          body
        };
      }
    }),
    askquestion: builder.mutation({
      query(body) {
        return {
          url: '/users/ask-question/',
          method: 'POST',
          body
        };
      }
    }),
    reviewlist: builder.mutation({
      query(body) {
        return {
          url: '/reviews/all/',
          method: 'POST',
          body
        };
      }
    }),
    getorderDetails: builder.query({
      query: ({ order_id, timezone }) => 
        `/orders/details?order_id=${order_id}&timezone=${timezone}`
    }),
    clearcart: builder.mutation({
      query(body) {
        return {
          url: '/shopping-bag/clear/cart',
          method: 'DELETE',
          body
        };
      }
    })
  })
});

export const {
  useGetProductsMutation,
  useGetProductsDetailsQuery,
  useAddtocartMutation,
  useSearchProductsQuery,
  useAddtocardlistQuery,
  useAddtowishlistMutation,
  useDisplayaddtowishlistQuery,
  useDeletecartitemMutation,
  useDeletewishlistMutation,
  useClearallwishlistMutation,
  useWritereviewMutation,
  useAskquestionMutation,
  useGetorderDetailsQuery,
  useReviewlistMutation,
  useClearcartMutation
} = productsApi;
