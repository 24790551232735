/* eslint-disable react/prop-types */
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import { useTranslation } from 'react-i18next';
import ProductAditionalinformation from './ProductAditionalinformation';
import ProductReviews from './ProductReviews';
import ProductTabDescription from './ProductTabDescription';
import ShipingDelivery from './ShipingDelivery';

const ProductTabforDescription = ({data, productid}) => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const [activeTab, setActiveTab] = useState('description');
  const [expandedIndex, setExpandedIndex] = useState(false);

  const handleToggle = () => {
    setExpandedIndex(!expandedIndex);
  };

  const HandleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return(
    <>
      <div className = 'hidden md:block'>
        <ul className = { `flex flex-wrap mt-6 mb-4 md:mb-4 gap-0 ${selectLanguage == 'ar'?'flex-row-reverse': 'flex-row'}` }>
          <li className = 'w-width25% '><button onClick = { () => HandleTabClick('description') } className = { `w-full text-xs bg-grey-50 py-1 px-3 text-black ${selectLanguage == 'ar'?'font-madani-arabic': ''} ${activeTab === 'description'? 'bg-primary text-white': ''}` }>{ t('DESCRIPTION') }</button></li>
          <li className = 'w-width25%'><button onClick = { () => HandleTabClick('additionalinformation') } className = { `w-full text-xs py-1 bg-grey-50 px-3 text-black ${selectLanguage == 'ar'?'font-madani-arabic': ''} ${activeTab === 'additionalinformation'? 'bg-primary text-white': ''}` }>{ t('INFORMATION') }</button></li>
          <li className = 'w-width25%'><button onClick = { () => HandleTabClick('reviews') } className = { `w-full text-xs uppercase bg-grey-50 py-1 px-3 text-black ${selectLanguage == 'ar'?'font-madani-arabic': ''} ${activeTab === 'reviews'? 'bg-primary text-white': ''}` }>{ t('Reviews') }</button></li>
          <li className = 'w-width25%'><button onClick = { () => HandleTabClick('shipingdelivery') } className = { `w-full text-xs bg-grey-50 py-1 px-3 text-black ${selectLanguage == 'ar'?'font-madani-arabic': ''} ${activeTab === 'shipingdelivery'? 'bg-primary text-white': ''}` }>{ t('SHIPPING') }</button></li>
        </ul>
        <div>
          { activeTab === 'description' && <ProductTabDescription data = { data } /> }
          { activeTab === 'additionalinformation' && <ProductAditionalinformation data = { data }  /> }
          { activeTab === 'reviews' && <ProductReviews dataproductid = { productid } /> }
          { activeTab === 'shipingdelivery' && <ShipingDelivery /> }
        </div>
      </div>
      <ul className = 'block md:hidden mt-5'>
        
        <li className = 'py-4 border-b border-borderColor'>
          <Collapsible
            trigger = {
              <div className = 'flex justify-between'>
                <h3 className = { `self-center text-sm ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('DESCRIPTION') }</h3>
                <span className = "text-grey self-center block w-6 cursor-pointer text-center" onClick = { () => handleToggle(3) }>
                  { expandedIndex === 0 ? (
                    <ExpandLessIcon style = { { fontSize: '24px' } } />
                  ) : (
                    <KeyboardArrowDownIcon style = { { fontSize: '24px' } } />
                  ) }
                </span>
              </div>
            }
            open = { expandedIndex === 3 }
            onOpening = { () => setExpandedIndex(true) }
            onClosing = { () => setExpandedIndex(false) }
          >
            <div className = 'mt-5'>
              <ProductTabDescription data = { data } />
            </div>
          </Collapsible>
        </li>
        <li className = 'py-4 border-b border-borderColor'>
          <Collapsible
            trigger = {
              <div className = 'flex justify-between'>
                <h3 className = { `self-center text-sm ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('ADDITIONALINFORMATION') }</h3>
                <span className = "text-grey self-center block w-6 cursor-pointer text-center" onClick = { () => handleToggle(3) }>
                  { expandedIndex === 0 ? (
                    <ExpandLessIcon style = { { fontSize: '24px' } } />
                  ) : (
                    <KeyboardArrowDownIcon style = { { fontSize: '24px' } } />
                  ) }
                </span>
              </div>
            }
            open = { expandedIndex === 3 }
            onOpening = { () => setExpandedIndex(true) }
            onClosing = { () => setExpandedIndex(false) }
          >
            <div>
              <ProductAditionalinformation data = { data } />
            </div>
          </Collapsible>
        </li>
        <li className = 'py-4 border-b border-borderColor'>
          <Collapsible
            trigger = {
              <div className = 'flex justify-between'>
                <h3 className = { `self-center text-sm uppercase ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Reviews') }</h3>
                <span className = "text-grey self-center block w-6 cursor-pointer text-center" onClick = { () => handleToggle(3) }>
                  { expandedIndex === 0 ? (
                    <ExpandLessIcon style = { { fontSize: '24px' } } />
                  ) : (
                    <KeyboardArrowDownIcon style = { { fontSize: '24px' } } />
                  ) }
                </span>
              </div>
            }
            open = { expandedIndex === 3 }
            onOpening = { () => setExpandedIndex(true) }
            onClosing = { () => setExpandedIndex(false) }
          >
            <div className = 'mt-5'>
              <ProductReviews />
            </div>
          </Collapsible>
        </li>
        <li className = 'py-4 border-b border-borderColor'>
          <Collapsible
            trigger = {
              <div className = 'flex justify-between'>
                <h3 className = { `self-center text-sm ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('SHIPPING&DELIVERY') }</h3>
                <span className = "text-grey self-center block w-6 cursor-pointer text-center" onClick = { () => handleToggle(3) }>
                  { expandedIndex === 0 ? (
                    <ExpandLessIcon style = { { fontSize: '24px' } } />
                  ) : (
                    <KeyboardArrowDownIcon style = { { fontSize: '24px' } } />
                  ) }
                </span>
              </div>
            }
            open = { expandedIndex === 3 }
            onOpening = { () => setExpandedIndex(true) }
            onClosing = { () => setExpandedIndex(false) }
          >
            <div className = 'mt-5'>
              <ShipingDelivery />
            </div>
          </Collapsible>
        </li>
      </ul>
    </>
  );
};

export default ProductTabforDescription;