import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';

const RefundPolicy = () => {

  return(
    <>
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = 'text-white text-xl lg:text-2xl text-center font-semibold uppercase'>Refund and Returns Policy</h3>
      </div>
      <div className = 'container mx-auto px-5 lg:px-10'>
        <ul>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-10'>Returns:</h3>
            <p className = 'text-sm lg:text-base text-grey mt-8'>Our policy lasts Until accepted upon delvery day. unfortunately we can offer you a exchange but NOT a refund.</p>
            <p className = 'text-sm lg:text-base text-grey mt-6'>To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.</p>
            <p className = 'text-sm lg:text-base text-grey mt-8'>Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund. If you are approved, then your refund will be processed either in cash repayment or gift voucher within a certain amount of days. If Payment was made through PAYPAL you wll have to create a FREE RETURN SHIPPING LABEL.</p>
            <h5 className = 'text-base text-grey font-bold mt-8'>Exclusive sale items and special offers</h5>
            <p className = 'text-sm lg:text-base text-grey mt-8'>Only regular priced items may be refunded, unfortunately sale items cannot be refunded.</p>
          </li>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>Gifts:</h3>
            <p className = 'text-sm lg:text-base text-grey mt-10'>If the item was meant to be delivered as a gift when purchased and was shipped directly to you, you should ask for a gift credit note value of your exchange. Once the returned item is received, a gift certificate will be mailed to you.</p>
            <p className = 'text-sm lg:text-base text-grey mt-6'>If the item wasn’t marked as a gift when purchased, or the gift giver had the order shipped to themselves to give to you later, we will send a refund to the gift giver and he will find out about your return.</p>
          </li>
          <li>
            <h3 className = 'text-black text-lg lg:text-xl mt-8'>Shipping:</h3>
            <p className = 'text-sm lg:text-base text-grey mt-6'>To return your product, you should contact us via Phone – WhatsApp (81 08 08 44) email at : Info@abcd.com </p>
            <p className = 'text-sm lg:text-base text-grey mt-6'>You will – in certain cases – be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.</p>
            <p className = 'text-sm lg:text-base text-grey mt-6'>Depending on where you live, the time it may take for your exchanged product to reach you, may vary.</p>
            <p className = 'text-sm lg:text-base text-grey mt-6'>For any question(s) or suggestion(s) please feel free to Contact Us.</p>
          </li>
          
        </ul>
        <Footer />
      </div>
    </>
  );
};

export default RefundPolicy;