import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'authSlice',
  initialState: {
    // Your initial state here
    isLoggedIn: false
    // other auth-related state
  },
  reducers: {
    logout: state => {
      state.isLoggedIn = false;
      localStorage.removeItem('token');
      localStorage.removeItem('userid');
    }
  }
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;