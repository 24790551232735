/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';
import birthday from '../../images/birthday.png';


const Celebrate = () => {

  return(
    <>
      <div className = 'flex flex-wrap md:flex-nowrap justify-between pt-8 md:pt-16'>
        <div className = 'w-full md:w-width49% text-center self-center'>
          <h3 className = 'text-lg md:text-xl text-black font-semibold uppercase'>LET'S CELEBRATE 48</h3>
          <h4 className = 'text-sm md:text-base text-black font-semibold mt-2 uppercase'>Join us on august 17th in stores for a birthday celebraration</h4>
          <p className = 'text-xs md:text-sm text-blacklight mt-3'>Enjoy 2X B Cos Loyalty Points when make a purchase during the festivities</p>
          <Link to = "#" className = 'inline-block py-1.5 px-3 border border-borderColor text-xs mt-3 hover:border-black hover:duration-300'>RSVP NOW</Link>
        </div>
        <div className = 'w-full md:w-width49% mt-8 md:mt-0'>
          <img className = 'w-full' src = { birthday } alt = 'birthday' />
        </div>
      </div>
    </>
  );
};


export default Celebrate;