import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMaxVal, setMinVal } from '../redux/features/rangeSlice';

const DoubleRangeSlider = ({ min, max, onChange }) => {
  const dispatch = useDispatch();
  const minVal = useSelector((state) => state.range.minVal);
  const maxVal = useSelector((state) => state.range.maxVal);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal, onChange]);

  return (
    <>
      <input
        type = "range"
        min = { min }
        max = { max }
        value = { minVal }
        ref = { minValRef }
        onChange = { (event) => {
          const value = Math.min(+event.target.value, maxVal - 1);
          dispatch(setMinVal(value));
          event.target.value = value.toString();
        } }
        className = { classnames('thumb thumb--zindex-3', {
          'thumb--zindex-5': minVal > max - 100
        }) }
      />
      <input
        type = "range"
        min = { min }
        max = { max }
        value = { maxVal }
        ref = { maxValRef }
        onChange = { (event) => {
          const value = Math.max(+event.target.value, minVal + 1);
          dispatch(setMaxVal(value));
          event.target.value = value.toString();
        } }
        className = "thumb thumb--zindex-4"
      />

      <div className = "slider">
        <div className = "slider__track" />
        <div ref = { range } className = "slider__range" />
        <div className = "slider__left-value">SAR { minVal }</div>
        <div className = "slider__right-value">SAR { maxVal }</div>
      </div>
    </>

  );
};

DoubleRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DoubleRangeSlider;
