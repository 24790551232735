import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { usePopularitemQuery } from '../redux/api/recomendedproductApi';
import Loader from './Loader';
import NewProductsItem from './NewProductsItem';

const NewProducts = () => {
  const { error, data, isLoading } = usePopularitemQuery();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(5);
  const [customWidth, setCustomWidth] = useState('auto');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setCustomWidth('18.5%');
        setSlidesToShow(5);
      } else if (window.innerWidth >= 768) {
        setCustomWidth('32%');
        setSlidesToShow(3);
      } else {
        setCustomWidth('48%');
        setSlidesToShow(2);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const goToNextSlide = () => {
    if (data?.data?.products?.length > currentIndex + slidesToShow) {
      setCurrentIndex((prevIndex) =>
        Math.min(prevIndex + 1, data.data.products.length - slidesToShow)
      );
    }
  };

  const showLeftArrow = currentIndex > 0;
  const showRightArrow = data?.data?.products?.length > currentIndex + slidesToShow;

  return (
    <>
      <ToastContainer />
      { isLoading && <Loader /> }
      { error && <p className = "text-sm text-blacklight">{ error?.data?.message }</p> }
      { data?.data?.total === 0 && (
        <p className = "text-sm text-blacklight text-center pb-16">
          { data?.data?.total === 0 ? 'No data available.' : '' }
        </p>
      ) }
      { data?.data?.total > 0 && (
        <div className = "relative">
          <div className = "overflow-hidden">
            <div
              className = "flex transition-transform duration-300"
              style = { { transform: `translateX(-${currentIndex * (100 / slidesToShow)}%)` } }
            >
              { data?.data?.products?.map((product) => (
                <div
                  key = { product?.product_id }
                  className = "mx-1 md:mx-2 mb-2 md:mb-4"
                  style = { {
                    flex: `0 0 ${customWidth === 'auto' ? `${100 / slidesToShow}%` : customWidth}`
                  } }
                >
                  <NewProductsItem product = { product } />
                </div>
              )) }
            </div>
          </div>
          { data?.data?.products?.length > slidesToShow && (
            <div className = "absolute w-full flex justify-between wrp-arrowicon">
              <button
                onClick = { goToPrevSlide }
                className = { `w-8 md:w-10 h-8 md:h-10 bg-white shadow-arrowsad rounded-full ${
                  showLeftArrow ? 'activeproductslide' : 'deactiveproductslide'
                }` }
                disabled = { !showLeftArrow }
              >
                <KeyboardArrowLeftOutlinedIcon />
              </button>
              <button
                onClick = { goToNextSlide }
                className = { `relative right-0.5 md:right-0 w-8 md:w-10 h-8 md:h-10 bg-white shadow-arrowsad rounded-full inline-block ml-3 ${
                  showRightArrow ? 'activeproductslide' : 'deactiveproductslide'
                }` }
                disabled = { !showRightArrow }
              >
                <KeyboardArrowRightOutlinedIcon />
              </button>
            </div>
          ) }
        </div>
      ) }
    </>
  );
};

export default NewProducts;
