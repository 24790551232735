import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Twitter from '@mui/icons-material/Twitter';
import { React, useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { useTranslation } from 'react-i18next';
import { MdOutlineMail } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import companylogof from '../images/companylogof.png';
import { useSubscribeMutation } from '../redux/api/userApi';
import { showToast } from '../redux/features/toastSlice';


const Footer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [expandedIndex, setExpandedIndex] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');

  const handleEmail = (e) => {
    const trimmedEmail = e.target.value.trim();
    setEmail(trimmedEmail);
  
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(trimmedEmail);
  
    setErrorEmail(isValidEmail ? '' : '');
    setIsEmailValid(isValidEmail);
  };

  const [subscribe,{data, isLoading,isSuccess, error}] = useSubscribeMutation();

  useEffect(() => {
    if (error) {
      dispatch(showToast(error?.data?.message));
      dispatch(showToast(error?.data?.message?.email[0]));
    }

    if (isSuccess) {
      dispatch(showToast(data?.message));
    }
  }, [error, isSuccess, data]);

  const handleForm = (e) => {
    e.preventDefault();

    subscribe({email});

  };

  const isValidForm = isEmailValid;
 
  return (
    <>
      <div className = 'w-full bg-white pt-12 md:pt-20 pb-5'>
        <div className = "w-full h-px bg-borderColor"></div>
        <div className = ' hidden md:flex justify-between pt-10 gap-3'>
          <div className = 'w-width22%'>
            <div>
              <h3 className = 'text-xs text-black font-semibold tracking-widest pb-3'>CUSTOMER SERVICE</h3>
              <ul>
                <li><Link to = '#' className = 'text-xs text-black'>FAQ</Link></li>
                <li><Link to = '/shipping-policy' className = 'text-xs text-black'>Shipping & Returns</Link></li>
                <li><Link to = '/contact-us' className = 'text-xs text-black'>Contact Us</Link></li>
                <li><Link to = '#' className = 'text-xs text-black'>Store Info & Events</Link></li>
                <li><Link to = '/store-locator' className = 'text-xs text-black'>Store Locator</Link></li>
              </ul>
            </div>
          </div>
          <div className = 'w-width22%'>
            <div>
              <h3 className = 'text-xs text-black font-semibold tracking-widest pb-3'>ABOUT BEAUTY ICON</h3>
              <ul>
                <li><Link to = '#' className = 'text-xs text-black'>About Us</Link></li>
                <li><Link to = '/privacy-policy' className = 'text-xs text-black'>Privacy Policy</Link></li>
                <li><Link to = '/terms-and-conditions' className = 'text-xs text-black'>Terms of Service</Link></li>
                <li><Link to = '#' className = 'text-xs text-black'>Employment</Link></li>
              </ul>
            </div>
          </div>
          <div className = 'w-width22%'>
            <div>
              <h3 className = 'text-xs text-black font-semibold tracking-widest pb-3'>ACCOUNT INFO</h3>
              <ul>
                <li><Link to = '/my-account' className = 'text-xs text-black'>My Account</Link></li>
                <li><Link to = '/shoping-cart' className = 'text-xs text-black'>My Shopping Bag</Link></li>
                <li><Link to = '#' className = 'text-xs text-black'>My Order Status</Link></li>
              </ul>
            </div>
          </div>
          <div className = 'w-width33%'>
            <div>
              <h3 className = 'text-xs text-black font-semibold tracking-widest pb-3'>SIGN UP FOR EMAILS</h3>
              <ul>
                <li className = 'mt-1'>
                  <div className = "relative">
                    <p className = 'text-xs text-red'>{ errorEmail }</p>
                    <form onSubmit = { handleForm }><input type = "text" className = "bg-white h-10 w-full px-4 rounded-md focus:outline-none text-xs" value = { email } placeholder = { t('Enteryouremail') } onChange = { handleEmail } />
                      <button className = "h-7 rounded bg-primary absolute top-1.5 text-xs right-2 px-2 text-white hover:cursor-pointer flex gap-1 text-center items-center" disabled = { !isValidForm }>{ isLoading?'Sending...': t('Subscribe') } <span><MdOutlineMail /></span></button>
                    </form>
                  </div>
                </li>
                <li className = 'mt-5'>
                  <div className = 'flex'>
                    <div>
                      <Link to = "#" className = 'mr-2 text-black'>
                        <Instagram style = { {fontSize: '26px'} } />
                      </Link>
                    </div>
                    <div>
                      <Link to = "#" className = 'mr-2 text-black'>
                        <Facebook style = { {fontSize: '26px'} } />
                      </Link>
                    </div>
                    <div>
                      <Link to = "#" className = 'mr-2 text-black'>
                        <Twitter style = { {fontSize: '26px'} } />
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ul className = 'block md:hidden'>
          <div>
            <li className = 'py-4 border-b border-borderColor'>
              <Collapsible
                trigger = {
                  <div className = 'flex justify-between'>
                    <h3 className = 'self-center text-xs text-black font-semibold tracking-widest'>CUSTOMER SERVICE</h3>
                    <span className = "text-grey self-center block w-6 cursor-pointer text-center">
                      { expandedIndex === 0 ? (
                        <ExpandLessIcon style = { { fontSize: '24px' } } />
                      ) : (
                        <KeyboardArrowDownIcon style = { { fontSize: '24px' } } />
                      ) }
                    </span>
                  </div>
                }
                open = { expandedIndex === 2 }
                onOpening = { () => setExpandedIndex(true) }
                onClosing = { () => setExpandedIndex(false) }
              >
                <ul className = 'mt-3'>
                  <li><Link to = '#' className = 'text-xs text-black'>FAQ</Link></li>
                  <li><Link to = '/shipping-policy' className = 'text-xs text-black'>Shipping & Returns</Link></li>
                  <li><Link to = '/contact-us' className = 'text-xs text-black'>Contact Us</Link></li>
                  <li><Link to = '#' className = 'text-xs text-black'>Store Info & Events</Link></li>
                  <li><Link to = '#' className = 'text-xs text-black'>Store Locator</Link></li>
                </ul>
              </Collapsible>
            </li>
            <li className = 'py-4 border-b border-borderColor'>
              <Collapsible
                trigger = {
                  <div className = 'flex justify-between'>
                    <h3 className = { 'self-center text-xs text-black font-semibold tracking-widest' }>{ t('ABOUT BEAUTY ICON') }</h3>
                    <span className = "text-grey self-center block w-6 cursor-pointer text-center" >
                      { expandedIndex === 0 ? (
                        <ExpandLessIcon style = { { fontSize: '24px' } } />
                      ) : (
                        <KeyboardArrowDownIcon style = { { fontSize: '24px' } } />
                      ) }
                    </span>
                  </div>
                }
                open = { expandedIndex === 1 }
                onOpening = { () => setExpandedIndex(true) }
                onClosing = { () => setExpandedIndex(false) }
              >
                <ul className = 'mt-3'>
                  <li><Link to = '#' className = 'text-xs text-black'>About Us</Link></li>
                  <li><Link to = '/privacy-policy' className = 'text-xs text-black'>Privacy Policy</Link></li>
                  <li><Link to = '/terms-and-conditions' className = 'text-xs text-black'>Terms of Service</Link></li>
                  <li><Link to = '#' className = 'text-xs text-black'>Employment</Link></li>
                </ul>
              </Collapsible>
            </li>
            <li className = 'py-4 border-b border-borderColor'>
              <Collapsible
                trigger = {
                  <div className = 'flex justify-between'>
                    <h3 className = { 'self-center text-xs text-black font-semibold tracking-widest' }>{ t('ACCOUNT INFO') }</h3>
                    <span className = "text-grey self-center block w-6 cursor-pointer text-center">
                      { expandedIndex === 0 ? (
                        <ExpandLessIcon style = { { fontSize: '24px' } } />
                      ) : (
                        <KeyboardArrowDownIcon style = { { fontSize: '24px' } } />
                      ) }
                    </span>
                  </div>
                }
                open = { expandedIndex === 3 }
                onOpening = { () => setExpandedIndex(true) }
                onClosing = { () => setExpandedIndex(false) }
              >
                <ul className = 'mt-3'>
                  <li><Link to = '/my-account' className = 'text-xs text-black'>My Account</Link></li>
                  <li><Link to = '/shoping-cart' className = 'text-xs text-black'>My Shopping Bag</Link></li>
                  <li><Link to = '#' className = 'text-xs text-black'>My Order Status</Link></li>
                </ul>
              </Collapsible>
            </li>
            <li className = 'py-4 border-b border-borderColor'>
              <Collapsible
                trigger = {
                  <div className = 'flex justify-between'>
                    <h3 className = { 'self-center text-xs text-black font-semibold tracking-widest' }>{ t('SIGN UP FOR EMAILS') }</h3>
                    <span className = "text-grey self-center block w-6 cursor-pointer text-center">
                      { expandedIndex === 0 ? (
                        <ExpandLessIcon style = { { fontSize: '24px' } } />
                      ) : (
                        <KeyboardArrowDownIcon style = { { fontSize: '24px' } } />
                      ) }
                    </span>
                  </div>
                }
                open = { expandedIndex === 3 }
                onOpening = { () => setExpandedIndex(true) }
                onClosing = { () => setExpandedIndex(false) }
              >
                <ul>
                  <li className = 'mt-5'>
                    <div className = "relative">
                      <p className = 'text-xs text-red'>{ errorEmail }</p>
                      <form onSubmit = { handleForm }><input type = "text" className = "bg-white h-10 w-full px-4 rounded-md focus:outline-none text-xs" value = { email } placeholder = { t('Enteryouremail') } onChange = { handleEmail } />
                        <button className = "h-7 rounded bg-primary absolute top-1.5 text-xs right-2 px-2 text-white hover:cursor-pointer flex gap-1 text-center items-center" disabled = { !isValidForm }>{ isLoading?'Sending...': t('Subscribe') } <span><MdOutlineMail /></span></button>
                      </form>
                    </div>
                  </li>
                  <li className = 'mt-5'>
                    <div className = 'flex'>
                      <div>
                        <Link to = "#" className = 'mr-2 text-black'>
                          <Instagram style = { {fontSize: '26px'} } />
                        </Link>
                      </div>
                      <div>
                        <Link to = "#" className = 'mr-2 text-black'>
                          <Facebook style = { {fontSize: '26px'} } />
                        </Link>
                      </div>
                      <div>
                        <Link to = "#" className = 'mr-2 text-black'>
                          <Twitter style = { {fontSize: '26px'} } />
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </Collapsible>
            </li>
            
          </div>
        </ul>
        <div className = 'mt-5 md:mt-8 gap-5'>
          <div className = 'w-10/12 md:w-width45% mx-auto md:mx-auto'><img className = 'w-full' src = { companylogof } alt = "companylogof" /></div>
          <div className = 'text-center mt-5'>
            <p className = 'text-xs block mt-2 text-blacklight'>© 2025 by Dimas. All rights reserved</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
