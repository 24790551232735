import CakeIcon from '@mui/icons-material/Cake';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';




const LoyaltyRewards = () => {
  return (
    <>
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = 'text-white text-xl lg:text-2xl text-center font-semibold uppercase'>Loyalty Rewards Program</h3>
      </div>
      <div className = "container mx-auto px-5 lg:px-10">
        <div className = 'page1 w-full flex justify-center items-center flex-col pb-40  max-sm:pb-10'>
          <div className = 'headingcontent flex justify-center items-center '>
            <p className = 'text-base md:text-2xl mt-5 w-full md:w-[40%] text-grey text-center' >Welcome to the exclusive Cos Bar loyalty rewards program, B. Cos! Earn points and save on your next purchase.</p>
          </div>
          <div className = 'btnheading flex gap-4 mt-10 max-sm:flex-col  '>
            <Link to = '/signup' className = 'border-solid bg-primary rounded-md text-white text-base md:text-lg w-40 py-2 uppercase flex items-center justify-center'>JOIN NOW</Link>
            <Link to = '/login' className = 'border-solid border-2 border-borderColor text-base md:text-lg rounded-md w-40 uppercase py-2 flex items-center justify-center'>LOGIN</Link>
          </div>
        </div>
        <div className = 'page2 pt-3 mb-10 max-sm:mb-2' >
          <h2 className = 'text-center text-2xl md:text-3xl font-semibold uppercase' >How to Earn Points</h2>
          <div className = 'w-full md:w-4/5 mx-auto block md:flex justify-between pt-16'>
            <div className = 'items-center text-center'>
              <h1 className = 'text-4xl md:text-6xl font-extrabold mb-5'>01</h1>
              <h2 className = 'text-xl md:text-2xl font-semibold'>SIGN UP</h2>
              <p className = 'text-base mt-2 text-center text-grey'>Create an account and <br></br>
                get 100 points.</p>
            </div>
            <div className = 'items-center text-center mt-5 md:mt-0'>
              <h1 className = 'text-4xl md:text-6xl font-extrabold mb-5'>02</h1>
              <h2 className = 'text-xl md:text-2xl font-semibold'>EARN POINTS</h2>
              <p className = 'text-base mt-2 text-center text-grey'>Earn points every time <br></br> you shop.</p>
            </div>
            <div className = 'items-center text-center mt-5 md:mt-0'>
              <h1 className = 'text-4xl md:text-6xl font-extrabold mb-5'>03</h1>
              <h2 className = 'text-xl md:text-2xl font-semibold'>REDEEM POINTS</h2>
              <p className = 'text-base mt-2 text-center text-grey'>Redeem points in your <br></br> shopping bag.</p>
            </div>
          </div>

        </div>
        <div className = 'pt-16 md:pt-20 mb-10 ' >
          <h2 className = 'text-center text-2xl md:text-3xl font-semibold max-sm:text-2xl uppercase' >Ways to earn points</h2>
          <div className = 'points flex flex-wrap md:flex-nowrap justify-center gap-5 pt-12 pb-4 md:pb-24'>
            <div className = 'w-full lg:w-[28%] bg-white shadow-custom p-5 text-center'>
              <h1 className = 'text-primary mb-5'><CurrencyExchangeIcon style = { {fontSize: '52px'} } /></h1>
              <h2 className = 'text-lg md:text-xl font-semibold'>1 Point for every Rs.1 spent </h2>
              <p className = 'text-lg md:text-xl  mt-2 text-center text-grey'>Make a purchase</p>
              <Link to = '/signup' className = 'block w-full mt-5 bg-primary py-2 px-5 rounded-full text-sm text-white font-medium'>Sign up</Link>
              <p className = 'text-sm mt-2'>Already a member? <Link to = '/login' className = 'text-primary'>Log in</Link></p>
            </div>
            <div className = ' w-full lg:w-[28%] bg-white shadow-custom p-5 text-center'>
              <h1 className = 'text-primary mb-5'><CakeIcon style = { {fontSize: '52px'} } /></h1>
              <h2 className = 'text-lg md:text-xl font-semibold'>100 points</h2>
              <p className = 'text-lg md:text-xl mt-2 text-center text-grey'>Birthday reward</p>
              <Link to = '/signup' className = 'block w-full mt-5 bg-primary py-2 px-5 rounded-full text-sm text-white font-medium'>Sign up</Link>
              <p className = 'text-sm mt-2'>Already a member? <Link to = '/login' className = 'text-primary'>Log in</Link></p>
            </div>
            <div className = 'w-full lg:w-[28%] bg-white shadow-custom p-5 text-center'>
              <h1 className = 'text-primary mb-5'><StarBorderIcon style = { {fontSize: '52px'} } /></h1>
              <h2 className = 'text-lg md:text-xl font-semibold text-center'>50 points</h2>
              <p className = 'text-lg md:text-xl mt-2 text-center text-grey'>Leave a Review</p>
              <Link to = '/signup' className = 'block w-full text-center mt-5 bg-primary py-2 px-5 rounded-full text-sm text-white font-medium'>Sign up</Link>
              <p className = 'text-sm mt-2'>Already a member? <Link to = '/login' className = 'text-primary'>Log in</Link></p>
            </div>
          </div>

        </div>
        <div className = 'flex justify-center flex-col items-center mb-8'>
          <h3 className = 'text-center font-bold text-2xl md:text-3xl mt-5 max-sm:text-2xl uppercase'> How to use your points </h3>
          <p className = 'text-center text-grey mt-5 text-base max-sm:w-full max-sm:text-sm'> Redeeming your points is easy. Simply go to your shopping cart and select <br></br> the your discount amount from the dropdown under the  section.</p>
        </div>
        <div className = 'flex flex-col-reverse md:flex-row justify-between'>
          <div className = 'w-full lg:w-width48% self-center'>
            <p className = 'text-center'>Refer a Friend</p>
            <h3 className = 'text-center mt-8 font-semibold text-2xl md:text-3xl mb-5 max-sm:mt-10'>GIVE $25, GET $25</h3>
            <p className = 'text-center text-sm md:text-base text-grey'>Give your friends $25 off their first order of $150+ and get <br></br> $25 (in points) for each successful referral.</p>
            <div className = ' mt-10 w-full md:w-3/5 mx-auto'>
              <input type = "text" placeholder = 'Your email address'className = 'block w-full border border-borderColor' />
              <button className = ' bg-primary block text-base text-white font-semibold w-full mt-5 py-2 rounded-md'>Next</button>
            </div>
          </div>
          <div className = 'w-full lg:w-width48% self-center pb-8 md:pb-0'>
            <img src = 'https://cdn-widget-assets.yotpo.com/static_assets/m2igOM6vpylIXDPCQMJUZA/images/image_2023_02_14_21_19_06_004' alt = "" className = 'w-full object-cover'/>
          </div>
        </div>
        
        <Footer />
       
      </div>  

    </>
  );
  
}; 

export default LoyaltyRewards ;
  