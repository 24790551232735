/* eslint-disable react/jsx-key */
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import TopHeader from '../../components/TopHeader';
import Amber from './Amber';
import AromaticFruity from './AromaticFruity';
import Chypre from './Chypre';
import Citrus from './Citrus';
import FloralAmber from './FloralAmber';
import FloralFruityGreen from './FloralFruityGreen';
import FloralRose from './FloralRose';
import FloralWatery from './FloralWatery';
import FragranceBanner from './FragranceBanner';
import Fruity from './Fruity';
import GourMand from './GourMand';
import HowTheFamily from './HowTheFamily';
import WhiteFloral from './WhiteFloral';
import Woody from './Woody';
import Woodyfrooty from './Woodyfrooty';

const FragranceFamilies = () => {


  return (
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = 'container mx-auto px-5 lg:px-10'>
        <FragranceBanner />
        <div className = 'text-center mt-5'>
          <span className = 'text-sm md:text-base text-primary font-medium'>SOME LIGHT READING</span>
          <h3 className = 'text-black text-xl lg:text-3xl font-bold uppercase'>EXPLORE OUR FRAGRANCE FAMILY <br></br>ENCYCLOPEDIA</h3>
          <p className = 'text-sm md:text-base text-gray-600 mt-3'>Learn more about every fragrance family, and the scents categorized within each.</p>
        </div>
        <div className = 'flex flex-wrap lg:flex-nowrap mt-8'>
          <div className = 'w-full lg:w-3/6'>
            <img src = 'https://imgcdn.scentbird.com/_/rt:fill/w:750/aGVhZGxlc3MvbGFuZGluZ192aWRlb19iYW5uZXJfY292ZXJfOGVkMjc4OTQzMS5qcGc=' alt = 'women' />
          </div>
          <div className = 'w-full lg:w-3/6 text-center'>
            <div className = "w-full h-full bg-cover bg-center pb-8 lg:pb-0 pt-8" style = { {backgroundImage: 'url(\'https://imgcdn.scentbird.com/_/rt:fill/w:750/ZnJvbnRiaXJkMi9pbWFnZXMvY292ZXItYmctZGVza3RvcF81MmJjY2MuanBn\')'} }>
              <span className = 'text-lg lg:text-xl xl:text-3xl uppercase font-semibold'>SCENT DISCOVERY</span>
              <h3 className = 'text-xl lg:text-2xl xl:text-5xl font-bold text-primary uppercase'>ALL IN THE <br></br> (FRAGRANCE) <br></br> FAMILY</h3>
              <button className = 'bg-black text-white uppercase text-xs xl:text-sm font-bold inline-block py-1.5 px-5 rounded-full mt-6'>Play video <span><PlayCircleIcon style = { {fontSize: '20px'} } /></span></button>
              <p className = 'text-sm xl:text-base text-black font-medium mt-5'>Hit the play button to get acquainted with each<br></br>
                fragrance family straight from Jeniece Trizzino,<br></br>
                VP of Innovation and Physical Product and all-<br></br>
                around scent expert.</p>
            </div>
          </div>
        </div>

        <Fruity />
        <Woodyfrooty />
        <Amber />
        <WhiteFloral />
        <FloralWatery />
        <FloralRose />
        <GourMand />
        <Woody />
        <FloralAmber />
        <FloralFruityGreen />
        <Chypre />
        <Citrus />
        <AromaticFruity />
        <HowTheFamily />
        <Footer />
      </div>
    </>
  );
};

export default FragranceFamilies;
