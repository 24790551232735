import { createSlice } from '@reduxjs/toolkit';

const orderidSlice = createSlice({
  name: 'orderid',
  initialState: {
    orderid: 0
  },
  reducers: {
    setorderid: (state, action) => {
      state.orderid = action.payload;
    }
  }
});

export const { setorderid } = orderidSlice.actions;
export default orderidSlice.reducer;
