import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import TopHeader from '../components/TopHeader';
import { useResetPasswordMutation } from '../redux/api/userApi';

const PasswordResetForm = () => {

  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
  const email = localStorage.getItem('emailforgetpassword');
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);

    setErrorConfirmPassword(
      e.target.value === password ? '' : 'Passwords do not match'
    );
  };


  const [resetPassword,{isLoading, error, isSuccess,data}] = useResetPasswordMutation();

  useEffect(() => {

    if(error){
      toast(error?.data?.message);
    }
    if(isSuccess){
      toast(data?.message);
      setTimeout(() => {
        navigate('/login');
      },2000);
    }

  },[error, isSuccess]);


  const submitHandler = (e) => {
    e.preventDefault();
    const Data = { 
      otp:otp,
      email:email,
      new_password:password,
      confirm_password:confirmPassword
    };

    resetPassword(Data);
  };

  const isFormValid = otp && password && email;

  return(
    <>
      <ToastContainer />
      <TopHeader />
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = { `text-white text-xl lg:text-2xl text-center font-semibold uppercase ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('MyAccount') }</h3>
      </div>
      <div className = 'container mx-auto px-5 lg:px-10'>
        <div className = 'w-full md:w-2/5 mx-auto'>
          <p className = { `text-base text-grey ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Enternewpasswordbelow') }</p>
          <form onSubmit = { submitHandler }>
            <div className = 'relative'>
              <lebel className = { `text-sm text-grey-500 lg:text-grey-800 inline-block mt-5 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Otp') }</lebel>
              <input
                value = { otp }
                onChange = { (e) => setOtp(e.target.value) }
                className = { `block w-full border border-grey rounded-md px-4 py-2 text-sm outline-primary-600 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }
                placeholder = { t('Enterpassword') }
              />
              <lebel className = { `text-sm text-grey-500 lg:text-grey-800 inline-block mt-5 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Newpassword') } *</lebel>
              <input
                onChange = { handlePasswordChange }
                value = { password }
                type = { showPassword ? 'text' : 'password' }
                className = { `block w-full border border-grey rounded-md px-4 py-2 text-sm outline-primary-600 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }
                placeholder = { t('Enterpassword') }
              />
              { password && <a href = '#' className = 'block absolute bottom-2 right-3.5 w-4' onClick = { toggleShowPassword }>{ showPassword?<VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon className = 'eyecolor' /> }</a> }
            </div>
            <div>
              <lebel className = { `text-sm text-grey-500 lg:text-grey-800 inline-block mt-5 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Re-enternewpassword') } *</lebel>
              <input
                onChange = { handleConfirmPasswordChange }
                value = { confirmPassword }
                type = { showPassword ? 'text' : 'password' }
                className = { `block w-full lg:border border-grey lg:rounded-md lg:px-4 py-2 text-sm ${selectLanguage == 'ar'?'font-madani-arabic': ''} ${
                  errorConfirmPassword ? 'outline-red' : 'outline-primary-600'
                }` }
                placeholder = { t('Re-enternewpassword') }
              />
              { errorConfirmPassword && (
                <p className = "text-xs text-red">{ errorConfirmPassword }</p>
              ) }
            </div>
            <button className = { `block w-full text-white rounded-3xl lg:rounded-md px-4 py-2.5 text-sm bg-gradient-primary mt-5 ${selectLanguage == 'ar'?'font-madani-arabic': ''} ${!isFormValid?'bg-gradient-primary-light':'bg-gradient-primary'}` }>{ isLoading?'Sending': t('Save') }</button>
          </form>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PasswordResetForm;