import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cartopen: false
};

export const opencardSlice = createSlice({
  initialState,
  name: 'opencardSlice',
  reducers: {
    setCartopen: (state, action) => {
      state.cartopen = action.payload;
    }
  }
});

export default opencardSlice.reducer;

export const { setCartopen } = opencardSlice.actions;
