/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useBrandcollectionQuery } from '../redux/api/recomendedproductApi';

const BrandDropdown = ({isHovered2}) => {
  const selectLanguage = localStorage.getItem('selectLanguage');
  const [brands, setBrands] = useState([]);
  const {error, data} = useBrandcollectionQuery();

  useEffect(() => {

    if(error){
      toast(error?.data?.message);
    }

  },[error]);


  
  useEffect(() => {
    const brandsData = data?.data;
    // Remove duplicates by using a Set
    const uniqueBrands = brandsData ? Array.from(new Set(brandsData.map(brand => brand.name)))
      .map(name => {
        return brandsData.find(brand => brand.name === name);
      }) : [];

    setBrands(uniqueBrands);
  }, [data]);

  const groupedBrands = brands.reduce((acc, brand) => {
    const firstLetter = brand.name.charAt(0).toUpperCase();
    if (!acc[firstLetter]) acc[firstLetter] = [];
    acc[firstLetter].push(brand);
    return acc;
  }, {});

  

  return(
    <>

      <div className = { `bg-white border-t border-boderColor-300 absolute z-10 ${selectLanguage == 'ar'?'right-0': 'left-0'}  top-30 w-2/5 ${isHovered2 ? 'block duration-150' : 'hidden'}` }>
        <Link to = "/all-brand" className = 'inline-block pl-8 text-base text-blacklight mt-3 pb-3 font-semibold duration-200 hover:text-primary hover:underline'>All brand</Link>
        <ul className = 'pl-8 pr-5 pb-5 scrollmenuheight'>
          { Object.keys(groupedBrands).sort().map(letter => (
            <li key = { letter } className = 'mt-4'>
              <span className = 'inline-block bg-grey-200 text-lg px-1.5'>{ letter }</span>
              <div className = 'border-b border-grey-300'></div>
              <div className = 'flex gap-x-8 gap-y-3 flex-wrap mt-2'>
                { groupedBrands[letter].map(brand => (
                  <Link
                    key = { brand?.brand_id }
                    to = { `/brand/shop/${brand?.name}` }
                    className = 'text-sm text-blacklight font-semibold duration-200 hover:text-primary hover:underline'
                  >
                    { brand?.name }
                  </Link>
                )) }
              </div>
            </li>
          )) }
        </ul>
      </div>
    </>
  );
};


export default BrandDropdown;