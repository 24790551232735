/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import ProductQuatity from '../../components/ProductQuatity';
import ShareSocialmedia from '../../components/ShareSocialmedia';
import productquickview from '../../images/productquickview.png';

const Product = ({ product }) => {

  const [isHovered, setIsHovered] = useState(false);
  const [hoveredImage, setHoveredImage] = useState(product?.images[0]); // Add state for hovered image
  const [open, setOpen] = useState(false);
  console.log('product',product);
  const handleMainMouseEnter = (imageUrl) => {
    setIsHovered(true);
    setHoveredImage(imageUrl); // Update hovered image source
  };

  const handleMainMouseLeave = () => {
    setIsHovered(false);
    setHoveredImage(product?.images[0]); // Reset to default image on mouse leave
  };

  const handleMouseEnter = (imageUrl) => {
    setHoveredImage(imageUrl); // Update hovered image source
  };


  const handleMouseLeave = () => {
    setHoveredImage(product?.images[0]?.url); // Reset to default image on mouse leave
  };

  return (
    <>
      <div
        key = { product?._id }
        className = "relative w-width48% md:w-catlogwidth xl:w-productwidth bg-white shadow-lg rounded-lg mt-3 transition duration-300 transform hover:-translate-y-1 hover:shadow-2xl"
        onMouseEnter = { () => handleMainMouseEnter(product?.images[1]) }
        onMouseLeave = { handleMainMouseLeave }
      >
        <Link to = '/product-details' className = 'block w-full'>
          <img
            src = { hoveredImage } // Use hoveredImage state here
            alt = { product?.name }
            className = "w-full"
          />
        </Link>
        <div className = "p-2 md:p-4">
          <Link to = '/product-details' className = "text-gray-800 block text-sm xl:base h-20 md:h-14 font-semibold hover:text-primary delay-500">{ product.name }</Link>
          <p className = 'hidden md:block'>
            <StarRatings
              rating = { product?.ratings }
              starRatedColor = "#ffb829"
              numberOfStars = { 5 }
              name = 'rating'
              starDimension = '22px'
              starSpacing = '1px'
            />
            <span className = 'text-sm align-middle inline-block ml-2'>({ product?.numOfReviews })</span>
          </p>
          <p className = 'block md:hidden'>
            <StarRatings
              rating = { product?.ratings }
              starRatedColor = "#ffb829"
              numberOfStars = { 5 }
              name = 'rating'
              starDimension = '18px'
              starSpacing = '1px'
            />
            <span className = 'text-sm align-middle inline-block ml-2'>({ product?.numOfReviews })</span>
          </p>
          <div className = 'flex justify-between mt-2'>
            { product.images.map((image, index) => (
              <div key = { index } className = 'w-8 h-8 md:w-12 md:h-12'>
                <img
                  className = 'w-full h-full border border-borderColor rounded-full'
                  src = { image }
                  alt = { 'smallproduct' }
                  onMouseEnter = { () => handleMouseEnter(image) }
                  onMouseLeave = { handleMouseLeave }
                />
              </div>
            )) }
          </div>
          <p className = "text-sm md:text-base font-bold text-primary pt-2">{ `SAR ${product?.price}` }</p>
          { isHovered && (
            <div className = "mt-4 flex justify-between items-center space-x-2 absolute top-48 md:top-56">
              <Tooltip title = "Add to Cart">
                <button className = "shadow-custom bg-white text-black w-8 h-8 md:w-12 md:h-12 rounded-full hover:bg-primary hover:text-white transition duration-300">
                  <LocalMallOutlinedIcon style = { { fontSize: '20px' } } />
                </button>
              </Tooltip>
              <Tooltip title = "Quick View">
                <button onClick = { () => setOpen(true) } className = "shadow-custom bg-white text-black w-8 h-8 md:w-12 md:h-12 rounded-full hover:bg-primary hover:text-white transition duration-300">
                  <VisibilityOutlinedIcon style = { { fontSize: '20px' } } />
                </button>
              </Tooltip>
              <Tooltip title = "Add to Wishlist">
                <button className = "shadow-custom bg-white text-black w-8 h-8 md:w-12 md:h-12 rounded-full hover:bg-primary hover:text-white transition duration-300">
                  <FavoriteBorderOutlinedIcon style = { { fontSize: '20px' } } />
                </button>
              </Tooltip>
            </div>
          ) }
        </div>
      </div>

      <Modal open = { open } onClose = { () => setOpen(false) } center>
        <div className = 'w-full block md:flex p-5'>
          <div className = 'w-64 self-center mb-5 md:mb-0 mr-0 md:mr-8'>
            <img className = 'w-full' src = { productquickview } alt = 'product' />
            <div className = 'w-full'>
              <Link to = "/product-details" className = 'block bg-white text-primary border border-primary uppercase text-center py-2 text-sm delay-300 hover:bg-primary hover:text-white hover:delay-300'>View Details</Link>
            </div>
          </div>
          <div className = 'self-center'>
            <h3 className = 'text-lg lg:text-xl text-black font-semibold'>Perfume Travel Atomizer</h3>
            <p className = 'text-base md:text-lg mt-5 font-bold text-primary pb-3'>SAR 45</p>
            <ProductQuatity />
            <p className = 'text-base text-grey mt-3'>SKU: 3700559605905</p>
            <button className = "bg-primary w-full rounded-full text-white text-sm uppercase py-2 font-bold my-5">Add to bag</button>
            <hr></hr>
            <ShareSocialmedia />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Product;
