import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AccountSidebar from '../components/AccountSidebar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import { useOrderlistQuery } from '../redux/api/orderApi';



const Order = () => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');

  const columns = [
    { id: 'ORDER', label: <p className = { `text-sm text-blacklight font-semibold font-sans ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('ORDER') }</p>, minWidth: 120 },
    { id: 'DATE', label: <p className = { `text-sm text-blacklight font-semibold font-sans ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('DATE') }</p>, minWidth: 120 },
    { id: 'STATUS', label: <p className = { `text-sm text-blacklight font-semibold font-sans ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('STATUS') }</p>, minWidth: 120, align: 'left' },
    { id: 'TOTAL', label: <p className = { `text-sm text-blacklight font-semibold font-sans ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('TOTAL') }</p>, minWidth: 120, align: 'left' },
    { id: 'TRACKING', label: <p className = { `text-sm text-blacklight font-semibold font-sans ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('TRACKING') }</p>, minWidth: 100, align: 'left' },
    { id: 'ACTIONS', label: <p className = { `text-sm text-blacklight font-semibold font-sans ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('ACTIONS') }</p>, minWidth: 100, align: 'right' }
  ];


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  console.log('timezone',timezone);

  const { error, data } = useOrderlistQuery({ timezone });
  

  const rows = data ? data?.data?.orders?.map(order => ({
    ORDER: <Link to = { `/order-details/${order?.order_uuid}` } className = 'text-primary cursor-pointer'>{ order?.order_id }</Link>,
    DATE: <span>{ order?.date }</span>,
    STATUS: <span>{ order?.status }</span>,
    TOTAL: <span>{ order?.total } SAR </span>,
    TRACKING: <span>View</span>,
    ACTIONS: (
      <p>
        { /* <Link className = 'inline-block py-2 px-2 bg-primary text-xs mx-1 text-white rounded' to = '#'>Pay</Link> */ }
        { /* <Link className = 'inline-block py-2 px-2 bg-primary text-xs mx-1 text-white rounded' to = '#'>View</Link> */ }
        <Link className = 'inline-block py-2 px-2 bg-primary text-xs mx-1 text-white rounded' to = '#'>Cancel</Link>
      </p>
    )
  })) : [];

  return (
    <>
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = 'text-white text-xl lg:text-2xl text-center font-semibold uppercase'>My Account</h3>
      </div>
      <div className = 'container mx-auto px-5 lg:px-0'>
        <div className = 'block md:flex'>
          <div className = 'w-full md:w-1/5'>
            <AccountSidebar />
          </div>
          <div className = 'w-full mt-5 md:mt-0 md:w-4/5 xl:flex-1 shadow-custom border border-boderColor-300 rounded'>
            <Paper className = 'hidden lg:block' sx = { { width: '100%', overflow: 'hidden', boxShadow: 'none', borderRadius: '8px' } }>
              <TableContainer sx = { { minHeight: 500 } }>
                <Table stickyHeader aria-label = "sticky table">
                  <TableHead>
                    <TableRow>
                      { columns.map((column, index) => (
                        <TableCell
                          key = { index }
                          align = { column.align }
                          style = { { minWidth: column.minWidth } }
                        >
                          { column.label }
                        </TableCell>
                      )) }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      error && <p className = 'text-sm text-center'>Error loading data...</p>
                    }
                    { rows
                      ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        return (
                          <TableRow hover role = "checkbox" tabIndex = { -1 } key = { index }>
                            { columns.map((column, index) => {
                              const value = row[column.id];
                              return (
                                <TableCell key = { index } align = { column.align }>
                                  { value }
                                </TableCell>
                              );
                            }) }
                          </TableRow>
                        );
                      }) }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions = { [10, 20, 25, 100] }
                component = "div"
                count = { rows?.length }
                rowsPerPage = { rowsPerPage }
                page = { page }
                onPageChange = { handleChangePage }
                onRowsPerPageChange = { handleChangeRowsPerPage }
              />
            </Paper>
            <ul className = 'block lg:hidden'>
              { rows?.map((row, index) => (
                <li key = { index }>
                  <div className = 'flex justify-between p-3 md:p-5'>
                    <p className = 'text-sm text-black'>Order id</p>
                    <p className = 'text-sm text-grey'><Link to = { `/order-details/${row?.order_uuid}` }>{ row.ORDER }</Link></p>
                  </div>
                  <div className = 'flex justify-between p-3 md:p-5'>
                    <p className = 'text-sm text-black'>Date</p>
                    <p className = 'text-sm text-grey'><Link to = "#">{ row?.DATE }</Link></p>
                  </div>
                  <div className = 'flex justify-between p-3 md:p-5'>
                    <p className = 'text-sm text-black'>Status</p>
                    <p className = 'text-sm text-grey'><Link to = "#">{ row?.STATUS }</Link></p>
                  </div>
                  <div className = 'flex justify-between p-3 md:p-5'>
                    <p className = 'text-sm text-black'>Total</p>
                    <p className = 'text-sm text-grey'><Link to = "#"><span className = 'text-primary'>{ row?.TOTAL }</span></Link></p>
                  </div>
                  <div className = 'flex justify-between p-3 md:p-5'>
                    <p className = 'text-sm text-black'>Tracking</p>
                    <p className = 'text-sm text-primary'><Link to = "#">{ row?.TRACKING }</Link></p>
                  </div>
                  <div className = 'flex justify-between p-3 md:p-5'>
                    <p className = 'text-sm text-black'>Action</p>
                    <p>{ row?.ACTIONS }</p>
                  </div>
                </li>
              )) }
            </ul>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Order;
