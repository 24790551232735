import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import NewarivalItem from './NewarivalItem';

const Newarival =() => {

  const selectLanguage = localStorage.getItem('selectLanguage');
  const { t } = useTranslation();

  return(
    <>
      <div className = 'pt-12 md:pt-20 text-center pb-5'>
        <h3 className = { `text-black text-xl text-center font-semibold uppercase ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }>
          { t('NewArrivals') }
        </h3>
        <Link to = "/shop/وصل%20حديثاً" className = 'inline-block py-2 px-5 border border-borderColor text-xs mt-4 uppercase tracking-widest hover:border-black hover:duration-300'>View all</Link>
      </div>
      <NewarivalItem />
    </>
  );
};


export default Newarival;