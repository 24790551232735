/* eslint-disable react/no-unescaped-entities */
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DensityLargeOutlinedIcon from '@mui/icons-material/DensityLargeOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import TopHeader from '../../components/TopHeader';


const GiftSubscription = () => {

  return(
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = "container mx-auto px-5 lg:px-10">
        <div>
          <img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3MvZ3NwX2hlcm9fZF9taW5fNjBmYjZmNTNkMy5qcGc=' alt = 'top banner' />
        </div>
        <ul className = 'flex mt-3'>
          <li>
            <Link to = '/gifts' className = 'text-sm text-primary'>Gifts</Link>
          </li>
          <li className = 'px-2'> / </li>
          <li>
            <Link to = '#' className = 'text-sm text-blacklight'>Gift subscription</Link>
          </li>
        </ul>
        <div>
          <h3 className = 'text-blacklight text-4xl font-bold mt-6'>GIVE A GIFT SUBSCRIPTION</h3>
          <p className = 'text-base text-blacklight mt-3'>What’s the best Mother’s Day gift for the person who has it all? A Scentbird gift subscription, of course.</p>
          <h4 className = 'text-black font-bold text-base mt-2'>Offer expires May 14th at midnight EST.</h4>
          <h4 className = 'text-black font-bold text-base mt-8'>Available for United States customers only*</h4>

          <div className = 'flex justify-between mt-8'>
            <div className = 'w-width32% bg-sky'>
              <div>
                <img src = 'https://imgcdn.scentbird.com/_/rt:fill/w:384/ZnJvbnRiaXJkMi9pbWFnZXMvMTJfMDIzNjAyLmpwZw==' />
              </div>
              <div className = 'px-5 py-5'>
                <h3 className = 'text-lg text-blacklight font-semibold'>12 months gift subscription - SAR203</h3>
                <h4 className = 'text-primary text-lg font-semibold mt-2'><span className = 'font-bold'>SAR153</span> (you save SAR50)</h4>
                <ul className = 'mt-5'>
                  <li><p className = 'text-primary text-base font-semibold'><span className = 'inline-block mr-3'><CheckOutlinedIcon /></span>You get TWO free fragrances!</p></li>
                  <li className = 'mt-2'><p className = 'text-blacklight text-sm font-medium'><span className = 'text-primary inline-block mr-3'><CheckOutlinedIcon /></span>Free shipping to receiver</p></li>
                  <li className = 'mt-2'><p className = 'text-blacklight text-sm font-medium'><span className = 'text-primary inline-block mr-3'><CheckOutlinedIcon /></span>Case with their first delivery</p></li>
                  <Link to = '#' className = 'text-sm uppercase bg-primary block w-full rounded py-2 px-2 text-white font-semibold text-center mt-5 hover:bg-primary-400 duration-300'>Continue with 12 months</Link>
                </ul>
              </div>
            </div>
            <div className = 'w-width32% bg-sky'>
              <div>
                <img src = 'https://imgcdn.scentbird.com/_/rt:fill/w:384/ZnJvbnRiaXJkMi9pbWFnZXMvNl82MTMzMTEuanBn' />
              </div>
              <div className = 'px-5 py-5'>
                <h3 className = 'text-lg text-blacklight font-semibold'>6 months gift subscription - SAR102</h3>
                <h4 className = 'text-primary text-lg font-semibold mt-2'><span className = 'font-bold'>SAR89</span> (you save SAR13)</h4>
                <ul className = 'mt-5'>
                  <li><p className = 'text-primary text-base font-semibold'><span className = 'inline-block mr-3'><CheckOutlinedIcon /></span>You get TWO free fragrances!</p></li>
                  <li className = 'mt-2'><p className = 'text-blacklight text-sm font-medium'><span className = 'text-primary inline-block mr-3'><CheckOutlinedIcon /></span>Free shipping to receiver</p></li>
                  <li className = 'mt-2'><p className = 'text-blacklight text-sm font-medium'><span className = 'text-primary inline-block mr-3'><CheckOutlinedIcon /></span>Case with their first delivery</p></li>
                  <Link to = '#' className = 'text-sm uppercase bg-primary block w-full rounded py-2 px-2 text-white font-semibold text-center mt-5 hover:bg-primary-400 duration-300'>Continue with 6 months</Link>
                </ul>
              </div>
            </div>
            <div className = 'w-width32% bg-sky'>
              <div>
                <img src = 'https://imgcdn.scentbird.com/_/rt:fill/w:384/ZnJvbnRiaXJkMi9pbWFnZXMvM184ZjNlNjkuanBn' />
              </div>
              <div className = 'px-5 py-5'>
                <h3 className = 'text-lg text-blacklight font-semibold'>3 months gift subscription - SAR51</h3>
                <h4 className = 'text-primary text-lg font-semibold mt-2'><span className = 'font-bold'>SAR48</span> (you save SAR3)</h4>
                <ul className = 'mt-5'>
                  <li className = 'mt-2'><p className = 'text-blacklight text-sm font-medium'><span className = 'text-primary inline-block mr-3'><CheckOutlinedIcon /></span>Free shipping to receiver</p></li>
                  <li className = 'mt-2'><p className = 'text-blacklight text-sm font-medium'><span className = 'text-primary inline-block mr-3'><CheckOutlinedIcon /></span>Case with their first delivery</p></li>
                  <Link to = '#' className = 'text-sm uppercase bg-primary block w-full rounded py-2 px-2 text-white font-semibold text-center mt-14 hover:bg-primary-400 duration-300'>Continue with 3 months</Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className = 'flex mt-20'>
          <div className = 'w-2/4 bg-sky flex flex-1 items-center justify-start'>
            <div className = 'm-24'>
              <h3 className = 'text-blacklight text-2xl font-bold'>STRAIGHT FROM <br></br>THE SOURCE</h3>
              <p className = 'text-base text-blacklight mt-5'>We partner directly with top brands/wholesalers and only sell 100% authentic products.</p>
            </div>
          </div>
          <div className = 'w-2/4'>
            <div>
              <img src = 'https://imgcdn.scentbird.com/_/rt:fill/w:640/ZnJvbnRiaXJkMi9pbWFnZXMvZGVza3RvcF9kZjJkYjYuanBn' alt = 'leftimg' />
            </div>
          </div>
        </div>
        <div className = 'flex justify-around mt-16'>
          <div className = 'text-center'>
            <h3 className = 'text-sm font-semibold text-black uppercase leading-4'>Fast, free <br></br> shipping</h3>
            <span className = 'text-blacklight inline-block mt-1'><LocalShippingOutlinedIcon style = { {fontSize: '32px'} } /></span>
          </div>
          <div className = 'text-center'>
            <h3 className = 'text-sm font-semibold text-black uppercase leading-4'>Cancel <br></br> anytime</h3>
            <span className = 'text-blacklight inline-block mt-1'><CancelOutlinedIcon style = { {fontSize: '32px'} } /></span>
          </div>
          <div className = 'text-center'>
            <h3 className = 'text-sm font-semibold text-black uppercase leading-4'>Flexible <br></br> plans</h3>
            <span className = 'text-blacklight inline-block mt-1'><DensityLargeOutlinedIcon style = { {fontSize: '32px'} } /></span>
          </div>
          <div className = 'text-center'>
            <h3 className = 'text-sm font-semibold text-black uppercase leading-4'>Top designer <br></br> fragrances</h3>
            <span className = 'text-blacklight inline-block mt-1'><GppGoodOutlinedIcon style = { {fontSize: '32px'} } /></span>
          </div>
        </div>
        <Footer />
      </div>  
    </>
  );
};

export default GiftSubscription;