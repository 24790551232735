/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import TopHeader from '../../components/TopHeader';
import imgbanner from '../../images/img2.jpeg';
import MainSubscriptionCollections from './MainSubscriptionCollections';


const BrandSisleyParis = () => {
    
  return(
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = "container mx-auto px-5 lg:px-10">
        <div>
          <img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3Mvc2lzbGV5X2JwX2hlcm9fZGVza3RvcF81NzFiYjdkNjFmLmpwZw==' alt = 'banner' />
        </div>
        <div className = 'mt-10'>
          <h3 className = 'text-black text-4xl uppercase font-bold'>SISLEY-PARIS FRAGRANCES</h3>
          <h4 className = 'text-base font-medium text mt-3'>Est in: Paris <span>9 products</span></h4>
        </div>
        <div className = 'flex justify-between mt-5'>
          <div className = 'w-width45%'>
            <img src = { imgbanner } alt = 'sideimg' />
          </div>
          <div className = 'w-width45%'>
            <h3 className = 'text-3xl text-black font-bold'>ABOUT SISLEY-PARIS</h3>
            <p className = 'mt-3 text-blacklight text-base'>
                Sisley-Paris is a French luxury brand that creates, produces
                and distributes high-performance, plant-based skincare,
                makeup, fragrance and hair care products. Founded over 4
                decades ago by the d'Ornano family in France, Sisley is
                known around the world for innovations in beauty. 
            </p>
            <p className = 'mt-3 text-blacklight text-base'>
            Discover the enchanting world of fragrances from Sisley-
            Paris. Each creation starts with an experience and captures
            a precious moment through a sequence of exceptional
            ingredients.  From the moment they touch our skin, Sisley
            creations evoke the senses, leaving their mark, coloring a
            unique and timeless story that’s deeply personal.
            </p>
          </div>
        </div>
        <div className = 'mt-20'>
          <img className = 'w-64 mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:384/aGVhZGxlc3Mvc2lzbGV5X2JwX2xvZ29fNzMxMzBhNjk4MS5qcGc=' />
          <h3 className = 'text-3xl text-black font-bold text-center uppercase mt-8'>Our fragrances</h3>
          <MainSubscriptionCollections />
        </div>
        <div className = "w-full h-fit py-28 bg-cover bg-center mt-20" style = { {backgroundImage: 'url(\'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3Mvc2lzbGV5X2JwX2VkaXRvcmlhbF9kZXNrdG9wX2NlNDI3M2RhNGMuanBn\')'} }>
          <div className = 'flex'>
            <div className = 'w-3/6'></div>
            <div className = 'w-3/6 relative'>
              <div className = 'bg-[#141414] text-white py-14 px-14 '>
                <span className = 'text-lg lg:text-xl font-semibold uppercase'>OUR MISSION</span>
                <p className = 'text-sm lg:text-base  mt-4'>
                Dreams inspire our creativity, they nourish us with images,
                memories and sensations. Les Eaux Rêvées, or "The Dreamy
                Waters," express this imaginary place where inspirations collide,
                join together and become one – the inspirations of different
                generations of the d’Ornano family, the creators of Sisley.
                </p>
                <p className = 'text-sm lg:text-base mt-5'>
                    This collection is made up of six universal eau de toilettes that will
                   take you on a unique journey.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className = 'mt-20'>
          <img className = 'mx-auto' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:750/aGVhZGxlc3MvSU1HXzhjOGMwZDJlZWIuanBn' alt = 'banner' />
          <div className = 'w-8/12 mx-auto'>
            <div className = 'w-full h-px bg-primary mt-8'></div>
            <div className = 'text-center py-5'>
              <h4 className = 'text-base text-primary font-semibold'>A MODERN APPROACH TO FRAGRANCE</h4>
              <h3 className = 'text-2xl font-semibold mt-5'>“A perfume can really become a person. The <br></br>perfume is also a part of the personality.”</h3>
              <h4 className = 'text-lg text-primary font-semibold mt-5'>Madame Isabelle d’Ornano</h4>
              <p className = 'text-base text-blacklight mt-2'>Sisley Co-Founder</p>
            </div>
            <div className = 'w-full h-px bg-primary'></div>
          </div>
          <img className = 'mt-20' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/aGVhZGxlc3Mvc2lzbGV5X2JwX2Zvb3Rlcl9kZXNrdG9wXzM2ZjAwN2MyNjEuanBn' alt = 'banner' />
        </div>
        <Footer />
      </div>
      
    </>
  );
};

export default BrandSisleyParis;