/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';

const SelectCountry = ({ value, onChange, country }) => {

  return (
    <Select
      className = 'textsize'
      options = { country }
      value = { value }
      onChange = { onChange }
      placeholder = "Select a Country"
      isSearchable
      id = 'country'
    />
  );
};

export default SelectCountry;
