import React, { useEffect } from 'react';

const Tabbycard = () => {

  useEffect(() => {
    // Function to dynamically load external script
    const loadScript = (src, onLoad) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = onLoad;
      document.body.appendChild(script);
    };
     
    loadScript('https://checkout.tabby.ai/tabby-card.js', () => {
      new window.TabbyCard({
        selector: '#tabbyCard',
        currency: 'SAR',
        lang: 'en',
        price: 100, // Set the price dynamically if needed
        size: 'narrow',
        theme: 'black',
        header: false
      });
    });

   
  }, []);

  return (
    <div>

      <div>
        <div id = "tabbyCard"></div>
        
      </div>
    </div>
  );
};

export default Tabbycard;
