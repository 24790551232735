import ClearIcon from '@mui/icons-material/Clear';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import AccountSidebar from '../components/AccountSidebar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import dummyimg from '../images/dummyimg.png';
import { useAddtocartMutation, useClearallwishlistMutation, useDeletewishlistMutation, useDisplayaddtowishlistQuery } from '../redux/api/productsApi';
import { setCartopen } from '../redux/features/opencardSlice';
import { showToast } from '../redux/features/toastSlice';


const WishList = () => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const [hoveredItem, setHoveredItem] = useState(null);
  const userid = useSelector((state) => state.auth?.user?.id);
  const dispatch = useDispatch();
  const [productDetails, setProductDetails] = useState([]);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
    
  const handleMouseEnter = (index) => {
    setHoveredItem(index);
  };
    
  const handleMouseLeave = () => {
    setHoveredItem(null);
  };
    
  const isItemHovered = (index) => {
    return hoveredItem === index;
  };

  const {error, data} = useDisplayaddtowishlistQuery(userid, {skip: !userid});

  useEffect(() => {
    if (error) {
      dispatch(showToast(error?.data?.message));
    }
  }, [error]);

  const [addtocart, { error: addtocarterror, isSuccess }] = useAddtocartMutation();

  useEffect(() => {
    if (addtocarterror) {
      dispatch(showToast(addtocarterror?.data?.message));
    }

    if (isSuccess) {
      dispatch(setCartopen(true));
    }
  }, [addtocarterror, isSuccess, dispatch]);

  const [addtocartmodal, setAddtocartmodal] = useState(false);

  const onOpenAddtocartmodal = (product) => {
    setProductDetails(product);
    setAddtocartmodal(true);
    setIsOutOfStock(false);
  };

  const onCloseAddtocartmodal = () => {
    setAddtocartmodal(false);
    setSelectedSize('');
  };

  const [selectedSize, setSelectedSize] = useState('');

  const handleSize = (option) => {

    setSelectedSize(option?.size);
    setIsOutOfStock(option?.stock === 0 || option?.stock === null);

    if(option?.size && option?.stock > 0 ){
      if(userid){
        const data = {
          'stock_options_id': option?.id,
          'quantity':1
        };
        addtocart([data]);
        setAddtocartmodal(false);
        setSelectedSize('');
      }
    }
   
  };

  const [deletewishlist, { error: ErrorWishlist }] = useDeletewishlistMutation();

  const handleDeleteWishlist = (productid) => {
    const data = {
      'product_id': productid
    };

    deletewishlist(data);
  };

  useEffect(() => {
    if (ErrorWishlist) {
      dispatch(showToast(ErrorWishlist?.data?.message));
    }
  }, [ErrorWishlist]);

  const [clearallwishlist, { error: Errorclearwishlist }] = useClearallwishlistMutation();

  const handleClearWishlist = () => {
    clearallwishlist();
  };

  useEffect(() => {
    if (Errorclearwishlist) {
      dispatch(showToast(Errorclearwishlist?.data?.message));
    }
  }, [Errorclearwishlist]);



  return (
    <>
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = { `text-white text-xl lg:text-2xl text-center font-semibold uppercase ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>{ t('Wishlist') }</h3>
      </div>
      <div className = 'container mx-auto px-5 lg:px-0'>
        <div className = 'block md:flex'>
          <div className = 'w-full md:w-1/5'>
            <AccountSidebar />
          </div>
          <div className = 'w-full mt-5 md:mt-0 md:w-4/5 xl:flex-1 border border-boderColor-300 rounded'>
            <div className = 'p-2 md:p-5'>
              <div className = 'flex justify-between'>
                <div className = 'self-center'>
                  <h3 className = { `text-black text-sm md:text-lg ${selectLanguage == 'ar' ? 'font-Tajawal' : ''}` }>{ t('MYWISHLIST') }</h3>
                </div>
                <div className = 'self-center'>
                  {
                    data?.data?.length > 1 && <button onClick = { handleClearWishlist } className = { `bg-primary text-sm inline-block py-2 px-4 rounded-full text-white ${selectLanguage == 'ar' ? 'font-Tajawal' : ''}` }><DeleteIcon style = { {fontSize: '20px'} } /> { t('Clearallwishlist') }</button>
                  }
                </div>
              </div>
              <div className = 'flex gap-1 md:gap-3 flex-wrap'>
                {
                  data?.data?.length === 0 && <p className = 'text-sm mt-5'>{ t('Nowishlistfound') }</p>
                }
                { data?.data?.map((product, index) => (
                  <div key = { index } className = 'relative w-width48% lg:w-width32% h-fit transition duration-300 transform mt-5' onMouseEnter = { () => handleMouseEnter(index) } onMouseLeave = { handleMouseLeave }>
                    <div className = 'text-grey absolute top-0 right-0 z-50 cursor-pointer' onClick = { () => handleDeleteWishlist(product?.product_id) }><ClearIcon style = { {fontSize: '20px'} } /></div>
                    <Link to = { `/product/${product?.product_id}` }>
                      <img
                        src = { product?.images?.[0] ? product?.images?.[0] : dummyimg }
                        alt = { product?.name }
                        className = "w-full h-2/4 object-cover"
                      />
                    </Link>
                    <div className = "p-0 md:p-4">
                      <Link to = { `/brand/shop/${product?.brand}` } className = 'text-primary block text-sm hover:text-primary hover:underline delay-200'>{ product?.brand }</Link>
                      <Link to = { `/product/${product?.product_id}` } className = { `text-gray-800 block text-xs md:text-sm hover:text-primary delay-200 ${selectLanguage == 'ar'?'font-Tajawal': 'font-Tajawal'}` }>{ product?.name }</Link>
                      <p className = 'hidden md:block'>
                        <StarRatings
                          rating = { product?.rating }
                          starRatedColor = "#ffb829"
                          numberOfStars = { 5 }
                          name = 'rating'
                          starDimension = '20px'
                          starSpacing = '1px'
                        />
                        <span className = 'text-sm align-middle inline-block ml-2'>({ product?.reviews_and_ratings?.total_reviews ? product?.reviews_and_ratings?.total_reviews : 0 })</span>
                      </p>
                      <span className = "text-sm text-primary pt-2">{ `SAR ${product?.options?.[0]?.selling_price}` }</span>
                      <div className = { `mt-4 flex justify-center items-center ${isItemHovered(index) ? 'flex space-x-2 absolute top-0' : 'hidden'}` }>
                        <Tooltip title = "Add to Cart">
                          <button onClick = { () => onOpenAddtocartmodal(product) } className = "shadow-custom bg-white text-black w-12 h-12 rounded-full hover:bg-primary hover:text-white transition duration-300">
                            <LocalMallOutlinedIcon />
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                )) }
              </div>
            </div>
          </div>
        </div>

        <Modal
          open = { addtocartmodal }
          showCloseIcon = { false }
          onClose = { onCloseAddtocartmodal }
        >
          <div className = "addtocartmodal">
            <div className = "flex justify-between bg-primary px-4 py-3">
              <h3 className = "text-white text-base font-semibold self-center">
            Select size
              </h3>
              <div className = "self-center">
                <span
                  onClick = { onCloseAddtocartmodal }
                  className = "cursor-pointer text-white"
                >
                  <CloseOutlinedIcon style = { { fontSize: '20px' } } />
                </span>
              </div>
            </div>
            <div className = "flex-col items-center py-4 md:py-8 px-4 md:px-8 text-center">
              <div className = "text-start">
                <ul className = 'flex flex-wrap gap-2 md:gap-4 mt-2'>
              
                  { productDetails?.options?.map((option) => (
                    <li
                      key = { option.id }
                      className = { `flex px-2 py-1.5 w-full md:w-width32% cursor-pointer bg-white rounded ${
                        selectedSize === option.size ? 'border-2 border-primary' : 'border border-borderColor'
                      }` }
                      onClick = { () => handleSize(option) }
                    >
                      <img className = 'w-8' src = { productDetails?.images[0] } alt = 'productsamll' />
                      <p className = { `text-sm text-center text-black self-center font-medium ${selectLanguage == 'ar' ? 'font-Tajawal' : ''}` }>
                        { option.size }
                      </p>
                    </li>
                  )) }
                </ul>
                {
                  isOutOfStock && <p className = 'text-sm text-red font-semibold mt-2'>Out Of Stock</p>
                }
              </div>
            </div>
          </div>
        </Modal>
        <Footer />
      </div>
    </>
  );
};

export default WishList;
