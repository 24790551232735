/* eslint-disable react/no-unescaped-entities */
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigationlistQuery } from '../redux/api/recomendedproductApi';
import { showToast } from '../redux/features/toastSlice';
import BrandDropdown from './BrandDropdown';
import GiftsandcardsDropdown from './GiftsandcardsDropdown';
import ShopDropdown from './ShopDropdown';

const Navigation = () => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const dispatch = useDispatch();

  const [isHoveredgift, setIsHoveredgift] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false); 
  const [isHoveredshop, setIsHoveredshop] = useState(false);
  // const [isHoveredSaleandOffers, setIsHoveredSaleandOffers] = useState(false);

  const {error, data} = useNavigationlistQuery();

  useEffect(() => {
 
    if(error){
      dispatch(showToast(error?.data?.message));
    }

  },[error]);

  const menudata = data?.data;

  const reversedMenudata = (menudata ?? []).slice().reverse();
  const firstTwoItemsmenu = reversedMenudata.slice(0, 2);
  const remainingItemsmenu = reversedMenudata.slice(2);

  return(
    <>
      <div className = 'relative  hidden lg:block'>
        <div className = 'container border-b border-boderColor-300 mx-auto px-5 lg:px-0'>
          <ul className = { `m-0 p-0 list-none flex justify-between uppercase ${selectLanguage == 'ar'?'flex-row-reverse' : 'flex-row'}` }>
            <li onMouseEnter = { () => setIsHovered2(true) } onMouseLeave = { () => setIsHovered2(false) }>
              <Link to = "/all-brand" className = { `text-sm font-normal text-blacklight inline-block py-2 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('BRANDS') }</Link>
              <BrandDropdown isHovered2 = { isHovered2 } />
              <KeyboardArrowDownOutlinedIcon style = { { fontSize: '16px', color: '#bbbbbb' } } />
            </li>

            {
              firstTwoItemsmenu?.map((item) => {
                const itemUrl = item?.url.startsWith('http') ? item.url : `/shop/${item?.url}`;
                return(
                  <li key = { item?.id }>
                    <Link to = { itemUrl } className = { `text-sm font-normal text-blacklight inline-block py-2 hover:underline ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ selectLanguage == 'ar'?item?.name_in_arabic: item?.name_in_english }</Link>
                  </li>
                );
              })
            }
           
            <li onMouseEnter = { () => setIsHoveredshop(true) } onMouseLeave = { () => setIsHoveredshop(false) }>
              <Link to = "/" className = { `text-sm font-normal text-blacklight inline-block py-2 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('FRAGRANCE') }</Link>
              <ShopDropdown isHoveredshop = { isHoveredshop } setIsHoveredshop = { setIsHoveredshop } />
              <KeyboardArrowDownOutlinedIcon style = { { fontSize: '16px', color: '#bbbbbb' } } />
            </li>
            {
              remainingItemsmenu?.map((item) => {
                const itemUrl = item?.url.startsWith('http') ? item.url : `/shop/${item?.url}`;
                return(
                  <li key = { item?.id }>
                    <Link to = { itemUrl } className = { `text-sm font-normal text-blacklight inline-block py-2 hover:underline ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ selectLanguage == 'ar'?item?.name_in_arabic: item?.name_in_english }</Link>
                  </li>
                );
              })
            }
            <li className = 'duration-150' onMouseEnter = { () => setIsHoveredgift(true) } onMouseLeave = { () => setIsHoveredgift(false) }>
              <Link to = "/" className = { `text-sm font-normal text-blacklight inline-block py-2 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('GIFTS') }</Link>
              <GiftsandcardsDropdown isHoveredgift = { isHoveredgift } />
              <KeyboardArrowDownOutlinedIcon style = { { fontSize: '16px', color: '#bbbbbb' } } />
            </li>
            
            { /* <li onMouseEnter = { () => setIsHoveredSaleandOffers(true) } onMouseLeave = { () => setIsHoveredSaleandOffers(false) }>
              <Link to = "/" className = { `text-sm font-normal text-blacklight inline-block py-2 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('SALE&OFFERS') }</Link>
              <SaleandOffersDropdown isHoveredSaleandOffers = { isHoveredSaleandOffers } />
              <KeyboardArrowDownOutlinedIcon style = { { fontSize: '16px', color: '#bbbbbb' } } />
            </li> */ }
          </ul>
          
        </div>
      </div>

      <div className = 'container mx-auto px-5 lg:px-0'>
        <div className = 'w-full border-b border-boderColor-300 block lg:hidden'></div>
      </div>
      
    </>
  );
};

export default Navigation;