/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Link, useNavigate } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dummyimg from '../../images/dummyimg.png';
import { useAddtowishlistMutation } from '../../redux/api/productsApi';
// import { setCartopen } from '../../redux/features/opencardSlice';

const ShopProduct = ({ product }) => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredImage, setHoveredImage] = useState(product?.images[0]); // Add state for hovered image
  const [open, setOpen] = useState(false);
  const userid = useSelector((state) => state.auth?.user?.id);
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMainMouseEnter = (imageUrl) => {
    setIsHovered(true);
    setHoveredImage(imageUrl); // Update hovered image source
  };

  const handleMainMouseLeave = () => {
    setIsHovered(false);
    setHoveredImage(product?.images[0]); // Reset to default image on mouse leave
  };

  const handleMouseEnter = (imageUrl) => {
    setHoveredImage(imageUrl); // Update hovered image source
  };


  const handleMouseLeave = () => {
    setHoveredImage(product?.images[0]); // Reset to default image on mouse leave
  };


  // const [addtocart, {error, isSuccess, isLoading }] = useAddtocartMutation();

  // useEffect(() => {

  //   if(error){
  //     toast(error?.data?.message);
  //   }

  //   if(isSuccess){
  //     dispatch(setCartopen(true));
  //     setOpen(false);
  //   }
    
  // },[error, isSuccess,dispatch]);

  // const handleAddtoCart = (productid) => {

  //   if(userid){
  //     const data = {
  //       'stock_options_id': productid,
  //       'quantity':1
  //     };
  
  //     addtocart(data);
  //   } else{
  //     navigate('/login');
  //   }

   
  // };


  const [addtowishlist, {error: erroraddtowishlist, data: dataaddtowishlist, isSuccess: issuccessaddtowishlist }] = useAddtowishlistMutation();


  const handleAddtowishlist = (productid) => {

    if(userid){
      const data = {
        'product_id': productid
      };
      addtowishlist(data);
    }else{
      navigate('/login');
    }
  };

  useEffect(() => {

    if(erroraddtowishlist){
      toast(erroraddtowishlist?.data?.message);
    } 
    
    if(issuccessaddtowishlist){
      navigate('/wishlist');
    }
  },[erroraddtowishlist, dataaddtowishlist,issuccessaddtowishlist]);


  return (
    <>
      <ToastContainer />
      <div
        className = "relative w-width48% md:w-width32% bg-white mt-3"
        onMouseEnter = { () => handleMainMouseEnter(product.images[1]?product.images[1]:product.images[0]) }
        onMouseLeave = { handleMainMouseLeave }
      >
        <Link to = { `/product/${product?.id}` } className = 'block w-full'>
          <img
            src = { hoveredImage?hoveredImage: dummyimg }
            alt = { product?.name }
            className = "w-full h-full"
          />
        </Link>
        <div>
          <Link to = { `/brand/shop/${product?.brand?.name}` } className = 'text-primary block text-sm hover:text-primary hover:underline delay-200'>{ product?.brand?.name?product?.brand?.name: 'NA' }</Link>
          <Link to = { `/product/${product?.id}` } className = { `text-gray-800 block text-xs md:text-sm hover:text-primary delay-200 ${selectLanguage == 'ar'?'font-Tajawal': 'font-Tajawal'}` }>{ product?.name }</Link>
          <p className = "text-sm text-primary">{ `SAR ${product?.selling_price}` }</p>
          <p className = 'hidden md:block'>
            <StarRatings
              rating = { product?.reviews?.average_rating ? product.reviews.average_rating : 0 }
              starRatedColor = "#ffb829"
              numberOfStars = { 5 }
              name = 'rating'
              starDimension = '20px'
              starSpacing = '1px'
            />
            <span className = 'text-sm align-middle inline-block ml-2'>({ product?.reviews?.review_count })</span>
          </p>
          <p className = 'block md:hidden'>
            <StarRatings
              rating = { product?.reviews?.average_rating ? product.reviews.average_rating : 0 }
              starRatedColor = "#ffb829"
              numberOfStars = { 5 }
              name = 'rating'
              starDimension = '16px'
              starSpacing = '1px'
            />
            <span className = 'text-sm align-middle inline-block ml-2'>({ product?.reviews?.review_count })</span>
          </p>
          <div className = 'flex gap-2 mt-2'>
            { product.images.length > 0? product.images.slice(0, 4).map((image, index) => (
              <div key = { index } className = 'w-8 h-8 md:w-10 md:h-10'>
                <img
                  className = 'w-full h-full border border-borderColor rounded-full'
                  src = { image }
                  alt = { 'smallproduct' }
                  onMouseEnter = { () => handleMouseEnter(image) }
                  onMouseLeave = { handleMouseLeave }
                />
              </div>
            )):<div  className = 'w-8 h-8 md:w-10 md:h-10'>
              <img
                className = 'w-full h-full border border-borderColor rounded-full'
                src = { dummyimg }
                alt = { 'smallproduct' }
                onMouseEnter = { () => handleMouseEnter(dummyimg) }
                onMouseLeave = { handleMouseLeave }
              />
            </div> }
          </div>
          
          { isHovered && (
            <div className = "mt-4 flex justify-between items-center space-x-2 absolute top-0">
              { /* <Tooltip title = "Add to Cart">
                <button onClick = { () => handleAddtoCart(product?.id) } className = "shadow-custom bg-white text-black w-8 h-8 md:w-10 md:h-10 rounded-full hover:bg-primary hover:text-white transition duration-300">
                  <LocalMallOutlinedIcon style = { { fontSize: '20px' } } />
                </button>
              </Tooltip>
              <Tooltip title = "Quick View">
                <button onClick = { () => setOpen(true) } className = "shadow-custom bg-white text-black w-8 h-8 md:w-10 md:h-10 rounded-full hover:bg-primary hover:text-white transition duration-300">
                  <VisibilityOutlinedIcon style = { { fontSize: '20px' } } />
                </button>
              </Tooltip> */ }
              <Tooltip title = "Add to Wishlist">
                <button onClick = { () => handleAddtowishlist(product?.id) } className = "shadow-custom bg-white text-black w-8 h-8 md:w-10 md:h-10 rounded-full hover:bg-primary hover:text-white transition duration-300">
                  <FavoriteBorderOutlinedIcon style = { { fontSize: '20px' } } />
                </button>
              </Tooltip>
            </div>
          ) }
        </div>
      </div>

      <Modal open = { open } onClose = { () => setOpen(false) } center>
        <div className = 'w-full block md:flex p-5'>
          <div className = 'w-64 self-center mb-5 md:mb-0 mr-0 md:mr-8'>
            <img className = 'w-full' src = { product.images[0] } alt = 'product' />
            <div className = 'w-full'>
              <Link to = { `/product/${product?.id}` } className = { `block bg-white text-primary border border-primary uppercase text-center py-2 text-sm delay-300 hover:bg-primary hover:text-white hover:delay-300 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('VIEWDETAILS') }</Link>
            </div>
          </div>
          <div className = 'self-center'>
            <h3 className = { `text-lg lg:text-xl text-black font-semibold ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ product?.name }</h3>
            <p className = 'text-base md:text-lg mt-5 font-bold text-primary pb-3'>SAR { product?.selling_price }</p>
            
            <p className = 'text-base text-grey mt-3'>SKU: { product?.sku }</p>
            { /* <button onClick = { () => handleAddtoCart(product?.id) } className = { `bg-primary w-full rounded-full text-white text-sm uppercase py-2 font-bold my-5 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ isLoading ? 'Adding...' : t('ADDTOBAG') }</button> */ }
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShopProduct;
