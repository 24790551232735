/* eslint-disable react/no-unescaped-entities */
import { Dialog, Transition } from '@headlessui/react';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TuneIcon from '@mui/icons-material/Tune';
import React, { Fragment, useState } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import TopHeader from '../../components/TopHeader';
import CandlehomeCollections from './CandlehomeCollections';

const CandlesndhomeScents = () => {
 
  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenfilter = () => {
    setOpenFilter(true);
  };


  const [isChecked, setIsChecked] = useState(false);

  const toggleSwitch = () => {
    setIsChecked(prevState => !prevState);
  };

  const [isSaveChecked, setIsSaveChecked] = useState(false);

  const toggleSwitchSave = () => {
    setIsSaveChecked(prevState => !prevState);
  };

  const [openSort, setOpenSort] = useState(false);

  const handleOpensort = () => {
    setOpenSort(true);
  };

  return(
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = "container mx-auto px-5 lg:px-10">
        <div className = 'relative'>
          <div className = 'text-center'>
            <img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:1440/ZnJvbnRiaXJkMi9pbWFnZXMvaGVyb0JhY2tncm91bmREZXNrdG9wXzExZjA2OC5qcGc=' alt = 'bannerimg' />
          </div>
          <h3 className = 'text-3xl txt-black font-bold uppercase absolute bottom-5 left-5'>CANDLES & HOME SCENTS</h3>
        </div>
        <div>
          <div className = 'flex justify-between mt-8'>
            <div>
              <h3 className = 'text-lg font-semibold text-primary'>Browsing 8 fragrances</h3>
            </div>
            <div>
              <button onClick = { handleOpenfilter } className = 'text-base font-semibold text-blacklight border border-borderColor px-5 py-2 rounded'>Filter  <span className = 'inline-block ml-16 rounded'><TuneIcon style = { {fontSize: '20px'} } /></span></button>
              <button onClick = { handleOpensort } className = 'text-base font-semibold text-blacklight border border-borderColor px-5 py-2 rounded inline-block ml-2'>Sort  <span className = 'inline-block ml-16 rounded'><ExpandMoreIcon style = { {fontSize: '20px'} } /></span></button>
            </div>
          </div>
          <CandlehomeCollections />
        </div>
        <Footer />

        <Transition.Root show = { openFilter } as = { Fragment }>
          <Dialog as = "div" className = "relative z-50" onClose = { setOpenFilter }>
            <Transition.Child
              as = { Fragment }
              enter = "ease-in-out duration-500"
              enterFrom = "opacity-0"
              enterTo = "opacity-100"
              leave = "ease-in-out duration-500"
              leaveFrom = "opacity-100"
              leaveTo = "opacity-0"
            >
              <div className = "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className = "fixed inset-0 overflow-hidden">
              <div className = "absolute inset-0 overflow-hidden">
                <div className = "pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <Transition.Child
                    as = { Fragment }
                    enter = "transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom = "translate-x-full"
                    enterTo = "translate-x-0"
                    leave = "transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom = "translate-x-0"
                    leaveTo = "translate-x-full"
                  >
                    <Dialog.Panel className = "pointer-events-auto w-screen max-w-md">
                      <div className = "flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className = "flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                          <div className = "flex items-start justify-between">
                            <Dialog.Title className = "text-lg font-medium text-gray-900">Filters</Dialog.Title>
                            <div className = "ml-3 flex h-7 items-center">
                              <button 
                                type = "button"
                                className = "relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                                onClick = { () => setOpenFilter(false) }
                              >
                                <span className = "absolute -inset-0.5" />
                                <span className = "sr-only">Close panel</span>
                                <CloseIcon />
                              </button>
                            </div>
                          </div>

                          <div className = "mt-8">
                            <div className = "flow-root bg-sky p-5">
                              <ul>
                                <li>
                                  <div className = 'flex justify-between'>
                                    <label className = 'text-sm font-bold text-blue cursor-pointer' htmlFor = "switch">GIFT WITH PURCHASE</label>
                                    <div className = "switch-container">
                                      <input
                                        type = "checkbox"
                                        checked = { isChecked }
                                        onChange = { toggleSwitch }
                                        id = "switch"
                                      />
                                      <label htmlFor = "switch" className = "switch-label">
                                        <span className = "switch-inner"></span>
                                        <span className = "switch-switch"></span>
                                      </label>
                                    </div>
                                  </div>
                                </li>
                                <li className = 'mt-5'>
                                  <div className = 'flex justify-between'>
                                    <label className = 'text-sm font-bold text-blue cursor-pointer' htmlFor = "switchsave">SALE! UP TO 50% OFF</label>
                                    <div className = "switch-container">
                                      <input
                                        type = "checkbox"
                                        checked = { isSaveChecked }
                                        onChange = { toggleSwitchSave }
                                        id = "switchsave"
                                      />
                                      <label htmlFor = "switchsave" className = "switch-label">
                                        <span className = "switch-inner"></span>
                                        <span className = "switch-switch"></span>
                                      </label>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          
                          </div>
                        </div>
                        <div className = "border-t border-gray-200 px-4 py-6 sm:px-6">
                          <button
                            className = "flex items-center w-full justify-center rounded-md border border-transparent bg-primary px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                          >
                            Show 0 product results
                          </button>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>


        { /* sort popup here */ }

        <Transition.Root show = { openSort } as = { Fragment }>
          <Dialog as = "div" className = "relative z-50" onClose = { setOpenSort }>
            <Transition.Child
              as = { Fragment }
              enter = "ease-in-out duration-500"
              enterFrom = "opacity-0"
              enterTo = "opacity-100"
              leave = "ease-in-out duration-500"
              leaveFrom = "opacity-100"
              leaveTo = "opacity-0"
            >
              <div className = "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className = "fixed inset-0 overflow-hidden">
              <div className = "absolute inset-0 overflow-hidden">
                <div className = "pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <Transition.Child
                    as = { Fragment }
                    enter = "transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom = "translate-x-full"
                    enterTo = "translate-x-0"
                    leave = "transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom = "translate-x-0"
                    leaveTo = "translate-x-full"
                  >
                    <Dialog.Panel className = "pointer-events-auto w-screen max-w-md">
                      <div className = "flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className = "flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                          <div className = "flex items-start justify-between">
                            <Dialog.Title className = "text-lg font-medium text-gray-900">Sort results</Dialog.Title>
                            <div className = "ml-3 flex h-7 items-center">
                              <button 
                                type = "button"
                                className = "relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                                onClick = { () => setOpenSort(false) }
                              >
                                <span className = "absolute -inset-0.5" />
                                <span className = "sr-only">Close panel</span>
                                <CloseIcon />
                              </button>
                            </div>
                          </div>

                          <div className = "mt-8">
                            <ul>
                              <li>
                                <p className = 'text-base text-blacklight cursor-pointer py-3'>By default</p>
                              </li>
                              <li>
                                <p className = 'text-base text-blacklight cursor-pointer py-3'>By price, low to high</p>
                              </li>
                              <li>
                                <p className = 'text-base text-blacklight cursor-pointer py-3'>By price, high to low</p>
                              </li>
                              <li>
                                <p className = 'text-base text-blacklight cursor-pointer py-3'>By rating, high to low</p>
                              </li>
                              <li>
                                <p className = 'text-base text-blacklight cursor-pointer py-3'>New arrivals, new to old</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className = "border-t border-gray-200 px-4 py-6 sm:px-6">
                          <button
                            className = "flex items-center w-full justify-center rounded-md border border-transparent bg-primary px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                          >
                            Sort
                          </button>
                          <button
                            className = "border border-borderColor text-black items-center w-full justify-center rounded-md bg-white px-6 py-3 text-base font-medium mt-3"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>  
    </>
  );
};

export default CandlesndhomeScents;