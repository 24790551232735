import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useBrandcollectionQuery } from '../redux/api/recomendedproductApi';
import { showToast } from '../redux/features/toastSlice';

const MobileBrandshopDropdown = () => {
  const selectLanguage = localStorage.getItem('selectLanguage');
  const dispatch = useDispatch();

  const [brands, setBrands] = useState([]);
  const {error: errorbrand, data:databrand} = useBrandcollectionQuery();

  useEffect(() => {

    if(errorbrand){
      dispatch(showToast(errorbrand?.data?.message));
    }

  },[errorbrand]);



  useEffect(() => {
    const brandsData = databrand?.data;

    // Remove duplicates by using a Set
    const uniqueBrands = brandsData ? Array.from(new Set(brandsData.map(brand => brand.name)))
      .map(name => {
        return brandsData.find(brand => brand.name === name);
      }) : [];

    setBrands(uniqueBrands);
  }, [databrand]);

  const groupedBrands = brands.reduce((acc, brand) => {
    const firstLetter = brand.name.charAt(0).toUpperCase();
    if (!acc[firstLetter]) acc[firstLetter] = [];
    acc[firstLetter].push(brand);
    return acc;
  }, {});


  return(
    <>
      <div>
        <Link to = "/all-brand" className = 'inline-block text-sm font-semibold text-blacklight mt-3 pb-3 duration-200 hover:text-primary hover:underline'>All brand</Link>
        <ul className = ' capitalize'>
          { Object.keys(groupedBrands).sort().map(letter => (
            <li key = { letter } className = 'mt-4'>
              <span className = 'inline-block bg-grey-200 text-lg px-1.5'>{ letter }</span>
              <div className = 'border-b border-grey-300'></div>
              <div className = 'flex gap-x-8 gap-y-3 flex-wrap mt-2'>
                { groupedBrands[letter].map(brand => (
                  <Link
                    key = { brand?.id }
                    to = { `/brand/shop/${brand?.name}` }
                    className = { `text-sm text-blacklight font-semibold duration-200 hover:text-primary hover:underline ${selectLanguage == 'ar'?'font-madani-arabic': 'font-madani-arabi'}` }
                  >
                    { brand?.name }
                  </Link>
                )) }
              </div>
            </li>
          )) }
        </ul>
      </div>
    </>
  );
};

export default MobileBrandshopDropdown;