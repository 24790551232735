import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../redux/api/authApi';
import { useAddtocartMutation } from '../redux/api/productsApi';
import { selectCartItems } from '../redux/features/cartSlice';
import { showToast } from '../redux/features/toastSlice';

const CheckoutpageLogin =() => {
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);
  const [productitem, setProductitem] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();


  const [addtocart, { isSuccess: addtocartSuccess, error: erroraddtocart }] = useAddtocartMutation();

  useEffect(() => {
    if (erroraddtocart) {
      dispatch(showToast(erroraddtocart?.data?.message || 'Error adding to cart'));
    }
  }, [erroraddtocart]);

  useEffect(() => {
    if (addtocartSuccess) {
      localStorage.removeItem('cartItems');
      setTimeout(() => {
        navigate('/shoping-cart');
      }, 1000);
    }
  }, [addtocartSuccess, navigate]);

  useEffect(() => {
    const productdata = cartItems.map(item => ({
      stock_options_id: item.options.id,
      quantity: item.quantity
    }));

    if (productdata.length > 0) {
      setProductitem(productdata);
    }
  }, [cartItems]);


  const handleEmail = (e) => {
    const trimmedEmail = e.target.value.trim();
    setEmail(trimmedEmail);
  
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(trimmedEmail);
  
    setErrorEmail(isValidEmail ? '' : '');
    setIsEmailValid(isValidEmail);
  };
  
  const handleEmailBlur = () => {
    if (!isEmailValid && email.trim()) {
      setErrorEmail('Please enter a valid email');
    }
  };
  

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const isFormValid = isEmailValid && password;


  const [ login, {error, data, isLoading, isSuccess} ] = useLoginMutation();

  useEffect(() => {

    if(isSuccess && token){
      dispatch(showToast(data?.message));
      addtocart(productitem);
    }
  },[ isSuccess, token]);

  useEffect(() => {

    if(error){
      dispatch(showToast(error?.data?.message));
    }
   
  },[error]);


  const LoginHandler = (e) => {
    e.preventDefault();
    const loginData = {
      email,
      password
    };

    login(loginData);
  };


  return(
    <>
      <form onSubmit = { LoginHandler }>
        <ul>
          <li >
            <label className = 'text-sm text-grey-500 lg:text-grey-800'>Email <span>*</span></label>
            <input className = { `block w-full border border-grey-300 rounded px-4 py-2.5 text-sm ${errorEmail?'outline-red':'outline-primary-600'}` } onChange = { handleEmail }  onBlur = { handleEmailBlur } value = { email } placeholder = 'Enter email' />
            { errorEmail && <p className = "text-xs text-red">{ errorEmail }</p> }
          </li>
          <li className = 'relative pt-6 lg:pt-4'>
            <label className = 'text-sm text-grey-500 lg:text-grey-800'>Password <span>*</span></label>
            <input
              onChange = { handlePasswordChange }
              value = { password }
              type = { showPassword ? 'text' : 'password' }
              className = 'block w-full border border-grey-300 rounded px-4 py-2.5 text-sm outline-primary-600'
              placeholder = 'Enter password'
            />
            { password && <a href = 'javascript:void(0)' className = 'block absolute bottom-2 right-3.5 w-4' onClick = { toggleShowPassword }>{ showPassword? <VisibilityOffOutlinedIcon className = 'eyecolor' /> : <VisibilityOutlinedIcon className = 'eyecolor' /> }</a> }
          </li>
          <li><div className = 'text-right pt-1 pb-4'><Link className = 'text-xs text-primary-200 font-bold' to = "/forgot-password">Forgot Password</Link></div></li>
          <li>
            <button className = { `block w-full text-white rounded-3xl lg:rounded px-4 py-2.5 text-sm uppercase ${isFormValid? 'bg-gradient-primary': 'bg-gradient-primary-light'}` } disabled = { !isFormValid }>
              { isLoading?'Authenticating...' : 'Login' }
            </button>
          </li>
        </ul>
      </form>
    </>
  );
};

export default CheckoutpageLogin;