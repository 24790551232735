import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import React, { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import amex from '../images/amex.svg';
import cash from '../images/cash.svg';
import dummyimg from '../images/dummyimg.png';
import tamara from '../images/icon-256x256.png';
import master from '../images/mastercard.svg';
import tabby from '../images/tabby-logo.svg';
import visa from '../images/visa.svg';
import voucher from '../images/voucher.svg';

import { useAddtocardlistQuery, useAddtocartMutation, useDeletecartitemMutation } from '../redux/api/productsApi';
import { useCheckoutpriceMutation } from '../redux/api/userApi';
import { addItemToCart, decreaseItemQuantity, removeItemFromCart, selectCartItems } from '../redux/features/cartSlice';
import { showToast } from '../redux/features/toastSlice';

const ShopingCard = () => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const userid = useSelector((state) => state.auth?.user?.id);
  const [expandedIndex, setExpandedIndex] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checkoutpricedata, setCheckoutpricedata] = useState();
  console.log('checkoutpricedata',checkoutpricedata);
  const [discountCoupon, setDiscountCoupon] = useState('');

  const cartItems = useSelector(selectCartItems);

  const { error, data } = useAddtocardlistQuery(userid, {skip: !userid});
  useEffect(() => {
    if (error) {
      dispatch(showToast(error?.data?.message));
    }
  }, [error]);

  const [addtocart, {error: erroraddtocart }] = useAddtocartMutation();

  useEffect(() => {

    if(erroraddtocart){
      dispatch(showToast(erroraddtocart?.data?.message));
    }
    
  },[erroraddtocart]);

  const handleAddtoCart = (option) => {
    if (userid) {
      const data = {
        'stock_options_id': option?.id
      };
  
      addtocart([data]);
    } else {
      const existingItem = cartItems.find(item => item.options.id === option?.id);
  
      if (existingItem) {
        dispatch(addItemToCart(existingItem));
      } 
    }
  };
  

  const [deletecart,{error: deletecarterror}] = useDeletecartitemMutation();

  useEffect(() => {
    if(deletecarterror){
      dispatch(showToast(error?.data?.message));
    }

  },[deletecarterror]);

  const deleteaddtocart = (id, optionsId) => {
    if (userid) {
      const data = { 'id': id };
      deletecart(data);
    } else {
      dispatch(decreaseItemQuantity({ id, options: { id: optionsId } }));
    }
  };

  const removeCartItemHandler = (id, optionsId) => {
    if(userid){
      const data = {
        id: id,
        quantity: 0
      };
      deletecart(data);

    }else{
      dispatch(removeItemFromCart({ id, options: { id: optionsId } }));
    }

  };

  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const calculateSubtotal = () => {
    return cartItems.reduce(
      (acc, item) => acc + (item?.options?.selling_price * item?.quantity),
      0
    ) || 0;
   
  };

  
  const [checkoutprice, {error: checkouterror, data: checkoutdata, isSuccess: CheckoutpriceisSuccess}] = useCheckoutpriceMutation();

  
  useEffect(() => {
    if (checkouterror) {
      dispatch(showToast(checkouterror?.data?.message));
    }
  }, [checkouterror]);

  useEffect(() => {
    if (data?.data?.length === 0 || !data) {
      setCheckoutpricedata(0);
    } else {
      const productdata = {
        products: data?.data?.map(item => ({
          product_id: item.options.id,
          quantity: item.quantity
        }))
      };
      checkoutprice(productdata);
    }
  }, [data]);

  useEffect(() => {
    if (CheckoutpriceisSuccess) {
      setCheckoutpricedata(checkoutdata?.data);
    }
  }, [CheckoutpriceisSuccess, checkoutdata]);

  const handleDiscountCoupon = (e) => {

    e.preventDefault();
    const productdata = {
      products: data?.data?.map(item => ({
        product_id: item.options.id,
        quantity: item.quantity
      })),
      coupon_code: discountCoupon
    };

    checkoutprice(productdata);

  };


  const validForm = discountCoupon?.length > 0;

  const itemCount = userid ? data?.data.length : cartItems.length;
  const cartProduct = userid ? data?.data : cartItems;

  const handleCheckoutLogin = () => {
    navigate('/checkout-login');
  };

  const handleToggle = () => {
    setExpandedIndex(!expandedIndex);
  };

  const handleshipping = () => {
    navigate('/shipping-details');
  };


  return (
    <>
      <Header />
      <Navigation />
      
      <div className = 'container mx-auto px-5 lg:px-0'>
        <div className = 'flex flex-wrap lg:flex-nowrap pt-6'>
          <div className = 'flex-1 md:hidden'>
            <ul>
              { itemCount === 0 ? (
                <><p className = { `${selectLanguage == 'ar'?'font-Tajawal': ''}` }>{ t('Yourcartisempty') }</p></>
              ) : (
                cartProduct?.map((product) => (
                  <li key = { product?.id } className = 'mb-5 pb-5 border-b border-grey-400'>
                    <div className = 'flex justify-between'>
                      <div className = 'mr-4'>
                        <span className = 'cursor-pointer text-grey' onClick = { () => removeCartItemHandler(product?.id, product?.options?.id) }>
                          <CloseIcon style = { { fontSize: '20px' } } />
                        </span>
                        <div className = 'w-28'><img className = 'w-full self-center' src = { userid ? (product?.product?.images[0]?product?.product?.images[0]:dummyimg): cartItems?.length > 0 && product?.images[0]?product?.images[0]:dummyimg } alt = 'product' /></div>
                      </div>
                      <div>
                        <Link to = { `/product/${userid?product?.product?.id: product?.id}` } className = 'text-sm font-bold font-Tajawal'>{ userid?product?.product?.name: product?.name }</Link>
                        <ul>
                          <li className = 'mt-3 border-b border-grey-400'>
                            <div className = 'flex justify-between'>
                              <div className = 'self-center mb-2'>
                                <p className = { `text-sm text-black ${selectLanguage == 'ar'?'font-Tajawal': ''}` }>{ t('Price') }</p>
                              </div>
                              <div className = 'self-center'>
                                <p className = 'text-sm text-grey'>{ product?.options?.selling_price } SAR</p>
                              </div>
                            </div>
                          </li>
                          <li className = 'mt-3 border-b border-grey-400'>
                            <div className = 'flex justify-between mb-2'>
                              <div className = 'self-center'>
                                <p className = { `text-sm text-black ${selectLanguage == 'ar'?'font-Tajawal': ''}` }>{ t('Quantity') }</p>
                              </div>
                              <div>
                                <button className = 'inline-block px-2' onClick = { () => deleteaddtocart(product?.id, product?.options?.id) }>
                                  <RemoveOutlinedIcon style = { { fontSize: '16px' } } />
                                </button>
                                <span className = 'inline-block text-base count border-none outline-0'>{ product.quantity }</span>
                                <button className = 'inline-block px-2' onClick = { () => handleAddtoCart(product?.options) } disabled = { product?.quantity === product?.options?.stock }>
                                  <AddOutlinedIcon style = { { fontSize: '16px' } } />
                                </button>
                              </div>
                            </div>
                          </li>
                          <li className = 'mt-3'>
                            <div className = 'flex justify-between'>
                              <div className = 'self-center'>
                                <p className = { `text-sm text-black ${selectLanguage == 'ar'?'font-Tajawal': ''}` }>{ t('Subtotal') }</p>
                              </div>
                              <div className = 'self-center'>
                                <p>{ userid?product?.total_price: product?.options?.selling_price * product?.quantity } SAR</p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                ))
              ) }
            </ul>
            
          </div>
          <div className = 'flex-1 hidden md:block'>
            <ul className = "flex">
              <li><a className = "inline-block text-grey text-xs md:text-sm hover:text-primary " href = "/">Home</a></li>
              <li><a className = "inline-block text-grey text-xs md:text-sm ml-2" href = "/">/</a></li>
              <li><a className = "inline-block text-black text-xs md:text-sm ml-2 font-semibold" href = "#">Cart</a></li>
            </ul>
            <ul className = 'border border-grey-400 rounded-lg p-3 mt-3'>
              { itemCount === 0 ? (
                <><p className = { `${selectLanguage == 'ar'?'font-Tajawal': ''}` }>{ t('Yourcartisempty') }</p></>
              ) : (
                cartProduct?.map((product, index) => (
                  <li key = { index }  className = { `pb-3 ${index !== 0 ? 'border-t border-grey-400' : ''} pt-2` }>
                    <div className = 'flex justify-between'>
                      <div className = 'self-center mr-2'>
                        <span className = 'cursor-pointer text-grey' onClick = { () => removeCartItemHandler(product?.id, product?.options?.id) }>
                          <CloseIcon style = { { fontSize: '20px' } } />
                        </span>
                      </div>
                      <div className = 'flex self-center w-width86%'>
                        <Link className = 'block w-28' to = { `/product/${userid?product?.product?.id: product?.id}` }>
                          <img
                            className = "block w-full h-auto self-center object-cover"
                            src = { userid ? (product?.product?.images[0] ? product?.product?.images[0] : dummyimg) : product?.images[0] ? product?.images[0] : dummyimg }
                            alt = "product"
                          />
                        </Link>
                        <div className = 'flex-1 ml-5'>
                          <Link to = { `/brand/shop/${userid?product?.product?.brand: product?.brand}` } className = 'block text-sm self-center text-primary font-bold hover:text-underline font-Tajawal'>{ userid?product?.product?.brand: product?.brand }</Link>
                          <Link to = { `/product/${userid?product?.product?.id: product?.id}` } className = 'text-sm self-center hover:text-grey font-Tajawal'>{ userid?product?.product?.name: product?.name }</Link>
                          <p className = 'text-sm font-bold mt-2'>{ product?.options?.selling_price } SAR</p>
                          <div className = 'inline-flex py-1 px-2 border border-grey-400 mt-2'>
                            <button className = 'inline-block px-2' onClick = { () => deleteaddtocart(product?.id, product?.options?.id) }>
                              <RemoveOutlinedIcon style = { { fontSize: '16px' } } />
                            </button>
                            <span className = 'inline-block text-base count border-none outline-0'>{ product?.quantity }</span>
                            <button className = 'inline-block px-2' onClick = { () => handleAddtoCart(product?.options) } disabled = { product?.quantity === product?.options?.stock }>
                              <AddOutlinedIcon style = { { fontSize: '16px' } } />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className = 'flex self-end justify-end w-36'>
                        <div>
                          <h3 className = 'text-black text-base'>Subtotal</h3>
                          <p className = 'text-sm font-semibold'>{ userid?product?.total_price: product?.options?.selling_price * product?.quantity } SAR</p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              ) }
            </ul>
           
          </div>
          <div className = 'w-full lg:w-4/12 mt-8 lg:mt-2 ml-0 lg:ml-8'>
            <div className = 'border border-grey-400 '>
              <ul>
                <li className = 'border-b border-grey-400'>
                  <Collapsible
                    trigger = {
                      <div className = 'py-4 pl-4 pr-3 flex justify-between' onClick = { () => handleToggle(0) }>
                        <div className = 'flex self-center'>
                          <div><img className = 'w-6 mr-1' src = { voucher } alt = 'voucher' /></div>
                          <p className = 'text-sm font-semibold'> Got a coupon code?</p>
                        </div>
                        <div className = 'self-center'>
                          <span className = "text-grey block w-6 cursor-pointer text-center" onClick = { () => handleToggle(0) }>
                            { expandedIndex !== false ? (
                              <ExpandLessIcon style = { { fontSize: '24px' } } />
                            ) : (
                              <KeyboardArrowDownIcon style = { { fontSize: '24px' } } />
                            ) }
                          </span>
                        </div>
                      </div>
                    }
                    open = { expandedIndex === 0 }
                    onOpening = { () => setExpandedIndex(true) }
                    onClosing = { () => setExpandedIndex(false) }
                  >
                
                    <div className = 'pb-3'>
                      <form onSubmit = { handleDiscountCoupon }>
                        <div className = 'flex px-4'>
                          <div className = 'w-width66%'><input placeholder = { t('Couponcode') } value = { discountCoupon } onChange = { (e) => setDiscountCoupon(e.target.value) } className = { `h-9 border border-grey-400 block py-2 px-5 text-sm w-full outline-none ${selectLanguage == 'ar'?'font-Tajawal': ''}` } /></div>
                          <div className = 'w-width34%'><button className = { `block h-9 py-2 px-2 font-medium text-sm text-white delay-300 w-full hover:bg-primary-600 hover:delay-300 ${selectLanguage == 'ar'?'font-Tajawal': ''} ${validForm?'bg-primary': 'bg-primary-600'}` } disabled = { !validForm }>{ t('APPLY') }</button></div>
                        </div>
                      </form>
                    </div>
                  </Collapsible>
                  
                </li>
                <li className = 'bg-lightwhite py-4 px-4'>
                  <div className = 'flex justify-between pb-2'>
                    <p className = { `text-black text-sm italic ${selectLanguage == 'ar'?'font-Tajawal': ''}` }>{ t('Subtotal') }</p>
                    <p className = 'text-black text-sm italic'>{ userid?checkoutpricedata?.subtotal: calculateSubtotal() } SAR</p>
                  </div>
                  { /* <div className = 'flex justify-between pb-2'>
                    <p className = { `text-black text-sm italic ${selectLanguage == 'ar'?'font-Tajawal': ''}` }>Delivery</p>
                    <p className = 'text-black text-sm italic'>{ userid?checkoutpricedata?.shipping_charge: itemCount } SAR</p>
                  </div> */ }
                  <div className = 'flex justify-between pb-3'>
                    <p className = { `text-black text-sm italic ${selectLanguage == 'ar'?'font-Tajawal': ''}` }>Coupon discount</p>
                    <p className = 'text-black text-sm italic'>{ userid?checkoutpricedata?.coupon_discount: 0 } SAR</p>
                  </div>
                  <div className = 'flex justify-between pb-3'>
                    <p className = { `self-center text-black text-sm ${selectLanguage == 'ar'?'font-Tajawal': ''}` }>{ capitalizeFirstLetter(t('TOTAL')) } (Incl. 15% VAT)</p>
                    <p className = 'self-center text-sm text-black'>{ userid?checkoutpricedata?.total_amount: itemCount != 0? (calculateSubtotal() * 1.15).toFixed(3) : 0 } SAR</p>
                  </div>
                  {
                    userid?
                      <li className = 'mt-3'>
                        
                        <button to = '#' onClick = { handleshipping } disabled = { itemCount === 0?true: false } className = { `block w-full text-white bg-primary rounded-md py-3 text-sabe hover:bg-primary-600 font-medium hover:delay-300 text-center ${selectLanguage == 'ar'?'font-Tajawal': ''}` } > <span className = 'text-white'><LockOutlinedIcon style = { {fontSize: '18px'} } /></span> { t('PROCEEDTOCHECKOUT') }</button>
                      
                      </li>:
                      <li className = 'mt-6'>
                        <button onClick = { handleCheckoutLogin } disabled = { itemCount === 0?true: false } className = { `block w-full text-white bg-primary rounded-md font-medium py-3 text-sabe hover:bg-primary-600 hover:delay-300 text-center ${selectLanguage == 'ar'?'font-Tajawal': ''}` } ><span className = 'text-white'> <LockOutlinedIcon style = { {fontSize: '18px'} } /></span> { t('PROCEEDTOCHECKOUT') }</button>
                      </li>
                  }
                </li>
              </ul>
            </div>
            <div>
              <ul className = 'flex justify-center mt-5'>
                <li><img className = 'w-10' src = { visa } alt = 'visa' /></li>
                <li><img className = 'w-10' src = { master } alt = 'master' /></li>
                <li><img className = 'w-10' src = { cash } alt = 'cash' /></li>
                <li><img className = 'w-10' src = { amex } alt = 'cash' /></li>
                <li><img className = 'w-7' src = { tamara } alt = 'cash' /></li>
                <li><img className = 'w-16' src = { tabby } alt = 'cash' /></li>
              </ul>
              <p className = 'text-sm text-grey text-center mt-2'>Have questions? Contact us <Link to = "/contact-us" className = 'font-bold underline'>here</Link>.</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ShopingCard;