import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import bg from '../images/download.png';
import logo from '../images/logo.jpg';
import { useRegisterMutation } from '../redux/api/authApi';
import { showToast } from '../redux/features/toastSlice';

const SignUp = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('966');
  const [countryCode, setCountryCode] = useState('966');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
  const [phoneError, setPhoneError] = useState('');


  const navigate = useNavigate();

  const [register, { isLoading, error, data, isSuccess }] = useRegisterMutation();

  useEffect(() => {
    if (error) {
      if (error?.data?.message.email) {
        dispatch(showToast(error?.data?.message.email[0]));
      }
      if (error?.data?.message.phone) {
        dispatch(showToast(error?.data?.message.phone[0]));
      }
      if (typeof error?.data?.message === 'string') {
        dispatch(showToast(error?.data.message));
      }
    }

    if (isSuccess) {
      dispatch(showToast(data?.message));
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    }
  }, [error, data, isSuccess, navigate]);

  const handleRegister = (e) => {
    e.preventDefault();

    const registerdata = {
      name,
      phone: '+' + countryCode + phone,
      email,
      password
    };

    register(registerdata);
  };

  const handleEmail = (e) => {
    const trimmedEmail = e.target.value.trim();
    setEmail(trimmedEmail);
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(trimmedEmail);
  
    setErrorEmail(isValidEmail ? '' : '');
    setIsEmailValid(isValidEmail);
  };
  
  const handleEmailBlur = () => {
    if (!isEmailValid && email.trim()) {
      setErrorEmail('Please enter a valid email');
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setErrorConfirmPassword(
      newPassword === confirmPassword ? '' : 'Passwords do not match'
    );
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setErrorConfirmPassword(
      newConfirmPassword === password ? '' : 'Passwords do not match'
    );
  };

  const isFormValid = name && phone && isEmailValid && password && confirmPassword === password;

  useEffect(() => {
    document.title = 'Sign Up';
  }, []);

  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const arabCountries = [
    { name: 'Bahrain', code: 'bh', dialCode: '973' },
    { name: 'Cyprus', code: 'cy', dialCode: '357' },
    { name: 'Egypt', code: 'eg', dialCode: '20' },
    { name: 'Iran', code: 'ir', dialCode: '98' },
    { name: 'Iraq', code: 'iq', dialCode: '964' },
    { name: 'Jordan', code: 'jo', dialCode: '962' },
    { name: 'Kuwait', code: 'kw', dialCode: '965' },
    { name: 'Lebanon', code: 'lb', dialCode: '961' },
    { name: 'Oman', code: 'om', dialCode: '968' },
    { name: 'Palestine', code: 'ps', dialCode: '970' },
    { name: 'Qatar', code: 'qa', dialCode: '974' },
    { name: 'Saudi Arabia', code: 'sa', dialCode: '966' },
    { name: 'Syria', code: 'sy', dialCode: '963' },
    { name: 'Turkey', code: 'tr', dialCode: '90' },
    { name: 'United Arab Emirates', code: 'ae', dialCode: '971' },
    { name: 'Yemen', code: 'ye', dialCode: '967' }
  ];

  const handleCountryChange = (e) => {
    const selectedCountry = arabCountries.find(country => country.code === e.target.value);
    setCountryCode(selectedCountry ? selectedCountry.dialCode : '');
  };

  const handlePhoneInputChange = (e) => {
    const phoneValue = e.target.value.trim(); // Trim whitespace from the input
    setPhone(phoneValue);
    if (phoneValue.length === 10 || phoneValue.length === 9) {
      setPhoneError('');
    } else {
      setPhoneError('Phone number must be exactly 9 or 10 digits');
    }
  };

  return (
    <>

      <div className = 'flex justify-between min-h-screen'>     
        <div className = 'lg:bg-white box-border w-full md:w-width60% flex items-center'>
          <div className = 'w-full md:w-width60% m-3 md:m-6 md:mx-auto p-4 md:p-8 shadow-xl rounded-3xl'>
            <Link to = "/" className = 'block'>
              <img className = 'w-52 mx-auto' src = { logo } alt = 'logo'/>
            </Link> 
            <form onSubmit = { handleRegister }>
              <ul>
                <li className = 'pt-4 md:pt-2.5'>
                  <label className = { 'text-sm text-grey-500 lg:text-grey-800 ' }>Name</label>
                  <input className = "block w-full text-base border-none bg-inputcolor outline-primary-300 rounded-md px-4 py-2" onChange = { (e) => setName(e.target.value) } value = { name } placeholder = "Name" />          
                </li>
                <li className = 'pt-4 md:pt-2.5'>
                  <label className = { `text-sm text-grey-500 lg:text-grey-800 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>{ t('Phone') }</label>
                  <div className = "flex">
                    <select 
                      className = " border-none text-base focus:outline-none bg-inputcolor outline-primary-300 rounded-md px-4 py-2"
                      onChange = { handleCountryChange }
                      defaultValue = "sa"
                    >
                      { arabCountries.map(country => (
                        <option key = { country.code } value = { country.code }>
                               +{ country.dialCode }
                        </option>
                      )) }
                    </select>
                    <input
                      type = "text"
                      className = { `block w-full ml-2 border-none focus:outline-none text-base bg-inputcolor outline-primary-300 rounded-md px-4 py-2" ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }
                      onChange = { handlePhoneInputChange }
                      value = { phone }
                      placeholder = "Phone number"
                    />
                  </div>
                  { phoneError && <p className = "text-xs text-red mt-1">{ phoneError }</p> }       
                </li>
                <li className = 'pt-4 lg:pt-2.5'>
                  <label className = { 'text-sm text-grey-500 lg:text-grey-800 ' }>Email</label>
                  <input onChange = { handleEmail } onBlur = { handleEmailBlur } value = { email } className = { `block w-full border-none rounded-md px-4 py-2 text-base bg-inputcolor ${errorEmail ? 'outline-red' : 'outline-primary-300'}` } placeholder = "Email" />
                  { errorEmail && <p className = "text-xs text-red">{ errorEmail }</p> }     
                </li>
                <li className = 'relative pt-4 md:pt-2.5'>
                  <label className = { `text-sm text-grey-500 lg:text-grey-800 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>{ t('Password') }</label>
                  <input
                    onChange = { handlePasswordChange }
                    value = { password }
                    placeholder = "Password"
                    type = { showPassword ? 'text' : 'password' }
                    className = { 'block w-full focus:outline-none border-none rounded-md px-4 py-2 text-base bg-inputcolor outline-primary-300' }
                  />
                  <span className = 'cursor-pointer' onClick = { toggleShowPassword }>
                    { showPassword ? (
                      <VisibilityOutlinedIcon
                        className = { `text-grey absolute bottom-2 right-3 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }
                      />
                    ) : (
                      <VisibilityOffOutlinedIcon
                        className = { `text-grey absolute bottom-2 right-3 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }
                      />
                    ) }
                  </span>
                </li>
                <li className = 'relative pt-4 md:pt-2.5'>
                  <label className = { `text-sm text-grey-500 lg:text-grey-800 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>{ t('Confirm password') }</label>
                  <input
                    type = { showPassword ? 'text' : 'password' }
                    placeholder = "Confirm Password"
                    className = { 'block w-full text-base focus:outline-none border-none bg-inputcolor outline-primary-300 rounded-md px-4 py-2' }
                    value = { confirmPassword }
                    onChange = { handleConfirmPasswordChange }
                  />
                  <span className = 'cursor-pointer' onClick = { toggleShowPassword }>
                    { showPassword ? (
                      <VisibilityOutlinedIcon
                        className = { `text-grey absolute bottom-2 right-3 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }
                      />
                    ) : (
                      <VisibilityOffOutlinedIcon
                        className = { `text-grey absolute bottom-2 right-3 ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }
                      />
                    ) }
                  </span>
                      
                </li>
                <li>
                  { errorConfirmPassword && (
                    <p className = { `text-xs text-red ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>
                      { errorConfirmPassword }
                    </p>
                  ) }
                </li>
                <li className = 'flex items-center justify-center mt-8'>
                  <button
                    type = 'submit'
                    className = { 'block w-6/12 bg-inputcolor rounded-full lg:rounded-full font-medium px-4 py-2.5 text-sm ' }
                    disabled = { !isFormValid || isLoading }
                  >
                    { isLoading ? 'Creating...' : capitalizeFirstLetter(t('signup')) }
                  </button>
                </li>
              </ul>
            </form>
            <ul>
              <li className = 'flex items-center justify-center mb-3'>
                <p className = { 'text-sm text-grey text-center w-full py-2.5 ' }>{ 'You have an account?' } <Link className = 'text-primary font-bold' to = "/login">Signin</Link></p>
              </li>
              { /* <li className = 'flex items-center justify-center gap-3 mt-5'>
                <p className = 'w-20 bg-sky h-0.5'></p>
                <p className = 'text-xs text-grey'>other way to sign in</p>
                <p className = 'w-20 bg-sky h-0.5'></p>
              </li>
              <li className = 'flex-col flex justify-center items-center pt-4'>
                <div
                  className = 'text-center w-6/12 rounded-full bg-inputcolor px-1 py-2 '
                >
                  <Googleauthentic />
                </div>
              </li> */ }
            </ul>
          </div>
        </div>
         
        <div className = 'hidden md:block w-width40% bg-inputcolor'>
          <div className = 'w-full h-screen flex items-center ml-[-80px]'>
            <img src = { bg } alt = "" className = 'w-width60%' />
          </div>
        </div>

      </div>
    </>
  );
};

export default SignUp;