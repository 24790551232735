/* eslint-disable no-unused-vars */
import { useGoogleLogin } from '@react-oauth/google';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import googlelogo from '../images/googlelogo.svg';
import { useGoogleregisterMutation } from '../redux/api/authApi';
import { useAddtocartMutation } from '../redux/api/productsApi';
import { selectCartItems } from '../redux/features/cartSlice';
import { showToast } from '../redux/features/toastSlice';

const Googleauthentic = () => {
  const cartItems = useSelector(selectCartItems);
  const [productitem, setProductitem] = useState([]);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [googleregister, { error: googleerror, data: googledata, isSuccess: googleisSuccess, isLoading: googleisLoading }] = useGoogleregisterMutation();
  const login = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      if (credentialResponse.access_token) {
        fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${credentialResponse.access_token}`)
          .then(response => response.json())
          .then(userInfo => {
            setData(userInfo);
            const requestBody = {
              email: userInfo.email,
              email_verified: userInfo.verified_email,
              family_name: userInfo.family_name,
              given_name: userInfo.given_name,
              name: userInfo.name,
              picture: userInfo.picture,
              sub: userInfo.id
            };
            googleregister(requestBody);
          })
          .catch(error => {
            console.error('Error fetching user info:', error);
          });
      }
    },
    onError: (error) => {
      console.error('Login Failed:', error);
    }
  });

  const [addtocart, { isSuccess: addtocartSuccess, error: erroraddtocart }] = useAddtocartMutation();

  useEffect(() => {
    if (erroraddtocart) {
      dispatch(showToast(erroraddtocart?.data?.message || 'Error adding to cart'));
    }
  }, [erroraddtocart]);

  useEffect(() => {
    if (addtocartSuccess) {
      localStorage.removeItem('cartItems');
      localStorage.removeItem('orderid');
      localStorage.removeItem('addressid');
      setTimeout(() => {
        navigate('/shoping-cart');
      }, 1000);
    }
  }, [addtocartSuccess, navigate]);

  useEffect(() => {
    const productdata = cartItems.map(item => ({
      stock_options_id: item.options.id,
      quantity: item.quantity
    }));

    if (productdata.length > 0) {
      setProductitem(productdata);
    }
  }, [cartItems]);

  useEffect(() => {
    if(googleisSuccess){
      dispatch(showToast('Registered successfully!'));
      localStorage.setItem('token', googledata?.access_token);
      localStorage.setItem('userid', googledata?.user_id);
      
      if(cartItems.length > 0){
        addtocart(productitem);
      }
      
    }

  },[googleisSuccess]);

  useEffect(() => {
    if(googleerror){
      dispatch(showToast('Registration failed!'));
    }

  },[googleerror]);

  

  return (
    <>
      <button
        className = 'block text-center w-full'
        onClick = { login }
        disabled = { googleisLoading }
      >
        <span>
          <img className = 'inline-block rounded w-5' src = { googlelogo } alt = 'Google Logo' />
        </span>
        <span className = 'text-sm font-semibold text-grey-800 inline-block ml-2'>
        Google
        </span>
        { googleisLoading && <span className = 'ml-2'>Loading...</span> }
      
      </button>
    </>
  );
};

export default Googleauthentic;
