import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AccountSidebar from '../components/AccountSidebar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';


const MyAccountDashboard = () => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const { user } = useSelector((state) => state.auth);
  return(
    <>
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = { `text-white text-xl lg:text-2xl text-center font-semibold uppercase ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('MyAccount') }</h3>
      </div>
      <div className = 'container mx-auto px-5 lg:px-0'>
        <div className = 'block md:flex'>
          <div className = 'w-full md:w-1/5'>
            <AccountSidebar />
          </div>
          <div className = 'w-full mt-5 md:mt-0 md:w-4/5 xl:flex-1'>
            <h3 className = 'text-balck text-lg pb-8'><span className = 'font-bold'>{ t('Hello') }</span> { user?.name }</h3>
            <div className = 'block md:flex justify-between'>
              <Link to = "/my-account/order" className = 'block w-full md:w-width32% shadow-custom border border-boderColor-300 text-center py-5 rounded delay-300 hover:bg-sky hover:delay-300'>
                <span className = 'text-primary-300'><FactCheckOutlinedIcon style = { {fontSize:'52px'} } /></span>
                <p className = { `text-base text-grey mt-1 font-semibold ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Orders') }</p>
              </Link>
              <Link to = "/my-account/address" className = 'block mt-4 md:mt-0 w-full md:w-width32% shadow-custom border border-boderColor-300 text-center py-5 rounded delay-300 hover:bg-sky hover:delay-300'>
                <span className = 'text-primary-300'><RoomOutlinedIcon style = { {fontSize:'52px'} } /></span>
                <p className = { `text-base text-grey mt-1 font-semibold ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Addresses') }</p>
              </Link>
              <Link to = "/my-account/edit-account" className = 'block mt-4 md:mt-0 w-full md:w-width32% shadow-custom border border-boderColor-300 text-center py-5 rounded delay-300 hover:bg-sky hover:delay-300'>
                <span className = 'text-primary-300'><AccountCircleOutlinedIcon style = { {fontSize:'52px'} } /></span>
                <p className = { `text-base text-grey mt-1 font-semibold ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Accountdetails') }</p>
              </Link>
            </div>
            <div className = 'block md:flex justify-between mt-4'>
              <Link to = "/wishlist" className = 'block mt-4 md:mt-0 w-full md:w-width32% shadow-custom border border-boderColor-300 text-center py-5 rounded delay-300 hover:bg-sky hover:delay-300'>
                <span className = 'text-primary-300'><FavoriteBorderOutlinedIcon style = { {fontSize:'52px'} } /></span>
                <p className = { `text-base text-grey mt-1 font-semibold ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Wishlist') }</p>
              </Link>
              <Link to = "#" className = 'block mt-4 md:mt-0 w-full md:w-width32% shadow-custom border border-boderColor-300 text-center py-5 rounded delay-300 hover:bg-sky hover:delay-300'>
                <span className = 'text-primary-300'><ExitToAppOutlinedIcon style = { {fontSize:'52px'} } /></span>
                <p className = { `text-base text-grey mt-1 font-semibold ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Logout') }</p>
              </Link>
              <div className = 'block w-width32%'>
               
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default MyAccountDashboard;