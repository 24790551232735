import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import React, { useEffect, useState } from 'react';
import { useBrandcollectionQuery } from '../redux/api/recomendedproductApi';

const Brandslide = () => {
  const { error, data } = useBrandcollectionQuery();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(6);
  const [customWidth, setCustomWidth] = useState('auto');
  const [filteredBrands, setFilteredBrands] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setCustomWidth('15%');
        setSlidesToShow(6);
      } else if (window.innerWidth >= 768) {
        setCustomWidth('32%');
        setSlidesToShow(3);
      } else {
        setCustomWidth('48%');
        setSlidesToShow(2);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (data?.data) {
      const filteredData = data.data.filter((branditem) => branditem.logo_image_url);
      setFilteredBrands(filteredData);
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      goToNextSlide();
    }, 3000); 

    return () => clearInterval(interval); 
  }, [currentIndex, filteredBrands]);

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const goToNextSlide = () => {
    if (filteredBrands.length > currentIndex + slidesToShow - 1) {
      setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, filteredBrands.length - slidesToShow));
    } else {
      setCurrentIndex(0); // Loop back to the start
    }
  };

  const showLeftArrow = currentIndex > 0;
  const showRightArrow = filteredBrands.length > currentIndex + slidesToShow;

  return (
    <div className = "relative">
      { error?.data?.message && <p>{ error?.data?.message }</p> }
      <div className = "overflow-hidden">
        <div
          className = "flex transition-transform duration-300"
          style = { {
            transform: `translateX(-${currentIndex * (100 / slidesToShow)}%)`
          } }
        >
          { filteredBrands.map((branditem) => (
            <div
              key = { branditem?.id }
              className = "mx-1 md:mx-2 self-center"
              style = { {
                flex: `0 0 ${customWidth === 'auto' ? `${100 / slidesToShow}%` : customWidth}`
              } }
            >
              <div className = "flex items-center">
                <img className = "w-24 aspect-square object-contain" src = { branditem?.logo_image_url } alt = { branditem?.name } />
              </div>
            </div>
          )) }
        </div>
      </div>
      { filteredBrands.length > slidesToShow && (
        <div className = "absolute w-full flex justify-between wrp-arrowiconbrandslide">
          <button
            onClick = { goToPrevSlide }
            className = { `w-8 md:w-10 h-8 md:h-10 bg-white shadow-arrowsad rounded-full ${
              showLeftArrow ? 'activeproductslide' : 'deactiveproductslide'
            }` }
            disabled = { !showLeftArrow }
          >
            <KeyboardArrowLeftOutlinedIcon />
          </button>
          <button
            onClick = { goToNextSlide }
            className = { `relative right-0.5 md:right-0 w-8 md:w-10 h-8 md:h-10 bg-white shadow-arrowsad rounded-full inline-block ml-3 ${
              showRightArrow ? 'activeproductslide' : 'deactiveproductslide'
            }` }
            disabled = { !showRightArrow }
          >
            <KeyboardArrowRightOutlinedIcon />
          </button>
        </div>
      ) }
    </div>
  );
};

export default Brandslide;
