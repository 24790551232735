import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../config/index';

const getToken = () => localStorage.getItem('token');

export const tamaraApi = createApi({
  reducerPath: 'tamaraApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    tamaraCheckout: builder.mutation({
      query(body) {
        return {
          url: '/notifications/tamara-checkout/',
          method: 'POST',
          body
        };
      }
    })
  })
});

export const {useTamaraCheckoutMutation} = tamaraApi;
