/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Link, useNavigate } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import dummyimg from '../images/dummyimg.png';
import { useAddtocartMutation, useAddtowishlistMutation, useDisplayaddtowishlistQuery } from '../redux/api/productsApi';
import { addItemToCart } from '../redux/features/cartSlice';
import { setCartopen } from '../redux/features/opencardSlice';
import { showToast } from '../redux/features/toastSlice';

const ShopSeacrhProduct = ({ product }) => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredImage, setHoveredImage] = useState(product?.images[0]); // Add state for hovered image
  const [open, setOpen] = useState(false);
  const userid = useSelector((state) => state.auth?.user?.id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [productDetails, setProductDetails] = useState([]);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const handleMainMouseEnter = (imageUrl) => {
    setIsHovered(true);
    setHoveredImage(imageUrl); // Update hovered image source
  };

  const handleMainMouseLeave = () => {
    setIsHovered(false);
    setHoveredImage(product?.images[0]); // Reset to default image on mouse leave
  };

  const handleMouseEnter = (imageUrl) => {
    setHoveredImage(imageUrl); // Update hovered image source
  };

  const handleMouseLeave = () => {
    setHoveredImage(product?.images[0]); // Reset to default image on mouse leave
  };

  const [addtocart, {error, isSuccess }] = useAddtocartMutation();

  useEffect(() => {

    if(error){
      dispatch(showToast(error?.data?.message));
    }

    if(isSuccess){
      dispatch(setCartopen(true));
      setOpen(false);
    }
    
  },[error, isSuccess,dispatch]);


  const {error: errorwishlist, data: datawishlist } = useDisplayaddtowishlistQuery(userid, {skip: !userid});
 
  useEffect(() => {

    if(errorwishlist){
      dispatch(showToast(errorwishlist?.data?.message));
    } 
    
  },[errorwishlist]);

  const [addtowishlist, {error: erroraddtowishlist, isSuccess: issuccessaddtowishlist }] = useAddtowishlistMutation();
  
  const handleAddtowishlist = (productid) => {
    if(userid){
      if (!datawishlist?.data.find(item => item.product_id === productid)) {
        const data = {
          'product_id': productid
        };
        addtowishlist(data);
      }else{
        dispatch(showToast('Product is already in the wishlist'));
      }
      
    }else{
      navigate('/login');
    }
  };

  useEffect(() => {

    if(erroraddtowishlist){
      dispatch(showToast(erroraddtowishlist?.data?.message));
    } 
    
  },[erroraddtowishlist]);

  useEffect(() => {
    if (issuccessaddtowishlist) {
      dispatch(showToast('Great choice! Product added to your wishlist.'));
    }
  }, [issuccessaddtowishlist]);

  const [addtocartmodal, setAddtocartmodal] = useState(false);
  const onCloseAddtocartmodal = () => {
    setAddtocartmodal(false);
    setSelectedSize('');
  };

  const onOpenAddtocartmodal = (product) => {
    setProductDetails(product);
    setAddtocartmodal(true);
    setIsOutOfStock(false);
  };

  const [selectedSize, setSelectedSize] = useState('');

  const handleSize = (option) => {

    setSelectedSize(option?.size);
    setIsOutOfStock(option?.stock === 0 || option?.stock === null);

    if(option?.size && option?.stock > 0 ){

      const productData = {
        id: product.product_id,
        name: product.name,
        brand: product.brand,
        description: product.description,
        images: product.images,
        fragrance_family: product.fragrance_family,
        ingredients: product.ingredients,
        gender: product.gender,
        model: product.model,
        sku: product.sku,
        scent_type: product.scent_type,
        type: product.type,
        launch_year: product.launch_year,
        total_stock: product.total_stock,
        options: option,
        quantity: 1, 
        total_price: option ? option.selling_price : null
      };
      if(userid){
        const data = {
          'stock_options_id': option?.id,
          'quantity':1
        };
        addtocart([data]);
        setAddtocartmodal(false);
        setSelectedSize('');
      }else{
        dispatch(addItemToCart(productData));
        setAddtocartmodal(false);
        setSelectedSize('');
        dispatch(setCartopen(true));
      }
    }
   
  };


  return (
    <> 
      <div
        key = { product?._id }
        className = "relative w-width48% md:w-width24% bg-white mt-3"
        onMouseEnter = { () => handleMainMouseEnter(product.images[1]?product.images[1]:product.images[0]) }
        onMouseLeave = { handleMainMouseLeave }
      >
        <Link to = { `/product/${product?.product_id}` } className = 'block w-full'>
          <img
            src = { hoveredImage?hoveredImage: dummyimg }
            alt = { product?.name }
            className = "w-full h-full rounded-lg md:rounded-none"
          />
        </Link>
        <div className = "p-2 md:py-4 md:pb-4 md:pt-0">
          <Link to = { `/brand/shop/${product?.brand}` } className = 'text-primary block text-sm hover:text-primary hover:underline delay-200'>{ product?.brand?product?.brand: 'NA' }</Link>
          <Link to = { `/product/${product?.product_id}` } className = { `text-gray-800 block text-xs md:text-sm hover:text-primary delay-200 ${selectLanguage == 'ar'?'font-Tajawal': 'font-Tajawal'}` }>{ product?.name }</Link>
          <p className = "text-sm text-primary">{ `SAR ${product?.options?.[0]?.selling_price}` }</p>
          <p className = 'hidden md:block'>
            <StarRatings
              rating = { product?.average_rating ?? 0 }
              starRatedColor = "#ffb829"
              numberOfStars = { 5 }
              name = 'rating'
              starDimension = '20px'
              starSpacing = '1px'
            />
            <span className = 'text-sm align-middle inline-block ml-2'>({ product?.review_count })</span>
          </p>
          <p className = 'block md:hidden'>
            <StarRatings
              rating = { product?.average_rating ?? 0 }
              starRatedColor = "#ffb829"
              numberOfStars = { 5 }
              name = 'rating'
              starDimension = '16px'
              starSpacing = '1px'
            />
            <span className = 'text-sm align-middle inline-block ml-2'>({ product?.review_count })</span>
          </p>
          <div className = 'flex gap-2 mt-2'>
            {  product.images.length > 0?product.images.slice(0, 4)?.map((image, index) => (
              <div key = { index } className = 'w-8 h-8 md:w-10 md:h-10'>
                <img
                  className = 'w-full h-full border border-borderColor rounded-full'
                  src = { image }
                  alt = { 'smallproduct' }
                  onMouseEnter = { () => handleMouseEnter(image) }
                  onMouseLeave = { handleMouseLeave }
                />
              </div>
            )):  <div  className = 'w-8 h-8 md:w-10 md:h-10'>
              <img
                className = 'w-full h-full border border-borderColor rounded-full'
                src = { dummyimg }
                alt = { 'smallproduct' }
                onMouseEnter = { () => handleMouseEnter(dummyimg) }
                onMouseLeave = { handleMouseLeave }
              />
            </div>
            }
          </div>
          { isHovered && (
            <div className = "mt-4 flex justify-between items-center space-x-2 absolute top-0">
              <Tooltip title = "Add to Cart">
                <button onClick = { () => onOpenAddtocartmodal(product) } className = "shadow-custom bg-white text-black w-8 h-8 md:w-10 md:h-10 rounded-full flex justify-center items-center">
                  <LocalMallOutlinedIcon fontSize = "small" />
                </button>
              </Tooltip>
              <Tooltip title = "Quick View">
                <button onClick = { () => setOpen(true) } className = "shadow-custom bg-white text-black w-8 h-8 md:w-10 md:h-10 rounded-full hover:bg-primary hover:text-white transition duration-300">
                  <VisibilityOutlinedIcon style = { { fontSize: '20px' } } />
                </button>
              </Tooltip>
              <Tooltip title = "Add to Wishlist">
                <button onClick = { () => handleAddtowishlist(product?.product_id) } className = "shadow-custom bg-white text-black w-8 h-8 md:w-10 md:h-10 rounded-full hover:bg-primary hover:text-white transition duration-300">
                  <FavoriteBorderOutlinedIcon style = { { fontSize: '20px' } } />
                </button>
              </Tooltip>
            </div>
          ) }
        </div>
      </div>

      <Modal open = { open } onClose = { () => setOpen(false) } center>
        <div className = 'w-full block md:flex p-5'>
          <div className = 'w-64 self-center mb-5 md:mb-0 mr-0 md:mr-8'>
            <img className = 'w-full' src = { hoveredImage?hoveredImage: dummyimg } alt = 'product' />
            <div className = 'w-full'>
              <Link to = { `/product/${product?.product_id}` } className = { `block bg-white text-primary border border-primary uppercase text-center py-2 text-sm delay-300 hover:bg-primary hover:text-white hover:delay-300 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('VIEWDETAILS') }</Link>
            </div>
          </div>
          <div className = 'self-center'>
            <h3 className = 'text-lg lg:text-xl text-black font-semibold font-madani-arabic'>{ product?.name }</h3>
            <p className = 'text-base md:text-lg mt-5 font-bold text-primary pb-3'>{ product?.options?.[0]?.selling_price }</p>
            <p className = 'text-base text-grey mt-3'>SKU: { product?.sku }</p>
          </div>
        </div>
      </Modal>

      <Modal
        open = { addtocartmodal }
        showCloseIcon = { false }
        onClose = { onCloseAddtocartmodal }
      >
        <div className = "addtocartmodal">
          <div className = "flex justify-between bg-primary px-4 py-3">
            <h3 className = "text-white text-base font-semibold self-center">
            Select size
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseAddtocartmodal }
                className = "cursor-pointer text-white"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "flex-col items-center py-4 md:py-8 px-4 md:px-8 text-center">
            <div className = "text-start">
              <ul className = 'flex flex-wrap gap-2 md:gap-4 mt-2'>
              
                { productDetails?.options?.map((option) => (
                  <li
                    key = { option.id }
                    className = { `flex px-2 py-1.5 w-full md:w-width32% cursor-pointer bg-white rounded ${
                      selectedSize === option.size ? 'border-2 border-primary' : 'border border-borderColor'
                    }` }
                    onClick = { () => handleSize(option) }
                  >
                    <img className = 'w-8' src = { productDetails?.images[0] } alt = 'productsamll' />
                    <p className = { `text-sm text-center text-black self-center font-medium ${selectLanguage == 'ar' ? 'font-madani-arabic' : ''}` }>
                      { option.size }
                    </p>
                  </li>
                )) }
              </ul>
              {
                isOutOfStock && <p className = 'text-base text-red font-semibold mt-2'>Out Of Stock</p>
              }
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShopSeacrhProduct;
