import CloseIcon from '@mui/icons-material/Close';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import React, { useRef, useState } from 'react';
import Filtter from './Filtter';


const MobileCollectionFilter = () => {

  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  

  return(
    <>

      <div className = 'block md:hidden'>
        <span className = 'text-center mx-auto mt-3 text-sm align-middle' onClick = { toggleMenu } ><MenuOutlinedIcon style = { {fontSize: '24px'} } /> Show sidebar</span>
      </div>
      <div className = { `block md:hidden mobile-menu max-h-screen ${
        isMenuOpen ? 'slide-in' : 'slide-out'
      } transition-transform duration-300 drop-shadow-lg` } ref = { menuRef }>
        <div>
          <p className = 'flex justify-end'><CloseIcon onClick = { toggleMenu } style = { {fontSize: '25px'} } /></p>
          <Filtter />
        </div>
      </div>
    </>
  );
};


export default MobileCollectionFilter;