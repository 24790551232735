import { configureStore } from '@reduxjs/toolkit';
import { authApi } from './api/authApi';
import { contactApi } from './api/contactApi';
import { orderApi } from './api/orderApi';
import { productsApi } from './api/productsApi';
import { recomendedproductApi } from './api/recomendedproductApi';
import { tabbyApi } from './api/tabbyApi';
import { userApi } from './api/userApi';
import logoutReducer from './features/authSlice';
import cartReducer, { loadCartFromLocalStorage } from './features/cartSlice';
import checkoutpriceReducer from './features/checkoutSlice';
import opencardReducer from './features/opencardSlice';
import orderidSlice from './features/orderidSlice';
import rangeReducer from './features/rangeSlice';
import toastReducer from './features/toastSlice';
import userReducer from './features/userSlice';

export const store = configureStore({
  reducer: {
    auth: userReducer,
    cart: cartReducer,
    opencard: opencardReducer,
    logout: logoutReducer,
    range: rangeReducer,
    checkoutpricecart: checkoutpriceReducer,
    orderid: orderidSlice,
    toast: toastReducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [recomendedproductApi.reducerPath]: recomendedproductApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [tabbyApi.reducerPath]: tabbyApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      productsApi.middleware,
      authApi.middleware,
      userApi.middleware,
      recomendedproductApi.middleware,
      orderApi.middleware,
      contactApi.middleware,
      tabbyApi.middleware
    )
});

// Load cart items from local storage when the app initializes
store.dispatch(loadCartFromLocalStorage());

export default store;
