import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Filtter from '../components/Filtter';
import Footer from '../components/Footer';
import Header from '../components/Header';
import MobileCollectionFilter from '../components/MobileCollectionFilter';
import Navigation from '../components/Navigation';
import TopHeader from '../components/TopHeader';



const ContactLenses = () => {

  const products =[
    {
      name: 'Coach For Men',
      description: 'Women Eau De Toilette Clarins',
      price: 55,
      image: 'https://odour-demo.myshopify.com/cdn/shop/products/product_13_large.png?v=1561608483'
    },
    {
      name: 'Perfume Travel Atomizer',
      description: 'Women Eau De Toilette Clarins',
      price: 45,
      image: 'https://odour-demo.myshopify.com/cdn/shop/products/product_06_bb4d170a-949b-443a-bf88-860835f74a0b_large.png?v=1561608600'
    },
    {
      name: 'Versace Eros men',
      description: 'Women Eau De Toilette Clarins',
      price: 19,
      image: 'https://odour-demo.myshopify.com/cdn/shop/products/product_02_large.png?v=1561605691'
    },
    {
      name: 'Versace Signature',
      description: 'Women Eau De Toilette Clarins Parfum',
      price: 36,
      image: 'https://odour-demo.myshopify.com/cdn/shop/products/product_09_3abd518b-3f03-417f-95fd-f923b6e6e6b4_large.png?v=1561606333'
    },
    {
      name: 'Coach For Men',
      description: 'Women Eau De Toilette Clarins',
      price: 55,
      image: 'https://odour-demo.myshopify.com/cdn/shop/products/product_13_large.png?v=1561608483'
    },
    {
      name: 'Perfume Travel Atomizer',
      description: 'Women Eau De Toilette Clarins',
      price: 45,
      image: 'https://odour-demo.myshopify.com/cdn/shop/products/product_06_bb4d170a-949b-443a-bf88-860835f74a0b_large.png?v=1561608600'
    },
    {
      name: 'Coach For Men',
      description: 'Women Eau De Toilette Clarins',
      price: 55,
      image: 'https://odour-demo.myshopify.com/cdn/shop/products/product_13_large.png?v=1561608483'
    },
    {
      name: 'Perfume Travel Atomizer',
      description: 'Women Eau De Toilette Clarins',
      price: 45,
      image: 'https://odour-demo.myshopify.com/cdn/shop/products/product_06_bb4d170a-949b-443a-bf88-860835f74a0b_large.png?v=1561608600'
    },
    {
      name: 'Coach For Men',
      description: 'Women Eau De Toilette Clarins',
      price: 55,
      image: 'https://odour-demo.myshopify.com/cdn/shop/products/product_13_large.png?v=1561608483'
    },
    {
      name: 'Perfume Travel Atomizer',
      description: 'Women Eau De Toilette Clarins',
      price: 45,
      image: 'https://odour-demo.myshopify.com/cdn/shop/products/product_06_bb4d170a-949b-443a-bf88-860835f74a0b_large.png?v=1561608600'
    },
    {
      name: 'Coach For Men',
      description: 'Women Eau De Toilette Clarins',
      price: 55,
      image: 'https://odour-demo.myshopify.com/cdn/shop/products/product_13_large.png?v=1561608483'
    },
    {
      name: 'Perfume Travel Atomizer',
      description: 'Women Eau De Toilette Clarins',
      price: 45,
      image: 'https://odour-demo.myshopify.com/cdn/shop/products/product_06_bb4d170a-949b-443a-bf88-860835f74a0b_large.png?v=1561608600'
    },
    {
      name: 'Coach For Men',
      description: 'Women Eau De Toilette Clarins',
      price: 55,
      image: 'https://odour-demo.myshopify.com/cdn/shop/products/product_13_large.png?v=1561608483'
    },
    {
      name: 'Perfume Travel Atomizer',
      description: 'Women Eau De Toilette Clarins',
      price: 45,
      image: 'https://odour-demo.myshopify.com/cdn/shop/products/product_06_bb4d170a-949b-443a-bf88-860835f74a0b_large.png?v=1561608600'
    },
    {
      name: 'Coach For Men',
      description: 'Women Eau De Toilette Clarins',
      price: 55,
      image: 'https://odour-demo.myshopify.com/cdn/shop/products/product_13_large.png?v=1561608483'
    },
    {
      name: 'Perfume Travel Atomizer',
      description: 'Women Eau De Toilette Clarins',
      price: 45,
      image: 'https://odour-demo.myshopify.com/cdn/shop/products/product_06_bb4d170a-949b-443a-bf88-860835f74a0b_large.png?v=1561608600'
    }
        
  ];
  const [hoveredItem, setHoveredItem] = useState(null);
    
  const handleMouseEnter = (index) => {
    setHoveredItem(index);
  };
    
  const handleMouseLeave = () => {
    setHoveredItem(null);
  };
    
  const isItemHovered = (index) => {
    return hoveredItem === index;
  };



  return (
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = 'text-white text-xl lg:text-2xl text-center font-semibold uppercase'>Contact Lenses</h3>
      </div>
      <div className = 'container mx-auto px-5 lg:px-10'>
        <div className = 'flex'>
          <div className = 'w-52 hidden md:block'>
            <Filtter />
          </div>
          
          <div className = 'ml-0 md:ml-5'>
            <p className = 'self-center text-base text-grey block lg:hidden mr-5'>16 Products</p>
            <ul className = 'flex flex-wrap mb-2 md:hidden'>
              <li>
                <Link className = 'inline-block text-grey text-sm hover:text-primary' to = "/">Home</Link>
              </li>
              <li>
                <Link className = 'inline-block text-grey text-sm ml-2' to = "/">/</Link>
              </li>
              <li>
                <Link className = 'inline-block text-grey text-sm hover:text-primary ml-2' to = "/shop">Shop</Link>
              </li>
              <li>
                <Link className = 'inline-block text-grey text-sm ml-2' to = "/shop">/</Link>
              </li>
              <li>
                <Link className = 'inline-block text-grey text-sm hover:text-primary ml-2' to = "/home/shop/eyewear">Eyewear</Link>
              </li>
              <li>
                <Link className = 'inline-block text-grey text-sm ml-2' to = "/home/shop/eyewear">/</Link>
              </li>
              <li>
                <span className = 'inline-block text-black text-sm ml-2'>Contact Lenses</span>
              </li>
            </ul>
            <div className = 'flex justify-between'>
              <div className = 'self-center'>
                <ul className = 'hidden mb-3 md:flex'>
                  <li>
                    <Link className = 'inline-block text-grey text-sm hover:text-primary' to = "/">Home</Link>
                  </li>
                  <li>
                    <Link className = 'inline-block text-grey text-sm ml-2' to = "/">/</Link>
                  </li>
                  <li>
                    <Link className = 'inline-block text-grey text-sm hover:text-primary ml-2' to = "/shop">Shop</Link>
                  </li>
                  <li>
                    <Link className = 'inline-block text-grey text-sm ml-2' to = "/shop">/</Link>
                  </li>
                  <li>
                    <Link className = 'inline-block text-grey text-sm hover:text-primary ml-2' to = "/home/shop/eyewear">Eyewear</Link>
                  </li>
                  <li>
                    <Link className = 'inline-block text-grey text-sm ml-2' to = "/home/shop/eyewear">/</Link>
                  </li>
                  <li>
                    <span className = 'inline-block text-black text-sm ml-2'>Contact Lenses</span>
                  </li>
                </ul>
                <MobileCollectionFilter />
              </div>
              <div className = 'flex self-center'>
                <p className = 'self-center text-base text-grey hidden lg:block mr-5'>16 Products</p>
                <select className = 'border border-[#E5E7EB] text-sm lg:text-base'>
                  <option>Newness</option>
                  <option>Best selling</option>
                  <option>Title descending</option>
                  <option>Price descending</option>
                  <option>Price ascending</option>
                  <option>Default</option>
                  <option>Relevance</option>
                </select>
              </div>
            </div>
            <div className = 'mt-3'>
              <div className = 'flex flex-wrap justify-between'>
                { products.map((product, index) => (
                  <Link to = "/product-details"
                    key = { index }
                    className = "relative w-full md:w-width48% lg:w-productwidth h-fit lg:h-productheight500 xl:h-productheight bg-white shadow-lg rounded-lg mt-3 transition duration-300 transform hover:-translate-y-1 hover:shadow-2xl"
                    onMouseEnter = { () => handleMouseEnter(index) }
                    onMouseLeave = { handleMouseLeave }
                  >
                    <img
                      src = { product.image }
                      alt = { product.name }
                      className = "w-full h-2/3 object-cover"
                    />
                    <div className = "p-4">
                      <h3 className = "text-gray-800 font-semibold">{ product.name }</h3>
                      <p className = "text-sm lg:text-base text-gray-600 mt-2 break-all">
                        { product.description }
                      </p>
                      <span className = "font-bold text-primary inline-block pt-2">{ `$ ${product.price}` }</span>
                      <div className = { `mt-4 flex justify-between items-center ${isItemHovered(index) ? 'flex space-x-2 absolute top-56' : 'hidden'}` }>
                        <Tooltip title = "Add to Cart">
                          <button className = "shadow-custom bg-white text-black w-12 h-12 rounded-full hover:bg-primary hover:text-white transition duration-300">
                            <LocalMallOutlinedIcon />
                          </button>
                        </Tooltip>
                        <Tooltip title = "Quick View">
                          <button className = "shadow-custom bg-white text-black w-12 h-12 rounded-full hover:bg-primary hover:text-white transition duration-300">
                            <VisibilityOutlinedIcon />
                          </button>
                        </Tooltip>
                        <Tooltip title = "Add to Wishlist">
                          <button className = "shadow-custom bg-white text-black w-12 h-12 rounded-full hover:bg-primary hover:text-white transition duration-300">
                            <FavoriteBorderOutlinedIcon />
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                  </Link>
                )) }
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ContactLenses;