import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import React from 'react';
import { Link } from 'react-router-dom';


const ShareSocialmedia = () => {

  return(
    <>
      <div className = 'flex mt-5'>
        <p>Share: </p>
        <ul className = 'flex ml-3'>
          <li><Link className = 'text-primary-300 hover:text-primary duration-500'><FacebookOutlinedIcon style = { {fontSize: '20px'} } /></Link></li>
          <li><Link className = 'text-primary-300 ml-1 hover:text-primary duration-500'><InstagramIcon style = { {fontSize: '20px'} } /></Link></li>
          <li><Link className = 'text-primary-300 ml-1 hover:text-primary duration-500'><LinkedInIcon style = { {fontSize: '20px'} } /></Link></li>
        </ul>
      </div>
    </>
  );
};

export default ShareSocialmedia;