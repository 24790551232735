import React, { useState } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import TopHeader from '../../components/TopHeader';



const GiftCard = () => {

  const [activeForm, setActiveForm] = useState(null);

  const handleActiveForm = (item) => {
    setActiveForm(item);
  };
    
  return(
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = "container mx-auto px-5 lg:px-10">
        <div className = 'flex justify-between mt-10'>
          <div className = 'w-width45%'>
            <img className = 'w-full' src = 'https://imgcdn.scentbird.com/_/rt:fill/w:640/ZnJvbnRiaXJkMi9pbWFnZXMvZGVza3RvcF8zY2U1YTkuanBn' alt = 'giftcard' />
            <div className = 'giftcard-Content'>
              <h3 className = 'text-3xl font-bold text-black uppercase'>SCENTBIRD GIFT CARD</h3>
              <p className = 'text-blacklight text-sm font-medium mt-2'>
                Our gift cards can be used for any subscription, gift set, full size <br></br>
                fragrance bottles, skincare and makeup!
              </p>
            </div>
          </div>
          <div className = 'w-width45%'>
            <p className = 'text-base text-blacklight'>Choose an amount</p>
            <div className = 'flex justify-between mt-3'>
              <div className = 'w-width49% bg-primary rounded h-28 text-center'>
                <h3 className = 'text-white text-xl font-bold mt-8'>SAR50</h3>
                <p className = 'text-sm font-medium text-white mt-2'>digital giftcard</p>
              </div>
              <div className = 'w-width49% bg-primary rounded h-28 text-center'>
                <h3 className = 'text-white text-xl font-bold mt-8'>SAR75</h3>
                <p className = 'text-sm font-medium text-white mt-2'>digital giftcard</p>
              </div>
            </div>
            <div className = 'flex justify-between mt-3'>
              <div className = 'w-width49% bg-primary rounded h-28 text-center'>
                <h3 className = 'text-white text-xl font-bold mt-8'>SAR100</h3>
                <p className = 'text-sm font-medium text-white mt-2'>digital giftcard</p>
              </div>
              <div className = 'w-width49% bg-primary rounded h-28 text-center'>
                <h3 className = 'text-white text-xl font-bold mt-8'>SAR200</h3>
                <p className = 'text-sm font-medium text-white mt-2'>digital giftcard</p>
              </div>
            </div>
            <ul>
              <li className = 'mt-5'>
                <p className = 'text-sm text-blacklight'>Who is this gift card for?</p>
                <input className = 'border border-borderColor inline-block py-2 px-4 text-sm w-full outline-none rounded mt-2' placeholder = "Recipient's name" />
              </li>
              <li className = 'mt-2'>
                <input className = 'border border-borderColor inline-block py-2 px-4 text-sm w-full outline-none rounded mt-2' placeholder = "Recipient's email" />
              </li>
              <li className = 'mt-2'>
                <textarea className = 'border border-borderColor inline-block py-2 px-4 text-sm w-full outline-none rounded h-24 mt-2' placeholder = "Write a personal message" />
              </li>
              <li className = 'mt-5'>
                <p className = 'text-sm text-blacklight'>Who is it from?</p>
                <input className = 'border border-borderColor inline-block py-2 px-4 text-sm w-full outline-none rounded mt-2' placeholder = "Your name" />
              </li>
              <li className = 'mt-5'>
                <p className = 'text-sm text-blacklight'>When would you like to send it?</p>
                <div className = 'mt-2'>
                  <input id = 'send' type = 'radio' name = 'now' className = 'border border-borderColor inline-block text-sm outline-none' onClick = { () => handleActiveForm(1) } />
                  <label className = 'text-sm text-blacklight font-medium align-middle inline-block ml-3' htmlFor = 'send' >Send right now</label>
                </div>
              </li>
              <li className = 'mt-4'>
                <div>
                  <input id = 'Choose' type = 'radio' name = 'now' className = 'border border-borderColor inline-block text-sm outline-none' onClick = { () => handleActiveForm(2) } />
                  <label className = 'text-sm text-blacklight font-medium align-middle inline-block ml-3' htmlFor = 'Choose' >Choose a later date to send</label>
                </div>
              </li>
              {
                activeForm == 2 && <>
                  <p className = 'text-sm text-blacklight mt-8'>Choose the date to send the gift card</p>
                  <div className = 'flex justify-between mt-3'>
                    <div className = 'w-width32%'>
                      <select className = 'w-full border border-borderColor inline-block text-sm outline-none rounded'>
                        <option>Month</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                      </select>
                    </div>
                    <div className = 'w-width32%'>
                      <select className = 'w-full border border-borderColor inline-block text-sm outline-none rounded'>
                        <option>Month</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                      </select>
                    </div>
                    <div className = 'w-width32%'>
                      <select className = 'w-full border border-borderColor inline-block text-sm outline-none rounded'>
                        <option>Month</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                      </select>
                    </div>
                  </div>
                </>
              }
              <li className = 'mt-10'>
                <button className = 'inline-block py-3 px-8 bg-primary uppercase text-sm font-medium text-white'>Checkout now</button>
              </li>
            </ul>
          </div>
        </div>

        <Footer />
      </div>
      
    </>
  );
};

export default GiftCard;