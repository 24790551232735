import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import React, { useEffect, useRef, useState } from 'react';
import Collapsible from 'react-collapsible';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../images/logo.png';
import userprofile from '../images/userprofile.svg';
import { useLoginMutation } from '../redux/api/authApi';
import { useAddtocardlistQuery, useAddtocartMutation, useDisplayaddtowishlistQuery } from '../redux/api/productsApi';
import { useGiftcardlistQuery, useNavigationlistQuery } from '../redux/api/recomendedproductApi';
import { useGetMeQuery } from '../redux/api/userApi';
import { logout } from '../redux/features/authSlice';
import { selectCartItems } from '../redux/features/cartSlice';
import { showToast } from '../redux/features/toastSlice';
import CustomToast from './Customtoast';
import MobileBrandshopDropdown from './MobileBrandshopDropdown';
import MobileShopdropdown from './MobileShopdropdown';
import Multilanguage from './Multilanguage';
import SearchMainMobile from './SearchMainMobile';
import ShopingCardPopup from './ShopingCardPopup';

const Header = () => {
  const userid = localStorage.getItem('userid');
  const selectLanguage = localStorage.getItem('selectLanguage');
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const profiledropdownref = useRef(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(false);
  const [isCartOpen, setCartOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [productitem, setProductitem] = useState([]);
  const navigate = useNavigate();
  const { isLoading } = useGetMeQuery(userid, {skip: !userid});
  const { user } = useSelector((state) => state.auth);
  const cartItems = useSelector(selectCartItems);
  const showToastmessage = useSelector((state) => state?.toast?.message);


  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (profiledropdownref.current && !profiledropdownref.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const {error: addtocartlisterror, data: addtocartlistdata, refetch: addtocartlistrefetch} = useAddtocardlistQuery(userid, { skip: !userid });
  const dispatch = useDispatch();
  useEffect(() => {

    if(addtocartlisterror){
      dispatch(showToast(addtocartlisterror?.data?.message));
    }
  },[addtocartlisterror]);

  const {error: addtowishlistdataerror, data: addtowishlistdata,refetch} = useDisplayaddtowishlistQuery(userid, { skip: !userid });

  useEffect(() => {

    if(addtowishlistdataerror){
      dispatch(showToast(addtowishlistdataerror?.data?.message));
    }
  },[addtowishlistdataerror]);

  const itemCount = addtocartlistdata?.data?.length > 0 ? addtocartlistdata.data.length : cartItems.length;


  const [addtocart, { isSuccess: addtocartSuccess, error: erroraddtocart }] = useAddtocartMutation();

  useEffect(() => {
    if (erroraddtocart) {
      dispatch(showToast(erroraddtocart?.data?.message || 'Error adding to cart'));
    }
  }, [erroraddtocart]);

  useEffect(() => {
    if (addtocartSuccess) {
      localStorage.removeItem('cartItems');
    }
  }, [addtocartSuccess, navigate]);

  useEffect(() => {
    const productdata = cartItems.map(item => ({
      stock_options_id: item.options?.id,
      quantity: item.quantity
    }));

    if (productdata?.length > 0) {
      setProductitem(productdata);
    }
  }, [cartItems]);


  const [ login, {error, isLoading: loginisLoading, isSuccess} ] = useLoginMutation();

  useEffect(() => {

    if(error){
      dispatch(showToast(error?.data?.message));
    }
  
  },[error]);

  useEffect(() => {

   
    if(isSuccess){

      if(cartItems?.length > 0){
        addtocart(productitem);
      }
      setTimeout(() => {
        navigate('/');
      },2000);
      refetch();
      addtocartlistrefetch();
    }
  },[isSuccess,isLoading]);


  const LoginHandler = (e) => {
    e.preventDefault();
    const loginData = {
      email,
      password
    };

    login(loginData);
  };


  const handleEmail = (e) => {
    const trimmedEmail = e.target.value.trim();
    setEmail(trimmedEmail);
  
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(trimmedEmail);
  
    setErrorEmail(isValidEmail ? '' : '');
    setIsEmailValid(isValidEmail);
  };
  
  const handleEmailBlur = () => {
    if (!isEmailValid && email.trim()) {
      setErrorEmail('Please enter a valid email');
    }
  };
  

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const isFormValid = isEmailValid && password;


  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleToggle = () => {
    setExpandedIndex(!expandedIndex);
  };

  const toggleCartPopup = () => {
    setCartOpen(true);
  };

  const { t } = useTranslation();

  const handleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate(0);
    setShowDropdown(false);
    
  };

  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const {error: errorgiftcard, data: datagiftcard} = useGiftcardlistQuery();

  // const {error: errorsaleoffer, data: datasaleoffer} = useSaleofferlistQuery();

  const {error: navigationerror, data: navigationdata} = useNavigationlistQuery();

  useEffect(() => {
 
    if(navigationerror){
      dispatch(showToast(navigationerror?.data?.message));
    }

  },[navigationerror]);

  const menudata = navigationdata?.data;

  const reversedMenudata = (menudata ?? []).slice().reverse();
  const firstTwoItemsmenu = reversedMenudata.slice(0, 2);
  const remainingItemsmenu = reversedMenudata.slice(2);

  return (
    <>
      <div className = ' bg-white sticky top-0 z-40'>
        <div className = { `container mx-auto px-5 lg:px-0 py-1.5 md:py-2 flex justify-between text-black relative ${selectLanguage == 'ar'?'flex-row-reverse': 'flex-row'}` }>
          <div className = { `w-width20% md:w-width40% self-center flex ${selectLanguage == 'ar'?'justify-end': 'justify-start'}` }>
            { showToastmessage && <CustomToast /> }
            <div className = 'self-center hidden lg:block'>
              <SearchMainMobile />
            </div>
            <div className = 'self-center block lg:hidden'>
              <span className = 'text-center mx-auto mt-3' onClick = { toggleMenu } ><MenuOutlinedIcon style = { {fontSize: '28px'} } /></span>
            </div>
            
          </div>
          <div className = 'w-width40% md:w-width20% self-center'><Link className = 'block w-24 md:w-28 mx-auto' to = '/' ><img src = { logo } alt = 'logo' /></Link></div>
          <div className = { `w-width20% md:w-width40% self-center flex ${selectLanguage == 'ar'?'justify-start': 'justify-end'} ` }>
            <div>
              {
                userid? <ul className = { `flex m-0 p-0 list-none gap-x-4 text-black text-sm self-center ${selectLanguage == 'ar'?'flex-row-reverse' : 'flex-row'}` }>
                  <li className = 'hidden lg:block self-center'>
                    <div className = 'relative flex'>
                      <img className = 'w-9 cursor-pointer rounded-full' onClick = { handleDropdown } src = { userprofile } alt = 'user' />
                      <div className = 'self-center cursor-pointer' onClick = { handleDropdown }><span>{ user?.role }</span> <span><ArrowDropDownIcon /></span></div>
                      {
                        showDropdown && 
                  <div ref = { profiledropdownref }  className = { `absolute ${selectLanguage == 'ar'?'left-0': 'right-0'} z-10 mt-2 w-56 top-11 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none` }>
                    <div className = "py-1">
                      <Link to = "/my-account" className = { `hover:text-grey-800 text-grey-600 block px-4 py-2 text-sm border-b border-grey-300 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Dashboard') }</Link>
                      <Link to = "/my-account/order" className = { `hover:text-grey-800 text-grey-600 block px-4 py-2 text-sm border-b border-grey-300 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Orders') }</Link>
                      <Link to = "/my-account/edit-account" className = { `hover:text-grey-800 text-grey-600 block px-4 py-2 text-sm border-b border-grey-300 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Profile') }</Link>
                      <button  className = { `hover:text-grey-800 text-grey-600 block w-full px-4 py-2 text-left text-sm ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } onClick = { () => handleLogout() }>{ t('Logout') }</button>
                      
                    </div>
                  </div>
                      }
                    </div>
                  </li>
                  <li className = 'hidden lg:block'>
                    <Link className = ' text-sm block text-primary px-1 py-2 relative' to = "/wishlist">
                      <FavoriteBorderOutlinedIcon />
                      <div className = 'w-4 h-4 rounded-full bg-primary text-white text-center text-xs absolute right-0 top-0'>{ addtowishlistdata?.data?.length }</div>
                    </Link>
                  </li>
                  <li className = 'block lg:hidden'>
                    <SearchMainMobile />
                  </li>
                  <li>
                    <Link className = ' text-sm block text-primary px-1 py-2 relative' onClick = { toggleCartPopup } to = "#">
                      <LocalMallOutlinedIcon />
                      <div className = 'w-4 h-4 rounded-full bg-primary text-white text-center text-xs absolute right-0 top-0'>{ addtocartlistdata?.data?.length }</div>
                    </Link>
                  </li>
                  <li className = 'hidden md:block self-center '>
                    <Multilanguage />
                  </li>
              
                </ul>:<div className = 'self-center'> <ul className = { `flex m-0 p-0 list-none gap-x-4 text-black text-sm ${selectLanguage == 'ar'?'flex-row-reverse' : 'flex-row'}` }>
                  <li className = 'relative hidden lg:block' onMouseEnter = { () => setIsHovered(true) } onMouseLeave = { () => setIsHovered(false) }>
                    { !userid && <Link className = ' text-sm block text-primary px-1 py-2 uppercase font-madani-arabic' to = "/login">{ t('Login') } / { t('signup') }</Link> }
                    <div className = { `w-96 bg-white p-6 border border-borderColor mt-5 absolute top-4 z-50 left-0 ${selectLanguage == 'ar'?'' : 'headerlogin'} ${isHovered ? 'block duration-150' : 'hidden'}` }>
                      <form onSubmit = { LoginHandler }>
                        <div className = 'flex justify-between pb-2'>
                          <p className = { `text-lg text-black font-semibold self-center ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Signin') }</p>
                          <Link to = '/signup' className = { `text-sm text-primary self-center hover:text-blue ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } > { t('createanaccount') } </Link>
                        </div>
                        <ul>
                          <li>
                            <label className = { `text-sm text-grey-500 lg:text-grey ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Email') }</label>
                            <input className = { `block w-full lg:border border-grey lg:rounded-md lg:px-4 py-2 text-sm ${selectLanguage == 'ar'?'font-madani-arabic': ''} ${errorEmail?'outline-red':'outline-primary-600'}` } onChange = { handleEmail }  onBlur = { handleEmailBlur } value = { email } placeholder = { t('Enteremail') } />
                            { errorEmail && <p className = "text-xs text-red">{ errorEmail }</p> }
                          </li>
                          <li className = 'relative pt-6 lg:pt-4 hidden lg:block'>
                            <label className = { `text-sm text-grey-500 lg:text-grey ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Password') }</label>
                            <input
                              onChange = { handlePasswordChange }
                              value = { password }
                              type = { showPassword ? 'text' : 'password' }
                              className = { `block w-full lg:border border-grey lg:rounded-md lg:px-4 py-2 text-sm outline-primary-600 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }
                              placeholder = { t('Enterpassword') }
                            />
                            { password && <a href = 'javascript:void(0)' className = 'block absolute bottom-2 right-3.5 w-4' onClick = { toggleShowPassword }>{ showPassword? <VisibilityOffOutlinedIcon className = 'eyecolor' /> : <VisibilityOutlinedIcon className = 'eyecolor' /> }</a> }
                          </li>
                          <li><div className = 'text-right py-4'><Link className = { `text-sm text-primary font-bold ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } to = "/forgot-password">{ t('ForgotPassword') }</Link></div></li>
                          <li>
                            <button type = 'submit' className = { `block w-full text-white rounded-3xl lg:rounded-md px-4 py-2.5 text-base ${selectLanguage == 'ar'?'font-madani-arabic': ''} ${isEmailValid && password? 'bg-gradient-primary': 'bg-gradient-primary-light'}` } disabled = { !isFormValid }>
                              { loginisLoading?'Authenticating...' : capitalizeFirstLetter(t('Login')) }
                            </button>
                          </li>
                        </ul>
                      </form>
                    </div>
                  </li>
                  <li className = 'hidden lg:block'>
                    <Link className = ' text-sm block text-primary px-1 py-2 relative' to = "/wishlist">
                      <FavoriteBorderOutlinedIcon />
                      <div className = 'w-4 h-4 rounded-full bg-primary text-white text-center text-xs absolute right-0 top-0'>{ addtowishlistdata?.data?addtowishlistdata?.data?.length: 0 }</div>
                    </Link>
                  </li>
                  <li className = 'block lg:hidden'>
                    <SearchMainMobile />
                  </li>
                  <li>
                    <Link className = ' text-sm block text-primary px-1 py-2 relative' onClick = { toggleCartPopup } to = "#">
                      <LocalMallOutlinedIcon />
                      <div className = 'w-4 h-4 rounded-full bg-primary text-white text-center text-xs absolute right-0 top-0'>{ itemCount }</div>
                    </Link>
                  </li>
                  <li className = 'hidden md:block self-center '>
                    <Multilanguage />
                  </li>
                </ul></div>
              }
            </div>
          </div>
          
        </div>
      </div>
      <div className = { `mobile-menu max-h-screen ${
        isMenuOpen ? 'slide-in' : 'slide-out'
      } transition-transform duration-300 drop-shadow-lg` } ref = { menuRef }>
        <div>
          <p className = 'flex justify-end'><CloseIcon onClick = { toggleMenu } style = { {fontSize: '25px'} } /></p>
          <ul className = 'fixed h-screen w-width90% overflow-x-auto uppercase'>
            <li className = 'mt-5 border-t border-borderColor py-3'><Link className = { `block text-black text-lg ${selectLanguage == 'ar'?'font-madani-arabic': ''}` } to = "/">{ t('Home') }</Link> </li>
            { userid && <li className = { `border-t border-borderColor py-3 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }><Link className = 'block text-black text-lg' to = "/my-account">{ t('Dashboard') }</Link> </li> }
            <li className = 'border-t border-borderColor py-3'>
              <Collapsible
                trigger = {
                  <div className = 'flex'>
                    <div className = 'flex-1 self-center'>
                      <span className = { `block text-black text-lg ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('BRANDS') }</span>
                    </div>
                    <div className = 'self-center'>
                      <span className = "text-grey block w-6 cursor-pointer text-center" onClick = { () => handleToggle(0) }>
                        { expandedIndex === 0 ? (
                          <ExpandLessIcon style = { { fontSize: '24px' } } />
                        ) : (
                          <KeyboardArrowDownIcon style = { { fontSize: '24px' } } />
                        ) }
                      </span>
                    </div>
                    
                  </div>
                }
                open = { expandedIndex === 0 }
                onOpening = { () => setExpandedIndex(true) }
                onClosing = { () => setExpandedIndex(false) }
              >
                
                <MobileBrandshopDropdown />
              </Collapsible>
            </li>
            {
              firstTwoItemsmenu?.map((item) => {
                const itemUrl = item?.url.startsWith('http') ? item.url : `/shop/${item?.url}`;
                return(
                  <li key = { item?.id } className = { `border-t border-borderColor py-3 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>
                    <Link to = { itemUrl } className = 'block text-black text-lg'>{ selectLanguage == 'ar'?item?.name_in_arabic: item?.name_in_english }</Link>
                  </li>
                );
              })
            }
            <li className = 'border-t border-borderColor py-3'>
              
              <Collapsible
                trigger = {
                  <div className = 'flex'>
                    <div className = 'flex-1 self-center'>
                      <span className = { `block text-black text-lg ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('FRAGRANCE') }</span>
                    </div>
                    <div className = 'self-center'>
                      <span className = "text-grey block w-6 cursor-pointer text-center" onClick = { () => handleToggle(1) }>
                        { expandedIndex === 1 ? (
                          <ExpandLessIcon style = { { fontSize: '24px' } } />
                        ) : (
                          <KeyboardArrowDownIcon style = { { fontSize: '24px' } } />
                        ) }
                      </span>
                    </div>
                    
                  </div>
                }
                open = { expandedIndex === 1 }
                onOpening = { () => setExpandedIndex(true) }
                onClosing = { () => setExpandedIndex(false) }
              >
                <MobileShopdropdown />
              </Collapsible>
            </li>
            {
              remainingItemsmenu?.map((item) => {
                const itemUrl = item?.url.startsWith('http') ? item.url : `/shop/${item?.url}`;
                return(
                  <li key = { item?.id } className = { `border-t border-borderColor py-3 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>
                    <Link to = { itemUrl } className = 'block text-black text-lg'>{ selectLanguage == 'ar'?item?.name_in_arabic: item?.name_in_english }</Link>
                  </li>
                );
              })
            }
            <li className = 'border-t border-borderColor py-3'>
              <Collapsible
                trigger = {
                  <div className = 'flex'>
                    <div className = 'flex-1 self-center'>
                      <span className = { `block text-black text-lg ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('GIFTS') }</span>
                    </div>
                    <div className = 'self-center'>
                      <span className = "text-grey block w-6 cursor-pointer text-center" onClick = { () => handleToggle(2) }>
                        { expandedIndex === 2 ? (
                          <ExpandLessIcon style = { { fontSize: '24px' } } />
                        ) : (
                          <KeyboardArrowDownIcon style = { { fontSize: '24px' } } />
                        ) }
                      </span>
                    </div>
                    
                  </div>
                }
                open = { expandedIndex === 2 }
                onOpening = { () => setExpandedIndex(true) }
                onClosing = { () => setExpandedIndex(false) }
              >
                {
                  errorgiftcard && <p className = 'text-sm text-blacklight'>{ errorgiftcard?.data?.message }</p>
                }
                {
                  datagiftcard?.data?.length === 0 && <p className = 'text-sm text-blacklight'>{ datagiftcard?.data?.length === 0?'No gift & card found': '' }</p>
                }
                <ul className = 'capitalize'>
                  {
                    datagiftcard?.data?.map((item) => (
                      <li key = { item?.id }><Link to = { item?.render_url } className = { `text-sm text-black mt-3 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ item?.name }</Link></li>
                    ))
                  }
                
                </ul>
              </Collapsible>
            </li>
            { /* <li className = 'border-t border-borderColor py-3'>
              <Collapsible
                trigger = {
                  <div className = 'flex'>
                    <div className = 'flex-1 self-center'>
                      <span className = { `block text-black text-lg ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('SALE&OFFERS') }</span>
                    </div>
                    <div className = 'self-center'>
                      <span className = "text-grey block w-6 cursor-pointer text-center" onClick = { () => handleToggle(3) }>
                        { expandedIndex === 3 ? (
                          <ExpandLessIcon style = { { fontSize: '24px' } } />
                        ) : (
                          <KeyboardArrowDownIcon style = { { fontSize: '24px' } } />
                        ) }
                      </span>
                    </div>
                    
                  </div>
                }
                open = { expandedIndex === 3 }
                onOpening = { () => setExpandedIndex(true) }
                onClosing = { () => setExpandedIndex(false) }
              >
                {
                  errorsaleoffer && <p className = 'text-sm text-blacklight'>{ errorsaleoffer?.data?.message }</p>
                }
                {
                  datasaleoffer?.data?.length === 0 && <p className = 'text-sm text-blacklight'>{ datasaleoffer?.data?.length === 0?'No sale offer found': '' }</p>
                }
                <ul className = 'capitalize'>
                  {
                    datasaleoffer?.data?.map((item) => (
                      <li key = { item?.id }><Link to = { item?.render_url } className = { `block text-sm text-gray-600 pb-2 hover:text-primary hover:underline ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ item?.name }</Link></li>
                    ))
                  }
                </ul>
              </Collapsible>
            </li> */ }
            <li className = { `border-t border-borderColor py-3 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }><Link className = 'block text-black text-lg' to = "/wishlist">{ t('Wishlist') }</Link> </li>
            {
              !userid? <><li className = { `border-t border-borderColor py-3 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }><Link className = 'block text-black text-lg' to = "/signup">{ t('signup') }</Link> </li>
                <li className = { `border-t border-borderColor py-3 ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }><Link className = 'block text-black text-lg' to = "/login">{ t('Login') }</Link> </li></>:<li className = 'border-t border-borderColor py-3 uppercase'><Link onClick = { () => handleLogout() } className = { `block text-black text-lg ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ t('Logout') }</Link> </li>
            }
            <li className = 'border-t border-borderColor pt-3 pb-20'>
              <Multilanguage />
            </li>
          </ul>
        </div>
      </div>
      <ShopingCardPopup setCartOpen = { setCartOpen } isCartOpen = { isCartOpen }/>
    </>
  );
};

export default Header;