import React from 'react';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useCategorylistQuery, useScentfamilylistQuery } from '../redux/api/recomendedproductApi';


const MobileShopdropdown = () => {
  const selectLanguage = localStorage.getItem('selectLanguage');
  const { error, data } = useCategorylistQuery();

  const { error: errorscentfamily, data: datascentfamily } = useScentfamilylistQuery();

  const scentfamily = datascentfamily?.data || [];

  return(
    <>
      <div>
        <ul className = 'capitalize'>
          <li>
            <h3 className = 'text-base font-medium text-blacklight'>Category</h3>
          </li>
          {
            error && <p className = 'text-sm text-red'>{ error?.data?.message }</p>
          }
          { data?.data?.map((item) => (
            <li key = { item?.item_id }>
              <Link to = { `/shop/${item?.name_en}` } className = { `text-sm text-black mt-3 ${selectLanguage == 'ar'?'font-madani-arabic': 'font-madani-arabic'}` }>
                { selectLanguage == 'ar'?item?.name_ar: item?.name_en }
              </Link>
            </li>
          )) }
        </ul>

        <ul className = 'capitalize mt-3'>
          <li>
            <h3 className = 'text-base font-medium text-blacklight'>Scent family</h3>
          </li>
          {
            errorscentfamily && <p className = 'text-sm text-red'>{ errorscentfamily?.data?.message }</p>
          }
          { scentfamily?.map((item) => (
            <li key = { item?.item_id }>
              <Link to = { `/shop/${item?.name_en}` } className = { `text-sm text-black mt-3 ${selectLanguage == 'ar'?'font-madani-arabic': 'font-madani-arabic'}` }>
                { selectLanguage == 'ar'?item?.name_ar: item?.name_en }
              </Link>
            </li>
          )) }
        </ul>
      </div>
    </>
  );
};

export default MobileShopdropdown;