/* eslint-disable no-unused-vars */

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PaymentFailed = () => {
  const { t } = useTranslation();
  const selectLanguage = localStorage.getItem('selectLanguage');

  // const [clearcart,{error: errorclearcart }] = useClearcartMutation();

  // useEffect(() => {
  //   if (errorclearcart) {
  //     console.log(errorclearcart?.data?.message);
  //   }
  // }, [errorclearcart]);





  // useEffect(() => {

  //   if(isSuccess){

  //     clearcart();
  //   }

  // },[isSuccess]);

  return (
    <div className = "flex justify-center items-center h-screen bg-gray-100">
      <div className = "bg-white m-4 p-4 md:p-8 rounded-lg shadow-lg text-center max-w-md">
        <div className = "flex justify-center items-center mb-6">
          <div className = "bg-red text-white p-4 rounded-full relative">
            <ClearOutlinedIcon style = { {fontSize: 50} } />
            <div className = "absolute inset-0 flex items-center justify-center">
              <div className = "w-20 h-20 rounded-full bg-red animate-ping"></div>
            </div>
          </div>
        </div>
        <h2 className = { `text-2xl text-red font-semibold mb-2 ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }>Payment Failed</h2>
        <p className = "text-gray-600 mb-6">
        We were unable to process your payment. Please check your payment details or try again. If the issue persists, contact customer support.
        </p>
        <div className = "flex justify-center space-x-4">
          <Link to = '/my-account/order' className = { `px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }>
            { t('ViewOrder') }
          </Link>
          <Link to = "/" className = { `px-4 py-2 bg-primary-500 text-white rounded hover:bg-primary ${selectLanguage === 'ar' ? 'font-madani-arabic' : ''}` }>
            { t('ContinueShopping') }
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;
