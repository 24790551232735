import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import TopHeader from '../components/TopHeader';

const PasswordResetLink = () => {

  return(
    <>
      <TopHeader />
      <Header />
      <Navigation />
      <div className = 'bg-black h-24 lg:h-36 flex items-center justify-center mb-8'>
        <h3 className = 'text-white text-xl lg:text-2xl text-center font-semibold uppercase'>My Account</h3>
      </div>
      <div className = 'container mx-auto px-5 lg:px-10'>
        <div className = 'min-h-96'>
          <div className = 'bg-[#459647] p-5'>
            <p className = 'text-white text-sm'><span><DoneOutlinedIcon /></span><span className = 'inline-block ml-0 md:ml-5'>Password reset email has been sent.</span></p>
          </div>
          <p className = 'text-sm text-grey mt-5'>A password reset email has been sent to the email address on file for your account, but may take several minutes to show up in your inbox. Please wait at least 10 minutes before attempting another reset.</p>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PasswordResetLink;