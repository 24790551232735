import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../config/index';

const getToken = () => localStorage.getItem('token');

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    orderlist: builder.query({
      query: () => '/orders/all'
    }),
    shippingcompanylist: builder.query({
      query: () => '/orders/admin/get-shipping-companies/'
    }),
    calculateshippingcost: builder.mutation({
      query(body) {
        return {
          url: '/orders/calculate-shipping-cost',
          method: 'POST',
          body
        };
      }
    })
  })
});

export const {useCalculateshippingcostMutation, useOrderlistQuery, useShippingcompanylistQuery } = orderApi;
