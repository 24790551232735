import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cartItems: []
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItemToCart: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.cartItems.find(
        item => item.id === newItem.id && item.options.id === newItem.options.id
      );

      if (!existingItem) {
        state.cartItems.push({ ...newItem, quantity: 1, total_price: newItem.price });
      } else {
        existingItem.quantity += 1;
        existingItem.total_price = existingItem.quantity * existingItem.price;
      }
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
    removeItemFromCart: (state, action) => {
      const { id, options } = action.payload;
      state.cartItems = state.cartItems.filter(
        item => !(item.id === id && item.options.id === options.id)
      );
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
    decreaseItemQuantity: (state, action) => {
      const { id, options } = action.payload;
      const existingItem = state.cartItems.find(
        item => item.id === id && item.options.id === options.id
      );

      if (existingItem) {
        if (existingItem.quantity === 1) {
          state.cartItems = state.cartItems.filter(
            item => !(item.id === id && item.options.id === options.id)
          );
        } else {
          existingItem.quantity -= 1;
          existingItem.total_price = existingItem.quantity * existingItem.price;
        }
      }
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
    loadCartFromLocalStorage: (state) => {
      const cartItems = localStorage.getItem('cartItems');
      if (cartItems) {
        state.cartItems = JSON.parse(cartItems);
      }
    }
  }
});

export const { addItemToCart, removeItemFromCart, decreaseItemQuantity, loadCartFromLocalStorage } = cartSlice.actions;

export const selectCartItems = state => state.cart.cartItems;

export default cartSlice.reducer;