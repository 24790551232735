/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { useGiftcardlistQuery } from '../redux/api/recomendedproductApi';

const GiftsandcardsDropdown = ({isHoveredgift}) => {

  const selectLanguage = localStorage.getItem('selectLanguage');

  const {error, data} = useGiftcardlistQuery();

  return(
    <>
      <div className = { `bg-white border-t border-boderColor-300 absolute z-10 left-0 top-30 w-full ${isHoveredgift ? 'block duration-150' : 'hidden'}` }>
        <div className = 'container mx-auto px-5 lg:px-10'>
          <div className = 'flex justify-between py-5'>
            <div className = 'w-width48%'>
              {
                error && <p className = 'text-sm text-blacklight'>{ error?.data?.message }</p>
              }
              {
                data?.data?.length === 0 && <p className = 'text-sm text-blacklight'>{ data?.data?.length === 0?'No gift & card found': '' }</p>
              }
              <ul className = 'capitalize'>
                {
                  data?.data?.map((item) => (
                    <li key = { item?.id }><Link to = { item?.render_url } className = { `inline-block text-sm text-gray-600 mt-2 duration-200 hover:text-primary hover:underline ${selectLanguage == 'ar'?'font-madani-arabic': ''}` }>{ item?.name }</Link></li>
                  ))
                }
                
              </ul>
            </div>

            <div className = 'w-width48%'>
              <img className = 'rounded' src = { data?.data?.[0]?.image_url } />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default GiftsandcardsDropdown;