/* eslint-disable react/prop-types */
import React from 'react';


const ProductTabDescription = ({data}) => {
  const selectLanguage = localStorage.getItem('selectLanguage');
  return(
    <>
      <div>
        <div className = { `discriptionlist text-sm ${selectLanguage == 'ar'?'font-Tajawal text-right': 'font-Tajawal'}` } dangerouslySetInnerHTML = { { __html: data?.description?data?.description:'No description available' } } />
      </div>
    </>
  );
};

export default ProductTabDescription;